import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./style.scss";
import { Toaster } from "../../utils/toaster";
import FormatUrl from "../../utils/UrlFormatter";
import axios from "axios";
import Button from "../common/Button";

const CreateUser = ({ handleModal, getUsers }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);

  const onboardUser = (user_id, email) => {
    let url = FormatUrl(`/email/onboarding`);
    axios
      .post(url, {
        user_id: user_id,
        email: email,
      })
      .then(function (response, error) {
        let data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const createUser = async (values) => {
    let url = FormatUrl(`/user/register`);

    const customerRef = localStorage.getItem("customerRef");
    const tenantRef = localStorage.getItem("tenantRef");
    const teamRef = localStorage.getItem("teamRef");

    axios
      .post(url, {
        username: values.username.trim(),
        first_name: "",
        last_name: "",
        email: values.email.trim(),
        phone: "",
        customerRef: customerRef,
        tenantRef: tenantRef,
        teamRef: teamRef,
        role: values.role,
      })
      .then((res) => {
        onboardUser(res.data.id, values.email);
        getUsers();
        handleModal(false);
      })
      .catch(function (error) {
        if (error.response) {
          Toaster(
            2,
            error.response?.data?.err?.message || "Failed to invite user"
          );
        } else {
          Toaster(2, "Failed to invite user");
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      role: "OPERATOR",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Please enter email."),
      role: Yup.string().required("Please enter role."),
    }),
    onSubmit: (values) => {
      createUser({ ...values });
    },
  });

  return (
    <div id="user-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <span className="header-title">{"Invite User"}</span>
              </div>
              <div className="modal-body">
                {showLoader && (
                  <img
                    src="static/img/loader-circle.svg"
                    className="modal-loading-img"
                  />
                )}

                <label className="label">
                  Email <span className="mandatory-icon">*</span>
                </label>

                <div className="row mt-2">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="host"
                      placeholder="Email"
                      name="host"
                      maxLength={50}
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                      }}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <div className="error-message">{formik.errors.email}</div>
                    )}
                  </div>
                </div>

                <label className="label">
                  Role <span className="mandatory-icon">*</span>
                </label>
                <div className="row mt-2">
                  <div className="col col-lg-12">
                    <select
                      type="text"
                      id="host"
                      placeholder="Role"
                      name="host"
                      maxLength={50}
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("role", e.target.value);
                      }}
                      value={formik.values.role}
                      onBlur={formik.handleBlur}
                    >
                      <option value={"ADMIN"}>ADMIN</option>
                      <option value={"OPERATOR"}>OPERATOR</option>
                    </select>
                    {formik.errors.role && formik.touched.role && (
                      <div className="error-message">{formik.errors.role}</div>
                    )}
                  </div>
                </div>

                {connectionFailed && (
                  <div className="row mt-2">
                    <div className="col-9 offset-md-3 pl-0 text-danger">
                      Connection failed, please check the details and try again.
                    </div>
                  </div>
                )}

                <div className="mt-3">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    Invite user
                  </Button>

                  <span className="separator mx-3"></span>

                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => handleModal(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
