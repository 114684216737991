import { useTheme } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { isDataWithinDefinedDate } from "../../utils/commonUtils";
import Box from "../common/Box";
import LineChart from "../common/LineChart";

interface Props {
  graphData: any[];
  svgHeight?: number;
  ticks?: number;
}

const PromptsTrendsGraph = ({ graphData, svgHeight, ticks }: Props) => {
  const theme = useTheme();
  const { sensitiveDataFilter } = useContext(AppContext);
  const [promptsData, setPromptsData] = useState(graphData);

  const categories = [
    {
      name: "sensitivePrompts",
      label: "Prompts",
      color: theme.palette.peach.main,
    },
    {
      name: "vectorDB",
      label: "Context",
      color: theme.palette.laurelGreen.main,
    },
    {
      name: "sensitiveResponses",
      label: "Responses",
      color: theme.palette.paleBlue.main,
    },
  ];

  useEffect(() => {
    const filteredData = graphData.filter((data) =>
      isDataWithinDefinedDate(
        sensitiveDataFilter,
        moment(data?.date, "YYYY-MM-DD").toDate()
      )
    );

    setPromptsData(filteredData || []);
  }, [sensitiveDataFilter, graphData]);

  return (
    <>
      <LineChart
        tooltipMargin={30}
        lineData={promptsData}
        categories={categories}
        classNameForGraph="lineChartForPrompts"
        svgHeight={svgHeight}
        ticks={ticks}
        svgWidth={350}
      />
    </>
  );
};

export default PromptsTrendsGraph;
