import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { getCustomerId } from "../../../../utils/SessionHelper";
import { getDataStoresStatsApi } from "../../../../apis/index.js";
import { getV1DatastoreIcon } from "../../../../utils/styles";
am4core.addLicense("ch-custom-attribution");

const PieChart = ({ selectedAccount }) => {
  const [datastoreStats, setDatastoreStats] = useState([]);
  const colors = ["#062DFF", "#AC5AFF", "#627BFF", "#C68EFF"];

  const dummyData = [
    {
      datastoreType: "Dummy",
      disabled: true,
      count: 100,
      color: am4core.color("#ffffff14"),
      opacity: 0.3,
      strokeDasharray: "4,4",
      tooltip: "",
    },
  ];
  const getDatastoreStats = async (selectedAccount) => {
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let resp = await getDataStoresStatsApi(payload);
    // if (resp && resp.payload && !resp.payload.err)
    if (resp && resp.datastores) {
      let respDatastores = resp.datastores.types;
      let updatedDatastores = [];

      respDatastores.map((datastore) => {
        datastore.src = getV1DatastoreIcon(datastore.datastoreType);
        updatedDatastores.push(datastore);
      });

      setDatastoreStats(updatedDatastores);

      const stats = resp.datastores.types;
      const updatedStats = [];
      let index = 0;
      stats.map((statObj) => {
        statObj.color = colors[index];
        index = index + 1;
        updatedStats.push(statObj);
      });
    }
  };

  const loadChart = (barId = null) => {
    // Create chart instance

    var chart = am4core.create("pie-chart", am4charts.PieChart);
    chart.paddingTop = 20;
    chart.paddingBottom = 20;
    chart.paddingLeft = 20;
    chart.paddingRight = 20;
    // Add data

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "count";
    pieSeries.dataFields.category = "datastoreType";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.tooltipHTML = "<b>{datastoreType}</b> ({count})";
    pieSeries.labels.template.adapter.add("html", function (disabled, target) {
      if (target.dataItem?.dataContext.count > 0) {
        return `<img width="20px" height="20px" src="{src}" />`;
      }
    });

    pieSeries.labels.template.adapter.add(
      "disabled",
      function (disabled, target) {
        if (target.dataItem?.dataContext.count === 0) {
          return true;
        }
      }
    );

    pieSeries.labels.template.html = `<img width="20px" height="20px" src="{src}" />`;
    pieSeries.labels.template.radius = am4core.percent(-15);
    pieSeries.labels.template.fill = am4core.color("white");
    pieSeries.alignLabels = false;

    // // Disable ticks and labels
    // pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Set up slices

    // Disable tooltips
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    chart.innerRadius = am4core.percent(70);
    chart.radius = am4core.percent(100);
    if (datastoreStats.length > 0) {
      chart.data = datastoreStats;
    } else {
      chart.data = dummyData;
      pieSeries.slices.template.tooltipText = "";
    }
  };

  useEffect(() => {
    getDatastoreStats(selectedAccount);
  }, []);

  useEffect(() => {
    loadChart();
  }, [datastoreStats]);

  return (
    <div>
      <div id="pie-chart" style={{ height: "275px", width: "100%" }}></div>
    </div>
  );
};

export default PieChart;
