import { getValueInBetween } from "./styles";
import moment from "moment";

export const removeEmpty = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export const matchObject = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const addIndex = (data) => {
  let index = 0;
  const res = [];
  for (let i of data) {
    index += 1;
    let keys = Object.keys(i);
    let obj = {};
    for (let key of keys) {
      obj[key] = i[key];
    }
    obj["rank"] = index;
    res.push(obj);
  }
  return res;
};

export const colorRiskData = (data) => {
  for (let i of data) {
    const x = i.x_directriskscore;
    const y = i.y_indirectscore;
    if (getValueInBetween(x, 0, 50) && getValueInBetween(y, 0, 50)) {
      i.color = "#3260E5";
      i.border = "#3260E5";
    } else if (getValueInBetween(x, 50, 100) && getValueInBetween(y, 0, 50)) {
      i.color = "#e0a44c80";
      i.border = "#e0a44c";
    } else if (getValueInBetween(x, 0, 50) && getValueInBetween(y, 50, 100)) {
      i.color = "#DC6E30";
      i.border = "#DC6E30";
    } else {
      i.color = "#E32B2A";
      i.border = "#E32B2A";
    }
    i.circle = 2;
  }

  return data;
};

export const createRank = (num) => {
  const array = Array.from(Array(num).keys());
};

export const dateFormatter = (date) => {
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
};
