import { useTheme } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";
import MUIButton from "@mui/material/Button";

const Button = (props: ButtonProps) => {
  const theme = useTheme();
  const styles = {
    muiButton: {
      "&.MuiButton-root": {
        textTransform: "none",
      },
      "&.MuiButton-contained": {
        backgroundColor: theme.palette.primaryBlue.main,
        color: theme.palette.surface100.main,
      },
      "&.MuiButton-contained:hover": {
        backgroundColor: "#0074E0",
      },
      "&.MuiButton-outlined": {
        border: `1px solid ${theme.palette.primaryBlue.main}`,
        color: theme.palette.primaryBlue.main,
      },
      "&.MuiButton-outlined:hover": {
        backgroundColor: "rgba(33, 149, 255, 0.1)",
        color: theme.palette.primaryBlue.main,
      },
    },
  };
  return <MUIButton sx={styles.muiButton} {...props} />;
};

export default Button;
