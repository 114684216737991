import { useTheme } from '@mui/material';
import type { SliderProps } from '@mui/material/Slider';
import MUISlider from '@mui/material/Slider';

const Slider = (props: SliderProps) => {
  const theme = useTheme();

  return (
    <MUISlider
      {...props}
      sx={{
        '& .MuiSlider-rail': {
          color: theme.palette.surface30.main,
          opacity: '30%',
          height: '4px'
        },
        '& .MuiSlider-track': {
          color: theme.palette.primaryBlue.main
        },
        '& .MuiSlider-thumb': {
          backgroundColor: theme.palette.primaryBlue.main,
          height: '12px',
          width: '12px',
          boxShadow: 'none'
        },
        '& .MuiSlider-mark': {
          backgroundColor: theme.palette.surface30.main
        },
        '& .MuiSlider-markLabel': {
          color: theme.palette.surface30.main,
          fontSize: '11px',
          '&[data-index="0"]': {
            transform: 'none'
          },
          '&[data-index="5"]': {
            right: '-20px'
          }
        },
        '& .MuiSlider-markLabelActive': {
          fontSize: '11px',
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.surface80.main
        }
      }}
    />
  );
};

export default Slider;
