import React, { useEffect, useState } from "react";
import PDFDOC from "../../assets/CD-Wiz-UserGuide.pdf";
import "./style.scss";

const Doc = ({}) => {
  return (
    <div className="doc-container">
      <div className="doc pb-2">
        <h3 className="mb-5 font-weight-bold">Wiz Integration</h3>

        <h4 className="mt-5 mb-3">1. Overview</h4>
        <p>
          Wiz and Cloud Defense will share prioritized security findings with
          the data context to enable our customers to better prioritize security
          efforts and reduce the burden of audits and remediations. With a
          combined Cloud Defense and Wiz solution, there will only be a single
          IT ticket. Once the ticket is generated by either product, Cloud
          Defense adds additional security findings to the ticket in the form of
          sensitive data value, an attack path and much more. These insights
          play a critical role in justifying the security efforts needed by
          DevSecOps, cloud engineering and data teams to resolve the ticket.
        </p>
        <p>
          This document describes the steps to prepare the Wiz service account
          and enable Wiz integration in Cloud Defense dashboard.
        </p>

        <h4 className="mt-5 mb-3">2. Before you begin</h4>
        <p>
          Make sure you have the following information from your Wiz account:
        </p>
        <ul>
          <li>
            1. Wiz Service Account with following permissions:{" "}
            <span className="monospace ml-2">
              create:reports, read:reports and read:vulnerabilities
            </span>
          </li>
          <li>
            2. Client ID and Client Secret from the Wiz Management Console
          </li>
          <li>
            3. API Endpoint URL under <b>Profile &gt; User Settings</b>. It will
            be in a format similar to{" "}
            <a href="https://api.region.app.wiz.io/graphql">
              https://api.&lt;region&gt;.app.wiz.io/graphql
            </a>
          </li>
          <li>
            4. Authentication Endpoint URL which is either the Amazon Cognito or
            Auth0 (legacy) endpoint depending on your service account’s identity
            provider.
          </li>
        </ul>

        <h4 className="mt-5 mb-3">
          3. Configure Wiz integration in Cloud Defense
        </h4>
        <p>
          To configure Wiz integration in Cloud Defense, log into Cloud Defense
          dashboard and complete the following steps:
        </p>
        <ul>
          <li>
            ● Navigate to <b>Integrations</b> page from the left menu.
          </li>
          <li>
            ● Click the Wiz logo to add Wiz integration.
            <ul>
              <li>
                ○ Enter the{" "}
                <b>Authentication Endpoint URL, API Endpoint URL, Client ID </b>{" "}
                and <b>Client Secret </b>values prepared in the previous step
              </li>
              <li>
                ○ Click <b>Submit</b> to enable the integration
              </li>
            </ul>
          </li>
        </ul>

        <p>To view the status of Wiz integration in Cloud Defense:</p>

        <ul>
          <li>
            ● Navigate to <b>Integration</b> page from the left menu.
          </li>
          <li>
            ● Click <b>View</b> icon on the Wiz tile to see the status, last
            synced date and time, and the number of vulnerabilities synced.
          </li>
        </ul>

        <p>To delete the Wiz integration in Cloud Defense:</p>
        <ul>
          <li>
            ● Navigate to <b>Integrations</b> page from the left menu.
          </li>
          <li>
            ● Click <b>Delete</b> icon on the Wiz tile to confirm an delete the
            integration.
          </li>
        </ul>

        <h4 className="mt-5 mb-3">
          4. Wiz findings in Cloud Defense dashboard{" "}
        </h4>

        <p>
          To locate the vulnerabilities from Wiz in Cloud Defense dashboard:
        </p>
        <ul className="mb-5">
          <li>
            ● Navigate to <b>Issues</b> page from the left menu.
          </li>
          <li>
            ● Enter the string Wiz in the <b>Search</b> text field to filter the
            issues retrieved from Wiz
          </li>
          <li>
            ● Click one or more of the Wiz Issues to understand how it affects
            security of sensitive datastores mapped out by Cloud Defense.
          </li>
          <li>
            ● Further drill down the details on specific security issue by
            clicking the link back to the Wiz portal.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Doc;
