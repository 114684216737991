import React, { useState } from "react";
import Popup from "reactjs-popup";
import "./DatastoreAttackPathGraph.scss";
import hackerIcon from "../../../assets/img/icons/hacker-icon.svg";
import kubernetesIcon from "../../../assets/img/icons/google-kubernetes.png";
import { getScoreColor } from "../../../utils/styles";
const CustomNodeComponent = (node) => {
  const [selected, setSelected] = useState(false);
  const getIcon = () => {
    return (
      <img
        src={node?.node.data?.image?.src}
        height={node?.node.data?.image?.height}
        width={node?.node.data?.image?.width}
        id={node?.node?.id}
        className={`node-image ${getNodeScoreColorClass(node?.node)}`}
        alt=""
        style={{
          display: "block",
          margin: node?.node.data?.image?.margin,
        }}
        onClick={(e) => {
          setSelected(e.target.id === node?.node?.id);
          node?.node.data.handleClick
            ? node?.node.data.handleClick(node?.node)
            : console.log("node?.node clicked");
        }}
      />
    );
  };

  const getNodeScoreColorClass = (node) => {
    return node?.is_primary ? `node-${getScoreColor(node?.score || 0)}` : "";
  };

  const getTooltip = () => {
    if (
      node?.node.data.tooltipContents &&
      node?.node.data.tooltipContents.length > 0
    ) {
      let popupContents = [];
      for (
        let index = 0;
        index < node?.node.data.tooltipContents.length;
        index++
      ) {
        let dataContent = node?.node.data.tooltipContents[index];
        popupContents.push(
          <div className="card" key={index}>
            <div class="card-header">{dataContent.name}</div>
            <div
              className="card-body"
              dangerouslySetInnerHTML={{ __html: dataContent.content }}
            ></div>
          </div>
        );
      }
      return (
        <div className="tooltip-style">
          <p className="header m-0">{node?.node.data.heading}</p>
          <div className="tooltip-content ">{popupContents}</div>
        </div>
      );
    } else {
      return <div className="p-2">{node?.node?.data.heading}</div>;
    }
  };

  return (
    <div
      style={{
        height: 64,
        width: 64,
      }}
      title={node?.node?.name}
    >
      {/* {node?.node.data?.tooltipRequired ? (
        <Popup
          trigger={
            <div>{node?.node.iconName !== "" && getIcon(node?.node)}</div>
          }
          on={["hover", "focus"]}
          position="top center"
        >
          {getTooltip()}
        </Popup>
      ) : ( */}
      <div>
        {node?.node?.iconName !== "" && getIcon(node?.node)}
        {node?.node?.active_attack && (
          <img
            src={hackerIcon}
            className={`${
              node?.node.data.is_primary
                ? "attack-image-primary"
                : "attack-image"
            }`}
          />
        )}
        {node?.node?.eks_enabled && (
          <img
            src={kubernetesIcon}
            className={`${
              node?.node.data.is_primary ? "eks-image-primary" : "eks-image"
            }`}
          />
        )}
      </div>
      {/* )} */}
      <div
        style={{
          whiteSpace: "nowrap",
          fontSize: 10,
          textAlign: "center",
          color: "#6c83bb",
          textOverflow: "ellipsis",
        }}
        className="mt-1"
        title={node?.node?.name}
      >
        {/* {node?.node.name} */}
        {node?.node?.name?.length > 17
          ? node.node.name.substring(0, 17) + "..."
          : node.node.name}
      </div>
    </div>
  );
};

export default CustomNodeComponent;
