import { useTheme } from "@mui/material";
import { RegionsTooltip } from "../../types/type";
import { getNumberAbbreviation } from "../../utils/commonUtils";
import Box from "./Box";
import Divider from "./Divider";
import Typography from "./Typography";
import gcpIcon from "../../assets/img/gcp.svg";
import awsIcon from "../../assets/img/awsIcon.svg";

export interface Props {
  region: string;
  recordsTitle: string;
  recordList: RegionsTooltip;
  tooltipCoordinates: { x: number; y: number };
  isTooltipVisible?: boolean;
  dataView: boolean;
  isFrameworkView: boolean;
}

const WorldMapTooltip = ({
  region,
  recordsTitle,
  recordList,
  tooltipCoordinates,
  isTooltipVisible,
  dataView,
  isFrameworkView,
}: Props) => {
  const impactedCount = recordList?.impacted;

  const showMoreText =
    recordList?.data?.length > 5 ? recordList?.data?.length - 5 : 0;

  const theme = useTheme();

  const styles = {
    parentContainer: {
      position: "fixed",
      top:
        tooltipCoordinates?.y -
          (recordList?.data?.length > 0 ? 260 : 130) +
          "px" || 0,
      left: tooltipCoordinates?.x - 105 + "px" || 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface80.main,
      width: "210px",
      borderRadius: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      zIndex: "50",
    },
    flexColContainer: {
      display: "flex",
      flexDirection: "column",
    },
    gapProperty: {
      gap: theme.spacing(0.5),
    },
    impactedTitle: {
      fontFamily: "Manrope",
      fontSize: "18px",
    },
    fontMedium: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    recordRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    recordContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    subSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    tooltipHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
  };
  return (
    <Box sx={styles.parentContainer} className="worldMapTooltip">
      <Box sx={{ ...styles.flexColContainer, ...styles.gapProperty }}>
        <Box sx={styles.tooltipHeader}>
          <Typography
            variant="tooltip"
            color={theme.palette.surface40.main}
            sx={styles.fontMedium}
          >
            {region}
          </Typography>
          <img
            src={
              !recordList?.cloud || recordList?.cloud === "aws"
                ? awsIcon
                : gcpIcon
            }
            alt="cloud_icon"
            width={24}
          />
        </Box>
        <Box sx={styles.flexColContainer}>
          <Typography
            component="span"
            color={theme.palette.surface0.main}
            sx={styles.impactedTitle}
          >
            {impactedCount}
          </Typography>
          <Typography
            component="span"
            color={theme.palette.surface0.main}
            variant="caption"
          >
            {dataView
              ? "Data Stores Impacted"
              : !isFrameworkView
              ? "LLMs Impacted"
              : "Frameworks Impacted"}
          </Typography>
        </Box>
      </Box>
      {recordList?.data?.length > 0 && (
        <>
          <Divider variant="fullWidth" color={theme.palette.surface70.main} />
          <Box sx={styles.subSection}>
            <Typography
              component="span"
              variant="tooltip"
              color={theme.palette.surface40.main}
              sx={styles.fontMedium}
            >
              {recordsTitle}
            </Typography>
            <Box sx={styles.recordContainer}>
              {recordList?.data
                ?.slice(0, 5)
                ?.map(
                  ({
                    dataType,
                    records,
                  }: {
                    dataType: string;
                    records: number;
                  }) => (
                    <Box sx={styles.recordRow} key={dataType}>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface0.main}
                      >
                        {dataType}
                      </Typography>
                      <Typography
                        variant="caption"
                        color={theme.palette.critical.main}
                      >
                        {getNumberAbbreviation(records)}
                      </Typography>
                    </Box>
                  )
                )}
              {showMoreText > 0 && (
                <Typography
                  variant="caption"
                  color={theme.palette.surface0.main}
                  sx={styles.fontMedium}
                  align="right"
                >
                  + {showMoreText} more
                </Typography>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default WorldMapTooltip;
