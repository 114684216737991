import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
const CustomTooltip = ({
  component,
  tooltipTitle,
  className = "title-popup",
}) => {
  return (
    <Popup
      trigger={(open) => component}
      position="right top"
      closeOnDocumentClick
      className={className}
      arrow={false}
      on={["hover", "focus"]}
    >
      <div className="title-popup-body p-1 text-white">{tooltipTitle}</div>
    </Popup>
  );
};

export default CustomTooltip;
