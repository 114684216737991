import "./DataPolicyViolation.scss";
import React, { useEffect, useState } from "react";
import DatabaseIcon from "../../../../assets/img/v1-icons/database.svg";
import Location from "../../../../assets/img/v1-icons/location.svg";
import Arrow from "../../../../assets/img/v1-icons/arrow.svg";
import Logging from "../../../../assets/img/v1-icons/logging.svg";
import DataAccess from "../../../../assets/img/v1-icons/data-access.svg";
import Lock from "../../../../assets/img/v1-icons/lock.svg";
import Alert from "../../../../assets/img/v1-icons/alert.svg";
import RightArrow from "../../../../assets/img/v1-icons/right_arrow.svg";
import CPU from "../../../../assets/img/v1-icons/cpu.svg";
import { useHistory } from "react-router-dom";
import LeftArrow from "../../../../assets/img/v1-icons/left-arrow.svg";
import Llm from "../../../../assets/img/v1-icons/llm.svg";
import DataLeak from "../../../../assets/img/v1-icons/data-leakage.svg";

import {
  fetchPolicyViolationFiltersSlice,
  fetchPolicyViolationsSlice,
} from "../../../../redux/slices/dashboardSlice";

import DBPlain from "../../../../assets/img/v1-icons/database-plain.svg";
import { useDispatch } from "react-redux";
import { getCustomerId } from "../../../../utils/SessionHelper";

const DataPolicyViolation = ({ selectedAccount }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [policyViolationFilters, setPolicyViolationFilters] = useState([]);
  const [updatedPolicyViolationFilters, setUpdatedPolicyViolationFilters] =
    useState();
  const [filterIndex, setFilterIndex] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [policyViolations, setPolicyViolations] = useState([]);

  const getPolicyViolationFilters = async () => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let resp = await dispatch(fetchPolicyViolationFiltersSlice(payload));
    if (resp && resp.payload && !resp.payload.err) {
      setPolicyViolationFilters(resp.payload);
      let filters = resp.payload;
      let updatedFilters = filters.slice(0, 3);
      setUpdatedPolicyViolationFilters([...updatedFilters]);
    }
  };

  const getPolicyViolations = async () => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
      filters: selectedFilter,
    };
    let resp = await dispatch(fetchPolicyViolationsSlice(payload));
    if (resp && resp.payload && !resp.payload.err) {
      setPolicyViolations(resp.payload);
    }
  };

  const getIconByPolicyFilter = (type) => {
    if (type === "encryption") {
      return Lock;
    }
    if (type === "public-access") {
      return Arrow;
    }
    if (type === "data-residency") {
      return Location;
    }
    if (type === "data-access") {
      return DataAccess;
    }
    if (type === "logging") {
      return Logging;
    }
    if (type === "llm") {
      return Llm;
    }
    if (type === "data-leakage") {
      return DataLeak;
    }
  };

  const updatePolicyFilter = (type) => {
    if (
      policyViolationFilters.length > 3 &&
      policyViolationFilters.length - filterIndex >= 3
    ) {
      let updatedFilters = [];
      for (let index = filterIndex; index < 3 + filterIndex; index++) {
        updatedFilters.push(policyViolationFilters[index]);
      }

      setUpdatedPolicyViolationFilters([...updatedFilters]);
    }
  };

  const getTotalViolatingPolicies = () => {
    let total = 0;
    if (policyViolations.length > 0) {
      policyViolations.map((issue) => {
        total = total + issue.dsCount;
      });
    }
    return total;
  };

  const viewIssues = (data) => {
    history.push("/vulnerabilities/policy-violation/" + data.id);
  };

  const viewDatastores = (data) => {
    if (!data.actionType) {
      data.actionType = "ticket/accept";
    }
    sessionStorage.setItem("policy", JSON.stringify(data));
    history.push("/datastore/policy-violation/" + data.id);
  };

  useEffect(() => {
    getPolicyViolationFilters();
  }, []);

  useEffect(() => {
    getPolicyViolations();
  }, [selectedFilter]);

  useEffect(() => {
    updatePolicyFilter();
  }, [filterIndex]);

  return (
    <div id="data-policy-violation">
      <h3 className="title mb-3">
        Data Policy Violation ({getTotalViolatingPolicies()})
      </h3>
      <ul className="nav nav-pills mb-1 d-flex" id="pills-tab" role="tablist">
        <li className="nav-item nav-item-all">
          <a
            className={`nav-link ${selectedFilter === "" ? "active" : ""}`}
            id="filter-tab"
            data-toggle="pill"
            href="#filter-types"
            role="tab"
            aria-selected="false"
            onClick={() => {
              setSelectedFilter("");
            }}
          >
            All{" "}
          </a>
        </li>
        {updatedPolicyViolationFilters &&
          updatedPolicyViolationFilters.map((filter) => {
            return (
              <li className="nav-item nav-filters">
                <a
                  className={`nav-link ${
                    selectedFilter === filter.type ? "active" : ""
                  }`}
                  id="filter-types"
                  data-toggle="pill"
                  href="#filter-types-tab"
                  role="tab"
                  aria-controls="filter-types"
                  aria-selected="false"
                  onClick={() => {
                    setSelectedFilter(filter?.type);
                  }}
                >
                  <img
                    height="18px"
                    alt="icon"
                    className="filter-icon pl-3 pr-2"
                    src={getIconByPolicyFilter(filter?.type)}
                  />

                  {filter.name}
                </a>
              </li>
            );
          })}

        <div className="p-1 d-flex align-items-center nav-actions">
          {/* {filterIndex > 0 && ( */}
          <img
            className={`${
              filterIndex <= 0 ? "filter-icon-disabled" : ""
            } pl-3 pr-1`}
            alt="right-arrow"
            title="Previous"
            onClick={() => {
              if (filterIndex > 0) {
                setFilterIndex(filterIndex - 1);
              }
            }}
            src={LeftArrow}
          />
          {/* )} */}

          {/* {policyViolationFilters.length - filterIndex > 3 && ( */}
          <img
            alt="left-arrow"
            className={`${
              policyViolationFilters.length - filterIndex > 3
                ? ""
                : "filter-icon-disabled"
            }  pl-1 pr-2`}
            onClick={() => {
              if (policyViolationFilters.length - filterIndex > 3) {
                setFilterIndex(filterIndex + 1);
              }
            }}
            src={RightArrow}
            title="Next"
          />
          {/* )} */}
        </div>
      </ul>

      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="top-issues"
          role="tabpanel"
          aria-labelledby="high-risk-tab"
        >
          <div className="mt-2 top-issues table-responsive">
            <table className="table table-sm">
              <tbody>
                {policyViolations &&
                  policyViolations.map((data, index) => {
                    return (
                      <tr
                        className="cursor-pointer"
                        onClick={() => {
                          viewDatastores(data);
                        }}
                      >
                        <td>
                          <img
                            className="icon"
                            width="20px"
                            src={getIconByPolicyFilter(data.policyType)}
                          />
                        </td>

                        <td title={data.title.length > 55 ? data.title : ""}>
                          {data.title.length > 55
                            ? data.title.slice(0, 55) + ".."
                            : data.title}
                          {data.datastoreCategory === "shadow-ai" && (
                            <span className="beta ml-1">Beta</span>
                          )}
                        </td>
                        <td className="sensitivity-count">
                          {data.dsCount}
                          {data.datastoreCategory &&
                          data.datastoreCategory === "shadow-ai" ? (
                            <img className="icon " src={CPU} />
                          ) : (
                            <img className="icon " src={DBPlain} />
                          )}
                        </td>
                        <td>
                          {data.attackStatus && (
                            <img className="icon" src={Alert} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataPolicyViolation;
