import "./SensitiveData.scss";
import { useDispatch } from "react-redux";
import { listSensitiveData } from "../../../../redux/slices/dataStoreSlice.js";
import React, { useEffect, useState } from "react";
import DatabaseIcon from "../../../../assets/img/v1-icons/database.svg";
import { getCustomerId } from "../../../../utils/SessionHelper";

const SensitiveData = ({ selectedAccount }) => {
  const [sensitiveData, setSensitiveData] = useState([]);

  const dispatch = useDispatch();

  const tags = {
    "PII-SSN": "Social Security Numbers",
    "PII-CC": "Credit Cards",
    "PII-MN": "Mobile Numbers",
    "PII-ADDRESS": "Street Addresses",
    "PII-IP": "IP Addresses",
    "PII-EMAILS": "Emails",
    "PII-EMAIL": "Email",
    "PII-DL": "Drivers Licenses",
    "PII-NAMES": "Names",
    "PII-PHONE": "Mobile Numbers",
    "PII-AADHAR": "Aadhar Card Numbers",
    "PII-EUA": "EU Street Addresses",
    "PII-NIH": "NIH Numbers",
  };

  const getDatastoreStats = async () => {
    const resp = await dispatch(
      listSensitiveData({
        customerId: getCustomerId(),
        cloudAccountId:
          selectedAccount.value === "all" ? null : selectedAccount.value,
      })
    );
    if (resp && resp.payload) {
      setSensitiveData(resp.payload);
    }
  };
  const formatCount = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
  };
  useEffect(() => {
    getDatastoreStats();
  }, [selectedAccount]);

  return (
    <div id="sensitive-data">
      <table class="table table-sm">
        <tbody>
          {sensitiveData &&
            sensitiveData.map((data, index) => {
              return (
                <tr>
                  <td> {tags[data.tag]}</td>
                  <td className="sensitivity-count">
                    {formatCount(data.count)}
                  </td>
                  <td className="sensitivity-count">
                    {data.datastores}{" "}
                    <img className="icon" src={DatabaseIcon} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default SensitiveData;
