import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import closeIcon from "../../../assets/img/icons/close-icon.svg";
import deleteIcon from "../../../assets/img/icons/ico-delete-grey.svg";
import { updateDataStoreTagsAsync } from "../../../redux/slices/dataStoreSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import "./../modal.scss";
import "./ManageTagsModal.scss";
import Button from "../../common/Button";
const ManageTagsModal = ({
  handleModal,
  tags,
  refreshDataStoreTags,
  datastoreId,
  cloudAccountId,
}) => {
  const dispatch = useDispatch();

  // Defining sensitive tags
  const [sensitiveTags, setSensetiveTags] = useState([
    { value: "low", label: "Low", type: "sensitive" },
    { value: "medium", label: "Medium", type: "sensitive" },
    { value: "high", label: "High", type: "sensitive" },
    { value: "critical", label: "Critical", type: "sensitive" },
  ]);

  const [managedTags, setManagedTags] = useState(tags);
  const [customTagErrors, setCustomTagError] = useState("");
  const [loader, setLoader] = useState(false);
  const [tagsUpdated, setTagsUpdated] = useState(false);

  // Update the tags list
  const updateTags = (tag) => {
    // updating custom tags
    if (tag.type === "custom") {
      if (tags.some((obj) => obj.label === tag.label)) {
        setCustomTagError("Duplicate custom tags are not allowed");
      } else if (tag.type === "custom") {
        tag.label = tag.label.trim();
        setCustomTagError("");
        setManagedTags([...managedTags, tag]);
      }
    } else {
      // updating sensitive tags
      let temp = [...managedTags];
      let objIndex = temp.findIndex((obj) => obj.type == "sensitive");
      if (objIndex !== -1) {
        temp[objIndex] = tag;
        setManagedTags(temp);
      } else {
        setManagedTags([...managedTags, tag]);
      }
    }
    setTagsUpdated(true);
  };

  // Remove tag handler
  const removeTag = (tag) => {
    const tempTags = [...managedTags];
    tempTags.splice(
      tempTags.findIndex((a) => a.value === tag.value),
      1
    );
    setTagsUpdated(true);
    setManagedTags(tempTags);
  };

  // Submit manage tag handler

  const submitModal = async () => {
    setLoader(true);
    let customTags = managedTags
      .filter((tag) => tag.type === "custom")
      .map((tag) => {
        return tag.label;
      });
    let sensitiveTags = managedTags
      .filter((tag) => tag.type === "sensitive")
      .map((tag) => {
        return tag.label;
      });
    const resp = await dispatch(
      updateDataStoreTagsAsync({
        customerTags: customTags,
        customerSensTag: sensitiveTags[0],
        customerId: getCustomerId(),
        cloudAccountId: cloudAccountId || "",
        datastoreId: datastoreId,
      })
    );
    if (resp && resp.payload && !resp.payload.err) {
      handleModal(false);
      setLoader(false);
      refreshDataStoreTags();
    } else {
      setLoader(false);
    }
  };

  return (
    <div id="manage-tags-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            {loader && (
              <img
                src="/static/img/loader-circle.svg"
                className="modal-loading-img mt-2"
              ></img>
            )}
            <div className="modal-header d-flex justify-content-start align-items-center">
              {/* <img src={infoIcon} className="mr-3" /> */}
              <span className="header-title">Manage Tags</span>
              <img
                src={deleteIcon}
                className="mr-3 delete-icon"
                onClick={handleModal}
              />
            </div>
            <div className="modal-body mb-2">
              <div className="row py-2">
                <div className="col col-sm-4 d-flex flex-row justify-content-start align-items-center workday-subheading">
                  Sensitive Tag:
                </div>
                <div className="col col-sm-8 workday-subheading-value">
                  <Select
                    options={sensitiveTags}
                    classNamePrefix="sensitive-tags-dropdown"
                    placeholder="Select Sensitive Tag"
                    onChange={(value) => {
                      updateTags(value);
                    }}
                    isDisabled={loader}
                  />
                </div>
              </div>
              <div className="row py-2">
                <div className="col col-sm-4 d-flex flex-row justify-content-start align-items-center workday-subheading">
                  Custom Tag:
                </div>
                <div className="col col-sm-8 workday-subheading-value">
                  <input
                    class="form-control form-control-sm "
                    type="text"
                    placeholder="Enter Custom Tag"
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && e.target.value !== "") {
                        updateTags({
                          value: e.target.value,
                          label: e.target.value,
                          type: "custom",
                        });
                        e.target.value = "";
                        setTagsUpdated(true);
                      }
                    }}
                    disabled={loader}
                  ></input>
                  <div className="error-message">{customTagErrors} </div>
                </div>
              </div>
              <div className="row py-1">
                <div className="col col-sm-8 col-xs-6 ml-auto  workday-subheading-value">
                  <div class="d-flex flex-wrap chip-container">
                    {managedTags.map((tag, i) => (
                      <div class="chip m-1" key={i}>
                        <span className="mr-2" title={tag?.label}>
                          {" "}
                          {tag?.label.length > 25
                            ? tag?.label.slice(0, 25) + "..."
                            : tag.label || ""}
                        </span>
                        <img
                          className="close-icon"
                          src={closeIcon}
                          title="Close"
                          onClick={() => (loader ? null : removeTag(tag))}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer p-2">
              <Button
                variant="contained"
                type="button"
                className="mr-2"
                onClick={() => submitModal()}
                disabled={loader || !tagsUpdated}
              >
                Submit
              </Button>
              {/* <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => submitModal()}
                disabled={loader || !tagsUpdated}
              >
                Submit
              </button> */}

              <Button
                variant="outlined"
                type="button"
                className=" "
                onClick={handleModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTagsModal;
