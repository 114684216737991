import { useTheme } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";

import { isContainsValue } from "../../utils/commonUtils";
import Box from "../common/Box";
import Loader from "../common/Loader";
import {
  aiDataStoreTabs,
  DASHBOARD_DATA_VIEW,
  datastoreFilter,
  dataStoreTypes,
  sensitiveInfoTags,
} from "../constants/constants";
import BarChartCard from "./BarChartCard";
import { useHistory } from "react-router-dom";
import CardTable from "./CardTable";
import { DSTable } from "../../types/type";

type BarData = {
  barLabel: string;
  barValue: number;
  totalValue: number;
  barFaceColor: string;
  barBackColor: string;
  onClick?: () => void;
  id: number;
};

type TabData = {
  tabIndex: string;
  chartTitle: string;
  xAxisLabel: string;
  barData: Array<BarData>;
};

const DataViolationBarChart = () => {
  const theme = useTheme();
  const history = useHistory();
  const {
    dashboardViewLense,
    dataStoreViolation,
    getDataStoreViolation,
    getAIDatastores,
    isFetchingStoreViolation,
    setOpenPolicyStoreDetailDrawer,
    setDrawerData,
  } = useContext(AppContext);

  const [activeTabNumber, setActiveTabNumber] = useState<string>("all");
  const [tabData, setTabData] = useState<Array<TabData>>([]);
  const [tableData, setTableData] = useState<Array<DSTable>>([]);

  const dataView = dashboardViewLense === DASHBOARD_DATA_VIEW;

  const styles = {
    barCard: {
      width: "50%",
      paddingY: theme.spacing(1),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
  };

  const getSensitiveDataTags = (sensitiveInfo) => {
    let sensitiveTag = "";
    return sensitiveTag?.concat(
      sensitiveInfo?.map(
        (sensitiveData) => sensitiveInfoTags[sensitiveData?.name]
      )
    );
  };

  useEffect(() => {
    if (dashboardViewLense === DASHBOARD_DATA_VIEW) {
      getDataStoreViolation();
    } else {
      getAIDatastores();
    }
  }, [dashboardViewLense]);

  useEffect(() => {
    if (dataStoreViolation.length > 0) {
      if (dataView) {
        const tabDataList =
          dataStoreTypes.map((tab) => {
            const tabDataStore = dataStoreViolation?.find(
              (data) => data.type === tab.label
            )?.data;

            const totalChartValue =
              tabDataStore?.length > 0
                ? Math.max(
                    ...tabDataStore?.map(
                      (item: { count: number }) => item.count
                    )
                  )
                : 0;
            return {
              tabIndex: tab.value,
              chartTitle: `${tabDataStore?.length} Impacted Data Store Types`,
              xAxisLabel: "# of Data Stores",
              barData: tabDataStore
                ?.sort((a, b) => b.count - a.count)
                ?.map((dataItem, index) => ({
                  barLabel: dataItem?.datastoreType,
                  barValue: dataItem?.count,
                  totalValue: totalChartValue,
                  barFaceColor: theme.palette.lightMagenta.main,
                  barBackColor: theme.palette.surface20.main,
                  id: index,
                })),
            };
          }) || [];
        setTabData(tabDataList);
      } else {
        const tabDataList = aiDataStoreTabs.map((tab) => {
          const tabDataStore = dataStoreViolation.find(
            (data) => data.type === tab.label
          )?.data;
          return {
            tabIndex: tab.value,
            data: tabDataStore?.map((dataItem) => ({
              id: dataItem?.datastoreId,
              assetId: dataItem?.assetId,
              name: dataItem?.name,
              cloud: dataItem?.cloud,
              category: dataItem?.datastoreType,
              labels: getSensitiveDataTags(dataItem?.sensitiveInfo),
            })),
          };
        });
        setTableData(tabDataList);
      }
    }
  }, [dataStoreViolation]);

  return (
    <Box sx={styles.barCard}>
      {isFetchingStoreViolation ? (
        <Loader />
      ) : dataView ? (
        <BarChartCard
          listOfTabs={dataStoreTypes}
          activeTabNumber={activeTabNumber}
          tabData={tabData}
          tabTitle={dataView ? "Data Store" : ""}
          openDrawer={false}
          buttonText="View All"
          showButton={dataView ? true : false}
        />
      ) : (
        <CardTable
          tabData={tableData}
          activeTabNumber={activeTabNumber}
          listOfTabs={aiDataStoreTabs}
          data={[]}
        />
      )}
    </Box>
  );
};

export default DataViolationBarChart;
