import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchAccounts,
  launchStack,
  verifyAccount,
  addAccount,
  generateExternalId,
  deleteAccount,
  getRegionList,
  deleteStack,
  updateStack,
  verifyDeleteStack,
  fetchAccountScanningStatus,
  launchStackV2,
  verifyLaunchStack,
  fetchAccountStatus,
  verifyLaunchStackStaus,
  verifyUpdateStack,
  revertUpdateStack,
  createAccount,
  updateAccount,
} from "../apis/accountAPI";

const initialState = {
  accounts: [],
  accountAvailable: null,
};

// Async calls.
export const listAccountsAsync = createAsyncThunk("account/list", async () => {
  const response = await fetchAccounts();
  return response.data;
});

export const getAccountStatus = createAsyncThunk(
  "account/status",
  async (params) => {
    const response = await fetchAccountStatus(params);
    return response.data;
  }
);

export const verifyAccountAsync = createAsyncThunk(
  "account/verifyAccount",
  async (params) => {
    try {
      const response = await verifyAccount(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const launchStackAsync = createAsyncThunk(
  "account/launchStack",
  async (params) => {
    try {
      const response = await launchStack(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const launchStackV2Async = createAsyncThunk(
  "account/launchStack_v2",
  async (params) => {
    try {
      const response = await launchStackV2(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const addAccountAsync = createAsyncThunk(
  "account/addAccount",
  async (params) => {
    console.log({ params });
    try {
      const response = await addAccount(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const createAccountAsync = createAsyncThunk(
  "account/createAccount",
  async (params) => {
    try {
      const response = await createAccount(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const generateExternalIdAsync = createAsyncThunk(
  "account/generateExternalId'",
  async () => {
    const response = await generateExternalId();
    return response.data;
  }
);

export const getRegionListAsync = createAsyncThunk(
  "account/regionListing'",
  async () => {
    const response = await getRegionList();
    return response.data;
  }
);

export const deleteAccountAsync = createAsyncThunk(
  "account/deleteAccount",
  async (params) => {
    try {
      const response = await deleteAccount(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const deleteStackAsync = createAsyncThunk(
  "account/deleteStack",
  async (params) => {
    try {
      const response = await deleteStack(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const updateAccountAsync = createAsyncThunk(
  "account/deleteAccount",
  async (params) => {
    try {
      const response = await deleteAccount(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const updateAccountGCPAsync = createAsyncThunk(
  "account/updateGCPAccount",
  async (params) => {
    try {
      const response = await updateAccount(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const updateStackAsync = createAsyncThunk(
  "account/updateStack",
  async (params) => {
    try {
      const response = await updateStack(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const verifyUpdateStackAsync = createAsyncThunk(
  "account/verifyUpdateStack",
  async (params) => {
    const response = await verifyUpdateStack(params);
    return response.data;
  }
);

export const revertUpdateStackAsync = createAsyncThunk(
  "account/revertUpdateStack",
  async (params) => {
    const response = await revertUpdateStack(params);
    return response.data;
  }
);

export const verifyDeleteStackAsync = createAsyncThunk(
  "account/verifyDeleteStack",
  async (params) => {
    const response = await verifyDeleteStack(params);
    return response.data;
  }
);

export const verifyLaunchStackAsync = createAsyncThunk(
  "account/verifyLaunchStack",
  async (params) => {
    const response = await verifyLaunchStack(params);
    return response.data;
  }
);

export const verifyLaunchStackStatusAsync = createAsyncThunk(
  "account/verifyLaunchStackStatus",
  async (params) => {
    const response = await verifyLaunchStackStaus(params);
    return response.data;
  }
);

export const accountScanningStatus = createAsyncThunk(
  "account/accountScanningStatus",
  async (params) => {
    const response = await fetchAccountScanningStatus(params);
    return response.data;
  }
);

// Account slice for configuring reducers and actions.
export const accountSlice = createSlice({
  name: "account",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listAccountsAsync.fulfilled, (state, action) => {
        state.accounts = action.payload;
        state.accountAvailable = action?.payload?.length > 0;
        // state.accounts = [];
        // state.accountAvailable = false;
      })
      .addCase(listAccountsAsync.rejected, (state, action) => {
        state.accounts = [];
        state.accountAvailable = false;
      });
  },
});

export default accountSlice.reducer;

// Selectors will go here
export const accountById = (state, accountId) =>
  state.accounts.find((account) => account.id === accountId);
