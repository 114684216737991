import "./style.scss";
import { ReactComponent as DeleteIcon } from "../../../assets/img/close-icon.svg";
import Accordions from "./accordions";
import { useState } from "react";

const EmbededUiModal = ({ handleModal }) => {
  const [accordions, setAccordions] = useState([
    {
      title: "Data",
      data: [
        {
          open: false,
          title: "What is a Data Store?",
          description:
            "The Data Store is a repository of structured or unstructured data in the cloud. It can be a block store (e.g. Amazon EBS) or an object store (e.g. Amazon S3 bucket). In addition, Data store can be either cloud provider-managed (e.g. Amazon RDS) or self-managed (e.g. MongoDB).",
        },
        {
          open: false,
          title: "What is Data Inventory?",
          description:
            "The data inventory provides a summary of the number of data stores, their total storage capacity and the data store types in the selected cloud accounts.",
        },
        {
          open: false,
          title: "What is a Data Flow?",
          description:
            "A Data Flow is represented by the source and the destination of a data transfer along with a time stamp. The source is a data store and the destination a compute node (e.g. EC2) in your cloud environment.",
        },
        {
          open: false,
          title: "What is a Data Store Tag?",
          description:
            "Data Store tag is assigned to override the default assigned by Cloud Defense. For example, a custom tag is assigned when an organization wants to classify certain proprietary data such as source code, patents or legal documents as sensitive. Multiple custom tags can be assigned to a data store.",
        },
        {
          open: false,
          title: "What is a Geo Location Tag?",
          description:
            "Geo Location Tag is assigned to a data store to indicate the data is restricted to a certain geography. For example, the tag can be used when the data must remain within certain geography for regulatory compliance. Multiple custom tags can be assigned to a data store.",
        },
        {
          open: false,
          title: "What is an Impacted Data store plot?",
          description:
            "The Impacted Data store plot maps the data stores along two axes: Direct and Indirect. \n a. Direct risks: A measure of the severity of direct risks associated with the data store. \nb. Indirect risks: A measure of the ease of cloud risks to reach the data store",
        },
        {
          open: false,
          title: "What is a Data Store Report?",
          description:
            "The Data Store report provides a bird’s eye view of the current state of all data stores in your cloud environment. It provides the number of data stores as: \n a. Urgent data stores at risk requiring immediate attention, \n b. Important data stores at risk requiring attention, \n c. Managed data stores are the number of provider-managed data stores in selected accounts, \n d. Shadow data stores are the number of unauthorized data stores in selected accounts.",
        },
        {
          open: false,
          title: "What is Data Risk History?",
          description:
            "The data risk history provides the current map of the selected data stores on the data risk plot. It is used to trace the movement of a one or more data stores on the plot over last 7 days. The history helps identify specific data stores moving from low-to-high risk over the last several days.",
        },
      ],
    },
    {
      title: "Cloud",
      data: [
        {
          open: false,
          title: "What is a Cloud on the dashboard?",
          description:
            "Cloud provides a summary of the onboarded cloud service provider accounts, the number of cloud resources on those accounts, the change in cloud resources within the last 24hrs and the number of users using the accounts.",
        },
      ],
    },
    {
      title: "Risk",
      data: [
        {
          open: false,
          title: "What is a Risk?",
          description:
            "Risk could be a vulnerability, misconfiguration, excessive user permission or malicious activity in the cloud environment.",
        },
        {
          open: false,
          title: "What is Accept Risk?",
          description:
            "Organizations may decide to accept a risk when they understand the implications of the risk and consider it a lower priority at this time compared to other risks in their environment. They may also accept the risk when the overheads to fix the risk far outweigh the cost to do nothing.",
        },
        {
          open: false,
          title: "What is a Risk Score?",
          description:
            "A risk score is a proprietary measure of the severity of the risk associated with your cloud data. It indicates the criticality of the risk (of an issue or data store) as compared to other risks in your cloud environment at the present time. Risk score has a range of 0-100 with 100 being the most severe risk. Risk score is also color-coded with Red as the most severe (90+), followed byYellow (70-89) and Green (0-69).",
        },
        {
          open: false,
          title:
            "How is the Asset risk score different from the Overall Risk Score?",
          description:
            "Asset (issue or data store) risk score represents the severity of the risk to the resource itself as compared to other cloud resources. Overall Risk Score is an aggregate risk score of all cloud resources in the selected Accounts and is measured on the same scale as the asset risk score. Cloud Defense prioritizes cloud resources based on their risk scores.",
        },
      ],
    },
    {
      title: "Policy",
      data: [
        {
          open: false,
          title: "What is a Policy?",
          description:
            "A policy is a guardrail defined by the security team to restrict cloud assets and data in one or more ways. For example, a policy can be defined to enforce encryption enabled on every data store or that data cannot travel outside of a certain region for regulatory compliance.",
        },
        {
          open: false,
          title: "What are my default Policies?",
          description: "TBD.",
        },
        {
          open: false,
          title: "What is a Policy Violation?",
          description:
            "A policy violation occurs when a user or an application attempts to bypass a pre-defined guardrail. For example, a malicious user may try to exfiltrate sensitive data from a data store to an external entity.",
        },
        {
          open: false,
          title: "What is a Compliance Violation?",
          description:
            "A compliance violation occurs when a user attempts to access or transfer unauthorized data as defined by a compliance policy. The policies are typically defined for regulatory compliance.",
        },
      ],
    },
    {
      title: "Issues",
      data: [
        {
          open: false,
          title: "What is a Security Issue?",
          description:
            "A security issue can be a vulnerability, malicious activity, misconfiguration orexcessive user permission. Critical issues must be addressed promptly since they may result in company-wide outbreaks and lead to business disruption.",
        },
        {
          open: false,
          title: "What are the Top-50 issues?",
          description:
            "Security issues are prioritized based on their ability to reach sensitive data. The Top-50 is a list of issues with the highest risk scores in the cloud environment that require immediate attention.",
        },
        {
          open: false,
          title: "What is an Issues Overview?",
          description:
            "The issues overview provides a bird’s eye view of the current security issues in your cloud environment. It provides the number of Issues as: \n a. Urgent risks requiring immediate attention, \n b. Important risks requiring attention, \n c. New risks recently introduced in your environment and \n d. Total number of risks in your cloud environment at present",
        },
      ],
    },
    {
      title: "Attack Path",
      data: [
        {
          open: false,
          title: "What is an Attack Path?",
          description:
            "An attack path originates from a vulnerable or compromised cloud asset (e.g.EC2) and leads up to a data store with everything in between.",
        },
        {
          open: false,
          title: "What are Trends?",
          description:
            "Data stores are mapped over a 10day period as follows: \n 1. Policy violations: Number of data stores that violate pre-defined policies \n 2. At-risk: Number of data stores with sensitive data that are still at risk \n 3. Cloud anomaly: Number of data stores subject to live risks in the cloud",
        },
        {
          open: false,
          title: "What are Achievements?",
          description:
            "Achievements track the progress made by security teams in de-risking data stores over the last 30-day period.",
        },
      ],
    },
    {
      title: "Accounts",
      data: [
        {
          open: false,
          title: "How do I add my Accounts?",
          description:
            "In order to ensure secure onboarding, each AWS cloud account in your organization is added individually as follows: \n 1. Select the “Accounts” tab \n 2. Click on “Add New Account” \n 3. Select the “Type of account” e.g. AWS, Azure, GCP \n 4. Select “Next: Account Info” and gather your root account information \n 5. Enter your 12-digit root “Account Id” \n 6. Next, enter a brief “Account Description” \n 7. Skip the “External Id” \n 8. Select the “Region” where the account resides e.g. us-east-1 \n 9. Click on “Launch Stack” to generate Role ARN in your AWS account \n 10. A separate window will appear to launch the CFN stack in your account \n 11. After around 10-15min, the CFN process will complete and generate a Role ARN \n 12. Copy the Role ARN link from the AWS account and paste in the “Role ARN” field here \n 13. Once a valid Role ARN is entered, click on “Add Account” \n 14. The account will now show up on the Accounts page and start gathering cloud resources",
        },
      ],
    },
  ]);

  return (
    <div
      className="new-modal modal embeded-modal fade show"
      id="exampleModalLong"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      style={{
        display: "block",
        paddingRight: "8px",
        background: "rgba(0, 0, 0, 0.5)",
      }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg embeded-ui-modal" role="document">
        <div className="modal-content">
          <div className="modal-body mt-2">
            <h5 className="float-left">Help</h5>
            <DeleteIcon
              className="cursor-pointer float-right"
              onClick={() => handleModal(false)}
            />

            <input
              type="text"
              placeholder="Search"
              className="form-control accordion-search-form"
            />

            {accordions.map((accordion, index) => (
              <span key={index}>
                <Accordions
                  title={accordion.title}
                  setAccordions={setAccordions}
                  data={accordion.data}
                />
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbededUiModal;
