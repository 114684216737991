import { PaletteColor, useTheme } from '@mui/material';
import * as d3 from 'd3';
import { useEffect } from 'react';
import Box from './Box';
import Tooltip from './Tooltip';
import Typography from './Typography';

export interface Props {
  maxValue: number;
  color: PaletteColor;
  classForGraph: string;
  xLabel: string;
}

const BarGraphBottomAxis = ({
  maxValue,
  color,
  xLabel,
  classForGraph,
}: Props) => {
  const theme = useTheme();

  const styles = {
    axisContainer: {
      width: '100%',
      height: '100%',
    },
    axisLabels: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    barLabel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    labelText: {
      textAlign: 'center',
      width: '70%',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  };

  const drawBottomAxis = () => {
    const svgWidth = parseFloat(
      d3.select(`.barChartXAxisContainer`).style('width')
    );
    const margin = { top: 0, bottom: 0, left: 12, right: -10 };
    const svgHeight = 30;
    let height = 35,
      width = svgWidth - margin.left - margin.right;
    d3.select(`.${classForGraph}Axis svg`).remove();

    let element = d3.select(`.${classForGraph}Axis`).append('svg');

    const xScale = d3
      .scaleLinear()
      .domain([0, maxValue] as [d3.NumberValue, d3.NumberValue])
      .rangeRound([0, width]);

    element.attr('height', svgHeight).attr('width', '100%');

    const el = element
      .append('g')
      .attr('class', 'barBottomAxis')
      .attr('transform', `translate(${margin.left - 10},${margin.top - 10})`);

    el.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale).ticks(2).tickValues([]));
  };

  useEffect(() => {
    drawBottomAxis();
  }, [classForGraph, maxValue]);

  return (
    <Box sx={styles.axisContainer}>
      <div className={`barChartXAxisContainer ${classForGraph}Axis`}></div>
      <Box sx={styles.axisLabels}>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          0
        </Typography>
        <Tooltip title={xLabel.replace('#', 'Number')}>
          <div style={styles.barLabel}>
            <Typography
              variant="caption"
              color={theme.palette.surface50.main}
              sx={styles.labelText}
            >
              {xLabel}
            </Typography>
          </div>
        </Tooltip>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          {maxValue}
        </Typography>
      </Box>
    </Box>
  );
};

export default BarGraphBottomAxis;
