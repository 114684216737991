import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@mui/material';
import Box from './Box';
import Typography from './Typography';

const Insights = ({ onClick = () => {} }) => {
  const theme = useTheme();

  const styles = {
    insights: {
      background: theme.palette.surface50.main,
      transform: 'rotate(-90deg) translate(-134px,56px)',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(3.5),
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
    expandIcon: {
      width: '20px',
    },
    text: {
      color: theme.palette.surfaceMain.main,
      letterSpacing: '2px',
    },
  };
  return (
    <Box sx={styles.insights} onClick={onClick}>
      <Typography variant="caption" sx={styles.text}>
        Insights
      </Typography>
      <ExpandLessIcon sx={styles.expandIcon} />
    </Box>
  );
};
export default Insights;
