import { useTheme } from "@mui/material";
import { CardWrapper } from "../CardWrapper";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import LangchainIcon from "../../../assets/img/langchainIcon.svg";
import GptIcon from "../../../assets/img/gptIcon.svg";
import ChormaIcon from "../../../assets/img/chromaIcon.svg";
import TextButton from "../../common/TextButton";
import { useHistory } from "react-router-dom";
import Divider from "../../common/Divider";
import ChromaIcon from "../../../assets/img/chromaIcon.svg";

export const FrameworkSummaryCard = ({ summaryData, vectorDBDetails }) => {
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    summaryCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      width: "100%",
    },
    frameworkSummaryCardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    frameworkSummaryCardHeading: {
      fontSize: "13px",
    },
    frameworkSummaryName: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2.5),
    },
    frameworkSummaryNameValue: {
      color: theme.palette.surface80.main,
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightRegular,
    },
    frameworkSummaryCardBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },

    frameworkSummaryDetail: {
      display: "flex",
    },
    frameworkSummaryKey: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      width: "50%",
      flexShrink: 0,
    },
    frameworkSummaryValue: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      flex: 1,
      h6: {
        color: theme.palette.surface80.main,
        fontSize: "12px",
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    frameworkSummaryValueWithIcon: {
      display: "flex",
      gap: theme.spacing(1),
      minWidth: "1%",
      "word-wrap": "break-word",
    },
    textButton: {
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    vectorDBContainer: {
      marginTop: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    vectorDB: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
    vectorDBDetails: {
      display: "flex",
      gap: theme.spacing(2.5),
    },
    vectorDBValues: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.25),
    },
    vectorDBDetailsKey: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
    },
    vectorDBDetailsValue: {
      fontSize: "12px",
    },
  };
  return (
    <CardWrapper>
      <Box sx={styles.summaryCard}>
        <Box sx={styles.frameworkSummaryCardHeader}>
          <Typography variant="body2" sx={styles.frameworkSummaryCardHeading}>
            Framework Summary
          </Typography>
        </Box>
        <Box sx={styles.frameworkSummaryName}>
          <img src={LangchainIcon} alt="Lang chain icon" />
          <Typography sx={styles.frameworkSummaryNameValue}>
            {summaryData?.name}
          </Typography>
        </Box>
        <Box sx={styles.frameworkSummaryCardBody}>
          <Box sx={styles.frameworkSummaryDetail}>
            <Typography sx={styles.frameworkSummaryKey}>Family</Typography>
            <Typography sx={styles.frameworkSummaryValue}>
              {summaryData?.family}
            </Typography>
          </Box>
          <Box sx={styles.frameworkSummaryDetail}>
            <Typography sx={styles.frameworkSummaryKey}>Version</Typography>
            <Typography sx={styles.frameworkSummaryValue}>
              {summaryData?.version}
            </Typography>
          </Box>
          {/*<Box sx={styles.frameworkSummaryDetail}>
            <Typography sx={styles.frameworkSummaryKey}>VectorDB</Typography>
            <Box sx={styles.frameworkSummaryValueWithIcon}>
              <img src={ChormaIcon} alt="Chroma icon" />
              <Typography sx={styles.frameworkSummaryValue}>
                {summaryData?.vectorDB}
              </Typography>
            </Box>
          </Box>*/}
          <Box sx={styles.frameworkSummaryDetail}>
            <Typography sx={styles.frameworkSummaryKey}>
              Embedding Model
            </Typography>
            <Typography sx={styles.frameworkSummaryValue}>
              {summaryData?.embeddingModel}
            </Typography>
          </Box>
          <Box sx={styles.frameworkSummaryDetail}>
            <Typography sx={styles.frameworkSummaryKey}>LLM Model</Typography>
            <Box sx={styles.frameworkSummaryValueWithIcon}>
              <img src={GptIcon} alt="GPT icon" />
              <TextButton
                onClick={() => {
                  if (summaryData?.modelLink && summaryData?.modelLink !== "") {
                    history.push(summaryData?.modelLink);
                  }
                }}
                sx={styles.textButton}
              >
                {summaryData?.llmModel}
              </TextButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.vectorDBContainer}>
        <Typography variant="body2" sx={styles.frameworkSummaryNameValue}>
          VectorDB Details
        </Typography>
        <Box sx={styles.vectorDBDetails}>
          <Box sx={styles.vectorDBValues}>
            <img src={ChromaIcon} alt="Chroma Icon" height="22" width="32" />
            <Box>
              <Typography sx={styles.vectorDBDetailsValue}>
                {vectorDBDetails?.dbName}
              </Typography>
              <Typography sx={styles.vectorDBDetailsValue}>
                {vectorDBDetails?.version}
              </Typography>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            <Typography sx={styles.vectorDBDetailsKey}>Size</Typography>
            <Typography sx={styles.vectorDBDetailsValue}>
              {vectorDBDetails?.size}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            <Typography sx={styles.vectorDBDetailsKey}>Directory</Typography>
            <Typography sx={styles.vectorDBDetailsValue}>
              {vectorDBDetails?.directory}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CardWrapper>
  );
};
