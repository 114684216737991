import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { CardWrapper } from "../CardWrapper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../common/Table";
import NoData from "../../common/NoData";

export const SensitiveDataDetailsCard = ({
  sensitiveDetails = {},
}: {
  sensitiveDetails: any;
}) => {
  const theme = useTheme();
  const styles = {
    sensitiveDataDetailCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      width: "100%",
    },
    sensitiveDataDetailsCardHeading: {
      fontSize: "13px",
    },
    sensitiveDataDetailCardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    tableHeader: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightLight,
    },
    tableRow: {
      "&:last-child td, &:last-child th": { border: 0 },
    },
    tableBody: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
      "&.MuiTableBody-root .MuiTableRow-root:hover": {
        backgroundColor: "none !important",
      },
    },
    tableBodyRecords: {
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  };

  return (
    <CardWrapper>
      <Box sx={styles.sensitiveDataDetailCard}>
        <Box sx={styles.sensitiveDataDetailCardHeader}>
          <Typography
            variant="body2"
            sx={styles.sensitiveDataDetailsCardHeading}
          >
            Sensitive Data
          </Typography>
        </Box>
        {sensitiveDetails && sensitiveDetails?.length > 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeader}>Sensitive Tags</TableCell>
                  <TableCell align="right" sx={styles.tableHeader}>
                    Records
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={styles.tableBody}>
                {sensitiveDetails?.map((item) => (
                  <TableRow sx={styles.tableRow}>
                    <TableCell sx={styles.tableBody}>
                      PII - {item?.PII}
                    </TableCell>
                    <TableCell sx={styles.tableBodyRecords} align="right">
                      {item?.count}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoData customText="No Sensitive Data Found" />
        )}
      </Box>
    </CardWrapper>
  );
};
