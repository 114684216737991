import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormatUrl from "../../utils/UrlFormatter";
import { Toaster } from "../../utils/toaster";
import axios from "axios";
import Logo from "../../assets/img/logo.png";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../utils/Constants";
import LoginPageLogo from "../../assets/img/login-page-logo-dark.svg";

const ForgotPassword = () => {
  const [view, setView] = useState(1);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Cloud Defense - Forgot Password";
  }, []);

  const verifyEmail = (ev) => {
    ev.preventDefault();

    if (!email) {
      Toaster(4, "email required !");
      return;
    }

    setLoading(true);
    let url = FormatUrl(`/user/forgotPassword`);

    axios
      .post(url, {
        email: email,
      })
      .then((res) => {
        setLoading(false);
        sessionStorage.setItem("email", email);
        setView(2);
      })
      .catch((err) => {
        setLoading(false);
        Toaster(
          2,
          err.response?.data?.err?.message || "Failed to verify email"
        );
      });
  };

  return (
    <>
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        <section className="sign-in-page">
          <div className="container p-0">
            <div className="logo-container">
              <div>
                <img src={LoginPageLogo} alt="" className="login-from-logo" />
              </div>
              <div>
                <h3 className="font-weight-bold text-white">Cloud Defense</h3>
              </div>
            </div>

            {view === 1 ? (
              <div className="row no-gutters">
                <div className="col-sm-12 align-self-center">
                  <div className="sign-in-from">
                    <form className="mt-4" onSubmit={verifyEmail}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="d-inline-block w-100">
                        <button type="submit" className="btn btn-primary">
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row no-gutters">
                <div className="col-sm-12 align-self-center">
                  <h3 className="text-center text-primary mt-5">
                    Please check your email to reset your password..
                  </h3>
                </div>
              </div>
            )}
          </div>
        </section>
      </BlockUi>
    </>
  );
};

export default ForgotPassword;
