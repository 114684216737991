import "./UpdateAccountModal.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import loaderImage from "../../../assets/img/loader.svg";
import { getAccountStatus } from "../../../redux/slices/accountSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import { capitalizeFirstLetter } from "../../../utils/utils";
import Button from "../../common/Button";

// Functional component for Add Account.
const ViewAccountModal = ({ handleModal, account }) => {
  const dispatch = useDispatch();
  const [defaultStat, setDefaultStat] = useState("monthly");
  const [accountData, setAccountData] = useState({});
  const [filters, setFilters] = useState([]);

  const viewAccount = async (account) => {
    const resp = await dispatch(
      getAccountStatus({
        customerId: getCustomerId(),
        cloudAccountId: account.id,
      })
    );
    setAccountData(resp.payload ? resp.payload : {});
  };

  useEffect(() => {
    const filters = [];
    if (accountData.stats) {
      if (accountData.stats.monthly) {
        filters.push("monthly");
      }
      if (accountData.stats.weekly) {
        filters.push("weekly");
      }
      if (accountData.stats.daily) {
        filters.push("daily");
      }
      if (accountData.stats.hourly) {
        filters.push("hourly");
      }
    }

    setFilters(filters);
  }, [accountData]);

  const handleCancel = () => {
    handleModal(false);
  };

  useEffect(() => {
    if (account) viewAccount(account);
  }, [account]);

  const MAPPINGS = {
    "rate(1 day)": "Daily",
    "rate(7 days)": "Weekly",
    "rate(30 days)": "Monthly",
  };

  return (
    <div id="update-account">
      <div
        className="modal fade show"
        id="updateModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="updateModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
            <div className="modal-header pl-0 d-flex justify-content-start align-items-center">
              <span> Account Status</span>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 pl-0"> Account ID:</div>
                <div className="col-md-7 modal-text">
                  {accountData.cloudAccountId}
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-md-4 pl-0"> CrossAccount Role ARN:</div>
                <div className="col-md-7 modal-text">
                  {accountData.awsRoleARN}
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-4 pl-0"> Onboarded On:</div>
                <div className="col-md-7 modal-text">
                  {accountData.onboardedOn}
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-4 pl-0"> Onboarded By:</div>
                <div className="col-md-7 modal-text">
                  {account?.userId?.email}
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-4 pl-0"> Data Scanning Frequency:</div>
                <div className="col-md-7 modal-text">
                  {MAPPINGS[accountData.dataScanFrequency]
                    ? MAPPINGS[accountData.dataScanFrequency]
                    : accountData.dataScanFrequency}
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-4 pl-0"> Last Heartbeat:</div>
                <div className="col-md-7 modal-text">
                  {accountData.lastHeartbeat}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-6">
                  <div className="mt-1 ml-15">
                    <span>
                      Cloud Defense Lambda ({capitalizeFirstLetter(defaultStat)}
                      ):
                    </span>

                    {accountData.stats ? (
                      <div className="ml-4">
                        <span>
                          Total Execution Runtime:
                          {filters.length ? (
                            <span className="modal-text ml-2">
                              {accountData.stats[
                                defaultStat
                              ]?.aggregate?.totalExecutionTime?.toFixed(2)}{" "}
                              ms
                            </span>
                          ) : (
                            <span className="modal-text ml-2">
                              {accountData.stats?.aggregate?.totalExecutionTime?.toFixed(
                                2
                              )}{" "}
                              ms
                            </span>
                          )}
                        </span>
                        <br />
                        <span>
                          Total Estimated Cost:
                          {filters.length ? (
                            <span className="modal-text ml-2">
                              ${" "}
                              {accountData.stats[
                                defaultStat
                              ]?.aggregate?.estimatedCost?.toFixed(6)}
                            </span>
                          ) : (
                            <span className="modal-text ml-2">
                              ${" "}
                              {accountData.stats?.aggregate?.estimatedCost?.toFixed(
                                6
                              )}
                            </span>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {filters.length ? (
                  <div className="col-sm-6">
                    <select
                      onChange={(e) => setDefaultStat(e.target.value)}
                      className="form-control"
                      value={defaultStat}
                    >
                      {filters.map((filter, key) => (
                        <option key={key} value={filter}>
                          {filter}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row modal-message mt-2 pt-4"></div>

              <div className="row">
                <div className="col col-xl-12">
                  <div className="table-responsive view-account-table">
                    {filters.length ? (
                      <table
                        className={`mb-0 pb-3 table table-border-less table-hover`}
                      >
                        <thead>
                          <tr className="vuls-row">
                            <th scope="col">Function</th>
                            <th scope="col">Execution time</th>
                            <th scope="col">Estimated cost</th>
                          </tr>
                        </thead>

                        {accountData.stats && accountData.stats.detailed ? (
                          <tbody>
                            {accountData.stats[defaultStat].detailed.map(
                              function (item, idx) {
                                return (
                                  <tr key={idx} className="vuls-row">
                                    <td className="data-store-name">
                                      {item.funcName}
                                    </td>

                                    <td className="data-store-name">
                                      {item?.totalExecutionTime?.toFixed(2) ||
                                        0}{" "}
                                      ms
                                    </td>

                                    <td className="data-store-name">
                                      $ {item?.estimatedCost?.toFixed(6)}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        ) : (
                          <tbody></tbody>
                        )}
                      </table>
                    ) : (
                      <table
                        className={`mb-0 pb-3 table table-border-less table-hover`}
                      >
                        <thead>
                          <tr className="vuls-row">
                            <th scope="col">Function</th>
                            <th scope="col">Execution time</th>
                            <th scope="col">Estimated cost</th>
                          </tr>
                        </thead>

                        {accountData.stats && accountData.stats.detailed ? (
                          <tbody>
                            {accountData.stats.detailed.map(function (
                              item,
                              idx
                            ) {
                              return (
                                <tr key={idx} className="vuls-row">
                                  <td className="data-store-name">
                                    {item.funcName}
                                  </td>

                                  <td className="data-store-name">
                                    {item?.totalExecutionTime?.toFixed(2) || 0}{" "}
                                    ms
                                  </td>

                                  <td className="data-store-name">
                                    $ {item?.estimatedCost?.toFixed(6)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody></tbody>
                        )}
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer pb-4 pt-0">
              <Button
                variant="outlined"
                type="button"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAccountModal;
