import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { UsageCardProps } from "../../types/type";
import {
  formatBytes,
  getNumberAbbreviation,
  isContainsValue,
  isDataWithinDefinedDate,
} from "../../utils/commonUtils";
import Box from "../common/Box";
import Loader from "../common/Loader";
import Typography from "../common/Typography";
import {
  DASHBOARD_AI_VIEW,
  DASHBOARD_DATA_VIEW,
  sensitiveFilters,
} from "../constants/constants";
import Divider from "../common/Divider";
import {
  sensitiveDataTabs,
  totalResponses,
  trendPromptsData,
} from "../../mock-data/llmPromptsData";
import { Select } from "../common/Select";
import LineChart from "../common/LineChart";
import PromptsTrendsGraph from "./PromptsTrendsGraph";
import LegendWithColor from "../common/LegendWithColor";
import { langchainFrameworkData } from "../../mock-data/llmFrameworkPageData";

const UsageCard = () => {
  const {
    sensitiveDataFilter,
    setSensitiveDataFilter,
    dashboardViewLense,
    resourcesData,
    isFetchingImpactedStoresData,
    dsInfo,
    getDsInfo,
    isFetchingDsInfo,
    getImpactedStoresData,
  } = useContext(AppContext);
  const graphData = langchainFrameworkData[0]?.data?.promptResponseTimeData;
  const promptsData = langchainFrameworkData[0]?.data?.promptsResponseSummary;
  const [resources, setResources] = useState<UsageCardProps>({
    deltaIncrease: true,
    currentResources: 0,
    deltaResources: 0,
    increaseInPercent: 0,
    users: 0,
  });
  const theme = useTheme();
  const dataView = dashboardViewLense === DASHBOARD_DATA_VIEW;

  useEffect(() => {
    if (dataView) {
      getImpactedStoresData();
    } else {
      getDsInfo();
    }
  }, [dashboardViewLense]);

  useEffect(() => {
    if (dataView && isContainsValue(resourcesData)) {
      const increaseFlag =
        resourcesData?.delta30_resources <= resourcesData?.current_resources;
      const increaseValue =
        resourcesData?.current_resources !== 0 &&
        resourcesData?.delta30_resources !== 0
          ? increaseFlag
            ? resourcesData?.delta30_resources /
              resourcesData?.current_resources
            : resourcesData?.current_resources /
              resourcesData?.delta30_resources
          : 0;
      setResources({
        deltaIncrease: increaseFlag,
        currentResources: resourcesData?.current_resources,
        deltaResources: resourcesData?.delta30_resources,
        increaseInPercent: Number(increaseValue.toFixed(2)),
        users: resourcesData?.current_users,
      });
    } else if (isContainsValue(dsInfo) && !dataView) {
      const increaseFlag = dsInfo?.deltaSize <= dsInfo?.size;
      const increaseValue =
        dsInfo?.size !== 0 && dsInfo?.deltaSize !== 0
          ? increaseFlag
            ? ((dsInfo?.size - dsInfo?.deltaSize) / dsInfo?.size) * 100
            : ((dsInfo?.deltaSize - dsInfo?.size) / dsInfo?.deltaSize) * 100
          : 0;
      setResources({
        deltaIncrease: increaseFlag,
        currentResources: dsInfo?.size,
        deltaResources: 0,
        increaseInPercent: Number(increaseValue.toFixed(2)),
        users: dsInfo?.dsCount,
      });
    }
  }, [resourcesData, dsInfo]);

  const styles = {
    cardContainer: {
      width: "100%",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      padding: theme.spacing(2),
    },
    cloudResourcesContainer: {
      display: "flex",
      flexDirection: dataView ? "row" : "column",
      gap: dataView ? 0 : theme.spacing(6),
    },
    resourcesTextContainer: {
      display: "flex",
      flexDirection: "column",
    },
    largeContainer: {
      width: "66%",
    },
    smallContainer: {
      width: dataView ? "33%" : "100%",
    },
    resourcesText: {
      display: "flex",
      gap: theme.spacing(0.25),
      alignItems: "end",
    },
    resourcePercent: {
      display: "flex",
      gap: theme.spacing(0.25),
      alignItems: "center",
    },
    noOfResourcesText: {
      fontFamily: "Manrope",
      fontSize: "28px",
    },
    cardTitle: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    upArrowIcon: {
      height: "14px",
      width: "14px",
      color: theme.palette.primaryBlue.main,
    },
    cardHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //flexDirection: "column",
      // gap: theme.spacing(0.5),
    },
    aiCardContent: {
      //marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      //gap: theme.spacing(5),
      gap: theme.spacing(2),
    },
    firstRow: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridColumnGap: theme.spacing(2),
    },
    select: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    cardHeading: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    promptsTrends: {
      display: "flex",
      flexDirection: "column",
    },
    legendContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "end",
      gap: theme.spacing(3),
    },
    promptMetric: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    totalMessages: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    messageBlock: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
  };

  const getResponsePromptCount = (keyword) => {
    let count = 0;
    promptsData
      .find((dataItem) => dataItem.tabValue === keyword)
      ?.data.forEach((msgData) => {
        const messageArr = msgData.messages.filter((message) =>
          isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
        );
        count += messageArr.length;
      });
    return count;
  };

  const categories = [
    {
      name: "sensitivePrompts",
      label: "Prompts",
      color: theme.palette.peach.main,
    },
    {
      name: "vectorDB",
      label: "Context",
      color: theme.palette.laurelGreen.main,
    },
    {
      name: "sensitiveResponses",
      label: "Responses",
      color: theme.palette.paleBlue.main,
    },
  ];

  const getTotalMessagesCount = () => {
    switch (sensitiveDataFilter) {
      case "7days": {
        return (
          langchainFrameworkData[0]?.data?.totalPrompts +
          langchainFrameworkData[1]?.data?.totalPrompts
        );
      }
      case "1day": {
        return (
          langchainFrameworkData[0]?.data?.last1Day +
          langchainFrameworkData[1]?.data?.last1Day
        );
      }
      case "1hour": {
        return (
          langchainFrameworkData[0]?.data?.last1Hour +
          langchainFrameworkData[1]?.data?.last1Hour
        );
      }
      default: {
        return 0;
      }
    }
  };

  return (
    <>
      <Box sx={styles.cardContainer}>
        {dataView ? (
          <>
            {isFetchingImpactedStoresData ? (
              <Loader height={dataView ? "68px" : "159px"} />
            ) : (
              <>
                <Typography
                  variant="caption"
                  color={theme.palette.surface80.main}
                  sx={styles.cardTitle}
                >
                  Last 30 Days
                </Typography>
                <Box sx={styles.cloudResourcesContainer}>
                  <Box
                    sx={{
                      ...styles.resourcesTextContainer,
                      ...styles.largeContainer,
                    }}
                  >
                    <Box sx={styles.resourcesText}>
                      <Typography
                        color={theme.palette.surface70.main}
                        sx={styles.noOfResourcesText}
                      >
                        {dataView
                          ? getNumberAbbreviation(resources?.currentResources)
                          : formatBytes(resources?.currentResources)}
                      </Typography>
                      <Box sx={styles.resourcePercent}>
                        {resources.deltaIncrease ? (
                          <ArrowUpwardIcon sx={styles.upArrowIcon} />
                        ) : (
                          <ArrowDownwardIcon sx={styles.upArrowIcon} />
                        )}
                        <Typography
                          variant="caption"
                          color={theme.palette.primaryBlue.main}
                        >
                          {`${resources.increaseInPercent}% ${
                            dataView
                              ? `(
                    ${getNumberAbbreviation(resources?.deltaResources)})`
                              : ""
                          }`}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      color={theme.palette.surface70.main}
                      variant="caption"
                    >
                      {dashboardViewLense === DASHBOARD_DATA_VIEW
                        ? "Cloud Resources"
                        : "Data Used for Training"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      ...styles.resourcesTextContainer,
                      ...styles.smallContainer,
                    }}
                  >
                    <Typography
                      color={theme.palette.surface70.main}
                      sx={styles.noOfResourcesText}
                    >
                      {resources?.users}
                    </Typography>
                    <Typography
                      color={theme.palette.surface70.main}
                      variant="caption"
                    >
                      {dashboardViewLense === DASHBOARD_DATA_VIEW
                        ? "Users"
                        : "Number of Datastores used"}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Box sx={styles.cardHeader}>
              <Typography
                sx={styles.cardHeading}
                color={theme.palette.surface80.main}
              >
                Prompt Session Summary
              </Typography>
              {/*<Divider orientation="vertical" flexItem />*/}
              <Select
                minWidth={100}
                options={sensitiveFilters}
                value={sensitiveDataFilter}
                variant="standard"
                sx={styles?.select}
                onChange={(event: any) => {
                  setSensitiveDataFilter(event.target.value);
                }}
              />
            </Box>
            <Box sx={styles.aiCardContent}>
              <Box sx={styles.promptMetric}>
                <Box sx={styles.messageBlock}>
                  <Typography
                    variant="caption"
                    component="span"
                    color={theme.palette.surface70.main}
                  >
                    Sensitive Messages
                  </Typography>
                  <Typography
                    variant="caption"
                    component="span"
                    color={theme.palette.critical.main}
                  >
                    {getResponsePromptCount("vectorDB") +
                      getResponsePromptCount("sensitiveResponses") +
                      getResponsePromptCount("sensitivePrompts")}
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box sx={styles.messageBlock}>
                  <Typography
                    variant="caption"
                    component="span"
                    color={theme.palette.surface70.main}
                  >
                    Total Prompts
                  </Typography>
                  <Typography
                    variant="caption"
                    component="span"
                    color={theme.palette.surface70.main}
                  >
                    {getTotalMessagesCount()}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.promptsTrends}>
                <PromptsTrendsGraph
                  graphData={graphData}
                  svgHeight={120}
                  ticks={2}
                />
                <Box sx={styles.legendContainer}>
                  {categories.map((category, index) => (
                    <LegendWithColor
                      key={index}
                      {...category}
                      label={`${category?.label} (${getResponsePromptCount(
                        category?.name
                      )})`}
                    />
                  ))}
                </Box>
              </Box>
              {/*<Box sx={styles.firstRow}>
                <Box
                  sx={{
                    ...styles.resourcesTextContainer,
                    ...styles.smallContainer,
                  }}
                >
                  <Typography
                    color={theme.palette.surface70.main}
                    sx={styles.noOfResourcesText}
                  >
                    {getResponsePromptCount("sensitivePrompts")}
                  </Typography>
                  <Typography
                    color={theme.palette.surface70.main}
                    variant="caption"
                  >
                    Prompts
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...styles.resourcesTextContainer,
                    ...styles.smallContainer,
                  }}
                >
                  <Typography
                    color={theme.palette.surface70.main}
                    sx={styles.noOfResourcesText}
                  >
                    {getResponsePromptCount("vectorDB")}
                  </Typography>
                  <Typography
                    color={theme.palette.surface70.main}
                    variant="caption"
                  >
                    Context
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...styles.resourcesTextContainer,
                    ...styles.smallContainer,
                  }}
                >
                  <Typography
                    color={theme.palette.surface70.main}
                    sx={styles.noOfResourcesText}
                  >
                    {getResponsePromptCount("sensitiveResponses")}
                  </Typography>
                  <Typography
                    color={theme.palette.surface70.main}
                    variant="caption"
                  >
                    Responses
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  ...styles.resourcesTextContainer,
                  ...styles.smallContainer,
                }}
              >
                <Typography
                  color={theme.palette.surface70.main}
                  sx={styles.noOfResourcesText}
                >
                  {totalResponses}
                </Typography>
                <Typography
                  color={theme.palette.surface70.main}
                  variant="caption"
                >
                  Total Prompts & Responses
                </Typography>
              </Box>*/}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default UsageCard;
