import { useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/appContext';
import { isContainsValue } from '../../utils/commonUtils';
import Box from '../common/Box';
import Loader from '../common/Loader';
import NumericTag from '../common/NumericTag';
import Typography from '../common/Typography';

export type AchievementsType = {
  issueResolved: { resolved: number; remaining: number; tag: string };
  policyTagAdded: { resolved: number; remaining: number; tag: string };
  dataStoresDerisked: { resolved: number; remaining: number; tag: string };
  shadowDataSecured: { resolved: number; remaining: number; tag: string };
};

const AchievementsCard = () => {
  const achievementsInitialData: AchievementsType = {
    issueResolved: { resolved: 0, remaining: 0, tag: '' },
    policyTagAdded: { resolved: 0, remaining: 0, tag: '' },
    dataStoresDerisked: { resolved: 0, remaining: 0, tag: '' },
    shadowDataSecured: { resolved: 0, remaining: 0, tag: '' },
  };
  const [achievementsData, setAchievementsData] = useState<AchievementsType>(
    achievementsInitialData
  );
  const { achievements, isFetchingAchievements, getAchievements } = useContext(
    AppContext
  );
  const theme = useTheme();
  const styles = {
    cardContainer: {
      backgroundColor: theme.palette.surface10.main,
      minWidth: '30%',
      width: '30%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(5.25),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
    },
    cardContent: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridRowGap: theme.spacing(6.5),
    },
    cardTitle: {
      fontSize: '13px',
      fontWeight: theme.typography.fontWeightMedium,
    },
    cardRow: {
      display: 'flex',
    },
  };

  useEffect(() => {
    getAchievements();
  }, []);

  useEffect(() => {
    if (isContainsValue(achievements)) {
      const cardData = {
        issueResolved: {
          resolved: achievements?.resolvedIssued,
          remaining: 0,
          tag: 'Issues resolved',
        },
        policyTagAdded: {
          resolved: achievements?.policiesTags,
          remaining: 0,
          tag: 'Policy tags added',
        },
        dataStoresDerisked: {
          resolved: achievements?.deRiskedData,
          remaining: 0,
          tag: 'Data stores de-risked',
        },
        shadowDataSecured: {
          resolved: achievements?.shadowData,
          remaining: 0,
          tag: 'Shadow data secured',
        },
      };
      setAchievementsData(cardData);
    }
  }, [achievements]);

  return (
    <Box sx={styles.cardContainer}>
      {isFetchingAchievements ? (
        <Loader />
      ) : (
        <>
          <Typography sx={styles.cardTitle}>Achievements</Typography>
          <Box sx={styles.cardContent}>
            {Object.values(achievementsData).map(
              ({ resolved, remaining, tag }, index) => {
                return (
                  <NumericTag
                    key={index}
                    number={resolved}
                    status={remaining > 0 ? `Remaining ${remaining}` : ''}
                    tag={tag}
                  />
                );
              }
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AchievementsCard;
