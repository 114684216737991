import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "./utils";

export const TOASTER_TYPES = {
  SUCCESS: 1,
  ERROR: 2,
  WARNING: 3,
  INFO: 4,
};

export const Toaster = (type = TOASTER_TYPES.SUCCESS, msg) => {
  switch (type) {
    case TOASTER_TYPES.SUCCESS:
      toast.success(capitalizeFirstLetter(msg));
      break;
    case TOASTER_TYPES.ERROR:
      toast.error(capitalizeFirstLetter(msg));
      break;
    case TOASTER_TYPES.WARNING:
      toast.warning(capitalizeFirstLetter(msg));
      break;
    case TOASTER_TYPES.INFO:
      toast.info(capitalizeFirstLetter(msg));
      break;
    default:
      toast.success(capitalizeFirstLetter(msg));
      break;
  }
  // if (type === 1) {
  //   toast.success(capitalizeFirstLetter(msg));
  // } else if (type === 2) {
  //   toast.error(capitalizeFirstLetter(msg));
  // } else if (type === 3) {
  //   toast.warning(capitalizeFirstLetter(msg));
  // } else if (type === 4) {
  //   toast.info(capitalizeFirstLetter(msg));
  // } else {
  //   toast.success(capitalizeFirstLetter(msg));
  // }
};
