import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Typography from "../common/Typography";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PlaylistAddCheckCircleOutlinedIcon from "@mui/icons-material/PlaylistAddCheckCircleOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";

const PolicyInfoCard = () => {
  const theme = useTheme();
  const { selectedPolicy } = useContext(AppContext);

  const styles = {
    cardContainer: {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.surfaceCard.main,
      padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    section: {
      width: "100%",
      display: "flex",
      overflowWrap: "break-word",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    sectionContent: {
      lineHeight: "18px",
    },
    sectionHeader: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    icons: {
      color: theme.palette.surface40.main,
      fontSize: "14px",
    },
    sectionTitle: {
      fontSize: "13px",
    },
    contentUnderline: {
      textDecoration: "underline",
      cursor: "pointer",
    },
    textButton: {
      textAlign: "left",
    },
  };

  const navigateToLink = () => {
    window.open("");
  };

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.section}>
        <Box sx={styles.sectionHeader}>
          <ContentPasteIcon sx={styles.icons} />
          <Typography
            color={theme.palette.surface40.main}
            sx={styles.sectionTitle}
          >
            Description
          </Typography>
        </Box>
        <Typography
          color={theme.palette.surface80.main}
          variant="caption"
          sx={styles.sectionContent}
        >
          {selectedPolicy?.description || "No description"}
        </Typography>
      </Box>
      <Box sx={styles.section}>
        <Box sx={styles.sectionHeader}>
          <PlaylistAddCheckCircleOutlinedIcon sx={styles.icons} />
          <Typography
            color={theme.palette.surface40.main}
            sx={styles.sectionTitle}
          >
            Remediation
          </Typography>
        </Box>
        <Typography
          color={theme.palette.surface80.main}
          variant="caption"
          sx={styles.sectionContent}
        >
          {selectedPolicy?.remediation || "No remediation"}
        </Typography>
      </Box>
      <Box sx={styles.section}>
        <Box sx={styles.sectionHeader}>
          <LinkOutlinedIcon sx={styles.icons} />
          <Typography
            color={theme.palette.surface40.main}
            sx={styles.sectionTitle}
          >
            Links
          </Typography>
        </Box>

        <Typography
          color={theme.palette.surface80.main}
          variant="caption"
          // onClick={navigateToLink}
          sx={{ ...styles.sectionContent }}
        >
          -
        </Typography>
      </Box>
    </Box>
  );
};

export default PolicyInfoCard;
