import { axiosDenaliPrivate } from "../../utils/ApiHandler";

/**
 * Provides the list of all dataStores.
 * @returns -- Accounts list
 */
export const fetchAssetsSearch = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/assets/search`, params);
};

export const fetchAssets = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/assets`, params);
};
