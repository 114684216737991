import { axiosDenaliPrivate } from "../../utils/ApiHandler";

/**
 * Provides the list of all dataStores.
 * @returns -- Accounts list
 */
export const fetchDataStoreTags = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/tags/get`, params);
};

/**
 * Add the tag with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const updateDataStoreTags = (params) => {
  return axiosDenaliPrivate.put(`/api/v1/tags/set`, params);
};

export const updateDatastore = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/datastoretype/update`, params);
};

export const fetchDatastoresSearch = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/datastores`, params);
};

export const fetchDatastoreNames = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/datastores/names`, params);
};

export const fetchTicketDetail = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/jira/bulk/get`, params);
};

export const fetchSensitiveInfo = (params) => {
  return axiosDenaliPrivate.post(
    `/api/v1/data/classification/overview`,
    params
  );
};
export const fetchDatastoreDescription = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/datastoredescription`, params);
};
