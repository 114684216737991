import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./Trends.scss";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../../utils/Constants";
import { getCustomerId } from "../../../utils/SessionHelper";
import { fetchTrendsStats } from "../../../redux/slices/dashboardSlice";
import { useDispatch } from "react-redux";

am4core.addLicense("ch-custom-attribution");
am4core.useTheme(am4themes_animated);

const Trend = ({ loading, selectedAccount }) => {
  const dispatch = useDispatch();
  const [trends, setTrends] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("data");

  const updateTrendsData = (stats) => {};

  const getTrends = async (selectedAccount) => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let resp = await dispatch(fetchTrendsStats(payload));
    if (resp && resp.payload && !resp.payload.err) {
      setTrends(resp.payload.reverse());
    }
  };

  const loadChart = () => {
    let chart = am4core.create("trends", am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = trends;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.category = "at";
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.renderer.baseGrid.stroke = "#141432";
    dateAxis.renderer.labels.template.fill = am4core.color("#797979");
    dateAxis.renderer.labels.template.fontSize = 12;
    let dateAxislabel = dateAxis.renderer.labels.template;
    dateAxislabel.maxWidth = 120;

    dateAxis.renderer.axisFills.template.disabled = false;
    dateAxis.renderer.axisFills.template.fill = am4core.color("#D2D68D");
    dateAxis.renderer.axisFills.template.fillOpacity = 0.2;

    dateAxis.fillRule = function (dataItem) {
      var date = new Date(dataItem.value);
      if (
        (date.getDay() == 0 || date.getDay() == 6) &&
        dateAxis.gridInterval.timeUnit == "day" &&
        dateAxis.gridInterval.count == 1
      ) {
        dataItem.axisFill.visible = true;
      } else {
        dataItem.axisFill.visible = false;
      }
    };

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.maxPrecision = 0;
    valueAxis.baseValue = 0;
    valueAxis.renderer.grid.template.stroke = "#8f8c8c";
    valueAxis.renderer.labels.template.fill = am4core.color("#797979");
    valueAxis.renderer.labels.template.fontSize = 12;
    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.name = "Policy Violation";
    series.dataFields.valueY = "dsNonCompliant";
    series.dataFields.dateX = "at";
    series.strokeWidth = 2;
    series.tensionX = 0.77;
    series.strokeDasharray = "3,3";
    series.stroke = am4core.color("#FECF1C");

    dateAxis.events.on("sizechanged", function (ev) {
      let axis = ev.target;
      axis.renderer.labels.template.rotation = -45;
      axis.renderer.labels.template.horizontalCenter = "right";
      axis.renderer.labels.template.verticalCenter = "middle";

      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);

      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color("#777D74");
    chart.legend.labels.template.fontSize = 12;

    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.name = "At risk";
    series2.dataFields.valueY = "dsAtRisk";
    series2.dataFields.dateX = "at";
    series2.strokeWidth = 2;
    series2.tensionX = 0.77;
    series2.stroke = am4core.color("#E32B2A");

    let series3 = chart.series.push(new am4charts.LineSeries());
    series3.name = "Cloud Anomaly";
    series3.fontSize = 12;
    series3.dataFields.valueY = "dsActiveAnomaly";
    series3.dataFields.dateX = "at";
    series3.strokeWidth = 2;
    series3.tensionX = 0.77;

    // green fill

    // let range = valueAxis.createSeriesRange(series);
    // range.value = 0;
    // range.endValue = -1000;
    // range.contents.stroke = am4core.color("#3260E5");
    // range.contents.fill = range.contents.stroke;

    // Add scrollbar
    // let scrollbarX = new am4charts.XYChartScrollbar();
    // scrollbarX.series.push(series);
    // chart.scrollbarX = scrollbarX;

    chart.cursor = new am4charts.XYCursor();
  };

  useEffect(() => {
    if (trends) {
      loadChart();
    }
  }, [trends, selectedFilter]);

  useEffect(() => {
    getTrends(selectedAccount);
  }, [selectedAccount]);

  return (
    <div>
      <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
        <li class="nav-item" onClick={() => setSelectedFilter("data")}>
          <a
            class="nav-link active"
            id="data"
            data-toggle="pill"
            href="#data"
            role="tab"
            aria-controls="data"
            aria-selected="false"
          >
            Data
          </a>
        </li>{" "}
        {/* <li class="nav-item" onClick={() => setSelectedFilter("issues")}>
          <a
            class="nav-link"
            id="issues"
            data-toggle="pill"
            href="#issues"
            role="tab"
            aria-controls="issues"
            aria-selected="false"
          >
            Issues
          </a>
        </li> */}
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="data"
          data-toggle="pill"
          role="tabpanel"
          aria-labelledby="high-risk-tab"
        >
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <div id="trends" style={{ height: "375px", width: "100%" }}></div>
          </BlockUi>
        </div>
        <div
          class="tab-pane fade show"
          id="issues"
          data-toggle="pill"
          role="tabpanel"
          aria-labelledby="high-risk-tab"
        >
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <div id="trends" style={{ height: "375px", width: "100%" }}></div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default Trend;
