import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'reactjs-popup/dist/index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@mui/material';
import ThemeObject from './ThemeProvider';
import AppContextProvider from './context/appContext';

ReactDOM.render(
  <GoogleOAuthProvider clientId="118420081966-s1n42272jcg4r5l4erufahti23ubp8o0.apps.googleusercontent.com">
    <React.StrictMode>
      <ThemeProvider theme={ThemeObject.dark}>
        <Provider store={store}>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
