import { ModalProps, useTheme } from '@mui/material';
import MUIDrawer, { DrawerProps } from '@mui/material/Drawer';

interface MUIDrawerProps extends DrawerProps {
  width: number | string;
  anchor: 'bottom' | 'left' | 'right' | 'top';
  open: boolean;
  onClose?: ModalProps['onClose'];
}

const Drawer = (props: MUIDrawerProps) => {
  const theme = useTheme();
  const styles = {
    drawerStyle: {
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: props.width,
        borderRadius: theme.spacing(1, 0, 0, 1),
        backgroundColor: theme.palette.surface0.main,
        backgroundImage: 'none'
      }
    }
  };
  return <MUIDrawer {...props} sx={styles.drawerStyle} />;
};

export default Drawer;
