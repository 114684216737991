import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
am4core.addLicense("ch-custom-attribution");

const PieChart = ({ cloudEnvIssues }) => {
  const loadChart = (barId = null) => {
    const dummyData = [
      {
        title: "Dummy",
        disabled: true,
        issueCount: 100,
        color: am4core.color("#ffffff14"),
        opacity: 0.3,
        strokeDasharray: "4,4",
        tooltip: "",
      },
    ];
    // Create chart instance
    var chart = am4core.create("pie-chart-2", am4charts.PieChart);
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.paddingLeft = 20;
    chart.paddingRight = 20;

    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "issueCount";
    pieSeries.dataFields.category = "title";

    pieSeries.slices.template.propertyFields.fill = "color";

    // Disable tooltips
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    let label = pieSeries.createChild(am4core.Label);
    label.text = "Data";

    // label
    label.fill = am4core.color("white");
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 14;

    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(40);
    chart.radius = am4core.percent(100);
    chart.fill = am4core.color("#A955FF");

    pieSeries.slices.template.propertyFields.fill = "color";

    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.adapter.add("html", function (disabled, target) {
      if (target.dataItem?.dataContext.issueCount > 0) {
        return `<img width="20px" height="20px" src="{src}" />`;
      }
    });

    pieSeries.labels.template.adapter.add(
      "disabled",
      function (disabled, target) {
        if (target.dataItem?.dataContext.issueCount === 0) {
          return true;
        }
      }
    );

    pieSeries.labels.template.html = `<img width="20px" height="20px" src="{src}" />`;
    pieSeries.labels.template.radius = am4core.percent(-20);
    pieSeries.labels.template.fill = am4core.color("white");
    pieSeries.slices.template.tooltipHTML = "<b>{title}</b> ({issueCount})";

    // Disable tooltips
    pieSeries.slices.template.strokeWidth = 1;

    // Add a legend
    const checkIssueCount = () => {
      const issue = (value) => value.issueCount > 0;
      return cloudEnvIssues.some(issue);
    };

    // Add data

    if (cloudEnvIssues.length > 0 && checkIssueCount()) {
      chart.data = cloudEnvIssues;
    } else {
      chart.data = dummyData;
      pieSeries.labels.template.html = "";
      pieSeries.slices.template.tooltipText = "";
      pieSeries.slices.template.tooltipHTML = "";
      pieSeries.labels.template.disabled = true;
    }
  };

  useEffect(() => {
    loadChart();
  }, [cloudEnvIssues]);

  return (
    <div>
      <div id="pie-chart-2" style={{ height: "275px", width: "100%" }}></div>
    </div>
  );
};

export default PieChart;
