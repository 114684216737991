import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import DonutChart from "../common/DonutChart";
import Loader from "../common/Loader";
import Slider from "../common/Slider";
import TextButton from "../common/TextButton";
import Tooltip from "../common/Tooltip";
import Typography from "../common/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  getFormattedTitle,
  getNumberAbbreviation,
  isContainsValue,
  isDataWithinDefinedDate,
} from "../../utils/commonUtils";
import { useHistory } from "react-router-dom";
import { CLOUD_ENV } from "../../routes/constants";
import { DASHBOARD_DATA_VIEW, langchainData } from "../constants/constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import LangchainIcon from "../../assets/img/langchain.svg";

import Bar from "../common/Bar";
import { langchainFrameworkData } from "../../mock-data/llmFrameworkPageData";

export interface Props {}

export const cloudRiskDonutChartTheme = [
  {
    color: "#ACCB9E",
  },
  {
    color: "#92CAFF",
  },
  {
    color: "#A75E6E",
  },
  {
    color: "#96DFD8",
  },
  {
    color: "#F5B483",
  },
];

const CloudRiskCard = () => {
  const {
    dashboardViewLense,
    impactedDataStores,
    cloudRiskData,
    isFetchingCloudRiskData,
    getCloudRiskData,
    promptTimeSeries,
    setPromptsTimeSeries,
    sensitiveDataFilter,
  } = useContext(AppContext);
  const [firstRender, setFirstRender] = useState(false);
  const history = useHistory();
  const [dataStoreCount, setDataStoreCount] = useState(0);
  const [cloudRisk, setCloudRisk] = useState<{
    totalValue: number;
    defaultColor: string;
    innerRadius: number | undefined;
    outerRadius: number | undefined;
    boxSize: number | undefined;
    donutHeight: number;
    donutWidth: number;
  }>({
    totalValue: 0,
    defaultColor: "",
    innerRadius: 0,
    outerRadius: 0,
    boxSize: 0,
    donutHeight: 0,
    donutWidth: 0,
  });
  const [donutData, setDonutData] = useState([]);
  const dataView = dashboardViewLense === DASHBOARD_DATA_VIEW;
  // const [hopFilter, setHopFilter] = useState(0);

  // useEffect(() => {
  //   getCloudRiskData(hopFilter / 10);
  // }, [hopFilter]);

  useEffect(() => {
    if (isContainsValue(impactedDataStores)) {
      setDataStoreCount(impactedDataStores?.datastores);
    }
  }, [impactedDataStores]);

  useEffect(() => {
    getCloudRiskData();
  }, [dashboardViewLense]);

  const navigateToCloudRisk = (title, path) => {
    sessionStorage.setItem("source_category", title);
    history.push(CLOUD_ENV + path);
  };

  const sliderLabels = [
    {
      value: 0,
      label: "Default",
    },
    {
      value: 10,
      label: "1",
    },
    {
      value: 20,
      label: "2",
    },
    {
      value: 30,
      label: "3",
    },
    {
      value: 40,
      label: "4",
    },
    {
      value: 50,
      label: "5+",
    },
  ];

  useEffect(() => {
    if (cloudRiskData?.length > 0) {
      setDonutData(
        cloudRiskData?.map(
          (
            {
              color,
              issueCount,
              dsCount,
              title,
              cloudEnvClass,
            }: {
              color: string;
              issueCount: number;
              dsCount: number;
              title: string;
              cloudEnvClass: string;
            },
            index: number
          ) => ({
            value: issueCount,
            color: cloudRiskDonutChartTheme[index]?.color,
            title,
            cloudEnvClass,
            dsCount,
          })
        )
      );
      const totalValue = cloudRiskData?.reduce(
        (
          acc: number,
          curr: { color: string; issueCount: number; title: string }
        ) => acc + curr.issueCount,
        0
      );

      setCloudRisk({
        totalValue,
        defaultColor: theme.palette.surface20.main,
        innerRadius: 115,
        outerRadius: 130,
        donutHeight: 90,
        donutWidth: 90,
        boxSize: 300,
      });
      setFirstRender(true);
    }
  }, [cloudRiskData]);

  const theme = useTheme();
  const styles = {
    flexAlignCenter: {
      display: "flex",
      alignItems: "center",
    },
    cloudRiskContainer: {
      width: "100%",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    contentContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      gap: theme.spacing(5),
      paddingRight: theme.spacing(1),
      marginX: theme.spacing(2),
    },
    cardHeader: {
      width: "100%",
      paddingX: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    moreDetailsButton: {
      display: "flex",
      marginLeft: "auto",
    },
    donutContainer: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(2.5),
      justifyContent: "space-between",
    },
    donutContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
    },
    donut: {
      width: "30%",
      display: "flex",
      justifyContent: "center",
    },
    donutDataContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      justifyContent: "center",
      width: "70%",
      overflow: "hidden",
    },
    donutDataRow: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    dataRiskFromCloudChartLegendLabel: {
      maxWidth: "144px",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    rowBadgeContainer: {
      gap: theme.spacing(0.5),
    },
    rowBadge: (color: string) => ({
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: "50%",
      backgroundColor: color,
    }),
    badgeValue: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    filterText: {
      fontWeight: theme.typography.fontWeightLight,
    },
    filterBarContainer: {
      visibility: "hidden",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    filterTextIcon: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    tooltipContent: {
      display: "flex",
      height: "100%",
    },
    infoIcon: {
      color: theme.palette.surface40.main,
      height: "16px",
      width: "16px",
    },
    donutInnerText: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      width: "66%",
      marginLeft: "auto",
      marginBottom: theme.spacing(1),
    },
    totalValueText: { fontFamily: "Manrope", lineHeight: "100%" },
    totalIssuesText: {
      letterSpacing: "1.5px",
    },
    langchainCol: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "center",
    },
    countOfLLMs: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: "150px",
    },
    langchainCell: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "start",
    },
    langchainVersion: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
  };

  const navigateToLangchain = (langchainData) => {
    history.push(`/framework/${langchainData?.id}`);
    setPromptsTimeSeries(langchainData.data.promptResponseTimeData);
  };

  const getResponsePromptCount = (promptsData, keyword) => {
    let count = 0;
    promptsData
      .find((dataItem) => dataItem.tabValue === keyword)
      ?.data.forEach((msgData) => {
        const messageArr = msgData.messages.filter((message) =>
          isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
        );
        count += messageArr.length;
      });
    return count;
  };

  return (
    <Box sx={styles.cloudRiskContainer}>
      {isFetchingCloudRiskData && !firstRender ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ ...styles.cardHeader, ...styles.flexAlignCenter }}>
            <Typography
              color={theme.palette.surface80.main}
              sx={styles.cardTitle}
            >
              {dataView ? "Data Risk From Cloud" : "Frameworks"}
            </Typography>
            {dataView ? (
              <TextButton
                hideText={true}
                endIcon={<KeyboardArrowRightIcon />}
                sx={styles.moreDetailsButton}
                link="vulnerabilities/cloud-env/all"
              >
                View All
              </TextButton>
            ) : (
              <></>
            )}
          </Box>
          {dataView ? (
            <Box sx={styles.contentContainer}>
              <Box sx={styles.donutContent}>
                <Box sx={styles.donutInnerText}>
                  <Typography
                    variant="tooltip"
                    color={theme.palette.surface40.main}
                    sx={styles.totalIssuesText}
                  >
                    TOTAL ISSUES
                  </Typography>
                  <Typography
                    variant="h4"
                    color={theme.palette.surface80.main}
                    sx={styles.totalValueText}
                  >
                    {getNumberAbbreviation(cloudRisk?.totalValue)}
                  </Typography>
                </Box>
                <Box sx={styles.donutContainer}>
                  <Box sx={styles.donut}>
                    <DonutChart
                      donutData={donutData}
                      totalValue={cloudRisk?.totalValue}
                      defaultColor={cloudRisk?.defaultColor}
                      variant="MultiColor"
                      innerRadius={cloudRisk?.innerRadius}
                      outerRadius={cloudRisk?.outerRadius}
                      boxSize={cloudRisk?.boxSize}
                      donutHeight={cloudRisk?.donutHeight}
                      donutWidth={cloudRisk?.donutWidth}
                      classNameForGraph="cloudRiskDonut"
                    />
                  </Box>
                  <Box sx={styles.donutDataContainer}>
                    {donutData.map(
                      (
                        { color, value, title, cloudEnvClass, dsCount },
                        index
                      ) => {
                        const percentValue =
                          cloudRisk?.totalValue !== 0 && value !== 0
                            ? parseFloat(
                                `${(value / cloudRisk?.totalValue) * 100}`
                              ).toPrecision(2)
                            : 0;
                        return (
                          <Box
                            sx={styles.donutDataRow}
                            key={index}
                            onClick={() =>
                              navigateToCloudRisk(title, cloudEnvClass)
                            }
                          >
                            <Box
                              sx={{
                                ...styles.rowBadgeContainer,
                                ...styles.flexAlignCenter,
                              }}
                            >
                              <Box sx={styles.rowBadge(color)}></Box>
                              <Tooltip
                                title={`${title} - ${value}  (${percentValue}%)`}
                              >
                                <div style={styles.tooltipContent}>
                                  <Typography
                                    variant="caption"
                                    color={theme.palette.surface60.main}
                                    sx={
                                      styles.dataRiskFromCloudChartLegendLabel
                                    }
                                  >
                                    {getFormattedTitle(title)}
                                  </Typography>
                                </div>
                              </Tooltip>
                            </Box>
                            <Box sx={styles.badgeValue}>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface80.main}
                              >
                                {getNumberAbbreviation(value)}
                              </Typography>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}
                              >
                                ({percentValue}%)
                              </Typography>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.filterBarContainer}>
                <Box sx={styles.filterTextIcon}>
                  <Typography
                    variant="caption"
                    color={theme.palette.surface80.main}
                    sx={styles.filterText}
                  >
                    Filter By Number of Hops
                  </Typography>
                  <InfoOutlinedIcon sx={styles.infoIcon} />
                </Box>
                <Slider
                  defaultValue={0}
                  step={10}
                  min={0}
                  max={50}
                  marks={sliderLabels}
                  // onChange={(event: Event, value: number | number[]) => setHopFilter(value as number)}
                />
              </Box>
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell>Name</TableCell>
                  <TableCell>Sensitive Messages</TableCell>
                  {/*<TableCell>Framework</TableCell>
                  <TableCell>Version</TableCell>*/}
                </TableHead>
                <TableBody>
                  {langchainFrameworkData?.map((cloudData: any) => {
                    const messageCount =
                      getResponsePromptCount(
                        cloudData.data.promptsResponseSummary,
                        "vectorDB"
                      ) +
                      getResponsePromptCount(
                        cloudData.data.promptsResponseSummary,
                        "sensitiveResponses"
                      ) +
                      getResponsePromptCount(
                        cloudData.data.promptsResponseSummary,
                        "sensitivePrompts"
                      );
                    return (
                      <TableRow onClick={() => navigateToLangchain(cloudData)}>
                        <TableCell>
                          <Box sx={styles.langchainCell}>
                            <img src={LangchainIcon} alt="Langchain" />
                            <Box sx={styles.langchainVersion}>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}
                              >
                                {cloudData?.data?.frameworkSummaryDetails?.name}
                              </Typography>
                              <Typography
                                variant="tooltip"
                                color={theme.palette.surface60.main}
                              >
                                {cloudData?.version}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        {/*<TableCell>
                          <Typography
                            component="span"
                            variant="caption"
                            color={theme.palette.surface60.main}
                          >
                            <img src={LangchainIcon} alt="Langchain" /> Langchain
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="caption"
                            color={theme.palette.surface60.main}
                          >
                            {cloudData?.version}
                          </Typography>
                        </TableCell>*/}
                        <TableCell sx={styles.countOfLLMs}>
                          <Typography
                            variant="caption"
                            color={theme.palette.surface60.main}
                          >
                            {messageCount}
                          </Typography>
                          <Bar
                            isLastBar={false}
                            xLabel={""}
                            barBackColor={theme.palette.surface20.main}
                            barFaceColor={theme.palette.lightMagenta.main}
                            rectWidth={(messageCount / 5) * 100}
                            index={messageCount}
                            totalValue={messageCount}
                            barLabel={""}
                            barValue={messageCount}
                            type={""}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default CloudRiskCard;
