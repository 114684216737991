import { useTheme } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { DSTable } from "../../types/type";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import Typography from "../common/Typography";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import Tooltip from "../common/Tooltip";
import { useHistory } from "react-router-dom";
import gcpIcon from "../../assets/img/gcp.svg";
import awsIcon from "../../assets/img/awsIcon.svg";
import secureData from "../../assets/img/secureData.svg";

interface Props {
  tabData: Array<DSTable>;
  activeTabNumber: string;
  listOfTabs: { value: string; label: string; filter?: string }[];
  data: [];
}

const CardTable = ({ tabData, activeTabNumber, listOfTabs, data }: Props) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("");
  const [activeTabData, setActiveTabData] = useState<DSTable>();
  const theme = useTheme();
  const styles = {
    cardContainer: { maxHeight: "305px", height: "305px" },
    tableImpactValue: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    content: {
      overflowY: "auto",
    },
    tabContainer: {
      display: "flex",
      width: "100%",
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    nameCol: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    sensitiveDataCell: {
      display: "flex",
      justifyContent: "center",
    },
  };

  useEffect(() => {
    setActiveTab(activeTabNumber);
  }, [activeTabNumber]);

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setActiveTabData(tabData?.find((tab) => tab?.tabIndex === newValue));
  };

  const navigateToLLM = (datastoreId, assetId) => {
    if (datastoreId && assetId) {
      history.push(`/llm/${datastoreId}/${assetId.replace("/", "_")}`);
    }
  };

  return (
    <Box>
      <Box sx={styles.tabContainer}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          {listOfTabs.map((tab: { label: string }, index: number) => (
            <Tab {...tab} key={index} disabled={tab?.label === "AI"} />
          ))}
        </Tabs>
      </Box>
      <Box sx={styles.content}>
        {tabData?.map((tab) => (
          <TabPanel value={activeTab} index={tab.tabIndex} key={tab?.tabIndex}>
            <TableContainer sx={styles.cardContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Labels</TableCell>
                </TableHead>
                <TableBody>
                  {tab?.data?.map((tableData) => (
                    <TableRow
                      onClick={() =>
                        navigateToLLM(tableData?.id, tableData?.assetId)
                      }
                    >
                      <TableCell>
                        <Tooltip title={tableData?.assetId}>
                          <div>
                            <Box sx={styles.nameCol}>
                              <img
                                src={
                                  !tableData?.cloud ||
                                  tableData?.cloud === "aws"
                                    ? awsIcon
                                    : gcpIcon
                                }
                                alt="gcp_icon"
                                width={24}
                              />
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}
                              >
                                {tableData?.name}
                              </Typography>
                            </Box>
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {capitalizeFirstLetter(tableData?.category)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {/*<Tooltip title={tableData?.labels}>
                          <div>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {tableData?.labels}
                            </Typography>
                          </div>
                        </Tooltip>*/}
                        <Box sx={styles.sensitiveDataCell}>
                          <img
                            src={secureData}
                            alt="sensitive_data"
                            height={20}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default CardTable;
