import { Checkbox, IconButton, useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import { Dialog, DialogActions } from "../../components/common/Dialog";
import Typography from "../../components/common/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "../../components/common/Button";
import { Autocomplete } from "../../components/common/AutoComplete";
import { TextField } from "../../components/common/TextField";
import { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const policyType = [
  "Restricted topics in prompt session ",
  "Sensitive identifiers in prompt session",
  "Fine-tuned on sensitive data",
  "Trained with data from publicly exposed datastore",
  "Fine-tuned with data violating residency",
];

const topicData = [
  { title: "All Topics" },
  { title: "HR Content" },
  { title: "Medical Advice" },
  { title: "Financial Reports" },
  { title: "Board meeting" },
];

const frameworkData = [
  { title: "All framework" },
  { title: "AcmeMortgageApp-1" },
  { title: "Langchain-d6ba4" },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AddPolicyPage = ({ isOpen, handleClose }) => {
  const [policyData, setPolicyData] = useState<any>({
    type: "",
    name: "",
    topic: [],
    owner: "John Doe",
    framework: [],
  });
  const theme = useTheme();
  const styles = {
    dialog: {
      display: "flex",
      flexDirection: "column",
      ".MuiPaper-root": {
        background: theme.palette.surface0.main,
      },
    },
    dialogHeader: {
      display: "flex",
      padding: 3,
      alignItems: "center",
    },
    backIcon: {
      color: "#9AA5C1",
      fontSize: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
    dialogBody: {
      display: "flex",
      flex: 1,
      borderBottom: `0.5px solid ${theme.palette.surface20.main}`,
      paddingX: 7.5,
      paddingY: 2,
      flexDirection: "column",
      gap: theme.spacing(4.5),
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      width: "40%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    formHeading: {
      fontSize: "14px",
      opacity: 0.6,
    },
  };

  const handleSubmit = () => {
    const data: any = localStorage.getItem("policyTableData");
    const array = JSON.parse(data);
    let mainData;
    if (array?.length > 0) {
      mainData = [...array, { ...policyData, createdOn: new Date() }];
    } else {
      mainData = [{ ...policyData, createdOn: new Date() }];
    }
    localStorage.setItem("policyTableData", JSON.stringify(mainData));
    setPolicyData({
      type: "",
      name: "",
      topic: [],
      framework: [],
    });
    handleClose();
  };

  return (
    <Dialog fullScreen open={isOpen} sx={styles.dialog}>
      <Box sx={styles.dialogHeader}>
        <IconButton onClick={handleClose}>
          <ArrowBackIosIcon sx={styles.backIcon} />
        </IconButton>
        <Typography variant="subtitle1" color={theme.palette.surface50.main}>
          Add Policy
        </Typography>
      </Box>
      <Box sx={styles.dialogBody}>
        <Box sx={styles.formBox}>
          <Typography
            color={theme.palette.surface50.main}
            sx={styles.formHeading}
          >
            Policy Details
          </Typography>
          <Box sx={styles.form}>
            <Autocomplete
              label="Type"
              variant="standard"
              options={policyType}
              value={policyData?.type}
              onChange={(e: any, newValue: string) => {
                setPolicyData({ ...policyData, type: newValue });
              }}
            />
            {policyData?.type && (
              <>
                <TextField
                  label="Name"
                  variant="standard"
                  fullWidth
                  placeholder="Custom Name"
                  value={policyData?.name}
                  onChange={(e: any) => {
                    setPolicyData({ ...policyData, name: e.target.value });
                  }}
                />
                <Autocomplete
                  multiple
                  label="Topic"
                  variant="standard"
                  options={topicData}
                  value={policyData?.topic}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </li>
                  )}
                  onChange={(e: any, newValue: string) => {
                    setPolicyData({ ...policyData, topic: newValue });
                  }}
                />
              </>
            )}
          </Box>
        </Box>
        {policyData?.type && (
          <Box sx={styles.formBox}>
            <Typography
              color={theme.palette.surface50.main}
              sx={styles.formHeading}
            >
              Target
            </Typography>
            <Box sx={styles.form}>
              <Autocomplete
                multiple
                label="Framework"
                variant="standard"
                options={frameworkData}
                getOptionLabel={(option) => option.title}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title}
                  </li>
                )}
                onChange={(e: any, newValue: string) => {
                  setPolicyData({ ...policyData, framework: newValue });
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add Policy
        </Button>
      </DialogActions>
    </Dialog>
  );
};
