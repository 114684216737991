import { IconButton, useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "../../components/common/Dialog";
import { TextField } from "../../components/common/TextField";
import TextButton from "../../components/common/TextButton";
import AddIcon from "@mui/icons-material/Add";
import Divider from "../../components/common/Divider";
import Typography from "../../components/common/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import DescriptionIcon from "@mui/icons-material/Description";
import Button from "../../components/common/Button";
import { useRef, useState } from "react";

const AlertBox = ({ type, title, createdAt, onDelete }) => {
  const theme = useTheme();
  const styles = {
    alert: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#2A2D40",
      padding: theme.spacing(1),
      borderRadius: "2px",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      width: "85%",
    },
    deleteIcon: {
      fontSize: theme.spacing(2.5),
      width: theme.spacing(2.5),
      color: theme.palette.primaryBlue.main,
    },
    detailHeader: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    icon: {
      color: theme.palette.surface40.main,
      fontSize: theme.spacing(2),
      width: theme.spacing(2),
    },
    createdAtBox: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    createdAt: {
      fontSize: theme.spacing(1.25),
    },
  };
  return (
    <Box sx={styles.alert}>
      <Box sx={styles.details}>
        <Box sx={styles.detailHeader}>
          {type === "text" ? (
            <WysiwygIcon sx={styles.icon} />
          ) : (
            <DescriptionIcon sx={styles.icon} />
          )}
          <Typography color={theme.palette.surface80.main} variant="subtitle2">
            {title}
          </Typography>
        </Box>
        <Box style={styles.createdAtBox}>
          <Typography
            color={theme.palette.surface50.main}
            sx={styles.createdAt}
          >
            Added on:
          </Typography>
          <Typography
            color={theme.palette.surface50.main}
            sx={styles.createdAt}
          >
            {createdAt}
          </Typography>
        </Box>
      </Box>
      <IconButton onClick={onDelete}>
        <DeleteIcon sx={styles.deleteIcon} />
      </IconButton>
    </Box>
  );
};

export const AddTopic = ({ isOpen, handleClose }) => {
  const [topicData, setTopicData] = useState<any>({
    topic: "",
    description: "",
    examples: [],
  });
  const [exampleValue, setExampleValue] = useState("");

  const theme = useTheme();
  const fileInputRef = useRef<any>();

  const styles = {
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    textArea: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    addButton: {
      marginLeft: "auto",
    },
    icon: {
      fontSize: theme.spacing(2),
      width: theme.spacing(2),
    },
    dividerBody: {
      display: "flex",
      gap: theme.spacing(2),
    },
    divider: {
      width: "100%",
      height: "1px",
      background: "#3A3D51",
      border: "none",
      margin: "auto",
      flexShrink: 1,
    },
    orText: {
      fontSize: theme.spacing(1.5),
    },
    uploadFileBox: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    exampleText: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1.25),
    },
    alertHeading: {
      fontSize: theme.spacing(1.25),
    },
  };

  const handleChange = (event) => {
    setTopicData({
      ...topicData,
      examples: [
        ...topicData?.examples,
        {
          id: topicData.examples.length + 1,
          value: event.target.files[0]?.name,
          createdOn: new Date(),
        },
      ],
    });
  };

  const onAddExample = () => {
    if (exampleValue) {
      setTopicData({
        ...topicData,
        examples: [
          ...topicData?.examples,
          {
            id: topicData.examples.length + 1,
            value: exampleValue,
            createdOn: new Date(),
          },
        ],
      });
      setExampleValue("");
    }
  };

  const handleSubmit = () => {
    const data: any = localStorage.getItem("topicsTableData");
    const array = JSON.parse(data);
    let mainData;
    if (array?.length > 0) {
      mainData = [...array, { ...topicData, createdOn: new Date() }];
    } else {
      mainData = [{ ...topicData, createdOn: new Date() }];
    }
    localStorage.setItem("topicsTableData", JSON.stringify(mainData));
    setTopicData({
      topic: "",
      description: "",
      examples: [],
    });
    handleClose();
  };

  const onDeleteExample = (id) => {
    if (id) {
      const filteredData = topicData.examples.filter((item) => item.id !== id);
      setTopicData({ ...topicData, examples: filteredData });
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle handleClose={handleClose}>Add Topic</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <TextField
          label="Name"
          variant="standard"
          value={topicData?.topic}
          onChange={(e) =>
            setTopicData({ ...topicData, topic: e.target.value })
          }
        />
        <TextField
          label="Description"
          variant="standard"
          value={topicData?.description}
          onChange={(e) =>
            setTopicData({ ...topicData, description: e.target.value })
          }
        />
        <Box sx={styles.textArea}>
          <TextField
            label="Examples"
            multiline
            maxRows={4}
            minRows={4}
            variant="outlined"
            value={exampleValue}
            onChange={(e) => setExampleValue(e.target.value)}
          />
          <TextButton
            startIcon={<AddIcon />}
            sx={styles.addButton}
            onClick={onAddExample}
          >
            Add
          </TextButton>
        </Box>
        <Box sx={styles.dividerBody}>
          <Divider variant="middle" sx={styles.divider} />
          <Typography color={theme.palette.surface40.main} sx={styles.orText}>
            OR
          </Typography>
          <Divider variant="middle" sx={styles.divider} />
        </Box>
        <Box sx={styles.uploadFileBox}>
          <Typography color={theme.palette.surface40.main} variant="body2">
            You can also
          </Typography>
          <TextButton onClick={() => fileInputRef?.current?.click()}>
            Upload Examples
          </TextButton>
          <input
            onChange={handleChange}
            multiple={false}
            ref={fileInputRef}
            type="file"
            hidden
          />
        </Box>
        <Box sx={styles.exampleText}>
          <Typography
            color={theme.palette.surface40.main}
            sx={styles.alertHeading}
          >
            Examples Added or Uploaded ({topicData?.examples?.length})
          </Typography>

          {topicData?.examples?.length > 0 &&
            topicData?.examples.map((item) => {
              let date = new Date(item?.createdOn);
              let dateString = [
                date.getFullYear(),
                ("0" + (date.getMonth() + 1)).slice(-2),
                ("0" + date.getDate()).slice(-2),
              ].join("-");

              const hoursAndMinutes =
                date.getHours() +
                ":" +
                date.getMinutes() +
                ":" +
                date.getSeconds();
              return (
                <AlertBox
                  type="text"
                  title={item.value}
                  createdAt={`${dateString} | ${hoursAndMinutes}`}
                  onDelete={() => onDeleteExample(item.id)}
                />
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add Topic
        </Button>
      </DialogActions>
    </Dialog>
  );
};
