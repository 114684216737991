import ConstructionIcon from "@mui/icons-material/Construction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/common/Table";
import {
  listPoliciesAsync,
  deletePolicyAsync,
} from "../../redux/slices/policySlice";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Divider from "../common/Divider";
import Loader from "../common/Loader";
import NoData from "../common/NoData";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import { TableSearchProp } from "../../types/type";
import { useHistory } from "react-router-dom";
import CompliancePolicyModal from "../modal/compliance-policy-modal/CompliancePolicyModal";
import { getAllPolicies } from "../../redux/slices/policySlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { listDataStoreNames } from "../../redux/slices/dataStoreSlice";
import { DASHBOARD_DATA_VIEW } from "../constants/constants";

const PolicyStoreTable = ({ onSearch }: { onSearch: TableSearchProp }) => {
  const {
    dashboardViewLense,
    drawerData,
    allPolicies,
    policyData,
    policyStoreDetails,
    selectedPolicy,
    setSelectedPolicy,
    isFetchingPolicyStoreDetails,
    getPolicyDetails,
    modifyPolicyDetails,
    isFetchingModifyPolicyDetails,
    getModifyPolicyDetails,
  } = useContext(AppContext);

  const [selectedPolicyCategoryPolicies, setSelectedPolicyCategoryPolicies] =
    useState<
      Array<{ dsCount: number; policyType: string; id: string; title: string }>
    >([{ dsCount: 0, policyType: "", id: "", title: "" }]);

  const [mainData, setMainData] = useState<
    Array<{ dsCount: number; policyType: string; id: string; title: string }>
  >([{ dsCount: 0, policyType: "", id: "", title: "" }]);

  const [isEditPolicyModalOpen, setIsEditPolicyModalOpen] = useState(false);
  const [policiesList, setPoliciesList] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [selectedAccountIDs, setSelectedAccountIDs] = useState();
  const [alertingPolicies, setAlertingPolicies] = useState<Array<any>>([]);
  const [compliancePolicies, setCompliancePolicies] = useState<Array<any>>([]);
  const [datastoreMappings, setDatastoreMappings] = useState({});
  const [policyId, setPolicyId] = useState("");

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const styles = {
    tableContainer: {
      maxHeight: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    tableHead: {
      "&.MuiTableCell-head": {
        paddingY: theme.spacing(1.25),
        zIndex: 0,
      },
    },
    tableCell: {
      fontSize: "13px",
      cursor: "pointer",
      "&.MuiTableCell-root": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
    },
    tableCellLast: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      gap: theme.spacing(1.5),
      zIndex: 0,
    },
    tableCellAction: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.26, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    moreIcon: {
      color: theme.palette.primaryBlue.main,
      width: "18px",
      cursor: "pointer",
    },
  };

  const getDatastoreNames = async (ids) => {
    let payload = {
      customerId: getCustomerId(),
      datastores: ids,
    };
    let res = await dispatch(listDataStoreNames(payload));
    if (res && res.payload) {
      setDatastoreMappings(res.payload);
    }
  };

  useEffect(() => {
    if (!compliancePolicies?.length) return;
    let ids: Array<any> = [];
    for (let i of compliancePolicies) {
      for (let j of i?.target?.datastoreIds) {
        ids.push(j);
      }
    }
    getDatastoreNames(ids);
  }, [compliancePolicies]);

  useEffect(() => {
    const filter = drawerData?.currentSelectedPolicy?.policyType;
    let llmFilter = {};
    if (dashboardViewLense !== DASHBOARD_DATA_VIEW) {
      llmFilter = { llm: true };
    }
    getPolicyDetails({
      filters: filter,
      ...llmFilter,
    });
  }, [drawerData?.currentSelectedPolicy?.policyType]);

  useEffect(() => {
    if (policyStoreDetails?.length > 0) {
      const totalCount = policyStoreDetails.reduce(
        (accumulator, item) => accumulator + item?.dsCount,
        0
      );
      const policyName =
        drawerData?.currentSelectedPolicy?.barLabel ||
        drawerData?.currentSelectedPolicy?.name;

      const policyType = drawerData?.currentSelectedPolicy?.policyType;
      let policyData: any = [];
      if (drawerData?.activeTab === "policy-category") {
        policyData.push({
          isAggregate: true,
          title: `All ${policyName} Policies (${policyStoreDetails?.length})`,
          dsCount: totalCount,
          policyType,
          id: policyType + "0",
        });
      }
      policyData = [...policyData, ...policyStoreDetails];
      setSelectedPolicyCategoryPolicies(policyData);
      setMainData(policyData);
    } else {
      setSelectedPolicyCategoryPolicies([]);
      setMainData([]);
    }
  }, [drawerData, policyStoreDetails]);

  useEffect(() => {
    if (drawerData?.activeTab === "policy-category")
      setSelectedPolicy(selectedPolicyCategoryPolicies[0]);

    if (
      (!selectedPolicy || selectedPolicy?.id === "") &&
      drawerData?.activeTab === "policy"
    )
      setSelectedPolicy(policyData[0]);
  }, [selectedPolicyCategoryPolicies]);

  useEffect(() => {
    if (onSearch?.isEnter && onSearch?.searchText) {
      const filteredData = selectedPolicyCategoryPolicies?.filter((row) =>
        row?.title
          ?.toLocaleLowerCase()
          .includes(onSearch?.searchText.toLocaleLowerCase())
      );
      setMainData(filteredData);
    } else {
      setMainData(selectedPolicyCategoryPolicies);
    }
  }, [onSearch?.isEnter]);

  const modifyPolicy = (policy) => {
    const policyCfgId = policyData.find(
      (policyItem) => policyItem?.id === policy?.id
    )?.policyCfgId;
    setPolicyId(policyCfgId);
    setIsEditPolicyModalOpen(true);
  };

  useEffect(() => {
    if (policyId) {
      getModifyPolicyDetails(policyId);
    }
  }, [policyId]);

  useEffect(() => {
    if (selectedPolicy?.id) {
      const el = document.querySelector("#row-selected");
      if (el) el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [document.getElementById("row-selected")]);

  const getPredefinesPolicieList = async () => {
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId: selectedAccountIDs ? selectedAccountIDs : "",
    };
    let res = await dispatch(getAllPolicies(payload));
    //let res = allPolicies;
    let updatedPolicies: Array<{ value: string; label: string }> = [];
    if (res && res.payload && !res.payload.err) {
      res.payload.map((policy) => {
        updatedPolicies.push({
          value: policy.rule,
          label: policy.description,
        });
      });
      setPoliciesList(updatedPolicies || []);
    }
  };

  useEffect(() => {
    getPredefinesPolicieList();
  }, []);

  const refreshAccounts = async () => {
    getAllPoliciesList();
  };

  const getAllPoliciesList = async () => {
    let res = await dispatch(listPoliciesAsync());
    if (res && res.payload && !res.payload.err) {
      if (res.payload && res.payload.length > 0) {
        let updatedCompliancePolicy: Array<any> = [];
        let updatedAlertingPolicy: Array<any> = [];
        res.payload.map((policy) => {
          if (policy.category == "alerting") {
            updatedAlertingPolicy.push(policy);
          }
          if (policy.category == "compliance") {
            updatedCompliancePolicy.push(policy);
          }
        });
        setAlertingPolicies(updatedAlertingPolicy);
        if (policyId) {
          setCompliancePolicies(
            filterCompliancePolicies(updatedCompliancePolicy)
          );
        } else {
          setCompliancePolicies(updatedCompliancePolicy);
        }
      }
    }
  };

  const filterCompliancePolicies = (updatedPolicies) => {
    let filteredPolicies = [];
    filteredPolicies = updatedPolicies.filter(
      (policyItem) => policyItem.policyId === policyId
    );
    return filteredPolicies;
  };

  return (
    <TableContainer sx={styles.tableContainer}>
      {!isFetchingPolicyStoreDetails && (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHead}>Policy Name</TableCell>
              <TableCell sx={styles.tableHead}>Policy Tags</TableCell>
              <TableCell sx={styles.tableHead} align="right">
                Violation Instance
              </TableCell>
              <TableCell sx={styles.tableHead} align="right">
                {dashboardViewLense === DASHBOARD_DATA_VIEW
                  ? "Impacted Data Stores"
                  : "Impacted LLMs"}
              </TableCell>
              <TableCell sx={styles.tableHead}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mainData?.map((row: any, index: number) => (
              <TableRow
                key={index}
                selected={row?.id === selectedPolicy?.id}
                onClick={() => setSelectedPolicy(row)}
                id={row?.id === selectedPolicy?.id ? "row-selected" : ""}
              >
                <TableCell
                  width="25%"
                  component="td"
                  scope="row"
                  sx={styles.tableCell}
                >
                  <Typography variant="caption">{row?.title} </Typography>
                  {!row.isAggregate && row?.id ? (
                    <>
                      <br />
                      <Typography
                        variant="caption"
                        sx={{ color: theme.palette.surface60.main }}
                      >
                        Policy ID: {row?.id}
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell
                  width="15%"
                  component="td"
                  sx={styles.tableCell}
                  scope="row"
                >
                  <Typography variant="caption">
                    {row?.policyTags || "-"}
                  </Typography>
                </TableCell>
                <TableCell
                  width="20%"
                  component="td"
                  sx={styles.tableCell}
                  scope="row"
                  align="right"
                >
                  <Typography variant="caption">
                    {row?.violationInstances || "-"}
                  </Typography>
                </TableCell>
                <TableCell
                  width="20%"
                  component="td"
                  sx={styles.tableCell}
                  scope="row"
                  align="right"
                >
                  <Typography variant="caption">{row?.dsCount}</Typography>
                </TableCell>
                <TableCell
                  width="20%"
                  component="td"
                  sx={styles.tableCell}
                  scope="row"
                >
                  {!row?.isAggregate && (
                    <Box sx={styles.tableCellLast}>
                      <TextButton
                        startIcon={<ConstructionIcon sx={styles.moreIcon} />}
                        sx={styles.tableCellAction}
                        onClick={(event) => {
                          event.stopPropagation();
                          modifyPolicy(row);
                        }}
                      >
                        Modify Policy
                      </TextButton>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={styles.tableActionDivider}
                      />
                      <MoreVertIcon sx={styles.moreIcon} />
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!isFetchingPolicyStoreDetails && mainData?.length < 1 && (
        <NoData
          customText={
            onSearch?.searchText
              ? `No search results containing ${onSearch?.searchText} found`
              : "No data found."
          }
        />
      )}
      {isFetchingPolicyStoreDetails && <Loader />}
      {isEditPolicyModalOpen && policyId === modifyPolicyDetails?.id && (
        <CompliancePolicyModal
          setSelectedAccountIDs={setSelectedAccountIDs}
          refreshAccounts={refreshAccounts}
          policyAction="edit"
          policyItem={modifyPolicyDetails}
          handleModal={() => {
            setPolicyId("");
            setIsEditPolicyModalOpen(false);
          }}
          policiesList={policiesList}
          setDatastoreMappings={setDatastoreMappings}
        />
      )}
    </TableContainer>
  );
};
export default PolicyStoreTable;
