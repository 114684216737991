import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { isContainsValue } from "../../utils/commonUtils";
import Box from "../common/Box";
import DonutChart from "../common/DonutChart";
import Typography from "../common/Typography";

const RiskScore = () => {
  const [riskDataScore, setRiskDataScore] = useState([{ value: 0 }]);
  const [riskDataStatus, setRiskDataStatus] = useState("");
  const { riskScore, getRiskScoreData } = useContext(AppContext);
  const theme = useTheme();

  useEffect(() => {
    getRiskScoreData();
  }, []);

  useEffect(() => {
    if (isContainsValue(riskScore)) {
      setRiskDataScore([{ value: riskScore?.issueUberScore }]);
      setRiskDataStatus(riskScore?.issueUberLabel);
    }
  }, [riskScore]);

  const styles = {
    donutContainer: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "center",
    },
    riskTextContainer: {
      display: "flex",
      flexDirection: "column",
    },
    fontMedium: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    letterSpacing: {
      letterSpacing: "1.5px",
    },
  };

  const colorValue =
    riskDataScore[0].value >= 0 && riskDataScore[0].value <= 25
      ? theme.palette.success.main
      : riskDataScore[0].value > 25 && riskDataScore[0].value <= 80
      ? theme.palette.warning.main
      : theme.palette.critical.main;
  const riskStatus = riskDataStatus
    ? riskDataStatus?.charAt(0) + riskDataStatus?.slice(1)?.toLowerCase()
    : "";
  return (
    <Box sx={styles.donutContainer}>
      <DonutChart
        donutData={riskDataScore}
        totalValue={100}
        defaultColor={theme.palette.surface20.main}
        variant="AutoColor"
        innerRadius={110}
        outerRadius={135}
        boxSize={350}
        donutHeight={33}
        donutWidth={33}
      />
      <Box sx={styles.riskTextContainer}>
        <Typography
          variant="subtitle2"
          color={colorValue}
          sx={styles.fontMedium}
        >
          {`${riskDataScore[0].value.toPrecision(2)}% ${riskStatus}`}
        </Typography>
        <Typography
          variant="tooltip"
          color={theme.palette.surface40.main}
          sx={{ ...styles.fontMedium, ...styles.letterSpacing }}
        >
          DATA RISK SCORE
        </Typography>
      </Box>
    </Box>
  );
};

export default RiskScore;
