import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Divider from "../common/Divider";
import Search from "../common/Search";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import IssuesTable from "./IssueTable";
import PolicyBarChart from "./PolicyStoreBarChart";
import PolicyStoreTable from "./PolicyStoreDetailsTable";
import JiraIcon from "../../assets/img/jira.svg";
import SlackIcon from "../../assets/img/slack.svg";
import { TableSearchProp } from "../../types/type";
import {
  showLoading,
  hideLoading,
} from "../../redux/slices/globalOperationSlice";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import {
  DASHBOARD_AI_VIEW,
  DASHBOARD_DATA_VIEW,
  nonSocActionType,
  NonSOCStatusFilters,
  non_SOC,
  socActionType,
  SOCStatusFilters,
} from "../constants/constants";
import DatastoreTicketModal from "../modal/datastore-ticket-modal/DatastoreTicketModal";
import { Select } from "../common/Select";
import { MenuItem } from "../common/Menu";
import PolicyInfoCard from "../create-issue-drawer/PolicyInfoCard";
import { useDispatch, useSelector } from "react-redux";
import { listIntegrationsAsync } from "../../redux/slices/integrationSlice";
import { getCustomerId } from "../../utils/SessionHelper";

const PolicyStoresTabPanel = ({
  activeTab,
  onSearch,
}: {
  activeTab: string;
  onSearch: TableSearchProp;
}) => {
  const theme = useTheme();

  const {
    dashboardViewLense,
    drawerData,
    isFetchingPolicyViolations,
    policyViolations,
    selectedPolicy,
    selectedIssues,
    notifyOnSlackBulkAction,
    getPolicyViolations,
    jiraTicketData,
    isFetchingJiraTicket,
    getBulkTicket,
  } = useContext(AppContext);

  const [issueSearchText, setIssueSearchText] = useState("");
  const [isSOC, setIsSOC] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string>("active");
  const integrations = useSelector(
    (state: any) => state.integration.integrations
  );

  const [filterOptions, setFilterOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const dispatch = useDispatch();

  useEffect(() => {
    refreshIntegrations();
  }, []);

  const refreshIntegrations = async () => {
    dispatch(showLoading());
    dispatch(listIntegrationsAsync(getCustomerId()));
    dispatch(hideLoading());
  };

  useEffect(() => {
    if (
      !selectedPolicy?.isAggregate &&
      selectedPolicy?.id &&
      selectedPolicy?.actionType === nonSocActionType
    ) {
      getBulkTicket({ policyViolationId: selectedPolicy?.id });
    }
  }, [selectedPolicy?.id]);

  useEffect(() => {
    setFilterOptions(
      non_SOC.includes(selectedPolicy?.policyType)
        ? NonSOCStatusFilters
        : SOCStatusFilters
    );
  }, [selectedPolicy?.id]);

  useEffect(() => {
    setStatusFilter("active");
  }, [isSOC]);

  const styles = {
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(3, 2.5),
    },
    drawerHeaderTitle: {
      textTransform: "uppercase",
      gap: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    chevron: {
      color: theme.palette.surface80.main,
      cursor: "pointer",
      height: "20px",
    },
    drawerContent: {
      padding: theme.spacing(0, 2.5),
    },
    policyDetailContainer: {
      display: "flex",
      gap: activeTab === "policy-category" ? theme.spacing(2) : 0,
      marginBottom: theme.spacing(5),
    },
    card: {
      backgroundColor: theme.palette.surface10.main,
      height: "100%",
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(3, 0),
    },
    policyBarChart: {
      width: "30%",
      height: "380px",
    },
    policyCard: {
      height: "380px",
      borderRadius: theme.spacing(0.5),
      width: "30%",
      overflowY: "auto",
    },
    policyDetailsTable: {
      width: activeTab === "policy-category" ? "70%" : "70%",
      paddingTop: theme.spacing(1.5),
      overflow: "auto",
      height: "380px",
    },
    ticketsSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    ticketSectionHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.5, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    issueCard: {
      padding: theme.spacing(2, 0),
    },
    issuesHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
    issueHeaderTitle: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      color: theme.palette.primaryBlue.main,
      cursor: selectedIssues?.length > 0 ? "pointer" : "not-allowed",
      opacity: selectedIssues?.length > 0 ? 1 : 0.7,
    },
    headerIcon: {
      width: "20px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    noBottomBorder: {
      marginLeft: "auto",
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 0,
        width: "120px",
      },
      //'&.MuiInputBase-root:before': {
      //  borderBottom: 'none',
      //},
      //'&.MuiInputBase-root:hover:before': {
      //  borderBottom: 'none',
      //},
      //'&.MuiInputBase-root:hover': {
      //  color: theme.palette.surface90.main,
      //  '& .MuiSvgIcon-root': {
      //    color: theme.palette.surface90.main,
      //  },
      //},
      //'&.MuiInputBase-root:focus:before': {
      //  borderBottom: 'none',
      //},
      "& .MuiInputBase-input": {
        padding: "8px !important",
      },
    },
    select: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    filterContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "end",
    },
  };

  const onClear = () => {
    setIssueSearchText("");
    getPolicyData("");
  };

  const handleKeyDown = (e) =>
    e.key === "Enter" && getPolicyData(e.target.value);

  const getPolicyData = (search) => {
    let requestBody: any = {
      category: !selectedPolicy?.policyId ? selectedPolicy?.policyType : "",
      policyId: selectedPolicy?.policyId ? selectedPolicy?.policyId : "",
      searchKeyword: search,
    };
    if (dashboardViewLense === DASHBOARD_AI_VIEW) {
      requestBody = { ...requestBody, llm: true };
    }
    getPolicyViolations(requestBody);
  };

  useEffect(() => {
    //const newValue = !non_SOC.includes(selectedPolicy?.policyType);
    const newValue = selectedPolicy?.actionType === socActionType;
    if (isSOC !== newValue) setIsSOC(newValue);
  }, [selectedPolicy?.policyType]);

  const navigateToJira = () => {
    if (jiraTicketData?.url) window.open(jiraTicketData.url);
  };

  return (
    <>
      <Box sx={styles.policyDetailContainer}>
        {activeTab === "policy-category" && (
          <Box sx={{ ...styles.card, ...styles.policyBarChart }}>
            <PolicyBarChart
              {...drawerData?.tabData.find(
                (tab) => tab?.tabIndex === activeTab
              )}
            />
          </Box>
        )}
        <Box sx={{ ...styles.card, ...styles.policyDetailsTable }}>
          <PolicyStoreTable onSearch={onSearch} />
        </Box>
        {activeTab === "policy" && (
          <Box sx={styles.policyCard}>
            <PolicyInfoCard />
          </Box>
        )}
      </Box>
      <Box>
        <Box sx={styles.ticketsSection}>
          <Box sx={styles.ticketSectionHeader}>
            <Typography variant="body2">
              {`Policy Violations (${
                (!isFetchingPolicyViolations &&
                  policyViolations?.data?.length) ||
                0
              })`}

              {selectedPolicy && (
                <Typography
                  variant="caption"
                  color={theme.palette.surface50.main}
                >
                  {selectedPolicy?.policyName}
                </Typography>
              )}
            </Typography>
            <Search
              placeholder={"Search By Name, ID, Type"}
              value={issueSearchText}
              onChange={(e) => setIssueSearchText(e.target.value)}
              onClear={onClear}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </Box>
          <Box sx={{ ...styles.card, ...styles.issueCard }}>
            <Box sx={styles.issuesHeader}>
              <TextButton
                sx={styles.issueHeaderTitle}
                disabled={
                  isSOC ||
                  !selectedPolicy?.id ||
                  isFetchingJiraTicket ||
                  selectedPolicy?.isAggregate
                }
                onClick={() => {
                  if (integrations?.jira?.id) {
                    jiraTicketData?.ticketExist
                      ? navigateToJira()
                      : setTicketModal(true);
                  } else {
                    Toaster(TOASTER_TYPES.ERROR, "Please add Jira Integration");
                  }
                }}
              >
                <img src={JiraIcon} alt="jira" />
                {jiraTicketData?.ticketExist
                  ? "View Jira Ticket"
                  : "Create Jira Ticket"}
              </TextButton>
              <Divider orientation="vertical" sx={styles.tableActionDivider} />
              <TextButton
                sx={styles.issueHeaderTitle}
                onClick={() => {
                  if (integrations?.slack?.channelId) {
                    notifyOnSlackBulkAction({
                      policyViolationId: selectedPolicy?.id,
                    });
                  } else {
                    Toaster(
                      TOASTER_TYPES.ERROR,
                      "Please add Slack Integration"
                    );
                  }
                }}
                disabled={
                  !isSOC || selectedPolicy?.isAggregate || !selectedPolicy?.id
                }
              >
                <img src={SlackIcon} alt="slack" />
                Notify on Slack
              </TextButton>
              <Divider orientation="vertical" sx={styles.tableActionDivider} />
              <MoreVertIcon sx={styles.headerIcon} />
              <Box sx={styles.filterContainer}>
                <Select
                  value={statusFilter}
                  variant="standard"
                  label="Filter"
                  options={filterOptions}
                  sx={styles?.select}
                  onChange={(event: any) => {
                    setStatusFilter(event.target.value);
                  }}
                />
              </Box>
            </Box>
            <IssuesTable
              searchText={issueSearchText}
              statusFilter={statusFilter}
            />
          </Box>
        </Box>
        {ticketModal && (
          <DatastoreTicketModal
            type="bulk"
            ticketDetail={jiraTicketData}
            getBulkTicketDetail={() =>
              getBulkTicket({ policyViolationId: selectedPolicy?.id })
            }
            policyViolationId={selectedPolicy.id}
            handleModal={() => setTicketModal(false)}
          />
        )}
      </Box>
    </>
  );
};

export default PolicyStoresTabPanel;
