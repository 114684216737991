import React, { useEffect } from "react";
import FormatUrl from "../../utils/UrlFormatter";
import { setAuthToken } from "../../utils/SessionHelper";
import axios from "axios";
import { Toaster } from "../../utils/toaster";
import { useHistory } from "react-router-dom";

const LoginCallback = () => {
  let history = useHistory();

  const loginCallback = async () => {
    let url = FormatUrl(
      `/user/serviceProviderCallback${window.location.search}`
    );
    let res = await axios.get(url);
    res = res.data;

    if (res.message) {
      Toaster(1, res.message);
      history.push("/verify-email");
      return;
    }

    const token = res.token;
    const userInfo = token.userInfo;

    setAuthToken(token.id_token);
    localStorage.setItem("refreshToken", token.refresh_token);
    localStorage.setItem("username", userInfo.username);
    localStorage.setItem("first_name", userInfo.first_name);
    localStorage.setItem("last_name", userInfo.last_name);
    localStorage.setItem("customerRef", userInfo.customerRef);
    localStorage.setItem("userId", userInfo._id ? userInfo._id : userInfo.id);
    localStorage.setItem("customerId", userInfo.metadata.customerId);
    localStorage.setItem(
      "customer_name",
      userInfo.metadata.customer_name ? userInfo.metadata.customer_name : "CD"
    );
    window.location = "/";
  };
  useEffect(() => {
    loginCallback();
  }, []);

  return <></>;
};

export default LoginCallback;
