import { useState, useEffect } from "react";
import Jira from "../../assets/img/integrations/jira.svg";
import ServiceNow from "../../assets/img/integrations/servicenow.svg";
import Splunk from "../../assets/img/integrations/splunk.svg";
import IntegrationCards from "../cards/integration-cards";
import Slack from "../../assets/img/integrations/slack.svg";
import Wiz from "../../assets/img/integrations/wiz-1.svg";

import IntegrationModal from "../modal/integration-modal/IntegrationModal";
import "./style.scss";
import {
  showLoading,
  hideLoading,
} from "../../redux/slices/globalOperationSlice";
import Okta from "../../assets/img/integrations/okta.svg";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../utils/Constants";
import {
  wizIntegrationStatusAsync,
  listIntegrationsAsync,
  deleteIntegrationAsync,
  listOktaIntegrationsAsync,
  deleteOktaIntegrationAsync,
  listSlackIntegrationsAsync,
  deleteSlackIntegrationAsync,
  deleteWizIntegrationAsync,
} from "../../redux/slices/integrationSlice";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import DeleteConfirmationModal from "../modal/shared/DeleteConfirmationModal";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import OktaIntegrationModal from "../modal/integration-modal/OktaIntegrationModal";
import { getCustomerId, getUserId } from "../../utils/SessionHelper";
import UpdateJiraIntegrationModal from "../modal/integration-modal/updateJiraModal";
import SlackIntegrationModal from "../modal/integration-modal/SlackIntegrationModal";
import UpdateSlackIntegrationModal from "../modal/integration-modal/updateSlackModal";
import WizIntegrationModal from "../modal/integration-modal/WizIntegrationModal";
import UpdateWizIntegrationModal from "../modal/integration-modal/UpdateWizIntegrationModal";
import JiraIntegrationModal from "../modal/integration-modal/JiraIntegrationModal";
import WizStatusModal from "../modal/integration-modal/wizStatusModal";

import { ReactComponent as DeleteIcon } from "../../assets/img/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/img/edit.svg";
import { ReactComponent as ViewIcon } from "../../assets/img/view.svg";

const Index = () => {
  const IntegrationImages = {
    splung: Splunk,
    jira: Jira,
    okta: Okta,
    servicenow: ServiceNow,
    slack: Slack,
    wiz: Wiz,
  };
  const internal = [
    {
      heading: "SOC Group",
      title: "Security operation center in Cloud Defense",
      images: [
        "/static/img/connect/aws.png",
        "/static/img/connect/google-kubernetes.png",
      ],
      footer: "Running from 14-12-22",
    },
    {
      heading: "Malav",
      title: "UX team in Cloud Defense",
      images: [
        "/static/img/connect/aws.png",
        "/static/img/connect/google-kubernetes.png",
      ],
      footer: "Running from 14-12-22",
    },
    {
      heading: "Dev",
      title: "Develop team in Cloud Defense",
      images: [
        "/static/img/connect/aws.png",
        "/static/img/connect/google-kubernetes.png",
      ],
      footer: "Running from 14-12-22",
    },
  ];

  const external = [
    {
      heading: "Directory name here",
      title: "Staging account",
      images: [
        "/static/img/connect/aws.png",
        "/static/img/connect/google-kubernetes.png",
      ],
      icon: "las la-check-circle",
      status: "primary",
      footer: "Running from 14-12-22",
      logo: Splunk,
    },
    {
      heading: "Not connected...",
      title: "Dev test account",
      images: [
        "/static/img/connect/aws.png",
        "/static/img/connect/google-kubernetes.png",
      ],
      icon: "las la-minus-circle",
      status: "secondary",
      footer: "Click to add jira connection",
      logo: ServiceNow,
    },
    {
      heading: "Directory name here",
      title: "Data science account",
      images: [
        "/static/img/connect/aws.png",
        "/static/img/connect/google-kubernetes.png",
      ],
      icon: "las la-exclamation-circle",
      status: "danger",
      footer: "Lost connection 14-12-22",
      logo: Jira,
    },
  ];

  const [isViewModal, setIsViewModal] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.integration.integrations);
  const oktaIntegrations = useSelector(
    (state) => state.integration.oktaIntegrations
  );
  const slackIntegrations = useSelector(
    (state) => state.integration.slackIntegrations
  );
  const loading = useSelector((state) => state.globalOperation.loading);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [integrationType, setIntegrationType] = useState(false);
  const [card, setCard] = useState(null);
  const [edit, setEdit] = useState(false);

  const handleUpdateIntegrationModal = (
    status,
    integrationType,
    card = null,
    type = false
  ) => {
    setIsUpdateModal(status);
    setCard(card);
    setIntegrationType(integrationType.type);
    setEdit(type);
  };

  const handleIntegrationModal = (status, integrationType) => {
    setIsModal(status);
    setIntegrationType(integrationType);
  };

  useEffect(() => {
    refreshIntegrations();
  }, []);

  const refreshIntegrations = async () => {
    dispatch(showLoading());
    dispatch(listIntegrationsAsync(getCustomerId()));
    // dispatch(listOktaIntegrationsAsync(getUserId()));
    // dispatch(listSlackIntegrationsAsync());
    setIsModal(false);
    setDeleteModal(false);
    dispatch(hideLoading());
    setIsUpdateModal(false);
  };

  const handleDelete = (integration) => {
    setSelectedIntegration(integration);
    setDeleteModal(true);
  };

  const deleteIntegration = async () => {
    const resp = await dispatch(
      selectedIntegration.type === "Okta"
        ? deleteOktaIntegrationAsync(selectedIntegration._id)
        : selectedIntegration.type === "Slack"
        ? deleteSlackIntegrationAsync(selectedIntegration._id)
        : selectedIntegration.type === "Wiz"
        ? deleteWizIntegrationAsync(selectedIntegration._id)
        : deleteIntegrationAsync(selectedIntegration._id)
    );
    if (resp && resp.payload) {
      setShowLoader(false);
      Toaster(TOASTER_TYPES.SUCCESS, "Integration deleted successfully.");
      refreshIntegrations();
    } else {
      setShowLoader(false);
      Toaster(TOASTER_TYPES.ERROR, "Failed to delete integration.");
    }
  };

  return (
    <>
      <div className="settings-container">
        <div className="integration px-3 mb-2">
          <div class="row mb-2 mt-1 integration-title">
            <div class="col col-xl-12 align-self-end">
              <span> Integrations</span>
            </div>
          </div>
        </div>

        <div className="px-3">
          {/* <div className="ml-4 mt-2">
            <p> External integration</p>
            <hr />
          </div> */}
          <div className="row">
            {/* <div className="col-auto">
              <b className="page-heading align-middle fs-14">SEARCH & FILTERS</b>
            </div>

            <div className="col-auto">
              <div className="form-group">
                <select className="form-control form-control-sm">
                  <option>KEYWORK</option>
                </select>
              </div>
            </div>

            <div className="col-auto">
              <div className="form-group">
                <input
                  className="form-control form-control-sm"
                  placeholder="Enter Keyword"
                  type={"text"}
                />
              </div>
            </div> */}

            {/* <div className="col-sm-4 mb-1">
              <span class="badge badge-primary">
                Primary
                <i class="las la-times-circle"></i>
              </span>
              <span class="badge badge-primary">
                Primary
                <i class="las la-times-circle"></i>
              </span>
              <span class="badge badge-primary">
                Primary
                <i class="las la-times-circle"></i>
              </span>
              <span class="badge badge-primary">
                Primary
                <i class="las la-times-circle"></i>
              </span>
            </div> */}
          </div>
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <IntegrationCards
              oktaIntegrations={oktaIntegrations}
              handleModal={handleIntegrationModal}
            />
          </BlockUi>

          <div className="account mt-2">
            <div className="row">
              {integrations &&
                Object.keys(integrations).map((card, index) => (
                  <>
                    {card && integrations[card] ? (
                      <div className="col-6 col-md-4 col-lg-3" key={index}>
                        <div
                          className={`iq-card iq-card-inner account-card ${
                            card === "wiz" ? "wiz-background" : ""
                          }`}
                        >
                          <div className="row m-0">
                            <div className={`col-sm-2 check check-primary`}>
                              <i class="las la-check-circle"></i>
                            </div>
                            <div className="col-sm-10">
                              <h6 className={`card-title-primary`}>
                                {card.ProviderName}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="card-body"
                            style={
                              card === "wiz"
                                ? {
                                    padding: 0,
                                  }
                                : {}
                            }
                          >
                            <img
                              src={IntegrationImages[card]}
                              style={
                                card === "wiz"
                                  ? {
                                      height: "60px",
                                    }
                                  : {}
                              }
                            />
                          </div>
                          <div className="footer">
                            <h6 className="primary mb-2">
                              {`Running from `}
                              <Moment format="MM/DD/YY">
                                {card?.metadata?.createdAt}
                              </Moment>
                            </h6>
                            <div>
                              {card === "wiz" ? (
                                <ViewIcon
                                  className="card-action-images"
                                  onClick={() => setIsViewModal(true)}
                                />
                              ) : (
                                ""
                              )}

                              <EditIcon
                                className="card-action-images"
                                onClick={() =>
                                  handleUpdateIntegrationModal(
                                    true,
                                    integrations[card],
                                    integrations[card],
                                    true
                                  )
                                }
                              />
                              <DeleteIcon
                                className="card-action-images"
                                onClick={() => handleDelete(integrations[card])}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </div>
          </div>
          {/* <div className="account mt-2">
            <div className="row">
              {integrations &&
                integrations.map((card, index) => (
                  <div className="col-6 col-md-4 col-lg-3" key={index}>
                    <div className="iq-card iq-card-inner account-card">
                      <div className="row m-0">
                        <div className={`col-sm-2 check check-primary`}>
                          <i class="las la-check-circle"></i>
                        </div>
                        <div className="col-sm-10">
                          <h6 className={`card-title-primary`}>{card.name}</h6>
                        </div>
                      </div>
                      <div className="card-body">
                        <img src={Jira} />
                      </div>
                      <div className="footer">
                        <h6 className="primary">
                          {`Running from `}
                          <Moment format="DD/MM/YY">
                            {card?.metadata?.createdAt}
                          </Moment>
                        </h6>
                        <div>
                          <i
                            class="las la-edit"
                            onClick={() =>
                              handleUpdateIntegrationModal(true, "jira", card)
                            }
                          ></i>
                          <i
                            class="las la-trash-alt"
                            onClick={() => handleDelete(card)}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {slackIntegrations &&
                slackIntegrations.map((card, index) => (
                  <div className="col-6 col-md-4 col-lg-3" key={index}>
                    <div className="iq-card iq-card-inner account-card">
                      <div className="row m-0">
                        <div className={`col-sm-2 check check-primary`}>
                          <i class="las la-check-circle"></i>
                        </div>
                        <div className="col-sm-10">
                          <h6 className={`card-title-primary`}>{card.name}</h6>
                        </div>
                      </div>
                      <div className="card-body">
                        <img src={Jira} />
                      </div>
                      <div className="footer">
                        <h6 className="primary">
                          {`Running from `}
                          <Moment format="DD/MM/YY">
                            {card?.metadata?.createdAt}
                          </Moment>
                        </h6>
                        <div>
                          <i
                            class="las la-edit"
                            onClick={() =>
                              handleUpdateIntegrationModal(true, "jira", card)
                            }
                          ></i>
                          <i
                            class="las la-trash-alt"
                            onClick={() => handleDelete(card)}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {oktaIntegrations && oktaIntegrations.ProviderName ? (
                <>
                  {oktaIntegrations &&
                    [oktaIntegrations].map((card, index) => (
                      <div className="col-6 col-md-4 col-lg-3" key={index}>
                        <div className="iq-card iq-card-inner account-card">
                          <div className="row m-0">
                            <div className={`col-sm-2 check check-primary`}>
                              <i class="las la-check-circle"></i>
                            </div>
                            <div className="col-sm-10">
                              <h6 className={`card-title-primary`}>
                                {card.ProviderName}
                              </h6>
                            </div>
                          </div>
                          <div className="card-body">
                            <img src={Okta} />
                          </div>
                          <div className="footer">
                            <h6 className="primary">
                              {`Running from `}
                              <Moment format="DD/MM/YY">
                                {card?.metadata?.createdAt}
                              </Moment>
                            </h6>
                            <div>
                              <i
                                class="las la-trash-alt"
                                onClick={() => handleDelete(card)}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                ""
              )}
            </div>
          </div> */}
          {/* <button
            className="btn btn-info btn-sm create-btn"
            onClick={() => setIsModal(!isModal)}
          >
            <img src={CrossIcon} />
            ADD NEW ACTIVE DIRECTORY
          </button> */}
          {/* <div className="ml-4 mt-2">
            <p> Internal active directory</p>
            <hr />
          </div> */}
          {/* <div className="account">
            {internal.map((card, index) => (
              <div className="iq-card iq-card-inner account-card" key={index}>
                <div className="row">
                  <div className="col-sm-2 check">
                    <i class="las la-check-circle"></i>
                  </div>
                  <div className="col-sm-8">
                    <h6 className={`card-title-primary`}>{card.heading}</h6>
                  </div>
                </div>
                <div className="card-body">
                  <h6>
                    {" "}
                    <i class="lab la-first-order-alt"></i>
                    {card.title}
                  </h6>
                </div>
                <div className="footer">
                  <h6 className="primary">{card.footer}</h6>

                  <div>
                    <i class="las la-edit"></i>
                    <i class="las la-trash-alt"></i>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      {isModal ? (
        <>
          {integrationType === "okta" ? (
            <OktaIntegrationModal
              handleModal={() => setIsModal(false)}
              refreshIntegrations={refreshIntegrations}
            />
          ) : integrationType === "slack" ? (
            <SlackIntegrationModal
              handleModal={() => setIsModal(false)}
              refreshIntegrations={refreshIntegrations}
              card={card}
            />
          ) : integrationType === "wiz" ? (
            <WizIntegrationModal
              handleModal={() => setIsModal(false)}
              refreshIntegrations={refreshIntegrations}
              card={card}
            />
          ) : integrationType === "jira" ? (
            <JiraIntegrationModal
              handleModal={() => setIsModal(false)}
              refreshIntegrations={refreshIntegrations}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {isViewModal ? (
        <WizStatusModal handleModal={() => setIsViewModal(false)} />
      ) : (
        ""
      )}
      {isUpdateModal ? (
        <>
          {integrationType === "Jira" ? (
            <UpdateJiraIntegrationModal
              card={card}
              handleModal={() => setIsUpdateModal(false)}
              refreshIntegrations={refreshIntegrations}
            />
          ) : integrationType === "Slack" ? (
            <UpdateSlackIntegrationModal
              card={card}
              handleModal={() => setIsUpdateModal(false)}
              refreshIntegrations={refreshIntegrations}
            />
          ) : integrationType === "Wiz" ? (
            <UpdateWizIntegrationModal
              handleModal={() => setIsUpdateModal(false)}
              refreshIntegrations={refreshIntegrations}
              card={card}
              edit={edit}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {deleteModal && (
        <DeleteConfirmationModal
          handleModal={() => setDeleteModal(false)}
          deleteHandle={deleteIntegration}
          showLoader={showLoader}
          modalData={{
            title: "Delete Integration",
            message: "Are you sure, you want to delete this integration?",
          }}
        />
      )}
    </>
  );
};

export default Index;
