import { axiosDenaliPrivate } from "../utils/ApiHandler";
import { removeEmpty, matchObject } from "../utils/dataHandler";

export const getDatastoreDetailsApi = async (payload) => {
  payload = removeEmpty(payload);
  const url = "/datastore/instance/detail";
  let res = await axiosDenaliPrivate.post(url, payload);
  return res.data;
};

let dataStoresData = null;
let dataStoresPayload = null;

export const getDataStoresApiV2 = async (payload) => {
  payload = removeEmpty(payload);
  let url = `/api/v1/datastores`;
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    dataStoresData = res.data;
    dataStoresPayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};

export const getDataStoresApi = async (payload) => {
  payload = removeEmpty(payload);
  // if (dataStoresData && matchObject(dataStoresPayload, payload)) return dataStoresData;
  const url = "/datastore/instance";
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    dataStoresData = res.data;
    dataStoresPayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};

export const getDatastoresByFilterApi = async (payload) => {
  payload = removeEmpty(payload);
  // if (dataStoresData && matchObject(dataStoresPayload, payload)) return dataStoresData;
  const url = "/api/v2/datastores";
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    dataStoresData = res.data;
    dataStoresPayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};

export const getAssetsApi = async (payload) => {
  payload = removeEmpty(payload);
  // if (dataStoresData && matchObject(dataStoresPayload, payload)) return dataStoresData;
  const url = "/api/v1/assets/search";
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    dataStoresData = res.data;
    dataStoresPayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};

export const getAssetStatsApi = async (payload) => {
  payload = removeEmpty(payload);
  // if (dataStoresData && matchObject(dataStoresPayload, payload)) return dataStoresData;
  const url = "/api/v1/asset/stats";
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    dataStoresData = res.data;
    dataStoresPayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};

let dataRiskPlotData = null;
let dataRiskPlotPayload = null;
export const getDataRiskPlotApi = async (payload) => {
  payload = removeEmpty(payload);
  if (dataRiskPlotData && matchObject(dataRiskPlotPayload, payload))
    return dataRiskPlotData;
  const url = "/api/v1/dashboardplot";
  let res = await axiosDenaliPrivate.post(url, payload);
  dataRiskPlotData = res.data;
  dataRiskPlotPayload = payload;
  return res.data;
};

let issuesData = null;
let issuesPayload = null;

export const getIssuesApiV2 = async (payload) => {
  payload = removeEmpty(payload);
  let url = `/api/v1/issues`;
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    issuesData = res.data;
    issuesPayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};

export const getIssuesApi = async (payload) => {
  payload = removeEmpty(payload);
  const url = "/securityissue/instance";
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    issuesData = res.data;
    issuesPayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};
let impactedDatastore = null;
let impactedDatastorePayload = null;
export const getImpactedDatastores = async (payload) => {
  payload = removeEmpty(payload);
  const url = "/api/v1/impacted/datastores";
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    impactedDatastore = res.data;
    impactedDatastorePayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};

export const issueDetailsApi = async (payload, url_) => {
  payload = removeEmpty(payload);
  const url = url_ ? url_ : "/securityissue/instance/detail";
  let res = await axiosDenaliPrivate.post(url, payload);
  return res.data;
};

export const getDataStoresStatsApi = async (payload) => {
  payload = removeEmpty(payload);
  const url = "/api/v1/customer/datastore/stats";
  try {
    let res = await axiosDenaliPrivate.post(url, payload);
    dataStoresData = res.data;
    dataStoresPayload = payload;
    return res.data;
  } catch (err) {
    return {
      err: "Failed to get result",
    };
  }
};
