import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { PolicyViolationDetailsCard } from "../LLMModel/PolicyViolationDetailsCard";
import { FrameworkSummaryCard } from "./FrameworkSummaryCard";
import { FrameworkInstanceDetails } from "./FrameworkInstanceDetails";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { FrameworkSensitiveData } from "./FrameworkSensitiveData";
import TranningDataOrchestratorGraph from "../../charts/llm-charts/training-data-orchestrator/TrainingDataOrchestratorGraph";
import { trainingDataList } from "../../shadow-llm/training_data";
import ManageTagsModal from "../../../components/modal/manage-tags-modal/ManageTagsModal";
import { useDispatch } from "react-redux";
import { getCustomerId } from "../../../utils/SessionHelper";
import { listDataStoreTagsAsync } from "../../../redux/slices/dataStoreSlice";
import Loader from "../../common/Loader";
import PromptResponseCard from "../PromptResponseCard";
import { langchainFrameworkData } from "../../../mock-data/llmFrameworkPageData";
import { AppContext } from "../../../context/appContext";

export const LLMFrameworkPage = () => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const [frameworkData, setFrameworkData] = useState<any>({});
  const [isManageTagsModal, setIsManageTagsModal] = useState(false);
  const objectId = params.id;
  const [width, setWidth] = useState<any>(0);
  const [tags, setTags] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const datastoreId = "7cd5c0fa-4321-4f89-bd9b-e9979b823c30";
  const {
    promptTimeSeries,
    setPromptsTimeSeries,
    selectedLangchainPrompts,
    setSelectedLangchainPrompts,
  } = useContext(AppContext);

  const styles = {
    loader: {
      height: "100vh",
      width: "100%",
      backgroundColor: theme.palette.surface10.main,
    },
    pageLayout: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    headerSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    headerSectionTitle: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    headerSectionTitleValue: {
      fontSize: "20px",
      fontWeight: theme.typography.fontWeightRegular,
    },
    headerSectionDescription: {
      display: "flex",
      gap: theme.spacing(1),
    },
    headerSectionDescriptionBody: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
    },
    pageDetailsSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    llmDetailsSection: {
      display: "grid",
      gridTemplateColumns: "30% 1fr",
      gap: theme.spacing(2),
    },
    cardContainer: {
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      marginY: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
    },
    cardTitle: {
      fontSize: "13px",
    },
    cardHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    cardContent: {},
    cardControls: {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (objectId) {
      const filteredData = langchainFrameworkData.find(
        (item) => item?.id === objectId
      );
      setFrameworkData(filteredData?.data);
      setPromptsTimeSeries(filteredData?.data?.promptResponseTimeData);
      setSelectedLangchainPrompts(filteredData?.data?.promptsResponseSummary);
    }
  }, [objectId]);

  useEffect(() => {
    const widthValue: any = ref?.current?.offsetWidth;
    setWidth(widthValue);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getWidth);
    // return () => {
    //   removeSelectedDataFlow();
    // };
  }, []);

  const getWidth = () => {
    setWidth(ref?.current?.clientWidth);
  };

  const refreshDataStoreTags = async () => {
    const res = await dispatch(
      listDataStoreTagsAsync({
        customerId: getCustomerId(),
        cloudAccountId: frameworkData?.cloudAccountId || "",
        datastoreId: datastoreId,
      })
    );
    let tagList: any = [];
    if (res && res.payload && !res.payload.err) {
      // Adding sensitive tag
      if (res.payload?.customerSensTag) {
        tagList.push({
          value: res?.payload?.customerSensTag,
          label: res?.payload?.customerSensTag,
          type: "sensitive",
        });
      }
      if (res.payload?.customerTags) {
        // Adding custom tag
        res.payload.customerTags.map((tag) => {
          if (
            tag.toLowerCase() === "us_access_only" ||
            tag.includes("us access only") ||
            tag.includes("us-access-only")
          ) {
            // props.setIsUSOnlyAccess(true);
          }

          tagList.push({
            value: tag,
            label: tag,
            type: "custom",
          });
        });
      }
    }
    setTags(tagList);
  };

  return (
    <>
      {isLoading ? (
        <Box sx={styles.loader}>
          <Loader />
        </Box>
      ) : (
        <Box sx={styles.pageLayout}>
          <Box sx={styles.headerSection}>
            <Box sx={styles.headerSectionTitle}>
              <Typography sx={styles.headerSectionTitleValue}>
                {frameworkData?.frameworkSummaryDetails?.name}
              </Typography>
            </Box>
          </Box>
          {Object.keys(frameworkData)?.length > 0 && (
            <Box sx={styles.pageDetailsSection}>
              <Box sx={styles.llmDetailsSection}>
                <FrameworkSummaryCard
                  summaryData={frameworkData?.frameworkSummaryDetails}
                  vectorDBDetails={frameworkData?.vectorDBDetails}
                />
                {/*<FrameworkInstanceDetails
                  instanceDetails={frameworkData?.frameworkInstanceDetails}
                  onClick={() => setIsManageTagsModal(true)}
                />
                <FrameworkSensitiveData
                  dbDetails={frameworkData?.frameworkSummaryDetails}
                  vectorDBDetails={frameworkData?.vectorDBDetails}
                  sensitiveDetails={frameworkData?.sensitiveDetails}
                />*/}
                <PromptResponseCard llmData={frameworkData} />
              </Box>

              <Box sx={styles.cardContainer}>
                <Box sx={styles.cardContent}>
                  <div ref={ref}>
                    <Box sx={styles.cardControls}>
                      <Box sx={styles.cardHeader}>
                        <Typography
                          color={theme.palette.surface80.main}
                          sx={styles.cardTitle}
                        >
                          Framework Graph
                        </Typography>
                      </Box>
                    </Box>

                    <TranningDataOrchestratorGraph
                      trainingData={trainingDataList?.filter(
                        (data) => data.Source === "Configured"
                      )}
                      llmInfo={frameworkData?.llmInfo?.llmInformation}
                      width={width}
                    />
                  </div>
                </Box>
              </Box>

              <PolicyViolationDetailsCard
                policyViolationDetails={
                  frameworkData.policyViolationDetails.policyViolations
                }
                ticketIntegration={
                  frameworkData.policyViolationDetails?.ticketIntegration
                }
              />
            </Box>
          )}
          {isManageTagsModal && (
            <ManageTagsModal
              tags={tags}
              handleModal={() => setIsManageTagsModal(false)}
              datastoreId={datastoreId}
              cloudAccountId={frameworkData?.cloudAccountId}
            />
          )}
        </Box>
      )}
    </>
  );
};
