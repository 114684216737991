import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchReports, addReport, deleteReport } from "../apis/reportsAPI";

const initialState = {
  reports: [],
};

// Async calls.
export const listReportsAsync = createAsyncThunk("report/list", async () => {
  const response = await fetchReports();
  return response.data;
});

export const addReportAsync = createAsyncThunk(
  "report/addReport",
  async (params) => {
    try {
      const response = await addReport(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const deleteReportAsync = createAsyncThunk(
  "report/deleteReport",
  async (params) => {
    try {
      const response = await deleteReport(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

// Account slice for configuring reducers and actions.
export const reportSlice = createSlice({
  name: "report",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listReportsAsync.fulfilled, (state, action) => {
        state.reports = action.payload;
      })
      .addCase(listReportsAsync.rejected, (state, action) => {
        state.reports = [];
      });
  },
});

export default reportSlice.reducer;

// Selectors will go here
export const reportById = (state, reportId) =>
  state.integrations.find((report) => report.id === reportId);
