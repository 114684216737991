import Close from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import { SyntheticEvent, useContext, useState } from "react";
import { AppContext } from "../../context/appContext";
import {
  sensitiveDataTabs,
  tabs,
  totalResponses,
} from "../../mock-data/llmPromptsData";
import { isDataWithinDefinedDate } from "../../utils/commonUtils";
import Box from "../common/Box";
import { Dialog, DialogTitle } from "../common/Dialog";
import Divider from "../common/Divider";
import Drawer from "../common/Drawer";
import NoData from "../common/NoData";
import { Select } from "../common/Select";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import { sensitiveFilters } from "../constants/constants";
import MessageDetails from "./MessageDetails";
import SensitiveDataTable from "./SensitiveDataTable";

const MessagePromptsDialog = ({ open, onClose, anchor, totalMessages }) => {
  const theme = useTheme();
  const { selectedLangchainPrompts } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("vectorDB");
  const styles = {
    dialog: {
      "& .MuiPaper-root": {
        backgroundColor: theme.palette.surface10.main,
        height: "80vh",
        padding: theme.spacing(2),
        minWidth: "98%",
        maxWidth: "98%",
      },
      "& .MuiDialog-paper": {
        backgroundImage: "none",
      },
    },
    dialogContent: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      width: "100%",
    },
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    dialogTitle: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    closeIcon: {
      cursor: "pointer",
    },
    dialogBody: {
      display: "flex",
      flexDirection: "column",
      color: theme.palette.surface80.main,
      gap: theme.spacing(2),
    },
    select: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    tab: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      gap: theme.spacing(0.5),
    },
    tabMetric: {
      fontSize: "28px",
    },
    selectedTab: (isSelected) => ({
      fontSize: "13px",
      fontWeight: isSelected
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    }),
    tableContainer: {
      marginTop: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: "40% 60%",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
    promptMetric: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    totalMessages: {
      //display: "flex",
      //alignItems: "center",
      //gap: theme.spacing(0.5),
    },
    sensitiveMessageText: {
      fontSize: "13px",
    },
    responseCountText: {
      fontSize: "24px",
    },
    messageBlock: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  };
  const {
    selectedSensitiveData,
    setSelectedSensitiveData,
    setSelectedSensitiveType,
    sensitiveDataFilter,
    setSensitiveDataFilter,
  } = useContext(AppContext);

  const constructTabData = () => {
    return tabs.map((tab) => {
      let count = 0;
      selectedLangchainPrompts
        .find((dataItem) => dataItem.tabValue === tab.value)
        ?.data.forEach((msgData) => {
          const messageArr = msgData.messages.filter((message) =>
            isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
          );
          count += messageArr.length;
        });

      return {
        value: tab.value,
        label: (
          <Box sx={styles.tab}>
            <Typography sx={styles.tabMetric}>{count}</Typography>
            <Typography sx={styles.selectedTab(activeTab === tab.value)}>
              {tab.label}
            </Typography>
          </Box>
        ),
      };
    });
  };

  const onRowClick = (data) => {
    setSelectedSensitiveData(data);
  };

  const getResponsePromptCount = (keyword) => {
    let count = 0;
    selectedLangchainPrompts
      .find((dataItem) => dataItem.tabValue === keyword)
      ?.data.forEach((msgData) => {
        const messageArr = msgData.messages.filter((message) =>
          isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
        );
        count += messageArr.length;
      });
    return count;
  };

  return (
    <Drawer
      open={open}
      anchor={anchor}
      width="95%"
      sx={{ position: "relative", height: "100vh", overflow: "auto" }}
      onClose={onClose}
    >
      <Box sx={styles.dialogContent}>
        <Box sx={styles.dialogHeader}>
          <Box sx={styles.dialogTitle}>
            <Typography color={theme.palette.surface80.main} variant="body2">
              Prompt Session Summary
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Select
              minWidth={100}
              options={sensitiveFilters}
              value={sensitiveDataFilter}
              variant="standard"
              sx={styles?.select}
              onChange={(event: any) => {
                setSensitiveDataFilter(event.target.value);
              }}
            />
          </Box>

          <Close onClick={onClose} sx={styles.closeIcon} />
        </Box>

        <Box sx={styles.dialogBody}>
          <Box sx={styles.promptMetric}>
            <Box sx={styles.messageBlock}>
              <Typography
                sx={styles.sensitiveMessageText}
                color={theme.palette.surface70.main}
              >
                Sensitive Messages
              </Typography>
              <Typography
                sx={styles.sensitiveMessageText}
                color={theme.palette.critical.main}
              >
                {getResponsePromptCount("vectorDB") +
                  getResponsePromptCount("sensitiveResponses") +
                  getResponsePromptCount("sensitivePrompts")}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={styles.messageBlock}>
              <Typography
                sx={styles.sensitiveMessageText}
                color={theme.palette.surface70.main}
              >
                Total Prompts
              </Typography>
              <Typography
                sx={styles.sensitiveMessageText}
                color={theme.palette.surface70.main}
              >
                {totalMessages}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Tabs
              value={activeTab}
              onChange={(event: Event | SyntheticEvent, newValue: string) => {
                setActiveTab(newValue);
                setSelectedSensitiveData(
                  selectedLangchainPrompts.find(
                    (tab) => tab?.tabValue === newValue
                  )
                );
              }}
            >
              {constructTabData().map(
                (tab: { value: string; label: JSX.Element }, index: number) => (
                  <Tab {...tab} key={index} />
                )
              )}
            </Tabs>
          </Box>
          <Box sx={styles.tableContainer}>
            {selectedLangchainPrompts.map((sensitiveData, index) => (
              <TabPanel value={activeTab} index={sensitiveData?.tabValue}>
                {sensitiveData?.data?.length > 0 ? (
                  <SensitiveDataTable
                    sensitiveData={sensitiveData?.data}
                    onRowClick={() => onRowClick(sensitiveData)}
                  />
                ) : (
                  <NoData
                    customText={sensitiveData?.noDataText}
                    height="200px"
                  />
                )}
              </TabPanel>
            ))}
            <MessageDetails />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MessagePromptsDialog;
