import React from "react";
import deleteAccountIcon from "../../../assets/img/icons/delete-account-icon.svg";
import "./DeleteConfirmationModal.scss";
import loaderImage from "../../../assets/img/loader.svg";
import AcceptRisk from "../../../assets/img/icons/risk-accept.svg";
// Functional component for delete integration.
const DeleteConfirmationModal = ({
  handleModal,
  deleteHandle,
  showLoader,
  modalData,
  image,
}) => {
  const handleCancel = () => {
    handleModal(false);
  };

  return (
    <div id="delete-integration">
      <div
        className="modal fade show"
        id="deleteIntegration"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteIntegrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className={
                modalData.type === "accept"
                  ? "modal-header-accept d-flex justify-content-start align-items-center"
                  : "modal-header d-flex justify-content-start align-items-center"
              }
            >
              <img
                src={
                  modalData?.type === "accept" ? AcceptRisk : deleteAccountIcon
                }
                width="40px"
                className="mr-3"
              />
              <span>{modalData?.title || "Delete "}</span>
            </div>
            <div className="modal-body">
              {showLoader && (
                <img src={loaderImage} className="modal-loading-img" />
              )}
              <div className="row">
                <div className="col-md-12 delete-message">
                  {modalData?.message || "Are you sure, you want to delete?"}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={deleteHandle}>
                {modalData.type === "accept"
                  ? "accept"
                  : modalData.type === "archive"
                  ? "archive"
                  : "delete"}
              </button>
              <span className="separator mx-3"></span>
              <button
                className="btn btn-secondary"
                disabled={showLoader}
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
