import { axiosPrivate } from "../../utils/ApiHandler";
import { getUserId } from "../../utils/SessionHelper";

/**
 * Provides the list of all reports.
 * @returns -- Accounts list
 */
export const fetchReports = () => {
  return axiosPrivate.get(`/emailScheduler/emailScheduler/${getUserId()}`);
};

/**
 * Add the report with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const addReport = (params) => {
  return axiosPrivate.post(`/emailScheduler/emailScheduler`, params);
};

/**
 * Delete the report with the specified account Id.
 * @param {object} accountId -- account Id to be deleted
 */
export const deleteReport = (reportId) => {
  return axiosPrivate.delete(`/emailScheduler/emailScheduler/${reportId}`);
};
