import { useTheme } from "@mui/material";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import Box from "./Box";
import Typography from "./Typography";

export interface Props {
  donutData: Array<{ value: number; color?: string }>;
  totalValue: number;
  defaultColor: string;
  variant: string;
  innerRadius?: number;
  outerRadius?: number;
  boxSize?: number;
  donutHeight: number;
  donutWidth: number;
  classNameForGraph?: string;
}

const variants = {
  multiColor: "MultiColor",
  autoCalculateColor: "AutoColor",
};

const DonutChart = ({
  donutData,
  totalValue,
  defaultColor,
  variant,
  innerRadius = 115,
  outerRadius = 135,
  boxSize = 500,
  donutHeight,
  donutWidth,
  classNameForGraph = "",
}: Props) => {
  const [oldPieData, setOldPieData] = useState<Array<d3.PieArcDatum<{}>>>([]);
  const [totalDonutValue, setTotalDonutValue] = useState(0);
  const theme = useTheme();
  const ref = useRef(null);
  const isAutoColor = variant === variants.autoCalculateColor;

  const styles = {
    donutContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    donutInnerText: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    totalValueText: { fontFamily: "Manrope" },
    totalIssuesText: {
      letterSpacing: "1.5px",
    },
  };

  const draw = (
    element: Element,
    data: Array<{ value: number; color?: string }>,
    totalValue: number
  ) => {
    let dataArray: { value: number; color?: string }[] = data;
    const totalChartValue = data.reduce(
      (addedValue, item) => addedValue + item.value,
      0
    );
    if (isAutoColor) {
      dataArray = [
        ...data.map((dataItem) => {
          let colorValue = dataItem?.color;
          if (!dataItem?.color) {
            const percentValue = (dataItem.value / totalValue) * 100;
            colorValue =
              percentValue >= 0 && percentValue <= 25
                ? theme.palette.success.main
                : percentValue > 25 && percentValue <= 80
                ? theme.palette.warning.main
                : theme.palette.critical.main;
          }
          return {
            value: dataItem.value,
            color: colorValue,
          };
        }),
      ];
      if (totalChartValue < totalValue) {
        dataArray.push({
          value: totalValue - totalChartValue,
          color: defaultColor,
        });
      }
    } else {
      setTotalDonutValue(totalChartValue);
    }
    const colors = [
      ...dataArray.map((item: { value: number; color?: string }) => item.color),
    ];
    const pieData = [...dataArray.map((item) => item.value)];

    let svg: any = d3.select(element);
    const width =
      classNameForGraph !== ""
        ? parseFloat(d3.select(`.${classNameForGraph}`)?.style("width"))
        : donutWidth;
    const height =
      classNameForGraph !== ""
        ? parseFloat(d3.select(`.${classNameForGraph}`)?.style("width"))
        : donutHeight;

    const isFistTimeDataPopulation = element.childNodes.length <= 0;

    if (isFistTimeDataPopulation) {
      svg = svg
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("height", height)
        .attr("width", width)
        .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
        .append("g");
    } else {
      svg = svg
        .select("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("height", height)
        .attr("width", width)
        .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
        .select("g");
    }
    svg = svg.attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`);

    const arcGenerator = d3
      .arc<d3.PieArcDatum<{}>>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const pie = d3
      .pie()
      .padAngle(0.01)
      .value((d) => {
        return typeof d === "number" ? d : d?.valueOf();
      })
      .sort(null);

    const angleInterpolation = d3.interpolate(
      pie.startAngle()([0]),
      pie.endAngle()([0])
    );
    svg.selectAll("path").remove();
    const arcs = svg
      .selectAll("path")
      .data(pie(pieData))
      .enter()
      .append("path")
      .style(
        "fill",
        (d, i) => colors[i % dataArray.length] || theme.palette.surfaceMain.main
      )
      .attr("stroke", "transparent")
      .attr("stroke-width", isAutoColor ? null : "0.3rem")
      .transition()
      .duration(500);

    if (isFistTimeDataPopulation) {
      arcs.attrTween("d", (d: d3.PieArcDatum<{}>) => {
        let originalEnd = d.endAngle;
        return ((t: number) => {
          let currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {
            return "";
          }
          d.endAngle = Math.min(currentAngle, originalEnd);
          return arcGenerator(d);
        }) as d3.PieArcDatum<{}> | any;
      });
    } else {
      arcs.attrTween("d", (d: d3.PieArcDatum<{}>, i: number) => {
        const currentArcOldData = oldPieData[i] || {};
        const {
          startAngle: oldStartAngle = 0,
          endAngle: oldEndAngle = 0,
        } = currentArcOldData;
        const startAngleInterpolator = d3.interpolate(
          oldStartAngle,
          d.startAngle
        );
        const endAngleInterpolator = d3.interpolate(oldEndAngle, d.endAngle);
        return ((t: number) => {
          d.startAngle = startAngleInterpolator(t);
          d.endAngle = endAngleInterpolator(t);
          return arcGenerator(d);
        }) as d3.PieArcDatum<{}> | any;
      });
    }
    setOldPieData(pie(pieData));
  };

  useEffect(() => {
    if (ref.current) {
      draw(ref.current, donutData, totalValue);
    }
  }, [ref, donutData]);

  return (
    <Box sx={styles.donutContainer} className={classNameForGraph}>
      <div ref={ref}></div>
      {/* {!isAutoColor && (
        <Box sx={styles.donutInnerText}>
          <Typography
            variant="h4"
            color={theme.palette.surface80.main}
            sx={styles.totalValueText}
          >
            {totalDonutValue}
          </Typography>
          <Typography
            variant="tooltip"
            color={theme.palette.surface40.main}
            sx={styles.totalIssuesText}
          >
            TOTAL ISSUES
          </Typography>
        </Box>
      )} */}
    </Box>
  );
};

export default DonutChart;
