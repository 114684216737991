import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

// Account slice for configuring reducers and actions.
export const globalOperationSlice = createSlice({
  name: "globalOperation",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showLoading: (state) => {
      state.loading = true;
      console.log("====== Showing loader =====");
    },
    hideLoading: (state) => {
      state.loading = false;
      console.log("====== Hiding loader =====");
    },
  },
});

// Exporting the reducer actions
export const { showLoading, hideLoading } = globalOperationSlice.actions;
export default globalOperationSlice.reducer;
