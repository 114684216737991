import { useTheme } from "@mui/material";
import Bar from "../common/Bar";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import { trainingDataList } from "../shadow-llm/training_data";
import AWSIcon from "../../assets/img/AWS_S3.svg";
import GCPIcon from "../../assets/img/gcpCloud.svg";
import { useHistory } from "react-router-dom";
import { gcpTrainingDataList } from "../shadow-llm/gcp_training_data";

const TrainingDataCard = ({}) => {
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    cardContainer: {
      width: "70%",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    cardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    countOfLLMs: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: "130px",
    },
    fontMedium: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    locationCell: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  };

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardHeader}>
        <Typography color={theme.palette.surface80.main} sx={styles.cardTitle}>
          Training Data
        </Typography>
        <Typography
          color={theme.palette.surface80.main}
          variant="caption"
          sx={styles.fontMedium}
        >
          Most Used Training Data Files
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>File Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Sensitive Tags</TableCell>
              <TableCell>LLMs</TableCell>
            </TableHead>
            <TableBody>
              {[...trainingDataList, ...gcpTrainingDataList].map((dataItem) => (
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {dataItem?.FileName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={styles.locationCell}>
                      <img
                        src={
                          !dataItem?.cloud || dataItem?.cloud === "aws"
                            ? AWSIcon
                            : GCPIcon
                        }
                        alt="s3_icon"
                        height={24}
                        width={24}
                      />
                      <Typography
                        variant="caption"
                        color={theme.palette.primaryBlue.main}
                        onClick={() => navigateTo(dataItem?.redirectTo)}
                      >
                        {dataItem?.Location}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {dataItem?.sensitive_labels}
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.countOfLLMs}>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {dataItem?.count}
                    </Typography>
                    <Bar
                      isLastBar={false}
                      xLabel={""}
                      barBackColor={theme.palette.surface20.main}
                      barFaceColor={theme.palette.lightMagenta.main}
                      rectWidth={(dataItem?.count / 4) * 100}
                      index={dataItem?.Title}
                      totalValue={4}
                      barLabel={""}
                      barValue={dataItem?.count}
                      type={""}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TrainingDataCard;
