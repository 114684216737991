import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAssets, fetchAssetsSearch } from "../apis/assetsAPI";

const initialState = {
  assetsSearch: [],
};

// Async calls.
export const listAssetsSearchAsync = createAsyncThunk(
  "asset/search/get",
  async (params) => {
    const response = await fetchAssetsSearch(params);
    return response.data;
  }
);

export const listAssetsAsync = createAsyncThunk("asset/get", async (params) => {
  const response = await fetchAssets(params);
  return response.data;
});

export const assetsSlice = createSlice({
  name: "asset",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listAssetsSearchAsync.fulfilled, (state, action) => {
        state.assetsSearch = action.payload;
      })
      .addCase(listAssetsSearchAsync.rejected, (state, action) => {
        state.assetsSearch = [];
      })
      .addCase(listAssetsAsync.fulfilled, (state, action) => {
        state.assets = action.payload;
      })
      .addCase(listAssetsAsync.rejected, (state, action) => {
        state.assets = [];
      });
  },
});

export default assetsSlice.reducer;
