export const gcpTrainingDataList = [
  {
    FileName: "train_v2.jsonl",
    Location: "gs://training-data-bucket-1/data/train_v2.jsonl",
    cloud: "gcp",
    redirectTo: "",
    Source: "Configured",
    size: "9.9 MB",
    type: "Google Storage",
    training_date: "2023-09-27T07:32:36.491000",
    sensitive_labels: "SSN",
    policy_violation: true,
    account: "099873896856",
    count: 1,
  },
];
