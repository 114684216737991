import { useTheme } from "@mui/material";
import Box from "./Box";
import LegendWithColor from "./LegendWithColor";
import Typography from "./Typography";

interface Props {
  title: string;
  data: Array<{ label: string; value: number; color: string }>;
}

const AIDonutTooltip = ({ title, data }: Props) => {
  const theme = useTheme();
  const styles = {
    tooltipContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2),
    },
    legendContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    legend: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: theme.spacing(1.5),
    },
  };
  return (
    <Box sx={styles.tooltipContainer}>
      <Typography variant="tooltip" color={theme.palette.surface50.main}>
        {title}
      </Typography>
      <Box sx={styles.legendContainer}>
        {data.map((dataItem) => (
          <Box sx={styles.legend}>
            <LegendWithColor
              color={dataItem?.color}
              label={dataItem?.label}
              textColor={theme.palette.surface0.main}
            />
            <Typography variant="caption" color={theme.palette.surface0.main}>
              {dataItem?.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AIDonutTooltip;
