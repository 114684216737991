export const policyTableData = [
  {
    name: "topic-policy-1",
    type: "Restricted topics in prompt session",
    framework: [
      "Hrproductivity_app",
      "Hrproductivity_app",
      "Hrproductivity_app",
    ],
    topics: [
      "HR Content",
      "HR Content",
      "HR Content",
      "HR Content",
      "HR Content",
    ],
    createdOn: "Wed Oct 19 2023 02:40:17 GMT+0530 (India Standard Time)",
    owner: "John Doe",
  },
  {
    name: "entity-policy-1",
    type: "Sensitive identifiers in prompt session",
    framework: [
      "Langchain_app",
      "Langchain_app",
      "Langchain_app",
      "Langchain_app",
    ],
    topics: ["Medical Advice", "Medical Advice", "Medical Advice"],
    createdOn: "Wed Oct 25 2023 19:23:53 GMT+0530 (India Standard Time)",
    owner: "John Doe",
  },
];

export const topicsTableData = [
  {
    topic: "Medical Advice",
    description: "Providing medical advice or information on ..",
    createdOn: "2023-10-21  05:12:34",
  },
  {
    topic: "Financial Reports",
    description: "Reports or information related to financ ..",
    createdOn: "2023-10-24 15:32:18",
  },
];

export const entityTableData = [
  {
    entity: "Employee ID",
    createdOn: "2023-10-22  12:11:48",
  },
  {
    entity: "Company Confidential",
    createdOn: "2023-10-23  04:32:32",
  },
];
