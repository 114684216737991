import React, { useState } from "react";
import { getCustomerId } from "../../utils/SessionHelper";
import Table from "../../components/datastore/table";
import { getImpactedDatastores } from "../../apis";
import { addIndex } from "../../utils/dataHandler";
import { useEffect } from "react";
import Pagination from "../pagination/Pagination";
import "react-toggle/style.css"; // for ES6 modules
import NoDataContainer from "../../components/cards/no-data";
import { formatHeader } from "../../utils/uppercase";

const ImpactedDataStoreModal = ({
  isAttackPath = false,
  node = null,
  assetIdValue,
  loading,
  setLoading,
}) => {
  const [severity, setSeverity] = useState(null);
  const [impactedDataStoreList, setImpactedDataStoreList] = useState([]);
  const page_ = sessionStorage.getItem("page");
  const assetId = assetIdValue?.replaceAll("_", "/");

  const [page, setPage] = useState(!assetId && page_ ? parseInt(page_) : 1);
  const [totalDatastoreCount, setTotalDatastoreCount] = useState(0);

  let limit = 5;

  const getImpactedDataStore = async () => {
    setLoading(true);

    const payload = {
      customerId: getCustomerId(),
      page: page,
      limit: limit,
      assetId: assetId,
    };

    let res = await getImpactedDatastores(payload);

    if (res && !res.err) {
      setTotalDatastoreCount(res.count);
      res = addIndex(res.data);
      setImpactedDataStoreList(res);
      setLoading(false);
    } else {
      setLoading(false);
      setImpactedDataStoreList([]);
      setTotalDatastoreCount(0);
    }
  };

  useEffect(() => {
    if (isAttackPath && node && node.is_primary && !node.datastore_node) {
      getImpactedDataStore();
    } else if (!isAttackPath) {
      getImpactedDataStore();
    }
  }, [page, severity]);

  useEffect(() => {
    sessionStorage.setItem("page", page);
  }, [page]);

  const getAttackPathContents = () => {
    if (node && node?.tooltip_content) {
      const contents = node?.tooltip_content;
      let popupContents = [];
      let index = 1;
      for (var key in contents) {
        index++;
        if (contents.hasOwnProperty(key)) {
          var val = contents[key];
          if (
            (key === "eks_cluster" || key === "EKS Cluster") &&
            node?.eks_enabled === false
          ) {
            continue;
          } else {
            popupContents.push(
              <div className=" pr-4" key={index}>
                <div className="node-title">{formatHeader(key)}</div>
                <div
                  dangerouslySetInnerHTML={{ __html: val }}
                  className="node-description"
                ></div>
              </div>
            );
          }
        }
      }
      return (
        <div>
          <div className="d-flex flex-wrap m-1 mx-2">{popupContents}</div>
        </div>
      );
    } else {
      return (
        <div className="right-panel">
          <p className="header m-0">{node?.name || ""}</p>
        </div>
      );
    }
  };

  return (
    <div>
      <div className={isAttackPath && node ? "attack-path-section" : ""}>
        {isAttackPath && node && getAttackPathContents()}
      </div>
      {node && node.is_primary && !node.datastore_node ? (
        <div>
          <div
            className={
              isAttackPath && node
                ? "d-flex justify-content-between mt-3"
                : "d-flex justify-content-between "
            }
          >
            <div className="d-flex justify-content-start">
              <span className="body-title ">Impacted Datastores</span>
            </div>
            <div className="mr-4">
              <Pagination
                page={page}
                setPage={(val) => setPage(val)}
                limit={limit}
                totalCount={totalDatastoreCount}
              />
            </div>
          </div>
          {impactedDataStoreList.length ? (
            <Table
              severity={severity}
              currentPosts={impactedDataStoreList}
              isTicketColEnable={false}
              isActionColEnable={false}
              tablePadding="px-0"
              page="impacted-datastore"
            />
          ) : !loading ? (
            <NoDataContainer message={"No Datastores Available"} />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {!isAttackPath && (
        <div>
          <div
            className={
              isAttackPath && node
                ? "d-flex justify-content-end mt-3"
                : "d-flex justify-content-end "
            }
          >
            <div className="mr-4">
              <Pagination
                page={page}
                setPage={(val) => setPage(val)}
                limit={limit}
                totalCount={totalDatastoreCount}
              />
            </div>
          </div>
          {impactedDataStoreList.length ? (
            <Table
              severity={severity}
              currentPosts={impactedDataStoreList}
              isTicketColEnable={false}
              isActionColEnable={false}
              page="impacted-datastore"
            />
          ) : !loading ? (
            <NoDataContainer message={"No Datastores Available"} />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default ImpactedDataStoreModal;
