import React, { useEffect, useState } from 'react';
import RiskCards from '../../cards/risk-cards';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const Cards = ({
  filterIssues,
  selectedAccount,
  setSelectedAccount,
  inputText,
  setInputText,
  assets,
  identities,
}) => {
  const [accountList, setAccountList] = useState([
    { value: 'all', label: 'All' },
  ]);
  const accounts = useSelector((state) => state.account.accounts);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setAccountList([
        { value: 'all', label: 'All' },
        ...accounts.map((account) => {
          return { value: account.id, label: account.id };
        }),
      ]);
    }
  }, [accounts]);
  return (
    <div>
      <div className="row align-items-center">
        <div className="col-12 col-md-12 col-xl-4 mb-2 page-heading">
          {'ASSETS > '}

          <span className="text-info">{'ASSET OVERVIEW'}</span>
        </div>
      </div>
      <div className="row pr-3">
        <div className="col-sm-12">
          <RiskCards
            filterIssues={filterIssues}
            accountId={selectedAccount ? selectedAccount.value : undefined}
            assets={assets}
            identities={identities}
          />
        </div>
      </div>
    </div>
  );
};

export default Cards;
