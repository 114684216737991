import './HomeV1.scss';
import SharedCard from './v1/sharedCard/SharedCard';
import Card from './v1/card/Card';
import { fetchDashboardStat } from '../../redux/slices/dashboardSlice';

import React, { useEffect, useState } from 'react';
import InfoModal from '../../components/modal/info-modal/InfoModal';
import DataStores from '../../components/home/datastores/Datastores';
import DataFlows from '../../components/home/data-flows/DataFlow';
import SecurityIssues from '../../components/home/security-issues/SecurityIssues';
import Achievements from '../../components/home/achievements/Achievements';
import ImpactedDatastores from '../../components/home/impacted-datastores/ImpactedDatastores';
import Trend from '../../components/home/trends/Trends';
import { getDatastoresByFilterApi } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerId } from '../../utils/SessionHelper';
import { addIndex, colorRiskData } from '../../utils/dataHandler';
import Inventory from './v1/inventory/Inventory';
import CloudEnvIssues from './v1/clound-env-issues/CloudEnvIssues';
import {
  firstAccountStatStatus,
  listRiskPlot,
} from '../../redux/slices/dashboardSlice';
import { useHistory } from 'react-router-dom';
import DashboardStats from './dashboard-stats/DashboardStats';
import { isJsonEmpty, numberFormatter } from '../../utils/utils';
import FirstAccountStats from './dashboard-stats/FirstAccountStats';
import FirstTimeLoginAccount from '../cards/account/FirstTimeLoginAccount';
import SensitiveData from './v1/sensitiveData/SensitiveData';
import DataPolicyViolation from './v1/dataPolicyViolation/DataPolicyViolation';
const HomeV1 = ({ setPath, tab, selectedAccount, isIssueViewVisible }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const limit = 10;
  const [risktype, setRisktype] = useState('high');
  const [severity, setSeverity] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [page, setPage] = useState(1);
  const [bars, setBars] = useState([]);
  const [selectedBar, setSelectedBar] = useState({});
  const [isBarClicked, setIsBarClicked] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(10);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState(false);
  const [dataStores, setDatastores] = useState([]);
  const [issues, setIssues] = useState([]);
  const [dataRisks, setDataRisks] = useState([]);
  const [totalDatastoresCount, setTotalDatastoresCount] = useState(0);
  const [issuesCount, setIssuesCount] = useState(0);
  const [datastoreIds, setDatastoreIds] = useState([]);
  const [hoveredDatastoreIds, setHoveredDatastoreIds] = useState([]);
  const [datastore, setDatastore] = useState({});
  const [loading, setLoading] = useState(false);
  const [datastoresLoading, setDatastoresLoading] = useState(false);
  const [riskplotLoading, setRiskplotLoading] = useState(false);
  const [filteredDatastores, setFiltersDatastores] = useState([]);
  const [filteredPolicyDatastores, setPolicyFiltersDatastores] = useState([]);
  const [stats, updateStats] = useState([]);
  const showShadowAI = localStorage.getItem('showShadowAI');
  const [dashboardCards, setDashboardCards] = useState([
    {
      id: 'sensitive',
      title: 'Sensitive Data',
      count: 0,
      clickable: true,
    },
    {
      id: 'SHADOW',
      title: 'Shadow Data Store',
      count: 0,
      clickable: true,
    },
    {
      id: 'SHADOW_AI',
      title: 'Shadow AI',
      count: 0,
      clickable: true,
    },
    {
      id: 'violatingPolicies',
      title: 'Violating Policies',
      count: 0,
    },
    {
      id: 'cloudEnvIssues',
      title: 'Cloud Risk Exposures',
      count: 0,
    },
  ]);

  const riskPlot = useSelector((state) => state.dashboard.riskPlot);
  const [breachCost, setBreachCost] = useState(0);

  const firstAccountStatus = useSelector(
    (state) => state.dashboard.firstAccountStatus
  );
  const accountAvailable = useSelector(
    (state) => state.account.accountAvailable
  );

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const getDashboardStats = async (selectedAccount) => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === 'all' ? null : selectedAccount.value,
    };
    let resp = await dispatch(fetchDashboardStat(payload));
    if (resp && resp.payload && !resp.payload.err) {
      updateStats(resp.payload);
    }
  };

  useEffect(() => {
    getDashboardStats(selectedAccount);
  }, [selectedAccount]);

  useEffect(() => {
    updateCards();
  }, [stats]);

  const updateCards = () => {
    let updatedstats = [...dashboardCards];

    const data = stats?.inventory?.data;
    const violatingPolicyCount = stats?.inventory?.policy?.stores;
    if (data) {
      updatedstats.map((card) => {
        switch (card.id) {
          case 'sensitive':
            card.count = data[card.id] ? data[card.id] : 0;
            break;
          case 'SHADOW':
            card.count = data['shadow'] ? data['shadow'] : 0;
            break;
          case 'SHADOW_AI':
            card.count = data['shadowAI'] ? data['shadowAI'] : 0;
            break;
          case 'cloudEnvIssues':
            card.count = data[card.id] ? data[card.id] : 0;
            break;
          case 'violatingPolicies':
            card.count = violatingPolicyCount;

            break;
          default:
        }
      });
    }
    if (showShadowAI == 'false') {
      updatedstats = updatedstats.filter((card) => card.title !== 'Shadow AI');
    }
    setDashboardCards([...updatedstats]);
  };

  // const getDataStores = async () => {
  //   setDatastoresLoading(true);
  //   const payload = {
  //     customerId: getCustomerId(),
  //     limit: limit,
  //     page: page,
  //     datastoreIds: datastoreIds,
  //     severity:
  //       !severity || severity.includes("TOTAL DATA STORES")
  //         ? null
  //         : severity.replaceAll(" ", "_"),
  //   };
  //   let res = await getDataStoresApi(payload);
  //   if (!res.data) return;
  //   setTotalDatastoresCount(res.count);
  //   res = addIndex(res.data);
  //   // res[0].numSecurityIssues = 100;
  //   // res[0].numAttackPath = 100;
  //   setDatastores(res);
  //   setFiltersDatastores(res);
  //   if (res.length) {
  //     setSelectedBar(res[0]);
  //   }
  //   setDatastoresLoading(false);
  // };

  const getFirstAccountStats = () => {
    const payload = {
      customerId: getCustomerId(),
    };

    dispatch(firstAccountStatStatus(payload));
  };

  const getDatastoresByFilter = async () => {
    setDatastoresLoading(true);
    const payload = {
      customerId: getCustomerId(),
      limit: limit,
      page: page,
      datastoreIds: datastoreIds,
      filters: 'all',
      limit: 10,
      page: 1,
      severity:
        !severity || severity.includes('TOTAL DATA STORES')
          ? null
          : severity.replaceAll(' ', '_'),
      cloudAccountId:
        selectedAccount.value === 'all' ? null : selectedAccount.value,
    };
    let res = await getDatastoresByFilterApi(payload);
    if (res.err) {
      setFiltersDatastores([]);
      setDatastoresLoading(false);
    } else {
      if (res.data) {
        setFiltersDatastores(res.data);
      } else {
        setFiltersDatastores(res);
      }

      if (res.length) {
        setSelectedBar(res[0]);
      }
      setDatastoresLoading(false);
    }
  };

  const getDatastoresByPolicyFilter = async () => {
    setDatastoresLoading(true);
    const payload = {
      customerId: getCustomerId(),
      limit: limit,
      page: page,
      datastoreIds: datastoreIds,
      filters: 'non-compliant',
      limit: 10,
      page: 1,
      severity:
        !severity || severity.includes('TOTAL DATA STORES')
          ? null
          : severity.replaceAll(' ', '_'),
      cloudAccountId:
        selectedAccount.value === 'all' ? null : selectedAccount.value,
    };
    let res = await getDatastoresByFilterApi(payload);
    if (res.err) {
      setFiltersDatastores([]);
      setDatastoresLoading(false);
    } else {
      if (res.data) {
        setPolicyFiltersDatastores(res.data);
      } else {
        setPolicyFiltersDatastores(res);
      }
      if (res.length) {
        setSelectedBar(res[0]);
      }
      setDatastoresLoading(false);
    }
  };

  const filterBars = (ids) => {
    setDatastoreIds(ids);
  };

  useEffect(() => {
    if (riskPlot) {
      let data = riskPlot;
      if (!data || isJsonEmpty(data)) return;
      data = colorRiskData(addIndex(data));
      setDataRisks(data);
    }
  }, [riskPlot]);

  // const getIssues = async () => {
  //   setDatastoresLoading(true);
  //   const payload = {
  //     customerId: getCustomerId(),
  //     limit: limit,
  //     page: page,
  //     severity:
  //       !severity || severity.includes("TOTAL ISSUES")
  //         ? null
  //         : severity.replaceAll(" ", "_"),
  //   };
  //   let res = await getIssuesApi(payload);
  //   if (!res.data) return;
  //   setIssuesCount(res.count);
  //   res = addIndex(res.data);
  //   setIssues(res);
  //   if (res.length) {
  //     setSelectedBar(res[0]);
  //   }
  //   setDatastoresLoading(false);
  // };

  const getDataRiskPlot = () => {
    setRiskplotLoading(true);
    const payload = {
      customerId: getCustomerId(),
      severity:
        !severity || severity.includes('TOTAL DATA STORES')
          ? null
          : severity.replaceAll(' ', '_'),
      cloudAccountId:
        selectedAccount.value === 'all' ? null : selectedAccount.value,
    };
    dispatch(listRiskPlot(payload));
    setRiskplotLoading(false);
  };

  const vulDatastores = () => {
    let vunerabilityDataStores_ = issues;
    if (severity) {
      vunerabilityDataStores_ = issues.filter((i) => severity === i.severity);
    }
    const data = vunerabilityDataStores_.slice(start - 1, end);
    return data;
  };

  const datastores = () => {
    let data_ = dataStores;
    if (severity) {
      data_ = dataStores.filter((i) => severity === i.severity);
    }
    const data = data_.slice(start - 1, end);
    return data;
  };

  const handleBarData = () => {
    const data = tab === 2 ? vulDatastores() : datastores();
    setBars(data);
    sessionStorage.setItem('selected_bar', JSON.stringify(data[0]));
  };

  const handleInfoModal = (visible, data) => {
    setInfoModalData(data);
    setShowInfoModal(visible);
  };

  useEffect(() => {
    getDatastoresByFilter();
    getDatastoresByPolicyFilter();
    // if (
    //   selectedFilter === "non-compliant" ||
    //   selectedFilter === "active-anomaly"
    // ) {
    //   getDatastoresByFilter();
    // } else {
    //   getDataStores();
    // }
  }, [selectedFilter, selectedAccount]);

  useEffect(() => {
    handleBarData();
  }, [page, risktype]);

  useEffect(() => {
    setStart(1);
    setEnd(10);
    setPage(1);
    handleBarData();
  }, [severity]);

  useEffect(() => {
    setSeverity(null);
    setStart(1);
    setEnd(10);
    setPage(1);

    handleBarData();
  }, [tab]);

  useEffect(() => {
    getDataRiskPlot();

    // if (tab === 1) {
    //   getDatastoresByFilter();
    //   // getDataStores();
    // } else {
    //   getIssues();
    // }
  }, [page, tab, selectedAccount, severity, datastoreIds]);

  useEffect(() => {
    setPath(window.location.pathname);
    document.title = 'Cloud Defense - Dashboard';
    getFirstAccountStats();
  }, []);

  const handleCardClick = (card) => {
    history.push('/datastore/filter/' + card.id);
  };

  return (
    <div>
      {accountAvailable === false ? (
        <FirstTimeLoginAccount />
      ) : (
        <div id="home-layout">
          <div>
            <div className="pr-3">
              <div className="d-flex flex-wrap">
                {dashboardCards &&
                  dashboardCards.map((card) => {
                    return (
                      <div
                        className={`${
                          card.id === 'cloudEnvIssues' ? 'pb-3' : 'pb-3 pr-3'
                        } ${
                          showShadowAI === 'false'
                            ? 'hero-stat-updated'
                            : 'hero-stat'
                        }`}
                      >
                        <div
                          onClick={() => {
                            if (card.clickable && card.count > 0) {
                              handleCardClick(card);
                            }
                          }}
                          className={
                            card.clickable && card.count > 0
                              ? 'cursor-pointer'
                              : ''
                          }
                        >
                          <SharedCard>
                            {card.title === 'Shadow AI' && (
                              <div class="credit top-right">Beta</div>
                            )}
                            <Card
                              title={card.title}
                              count={card.count}
                              subCount="15"
                            />
                          </SharedCard>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* <div className="row mb-4">
                <div class="col-lg-3 col-md-3">
                  <SharedCard>
                    <Card title="Sensitive Data" count="5" subCount="15" />
                  </SharedCard>
                </div>
                <div class="col-lg-3 col-md-3">
                  <SharedCard>
                    <Card title="Shadow Data Store" count="10" />
                  </SharedCard>
                </div>{" "}
                <div class="col-lg-3 col-md-3">
                  <SharedCard>
                    <Card title="Violating Policies" count="2" />
                  </SharedCard>
                </div>{" "}
                <div class="col-lg-3 col-md-3">
                  <SharedCard>
                    <Card title="Exposed due to cloud issues" count="10" />
                  </SharedCard>
                </div>
              </div> */}
              <div className="row mb-2 mt-1">
                <div class="col-lg-8 col-md-8 pr-2">
                  <SharedCard>
                    <Inventory selectedAccount={selectedAccount} />
                  </SharedCard>
                </div>
                <div class="col-lg-4 col-md-4">
                  <SharedCard title="Sensitive Data">
                    <SensitiveData selectedAccount={selectedAccount} />
                  </SharedCard>
                </div>
              </div>
              <div className="row mb-2 mt-4">
                <div class="col-lg-12 col-md-12">
                  <SharedCard>
                    <div className="row mb-2 ">
                      <div class="col-lg-6 col-md-6 dashboard-divider">
                        <DataPolicyViolation
                          selectedAccount={selectedAccount}
                        />
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <CloudEnvIssues selectedAccount={selectedAccount} />
                      </div>
                    </div>
                  </SharedCard>
                </div>
              </div>
            </div>
            {showInfoModal && (
              <InfoModal
                handleModal={() => setShowInfoModal(false)}
                data={infoModalData}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeV1;
