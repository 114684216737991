import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTheme } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import loaderImage from "../../../assets/img/loader.svg";
import {
  addTicketAsync,
  getDatastoreTicketAsync,
} from "../../../redux/slices/ticketSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import Box from "../../common/Box";
import Button from "../../common/Button";
import TextButton from "../../common/TextButton";
import Typography from "../../common/Typography";
import "./DatastoreTicketModal.scss";

const DatastoreTicketModal = ({
  handleModal,
  refreshDatastores,
  ticketDetail,
  datastoreId,
  type = "bulk",
  getBulkTicketDetail,
  tags = [],
  policyViolationId,
  issueId,
  instanceID,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [managedTags, setManagedTags] = useState(tags);
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [issue, setIssue] = useState({});

  const styles = {
    modalHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    closeButton: {
      color: theme.palette.surface80.main,
    },
    dialogFooter: {
      display: "flex",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  };

  const getTicket = async () => {
    setShowLoader(true);
    setConnectionFailed(false);
    let resp = {};
    let payload = {};

    payload = {
      customerId: getCustomerId(),
      datastoreId: datastoreId ? datastoreId : null,
      policyViolationId: policyViolationId ? policyViolationId : null,
      host: window.location.host,
    };
    // if (type === "liveness") {
    //   payload.liveness = true;
    // }
    resp = await dispatch(getDatastoreTicketAsync(payload));

    if (resp && resp.payload) {
      setShowLoader(false);
      setIssue(resp.payload);
      formik.setFieldValue("name", resp.payload.ticketName);
      formik.setFieldValue("description", resp.payload.description);
      const tagList = [];
      if (resp?.payload?.tags?.length > 0) {
        resp?.payload?.tags.map((id) => {
          tagList.push({ label: id, value: id });
        });
      }

      setManagedTags(tagList);
    } else {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (type === "datastore" || type === "liveness" || type === "policy") {
      getTicket();
    }
  }, [type]);

  useEffect(() => {
    if (ticketDetail && type !== "datastore") {
      formik.setFieldValue("name", ticketDetail.ticketName);
      formik.setFieldValue("description", ticketDetail.description);
      const tagList = [];
      if (ticketDetail?.tags?.length > 0) {
        ticketDetail.tags.map((id) => {
          tagList.push({ label: id, value: id });
        });
      }

      setManagedTags(tagList);
    }
  }, [ticketDetail]);

  // Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter name."),
      description: Yup.string().required("Please enter description."),
    }),
    onSubmit: (values) => {
      addIntegration({ ...values });
    },
  });

  const addIntegration = async (values) => {
    setShowLoader(true);
    setConnectionFailed(false);

    const tags_ = [];
    for (let i of managedTags) {
      tags_.push(i.value);
    }

    let payload = {
      customerId: getCustomerId(),
      ticketName: values.name,
      description: values.description,
      tags: tags_,
    };
    if (type === "bulk") {
      payload.policyViolationId = policyViolationId;
    } else if (type === "datastore" || type === "policy") {
      payload.datastoreId = datastoreId;
      payload.policyViolationId = policyViolationId;
    } else {
      payload.datastoreId = datastoreId;
      payload.policyViolationId = policyViolationId;
    }

    const resp = await dispatch(addTicketAsync(payload));

    if (resp.payload && resp.payload.success) {
      setShowLoader(false);
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      handleModal(false);
      if (type === "bulk") {
        getBulkTicketDetail();
      } else {
        refreshDatastores();
      }
    } else {
      setShowLoader(false);
      handleModal(false);
      Toaster(TOASTER_TYPES.ERROR, "Failed to create ticket.");
    }
  };

  return (
    <div id="datastore-ticket-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <Box sx={styles.modalHeader}>
                  <Typography
                    color={theme.palette.surface80.main}
                    variant="body2"
                  >
                    Create Ticket
                  </Typography>
                  <TextButton onClick={handleModal}>
                    <CloseRoundedIcon sx={styles.closeButton} />
                  </TextButton>
                </Box>
                {/*<img
                  src={deleteIcon}
                  className="mr-3 delete-icon"
                  onClick={handleModal}
                />*/}
              </div>
              <div className="modal-body ">
                {showLoader && (
                  <img src={loaderImage} className="modal-loading-img" />
                )}
                <label className="label">
                  <Typography
                    variant="caption"
                    color={theme.palette.surface60.main}
                  >
                    Ticket Name: <span className="mandatory-icon">*</span>
                  </Typography>
                </label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Policy Name"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                      value={formik.values.name}
                    ></input>
                    {formik.errors.name && formik.touched.name && (
                      <div className="error-message">{formik.errors.name}</div>
                    )}
                  </div>
                </div>
                <Typography
                  variant="caption"
                  color={theme.palette.surface60.main}
                >
                  Description:
                </Typography>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <textarea
                      className="form-control modal-textarea py-2 px-2"
                      placeholder="Account Description"
                      type="text"
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      onChange={(e) => {
                        formik.setFieldValue("description", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/*<div className="modal-footer p-2">*/}
              {/*<button className="btn btn-sm btn-primary mr-2" type="submit">
                  Create Ticket
                </button>*/}
              <Box sx={styles.dialogFooter}>
                <Button variant="contained" type="submit">
                  Create Ticket
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleModal();
                  }}
                >
                  Cancel
                </Button>
              </Box>
              {/*<button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    handleModal();
                  }}
                >
                  Cancel
                </button>*/}
              {/*</div>*/}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DatastoreTicketModal;
