import moment from "moment";

const commonUtils = {};
export default commonUtils;

/**
 *
 * @param date1
 * @param date2
 * @returns returns difference between two dates in days
 */
export const getDifferenceInDays = (date1: Date, date2: Date) => {
  // Convert both dates to milliseconds
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());

  // Convert milliseconds to days
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return diffDays;
};

/**
 *
 * @param number
 * @returns number with 0 prefix if less than 10
 */
export const prefixNumber = (number: number) => {
  return number > 0 && number < 10 ? `0${number}` : number;
};

/**
 *
 * @param object
 * @returns checks weather object contains any value or not
 */
export const isContainsValue = (data: object) => {
  if (data && Object.values(data)?.length > 0) {
    return true;
  }
  return false;
};

/**
 *
 * @param object
 * @returns checks weather string contains word vulnerable and returns string without vulnerable
 */
export const getFormattedTitle = (title: string) => {
  if (title.toLocaleLowerCase().includes("vulnerable")) {
    return title.replace("Vulnerable ", "");
  }
  return title;
};

/**
 *
 * @param num
 * @returns number abbreviation
 */
export const getNumberAbbreviation = (num) => {
  if (num > 9999) {
    if (num < 1e6) return +(num / 1e3).toFixed(1) + "K";
    if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + "M";
  }
  return num.toString();
};

/**
 *
 * @param str
 * @returns remove all the special characters
 */
export const removeSpecialCharacters = (str) => {
  return str && str !== "" ? str.replace(/[^a-zA-Z0-9 ]/g, "") : "";
};

/**
 * object property name to formatted string
 * @param title
 * @returns formatted string
 */
export const formatTitle = (title) => {
  let formatedTitle = title.replaceAll("_", " ");
  let arr = formatedTitle.split(" ");

  for (var index = 0; index < arr.length; index++) {
    arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1);
  }
  return arr.join(" ");
};

/**
 * convert bytes to GB
 * @param bytes
 * @param decimals
 * @returns
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isDataWithinDefinedDate = (dateKeyword, timestamp) => {
  const dateToCompare = moment(timestamp);
  const todaysDate = moment();

  switch (dateKeyword) {
    case "7days": {
      const diff = moment(todaysDate).diff(dateToCompare, "days");
      return diff <= 7;
    }
    case "1day": {
      const diff = moment(todaysDate).diff(dateToCompare, "days");
      return diff === 1;
    }
    case "1hour": {
      const diff = moment(todaysDate).diff(dateToCompare, "hours");
      return diff === 1;
    }
    default: {
      return false;
    }
  }
};
