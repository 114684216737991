import "./SharedCard.scss";

const SharedCard = ({ title, custom = "", children, subTitleComp = null }) => {
  return (
    <div id={custom ? `card-layout ${custom}` : "card-layout"}>
      {title && (
        <h5 className="text-white title mb-2">
          {title} {subTitleComp ? subTitleComp : null}
        </h5>
      )}

      <div className="">{children}</div>
    </div>
  );
};

export default SharedCard;
