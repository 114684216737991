import React, { useState, useEffect } from "react";
import "./../modal.scss";
import "./CreateIssue.scss";
import Select from "react-select";
import { useFormik } from "formik";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import loaderImage from "../../../assets/img/loader.svg";
import closeIcon from "../../../assets/img/icons/close-icon.svg";
import deleteIcon from "../../../assets/img/icons/ico-delete-grey.svg";
import * as Yup from "yup";
import { getCustomerId, getUserId } from "../../../utils/SessionHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  addTicketAsync,
  getTicketAsync,
} from "../../../redux/slices/ticketSlice";

const CreateIssueModal = ({
  handleModal,
  policyItem,
  tags = [],
  data = [],
  issueId,
  instanceID,
}) => {
  const dispatch = useDispatch();
  const [accountsList, setAccountsList] = useState();
  const accounts = useSelector((state) => state.account.accounts);
  const [loading, setLoading] = useState();
  const [tagsUpdated, setTagsUpdated] = useState(false);
  const [customTagErrors, setCustomTagError] = useState("");
  const [managedTags, setManagedTags] = useState(tags);
  const [loader, setLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [issue, setIssue] = useState({});

  const updateTags = (tag) => {
    // updating custom tags
    if (tag.type === "custom") {
      if (tags.some((obj) => obj.label === tag.label)) {
        setCustomTagError("Duplicate custom tags are not allowed");
      } else if (tag.type === "custom") {
        tag.label = tag.label.trim();
        setCustomTagError("");
        setManagedTags([...managedTags, tag]);
      }
    } else {
      // updating sensitive tags
      let temp = [...managedTags];
      let objIndex = temp.findIndex((obj) => obj.type == "sensitive");
      if (objIndex !== -1) {
        temp[objIndex] = tag;
        setManagedTags(temp);
      } else {
        setManagedTags([...managedTags, tag]);
      }
    }
    setTagsUpdated(true);
  };

  const removeTag = (tag) => {
    const tempTags = [...managedTags];
    tempTags.splice(
      tempTags.findIndex((a) => a.value === tag.value),
      1
    );
    setTagsUpdated(true);
    setManagedTags(tempTags);
  };

  const getTicket = async () => {
    setShowLoader(true);
    setConnectionFailed(false);
    const resp = await dispatch(
      getTicketAsync({
        customerId: getCustomerId(),
        id: issueId,
        securityIssueInstanceId: instanceID,
      })
    );

    if (resp && resp.payload) {
      setIssue(resp.payload);
      formik.setFieldValue("name", resp.payload.ticketName);
      formik.setFieldValue("description", resp.payload.description);
      const tagList = [];
      resp.payload.tags.map((id) => {
        tagList.push({ label: id, value: id });
      });

      setManagedTags(tagList);
    }
  };

  useEffect(() => {
    if (issueId && instanceID) {
      getTicket();
    }
  }, [instanceID, issueId]);

  useEffect(() => {
    const accountList = [];
    accounts.map((account) => {
      accountList.push({ label: account.id, value: account.id });
    });
    setAccountsList(accountList);
  }, [accounts]);

  // Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter name."),
      description: Yup.string().required("Please enter description."),
    }),
    onSubmit: (values) => {
      addIntegration({ ...values });
    },
  });

  const addIntegration = async (values) => {
    setShowLoader(true);
    setConnectionFailed(false);

    const tags_ = [];
    for (let i of managedTags) {
      tags_.push(i.value);
    }
    const resp = await dispatch(
      addTicketAsync({
        customerId: getCustomerId(),
        id: issueId,
        securityIssueInstanceId: instanceID,
        ticketName: values.name,
        description: values.description,
        tags: tags_,
      })
    );
    if (resp.payload && resp.payload.success) {
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      window.open(resp.payload?.url);
      handleModal(false);
    } else {
      Toaster(TOASTER_TYPES.ERROR, "Failed to create ticket.");
    }
  };

  return (
    <div id="add-policy-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <span className="header-title">Create Ticket</span>
                <img
                  src={deleteIcon}
                  className="mr-3 delete-icon"
                  onClick={handleModal}
                />
              </div>
              <div className="modal-body mb-2">
                {loading && (
                  <img src={loaderImage} className="modal-loading-img" />
                )}
                {/* <div className="row py-2 text-center justify-content-start">
                  <div className="col col-sm-3 d-flex flex-row modal-subtitle justify-content-start align-items-center workday-subheading">
                    <input
                      type="radio"
                      id="default"
                      checked={policyType === "alerting"}
                      value={policyType}
                      onClick={() => setPolicyType("alerting")}
                      name="frequency"
                      className="mr-3  custom-radio-button"
                    />{" "}
                    Alerting Policies
                  </div>
                  <div className="col col-sm-6 modal-subtitle workday-subheading-value">
                    <input
                      type="radio"
                      id="default"
                      checked={policyType === "automation"}
                      value={policyType}
                      onClick={() => setPolicyType("automation")}
                      name="frequency"
                      className="mr-3 ml-2 custom-radio-button"
                    />{" "}
                    Custom Automation Policies
                  </div>
                </div> */}
                <label className="label">
                  Ticket Name: <span className="mandatory-icon">*</span>
                </label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Policy Name"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                      value={formik.values.name}
                    ></input>
                    {formik.errors.name && formik.touched.name && (
                      <div className="error-message">{formik.errors.name}</div>
                    )}
                  </div>
                </div>{" "}
                <label className="label">Description:</label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <textarea
                      className="modal-textarea form-control py-2 px-2"
                      placeholder="Account Description"
                      type="text"
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      onChange={(e) => {
                        formik.setFieldValue("description", e.target.value);
                      }}
                    />
                  </div>
                </div>{" "}
                {/* <label className="label">Recepient:</label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <Select
                      isMulti
                      name="colors"
                      value={selectedAccounts}
                      options={accountsList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onBlur={formik.handleBlur}
                      onChange={(value) => {
                        setSelectedAccounts(value);
                        formik.setFieldValue("accounts", value);
                      }}
                    />
                    {formik.touched.accounts &&
                      selectedAccounts.length === 0 && (
                        <div className="error-message">
                          {"Please select account."}
                        </div>
                      )}
                  </div>
                </div> */}
                {/* <label className="label">Tags:</label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Custom Tag"
                      onKeyUp={(e) => {
                        if (e.key === "Enter" && e.target.value !== "") {
                          updateTags({
                            value: e.target.value,
                            label: e.target.value,
                            type: "custom",
                          });
                          e.target.value = "";
                          setTagsUpdated(true);
                        }
                      }}
                      disabled={loader}
                    ></input>
                    <div className="error-message">{customTagErrors} </div>
                  </div>
                </div> */}
                {/* <div className="row py-1">
                  <div className="col col-sm-8 col-xs-6 workday-subheading-value">
                    <div class="d-flex flex-wrap chip-container">
                      {managedTags.map((tag, i) => (
                        <div class="chip m-1" key={i}>
                          <span className="mr-2"> {tag.label}</span>
                          <img
                            className="close-icon"
                            src={closeIcon}
                            title="Close"
                            onClick={() => (loader ? null : removeTag(tag))}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="modal-footer p-2">
                <button
                  className="btn btn-sm btn-primary mr-2"
                  disabled={!formik.isValid}
                  type="submit"
                >
                  Create Ticket
                </button>

                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    handleModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateIssueModal;
