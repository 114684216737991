import { memo, useEffect } from "react";
import { useEdges } from "react-flow-renderer";
import { CardWrapper, CardWrapperSmall } from "../CardWrapper.component";

const Card = ({
  title,
  curve,
  arrow,
  score,
  scoreClass,
  severity,
  selectedSeverity,
  filterIssues,
  seperator,
  label,
  disabled,
  length,
}) => {
  return (
    <>
      {length === 5 ? (
        <CardWrapperSmall>
          <div
            class={`iq-mb-2 pa-r-0 main-card  ${
              disabled ? "deafult" : "pointer"
            } `}
            onClick={disabled ? () => "" : () => filterIssues(severity)}
          >
            <div class="card border-success header-risk-card">
              <div
                class={
                  severity === selectedSeverity
                    ? "card-body text-primary card-body-small selected-card"
                    : "card-body text-primary card-body-small "
                }
              >
                <h6 class="card-title risk-card-title mb-1">{label}</h6>
                <h2
                  class={
                    severity === selectedSeverity
                      ? `card-number ${scoreClass} risk-card-score selected-card-text`
                      : `card-number ${scoreClass} risk-card-score `
                  }
                >
                  {score}
                </h2>
              </div>
            </div>
          </div>
        </CardWrapperSmall>
      ) : (
        <CardWrapper>
          <div
            class={`iq-mb-2 pa-r-0 main-card  ${
              disabled ? "deafult" : "pointer"
            } `}
            onClick={disabled ? () => "" : () => filterIssues(title)}
          >
            <div class="card border-success header-risk-card">
              <div class="card-body text-primary card-body-small">
                <h6 class="card-title risk-card-title mb-1">
                  {label}
                  {/* <i className={`score-direction ${arrow}`}></i> */}
                </h6>
                <h2 class={`card-number ${scoreClass} risk-card-score`}>
                  {score}
                </h2>
                {/* <img alt="" src={curve} className="curve-line" /> */}
              </div>
            </div>
          </div>
          {/* {seperator ? <span class="vertical-line"></span> : ""} */}
        </CardWrapper>
      )}
    </>
  );
};

export default memo(Card);
