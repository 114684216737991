import "./modal.scss";

const Modal = ({ handleModal, data, createTicket }) => {
  return (
    <div
      className="new-modal modal fade show"
      id="exampleModalLong"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      style={{
        display: "block",
        paddingRight: "8px",
        background: "rgba(0, 0, 0, 0.5)",
      }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="row">
              <div className="col p-0">
                {" "}
                <h5 className="modal-title text-center">
                  <div className="row">
                    <div className="col p-0 col-lg-auto">
                      {" "}
                      <div className="count-circle">
                        <img src="static/img/ticket-unavailable.svg" />
                      </div>
                    </div>
                    <div className="col text-danger ml-2 p-0 col-lg-auto d-flex justify-content-center align-items-center">
                      {" "}
                      <b>{data?.title || "Block Access"}</b>
                    </div>
                  </div>
                </h5>
              </div>
              <div className="col col-lg-auto d-flex justify-content-center align-items-center ">
                <div class="float-right "></div>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col col-lg-auto">
                <b>Active Attack:</b>
              </div>
              <div className="col col-lg-auto modal-text">
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.activeAttackPath ||
                      "Anomalous API executed to perform Cloning on RDS-Mysql",
                  }}
                ></p>
              </div>
            </div>
            <div className="row ">
              <div className="col col-lg-2">
                <b>Attack Path Description:</b>
              </div>
              <div className="col col-lg-10 modal-text">
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.attackPathDesc ||
                      "1. Attacker exploits log4j vulnerability found in OS package to take control of EC-Public<br></br>2. EC2-Public has path to EC-Private, and able to launch privileged mode pods, by exploiting Anonymous Auth API on Kubelet<br></br>3. (Active Attack Detected) EC2-Private has excessive unused permissions to perform operations like Cloning on the RDS-Mysql DB",
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="row mt-3">
              <div className="col col-lg-2">
                <b>Name:</b>
              </div>
              <div className="col col-lg-4 p-0">
                <input
                  type="text"
                  id="fname"
                  placeholder="TK001001"
                  name="fname"
                  className="modal-input-style px-2"
                />
              </div>
              <div className="col col-lg-2">
                <b>Assigned By</b>
              </div>
              <div className="col col-lg-4 p-0">
                {" "}
                <input
                  type="text"
                  placeholder="Chris G"
                  id="fname"
                  name="fname"
                  className="modal-input-style px-2"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-lg-2">
                <b>Priority :</b>
              </div>
              <div className="col col-lg-4 p-0">
                <select
                  name="priority"
                  id="priority"
                  className="modal-input p-0 px-2"
                >
                  <option value="high">HIGH</option>
                </select>{" "}
              </div>
              <div className="col col-lg-2">
                <b>Assigned To</b>
              </div>
              <div className="col col-lg-4 p-0">
                {" "}
                <input
                  type="text"
                  placeholder="Cloud-ENG"
                  id="fname"
                  name="fname"
                  className="modal-input p-0 px-2"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-lg-2">
                <b>Remediation:</b>
              </div>
              <div className="col col-lg-10 p-0 ">
                <textarea
                  className="modal-textarea py-2 px-2"
                  value={
                    data?.remediation ||
                    "Block Access to RDS-Mysql API from EC2-Private (i-28h66556899) by removing excessive, unused permissions for actions like Cloning."
                  }
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="float-right">
                  <button
                    type="button"
                    className="modal-button mr-2"
                    onClick={createTicket}
                  >
                    Create Ticket{" "}
                  </button>
                  <button
                    type="button"
                    className="modal-button-cancel"
                    onClick={() => handleModal(false)}
                  >
                    Cancel{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
