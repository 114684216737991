import React, { useEffect, useState } from "react";
import "./Header.scss";
import user from "../../assets/img/new-theme/user-profile-icon.svg";
import questionIcon from "../../assets/img/new-theme/Question.svg";
import settings from "../../assets/img/new-theme/settings.svg";
import notification from "../../assets/img/new-theme/notification.svg";
import downArrow from "../../assets/img/new-theme/down-arrow.svg";
import { getUserId } from "../../utils/SessionHelper";
import RiskScoreChart from "../charts/RiskScoreChart";
import Popup from "reactjs-popup";
import Select from "react-select";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules
import {
  getSelectedDatastoreAccount,
  setSelectedDatastoreAccount,
  getCustomerId,
} from "../../utils/SessionHelper";
import { useSelector, useDispatch } from "react-redux";
import {
  issueViewVisible,
  issueViewDisable,
  manageDashboardViewSlice,
  fetchIssueUberScoreSlice,
} from "../../redux/slices/dashboardSlice";
import { getScoreColor } from "../../utils/styles";
import { useHistory } from "react-router-dom";
import { TIMEOUT_IN_MILLISECONDS } from "../../utils/Constants";
// import { useIdleTimer } from "react-idle-timer";
import FormatUrl from "../../utils/UrlFormatter";

const Header = ({
  selectedAccount,
  expandedSidebar,
  setSelectedAccount,
  setInfoModal,
  isIssueViewVisible,
}) => {
  const dispatch = useDispatch();
  const defaultAccount = getSelectedDatastoreAccount() || {
    value: "all",
    label: "All",
  };
  const [accountList, setAccountList] = useState([
    { value: "all", label: "All" },
  ]);

  const accounts = useSelector((state) => state.account.accounts);
  const [riskStats, setRiskStats] = useState(null);
  const [accountDropdownDisable, setAccountDrodpwonDisable] = useState(false);
  const history = useHistory();
  const path = history.location.pathname;
  const timeout = parseInt(TIMEOUT_IN_MILLISECONDS);
  const [isIdle, setIsIdle] = useState(false);
  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  // const {
  //   reset,
  //   pause,
  //   resume,
  //   getRemainingTime,
  //   getLastActiveTime,
  //   getElapsedTime,
  // } = useIdleTimer({
  //   timeout,
  //   onActive: handleOnActive,
  //   onIdle: handleOnIdle,
  // });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  useEffect(() => {
    if (isIdle) {
      localStorage.clear();
      sessionStorage.clear();
      const userId = getUserId();
      if (userId) {
        const url = FormatUrl(`/user/logout/${userId}`);
        window.location = url;
      } else {
        window.location = "/timeout";
      }
    }
  }, [isIdle]);

  const logout = () => {
    const userId = getUserId();
    localStorage.clear();
    sessionStorage.clear();
    if (userId) {
      const url = FormatUrl(`/user/logout/${userId}`);
      window.location = url;
    } else {
      window.location = "/";
    }
  };

  const getCloudAccountId = (selectedAccountId, accountFromStorage) => {
    return selectedAccountId
      ? selectedAccountId === "all"
        ? null
        : selectedAccountId
      : accountFromStorage
      ? accountFromStorage.value === "all"
        ? null
        : accountFromStorage.value
      : null;
  };

  const checkAccountDropdownPermission = () => {
    if (
      path === "/" ||
      path === "/datastore" ||
      path === "/vulnerabilities" ||
      path === "/cloud/assets"
    ) {
      setAccountDrodpwonDisable(false);
    } else {
      setAccountDrodpwonDisable(true);
    }
  };

  const getRiskScore = async () => {
    const resp = await dispatch(
      fetchIssueUberScoreSlice({
        customerId: getCustomerId(),
      })
    );
    if (resp && resp.payload && !resp.payload.err) {
      setRiskStats(resp?.payload);
    }
  };

  useEffect(() => {
    checkAccountDropdownPermission();
  }, [path]);

  useEffect(() => {
    // Populating risk score
    getRiskScore();
    const intervalCall = setInterval(() => {
      getRiskScore();
    }, 120000);
    return () => {
      if (intervalCall) {
        clearInterval(intervalCall);
      }
    };
  }, []);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setAccountList([
        { value: "all", label: "All" },
        ...accounts.map((account) => {
          return { value: account.id, label: account.id };
        }),
      ]);
    }
  }, [accounts]);

  const toggleView = () => {
    console.log(isIssueViewVisible);
    if (isIssueViewVisible) {
      dispatch(issueViewDisable());
      dispatch(
        manageDashboardViewSlice({
          userId: getUserId(),
          view: "data",
        })
      );
    } else {
      dispatch(issueViewVisible());
      dispatch(
        manageDashboardViewSlice({
          userId: getUserId(),
          view: "issue",
        })
      );
    }
  };

  return (
    <div id="cd-top-header">
      <div className="d-flex justify-content-between header-main-container ">
        <div className="left-container">
          <ul>
            <li>
              <div className="d-flex align-items-center">
                <label className="risk-score-label mr-2"> RISK SCORE</label>
                <RiskScoreChart riskStats={riskStats} />
                <div
                  className={`datastore-risk-score-label px-2 ml-2 ${getScoreColor(
                    riskStats?.issueUberScore || 0
                  )}-bg-level`}
                >
                  <label
                    className={`m-0 ${getScoreColor(
                      riskStats?.issueUberScore || 0
                    )}-level`}
                  >
                    {" "}
                    {riskStats?.issueUberLabel || "Low"}
                  </label>
                </div>
              </div>
            </li>

            <li className="ml-5 d-flex align-items-center">
              <label className="accounts-label mr-1"> Accounts</label>
              <Select
                options={accountList}
                isDisabled={accountDropdownDisable}
                classNamePrefix="accounts-dropdown"
                placeholder="Select Account"
                defaultValue={accountList.length > 0 && accountList[0]}
                value={
                  accountList.find(
                    (element) => element.value === selectedAccount.value
                  )
                    ? selectedAccount
                    : accountList[0]
                }
                onChange={(value) => setSelectedAccount(value)}
                isSearchable={false}
                className={
                  accountDropdownDisable
                    ? "accounts-dropdown"
                    : "accounts-dropdown cursor-pointer"
                }
              />
            </li>
          </ul>
        </div>
        <div className="right-container d-flex">
          {/* <img src={notification} className="mr-5 cursor-pointer" /> */}

          <Popup
            trigger={<img src={settings} className="mr-4 cursor-pointer" />}
            on={["click"]}
            position="bottom right"
            arrow={true}
            className="settings-popup"
          >
            <div className="p-4">
              <span
                className={
                  isIssueViewVisible
                    ? "mr-2 toggle-title-disable"
                    : "mr-2 toggle-title"
                }
              >
                {" "}
                Data View
              </span>
              <Toggle
                defaultChecked={isIssueViewVisible}
                onChange={() => toggleView()}
                className="risk-accept-toggle"
                icons={false}
                id="risk-accept-toggle"
              />
              <span
                className={
                  isIssueViewVisible
                    ? "ml-1 toggle-title"
                    : "ml-1 toggle-title-disable"
                }
              >
                {" "}
                Issue View
              </span>
            </div>
          </Popup>

          <Popup
            trigger={
              <div className="d-flex cursor-pointer">
                <img src={user} className="mr-2" />

                <div className="mr-2">
                  <span className="user-name">
                    {localStorage.getItem("username")}
                  </span>
                  <br />

                  <div className="customer-name">
                    {localStorage.getItem("customer_name")}
                  </div>
                </div>
                <img src={downArrow} />
              </div>
            }
            on={["click"]}
            position="bottom right"
            arrow={false}
            className="profile-popup"
          >
            <div className="popup-item" onClick={logout}>
              Logout
            </div>
          </Popup>

          <img
            src={questionIcon}
            onClick={() => setInfoModal(true)}
            className="ml-4 cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
