import React, { useEffect, useState, useRef, useCallback } from "react";
import closeIcon from "../../../assets/img/icons/close-icon-grey.svg";
import { formatHeader } from "../../../utils/uppercase";
import "./DatastoreAttackPathGraph.scss";
import { config } from "./attackPathConfig";
import { useHistory } from "react-router-dom";
import { getCustomerId } from "../../../utils/SessionHelper";
import { getIssuesApi } from "../../../apis";
import { useParams } from "react-router-dom";
import { Graph } from "react-d3-graph";
import { getNodeIconByType } from "./flowChartHelper";
import AffectedDatastoresAndIssuesModal from "../../modal/affected-datastore-and-issue-modal/AffectedDatastoresAndIssuesModal";

const DatastoreAttackPathGraph = ({
  rowIndex,
  handleClick,
  attackPaths,
  width,
}) => {
  const [graphData, setGraphData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [dataStoreIssueCount, setDataStoreIssueCount] = useState(0);
  const [isAttackPathNodeClicked, setIsAttackPathNodeClicked] = useState(false);
  const history = useHistory();
  const params = useParams();

  const assetId = params.assetId?.replaceAll("_", "/");

  const handleRefChange = (ref) => {
    if (ref) ref.resetNodesPositions();
  };
  const getDataStoreIssueCount = async () => {
    const payload = {
      customerId: getCustomerId(),
      assetId: assetId,
    };

    let res = await getIssuesApi(payload);
    if (res.err || !res.data) {
      return;
    }
    setDataStoreIssueCount(res.total_count);
  };
  const nodeClicked = (data) => {
    setSelectedNode(data);
    setIsAttackPathNodeClicked(true);
  };

  const viewIssuesByAssetId = (item) => {
    history.push({
      pathname: `/vulnerabilities/${item.CD_Asset_Id?.replaceAll("/", "_")}`,
    });
  };

  const getGraphData = (data) => {
    if (data) {
      let nodes = data.nodes.length;
      let nodeLine = (width - 150) / (nodes - 1);
      let nodeX = 0;
      data.nodes.map((node, index) => {
        node.height = "2rem";
        node.width = "2rem";
        nodeX = index == 0 ? 70 : nodeX + nodeLine;
        node.x = nodeX;
        node.y = 150;
        node.data = {};
        node.data.image = {
          width: node.is_primary ? 50 : 40,
          height: node.is_primary ? 50 : 40,
          src: getNodeIconByType(node.type),
          margin: node.is_primary ? "20px auto 0 auto" : "26px auto 0 auto",
        };
        node.data.handleClick = nodeClicked;
        node.selected = false;
      });
    }

    data.links = data.links
      .filter((x) => data.nodes.find((y) => y.id === x.source))
      ?.filter((x) => data.nodes.find((y) => y.id === x.target));

    return data;
  };

  const getRightPanelContents = () => {
    if (selectedNode && selectedNode?.tooltip_content) {
      const contents = selectedNode?.tooltip_content;
      let popupContents = [];
      let index = 1;
      for (var key in contents) {
        index++;
        if (contents.hasOwnProperty(key)) {
          var val = contents[key];
          if (
            (key === "eks_cluster" || key === "EKS Cluster") &&
            selectedNode?.eks_enabled === false
          ) {
            continue;
          } else {
            popupContents.push(
              <div className="contents" key={index}>
                <div class="content-header">{formatHeader(key)}:</div>
                <div
                  className="content-body"
                  dangerouslySetInnerHTML={{ __html: val }}
                ></div>
                <hr />
              </div>
            );
          }
        }
      }
      return (
        <div className="right-panel">
          <div className="header m-0 d-flex justify-content-between">
            <span>{selectedNode?.name || ""}</span>
            <img
              className="close-icon"
              src={closeIcon}
              title="Close"
              onClick={() => setSelectedNode(null)}
            />
          </div>
          <div>{popupContents}</div>
          {selectedNode?.is_primary ? (
            <div className="security-issue-button">
              <button
                onClick={() => viewIssuesByAssetId(selectedNode)}
                className="btn btn-primary"
                disabled={!dataStoreIssueCount > 0}
              >
                View Security Issue
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return (
        <div className="right-panel">
          <p className="header m-0">{selectedNode?.name || ""}</p>
        </div>
      );
    }
  };

  useEffect(() => {
    setSelectedNode(null);
    if (rowIndex.index >= 0 && attackPaths.length > 0 && width) {
      const graphDataset = getGraphData(attackPaths[rowIndex.index]);
      setGraphData({ nodes: graphDataset.nodes, links: graphDataset.links });
    } else {
      setGraphData({ nodes: [], links: [] });
    }
  }, [rowIndex, width]);

  return (
    <div>
      {graphData && (
        <Graph
          id="attack-graph-id"
          data={graphData}
          config={config}
          ref={handleRefChange}
        />
      )}
      {isAttackPathNodeClicked && (
        <AffectedDatastoresAndIssuesModal
          handleModal={() => setIsAttackPathNodeClicked(false)}
          isRiskAccepted={false}
          assetIdValue={selectedNode.CD_Asset_Id}
          node={selectedNode}
          isAttackPath={true}
          isSecurityIssueVisisble={true}
          page={"datastore"}
        />
      )}
    </div>
  );
};

export default DatastoreAttackPathGraph;
