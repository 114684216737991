import MUIAutocomplete from "@mui/material/Autocomplete";
import { InputAdornment, TextField, useTheme } from "@mui/material";

export const Autocomplete = (props) => {
  const theme = useTheme();
  const styles = {
    autoComplete: {
      ".MuiFormLabel-root": {
        color: theme.palette.surface40.main,
        "&.Mui-focused": {
          color: theme.palette.primaryBlue.main,
        },
      },
      ".MuiFormLabel-root .MuiInputLabel-root .Mui-focused": {
        color: theme.palette.primaryBlue.main,
      },
      ".MuiInputBase-root:before": {
        borderBottom: `1px solid ${theme.palette.surface40.main}`,
      },
      ".MuiInputBase-root:after": {
        borderBottom: `1px solid ${theme.palette.primaryBlue.main}`,
      },
      ".MuiAutocomplete-popupIndicator .MuiSvgIcon-root": {
        color: theme.palette.surface40.main,
      },
      ".MuiAutocomplete-popupIndicatorOpen .MuiSvgIcon-root": {
        color: theme.palette.primaryBlue.main,
      },
    },
  };
  return (
    <MUIAutocomplete
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant={props.variant}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start" />
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      sx={{ ...styles.autoComplete, ...props.sx }}
    />
  );
};
