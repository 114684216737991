import { ACCOUNT_TYPES } from "./Constants";

export const accountTypes = [
  {
    type: ACCOUNT_TYPES.GCP,
    image: "/static/img/connect/google-icon.svg",
    title: "Google Cloud",
  },
  {
    type: ACCOUNT_TYPES.AWS,
    image: "/static/img/connect/amazon-icon.svg",
    title: "Amazon AWS",
  },
  {
    type: ACCOUNT_TYPES.AZURE,
    image: "/static/img/connect/azure-icon.svg",
    title: "Microsoft Azure",
  },
];
