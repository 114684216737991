const Card = ({ index, title, content, footer, type, active = false }) => {
  return (
    <div class="col-lg-3 pointer">
      <div class="card iq-mb-2 border-success header-risk-card">
        <div
          className={`card-header ${
            type === "danger" ? "bg-red" : "bg-warning"
          }`}
        >
          <span>{index + 1}. </span>
          {title}
        </div>
        <div className={`card-body ${active ? "border-red" : ""}`}>
          The Data Store is misconfigured and on 7 Attack Paths. The Data Store
          also contains sensitive data. Addressing this risk will reduce your
          Enterprise Risk Score.
        </div>
        <div className={`card-footer ${active ? "border-red" : ""}`}>
          1. LeapLabs:RegUsers
        </div>
      </div>
    </div>
  );
};

export default Card;
