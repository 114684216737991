import { useTheme } from "@mui/material";
import { removeSpecialCharacters } from "../../utils/commonUtils";
import BarGraphBottomAxis from "./BarGraphBottomAxis";
import Box from "./Box";

export interface Props {
  barFaceColor: string;
  barBackColor: string;
  rectWidth: number;
  xLabel: string;
  totalValue: number;
  barLabel: string;
  index?: number;
  isLastBar?: boolean;
  barValue: number;
  type?: string;
}

const Bar = ({
  barFaceColor,
  barBackColor,
  rectWidth,
  xLabel,
  totalValue,
  barLabel,
  index,
  isLastBar = false,
  barValue,
  type,
}: Props) => {
  const theme = useTheme();
  const styles = {
    barOuterRect: {
      height: theme.spacing(1),
      width: "100%",
      backgroundColor: barBackColor,
      position: "relative",
    },
    barInnerRect: {
      height: "inherit",
      backgroundColor: barFaceColor,
      transition: "0.5s ease",
      width: `${rectWidth}%`,
    },
  };

  return (
    <Box sx={styles.barOuterRect}>
      <Box style={styles.barInnerRect}></Box>
      {isLastBar && (
        <BarGraphBottomAxis
          xLabel={xLabel}
          maxValue={totalValue}
          color={theme.palette.green}
          classForGraph={`${
            removeSpecialCharacters(barLabel)?.split(" ")[0]
          }-${barValue}-${index}${type ? type : ""}`}
        />
      )}
    </Box>
  );
};

export default Bar;
