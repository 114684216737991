import { useEffect } from "react";
import VulnerabilitiesDetailsContainer from "../../components/vulnerabilities-details/VulnerabilitiesDetailsContainer";

const Datastore = ({ setPath }) => {
  setPath(window.location.pathname);

  useEffect(() => {
    document.title = "Cloud Defense - Vulnerabily Details";
  }, []);

  return (
    <>
      <VulnerabilitiesDetailsContainer />
    </>
  );
};

export default Datastore;
