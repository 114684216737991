import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import "./index.scss";
import { getScoreColorCode } from "../../utils/styles";

am4core.addLicense("ch-custom-attribution");

const RiskScoreChart = ({ riskStats }) => {
  const loadChart = () => {
    // Create chart instance
    var chart = am4core.create("risk-score-chart", am4charts.PieChart);

    // Add data
    chart.data = [
      {
        risk: "High",
        score: riskStats?.issueUberScore || 0,
        color: am4core.color(getScoreColorCode(riskStats?.issueUberScore || 0)),
      },
      {
        risk: "",
        score: 100 - (riskStats?.issueUberScore || 0),
        color: am4core.color("#060C2B"),
      },
    ];

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "score";
    pieSeries.dataFields.category = "risk";

    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.radius = am4core.percent(80);
    chart.innerRadius = am4core.percent(80);
    // chart.startAngle = 170;
    // chart.endAngle = 270;

    // Put a thick white border around each Slice
    // pieSeries.slices.template.stroke = am4core.color("#FFFFFF");
    pieSeries.slices.template.strokeWidth = 3;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.tooltipText = "";

    var label = pieSeries.createChild(am4core.Label);
    label.text = `${Math.round(riskStats?.issueUberScore) || 0}`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 12;
    label.fontWeight = 800;
    label.fill = am4core.color(
      getScoreColorCode(riskStats?.issueUberScore || 0)
    );
    // label.color = am4core.color("#FFFFFF");
    label.populateText = true;

    // Add a legend
    // chart.legend = new am4charts.Legend();
  };

  useEffect(() => {
    // if (riskStats) {
    loadChart();
    // }
  }, [riskStats]);

  return (
    <div id="risk-score-chart" style={{ height: "47px", width: "47px" }}></div>
  );
};

export default RiskScoreChart;
