import React, { useEffect, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  hideLoading,
  showLoading,
} from "../../redux/slices/globalOperationSlice";
import { axiosDenaliPrivate } from "../../utils/ApiHandler";
import { LOADER_CONFIG } from "../../utils/Constants";
import {
  getCustomerId,
  removeSelectedDataFlow,
} from "../../utils/SessionHelper";
import DatastoreAccessGraph from "../charts/d3-flow-chart/DatastoreAccessGraph";
import DatastoreAttackPathGraph from "../charts/d3-flow-chart/DatastoreAttackPathGraph";
import Modal from "../modal/index";
import BottomPanelContainer from "./BottomPanel/BottomPanelContainer";
import "./DetailsComponent.scss";
import TopPanelContainer from "./TopPanel/TopPanelContainer";
import WorldMap from "../maps/WorldMap";
import Mongodb from "../../components/charts/data-store/images/MongoDB.svg";
import MYSQL from "../../components/charts/data-store/images/MYSQL.svg";
import PostgreDB from "../../components/charts/data-store/images/PostgreDB.svg";
import RDS from "../../components/charts/data-store/images/RDS.svg";
import S3 from "../../components/charts/data-store/images/S3.svg";
import TopPanelPolicyViolationContainer from "./TopPanel/TopPanelPolicyViolationComponent";
import DocDB from "../../components/charts/data-store/images/DocDB.svg";
import ElasticCache from "../../components/charts/data-store/images/ElasticCache.svg";
import NeptuneDb from "../../components/charts/data-store/images/NeptuneDb.svg";
import { getDatastoreNewIcon } from "../../utils/styles";
import AffectedDatastoresAndIssuesModal from "../modal/affected-datastore-and-issue-modal/AffectedDatastoresAndIssuesModal";
import moment from "moment";

import { useLocation } from "react-router-dom";
import { policyViolationDetailsAsync } from "../../redux/slices/policySlice";
const datastoreIcons = {
  mysql: MYSQL,
  rds: RDS,
  "RDS-Postgres": RDS,
  mongodb: Mongodb,
  PostgreSQL: PostgreDB,
  s3: S3,
  docdb: DocDB,
  neptune: NeptuneDb,
  elasticache: ElasticCache,
};
const DetailsComponent = ({ setTicket, selectedAccount }) => {
  const [toggle, setToggle] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [rowIndex, setRowIndex] = useState({});
  const [modalData, setModalData] = useState({});
  const [selectedBar, setSelectedBar] = useState({});
  const [createTicket, setCreateTicker] = useState(false);
  const [attackPaths, setAttackPaths] = useState([]);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.globalOperation.loading);
  const [isDatastoreIssuesModal, setIsDatastoreIssuesModal] = useState();
  const params = useParams();
  const history = useHistory();
  const datastoreId = params.id?.replaceAll("_", "/");
  const assetId = params.assetId?.replaceAll("_", "/");
  const policyViolationId = params.policyViolationId;
  const policyViolationInstanceId = params.policyViolationInstanceId;
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState("attack-path");
  const ref = useRef();
  const [datastoreSources, setDatastoreSources] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [isUSOnlyAccess, setIsUSOnlyAccess] = useState(false);
  const policy = JSON.parse(sessionStorage.getItem("policy"));
  const [width, setWidth] = useState(0);
  const [isPolicyDetails, setIsPolicyDetails] = useState();
  const [policyDetails, setPolicyDetails] = useState({});

  useEffect(() => {
    if (location.pathname.includes("/policy-details")) {
      setIsPolicyDetails(true);
      setSelectedTab("access");
    } else {
      setIsPolicyDetails(false);
    }
  }, [location]);

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, []);

  useEffect(() => {
    if (isPolicyDetails) {
      getPolicyViolationDetails();
    }
  }, [isPolicyDetails]);

  useEffect(() => {
    window.addEventListener("resize", getWidth);
    return () => {
      removeSelectedDataFlow();
    };
  }, []);

  const getWidth = () => {
    setWidth(ref?.current?.clientWidth);
  };
  const handleClick = (data) => {
    setModalData(data);
    setIsModal(true);
  };

  const refreshDetails = () => {
    getPolicyViolationDetails();
  };

  const getPolicyViolationDetails = async () => {
    dispatch(showLoading());
    const payload = {
      customerId: getCustomerId(),
      policyViolationInstanceId: policyViolationInstanceId,
    };
    let resp = await dispatch(policyViolationDetailsAsync(payload));

    if (resp || resp === null) {
      setPolicyDetails(resp?.payload);
      dispatch(hideLoading());
    }
  };

  const handleCreateTicket = () => {
    setCreateTicker(true);
    setIsModal(false);
    document.getElementById("tickets").innerHTML = "9";
    setTicket(true);
  };

  const getDatastore = async () => {
    if (isPolicyDetails) {
      setSelectedBar({
        cloudAccountId:
          policyDetails?.instanceDetails?.metadata?.cloudAccountId,
        name: policyDetails?.instanceDetails?.dsInfo.name,
        type: policyDetails?.instanceDetails?.dsInfo.type,
        region: policyDetails?.instanceDetails?.dsInfo.region,
      });
    } else if (isPolicyDetails === false) {
      dispatch(showLoading());
      let url = `/datastore/instance/detail`;
      const payload = {
        id: datastoreId,
        assetId: assetId,
        customerId: getCustomerId(),
      };
      let res = await axiosDenaliPrivate.post(url, payload);
      res = res?.data;
      setSelectedBar(res);
      dispatch(hideLoading());
    }
  };

  const getAttackPaths = async () => {
    try {
      let url = `/getattackpath`;
      dispatch(showLoading());
      const payload = {
        datastoreId: datastoreId, //selectedBar.name,
        customerId: getCustomerId(),
      };
      let res = await axiosDenaliPrivate.post(url, payload);
      res = res?.data;
      setAttackPaths(res ? (Object.keys(res).length === 0 ? [] : res) : []);
    } catch (e) {
      setAttackPaths([]);
      dispatch(hideLoading());
    } finally {
      dispatch(hideLoading());
    }
  };

  const viewIssues = () => {
    history.push({
      pathname: `/vulnerabilities/${params.assetId?.replaceAll("/", "_")}`,
    });
  };

  useEffect(() => {
    getDatastore();

    if (isPolicyDetails === false) {
      getAttackPaths();
    }
  }, [isPolicyDetails, policyDetails]);

  return (
    <BlockUi
      tag="div"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      <div id="details-component-main-container" className="container-fluid">
        <div className="pl-3 pr-3">
          <div className="row">
            <div className="col col-sm-11 page-heading mb-2">
              {isPolicyDetails ? "Policy Violations > " : "Data Stores > "}
              <span>
                {isPolicyDetails
                  ? policyDetails?.title
                  : selectedBar?.name || ""}
              </span>
            </div>
            <div className="col col-sm-1 top-bar-toggle-button">
              {/* <div className="float-right">
                <div onClick={() => setToggle(!toggle)}>
                  <img src={arrowDown} />
                </div>
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col col-sm-12">
              {isPolicyDetails && (
                <TopPanelPolicyViolationContainer
                  refreshDetails={refreshDetails}
                  policyDetails={policyDetails}
                  policy={policy}
                  toggle={toggle}
                  assetId={assetId}
                  selectedBar={selectedBar}
                  datastoreId={datastoreId}
                  policyViolationId={policyViolationId}
                  setIsDatastoreIssuesModal={(val) =>
                    setIsDatastoreIssuesModal(val)
                  }
                  setIsUSOnlyAccess={setIsUSOnlyAccess}
                  selectedAccount={selectedAccount}
                ></TopPanelPolicyViolationContainer>
              )}
              {isPolicyDetails === false && (
                <TopPanelContainer
                  toggle={toggle}
                  assetId={assetId}
                  selectedBar={selectedBar}
                  datastoreId={datastoreId}
                  // logo={getDatastoreNewIcon(selectedBar.type, selectedBar.score)}
                  // {datastoreIcons[selectedBar.type]}
                  setIsDatastoreIssuesModal={(val) =>
                    setIsDatastoreIssuesModal(val)
                  }
                  setIsUSOnlyAccess={setIsUSOnlyAccess}
                  refreshDetails={getDatastore}
                ></TopPanelContainer>
              )}
            </div>
          </div>
          <div className="row pb-2">
            <div className="col align-self-start">
              <div className="row my-2">
                {/* <div className="col-lg-auto d-flex">
                  <span className="card-tabs">
                    <b className="p-2">ATTACK PATHS - {attackPaths.length} </b>
                  </span>
                </div> */}

                {/* <div
                  className="col-lg-auto d-flex"
                  onClick={() => {
                    selectedBar.numSecurityIssues > 0 &&
                      setIsDatastoreIssuesModal(true);
                  }}
                  // onClick={() => viewIssues()}
                >
                  <span
                    className={
                      selectedBar.numSecurityIssues > 0
                        ? "card-tabs cursor-pointer"
                        : "card-tabs-disabled"
                    }
                  >
                    <b className="p-2"> SECURITY ISSUES </b>
                  </span>
                </div> */}

                {isDatastoreIssuesModal ? (
                  <AffectedDatastoresAndIssuesModal
                    handleModal={() => setIsDatastoreIssuesModal(false)}
                    assetIdValue={assetId}
                    isSecurityIssueVisisble={true}
                    page={"datastore"}
                  />
                ) : (
                  ""
                )}
                {/* <div className="col-lg-auto p-1 mr-2">
                <span className="text-info">
                  <b>ISSUES - {selectedBar.num2}</b>
                </span>{" "}
              </div>
              <div className="col-lg-auto p-1 mr-2">
                <span className="text-info">
                  <b>TICKET HISTORY - 4</b>
                </span>{" "}
              </div> */}
              </div>
            </div>
            <div className="col align-self-end">
              {" "}
              {/* <div className="float-right">
                {" "}
                <div className="col-lg-auto p-1">
                  {" "}
                  <div className="dropdown">
                    <button
                      className="btn btn-light dropdown-toggle table-dropdown btn-custom"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="dropdown-title">EXACT PATH</span>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {isPolicyDetails ? (
            <>
              {policyDetails?.instanceDetails?.livenessInfo ? (
                <div className="row">
                  <div className="col-xl-5 left-panel">
                    <BottomPanelContainer
                      isPolicyDetails={isPolicyDetails}
                      policyDetails={policyDetails}
                      createTicket={createTicket}
                      selectedBar={selectedBar}
                      RefreshChart={(idx, name) =>
                        setRowIndex({ index: idx, name: name })
                      }
                      setCreateTicket={() => setCreateTicker(false)}
                      attackPaths={attackPaths}
                      datastoreSources={datastoreSources}
                      setSelectedTab={(value) => setSelectedTab(value)}
                      setDatastoreSources={(value) =>
                        setDatastoreSources(value)
                      }
                      selectedTab={selectedTab}
                      setIsDatastoreIssuesModal={(val) =>
                        setIsDatastoreIssuesModal(val)
                      }
                      setSelectedNode={(value) => setSelectedNode(value)}
                      isUSOnlyAccess={isUSOnlyAccess}
                    />
                  </div>
                  <div className="col-xl-7" ref={ref}>
                    <div className="graph">
                      {selectedTab === "attack-path" ? (
                        <>
                          <p className="pl-3 pt-2">
                            {rowIndex.name?.replace("AP-", "")?.toUpperCase()}
                          </p>
                          <DatastoreAttackPathGraph
                            width={width}
                            rowIndex={rowIndex}
                            handleClick={handleClick}
                            attackPaths={attackPaths}
                          />
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-between">
                            <p className="pl-3 pt-2">{rowIndex.name}</p>
                            <div className="pr-3 pt-2">
                              Last Access On:&nbsp;
                              {datastoreSources?.ten_minutes?.time_window_end
                                ? moment(
                                    moment
                                      .utc(
                                        datastoreSources?.ten_minutes
                                          ?.time_window_end
                                      )
                                      .toDate()
                                  )
                                    .local()
                                    .format("YYYY-MM-DD HH:mm:ss")
                                : "N/A"}
                            </div>
                          </div>
                          <WorldMap
                            isPolicyDetails={isPolicyDetails}
                            isUSOnlyAccess={isUSOnlyAccess}
                            selectedBar={selectedBar}
                            assetId={
                              isPolicyDetails
                                ? policyDetails?.instanceDetails?.dsInfo
                                    ?.asset_id
                                : assetId
                            }
                            datastoreId={
                              isPolicyDetails
                                ? policyDetails?.dsId
                                : datastoreId
                            }
                            selectedTab={selectedTab}
                            datastoreSources={datastoreSources}
                            rowIndex={rowIndex}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className="row">
              <div className="col-xl-5 left-panel">
                <BottomPanelContainer
                  isPolicyDetails={isPolicyDetails}
                  policyDetails={policyDetails}
                  createTicket={createTicket}
                  selectedBar={selectedBar}
                  RefreshChart={(idx, name) =>
                    setRowIndex({ index: idx, name: name })
                  }
                  setCreateTicket={() => setCreateTicker(false)}
                  attackPaths={attackPaths}
                  datastoreSources={datastoreSources}
                  setSelectedTab={(value) => setSelectedTab(value)}
                  setDatastoreSources={(value) => setDatastoreSources(value)}
                  selectedTab={selectedTab}
                  setIsDatastoreIssuesModal={(val) =>
                    setIsDatastoreIssuesModal(val)
                  }
                  setSelectedNode={(value) => setSelectedNode(value)}
                  isUSOnlyAccess={isUSOnlyAccess}
                />
              </div>
              <div className="col-xl-7" ref={ref}>
                <div className="graph">
                  {selectedTab === "attack-path" ? (
                    <>
                      <p className="pl-3 pt-2">
                        {rowIndex.name?.replace("AP-", "")?.toUpperCase()}
                      </p>
                      <DatastoreAttackPathGraph
                        width={width}
                        rowIndex={rowIndex}
                        handleClick={handleClick}
                        attackPaths={attackPaths}
                      />
                    </>
                  ) : (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="pl-3 pt-2">{rowIndex.name}</p>
                        <div className="pr-3 pt-2">
                          Last Access On:&nbsp;
                          {datastoreSources?.ten_minutes?.time_window_end
                            ? moment(
                                moment
                                  .utc(
                                    datastoreSources?.ten_minutes
                                      ?.time_window_end
                                  )
                                  .toDate()
                              )
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "N/A"}
                        </div>
                      </div>
                      <WorldMap
                        isPolicyDetails={isPolicyDetails}
                        isUSOnlyAccess={isUSOnlyAccess}
                        selectedBar={selectedBar}
                        assetId={
                          isPolicyDetails
                            ? policyDetails?.instanceDetails?.dsInfo?.asset_id
                            : assetId
                        }
                        datastoreId={
                          isPolicyDetails ? policyDetails?.dsId : datastoreId
                        }
                        selectedTab={selectedTab}
                        datastoreSources={datastoreSources}
                        rowIndex={rowIndex}
                      />
                      {/* <DatastoreAccessGraph
                      width={width}
                      rowIndex={rowIndex}
                      selectedBar={selectedBar}
                      handleClick={handleClick}
                      datastoreSources={datastoreSources}
                      attackPaths={attackPaths}
                      assetId={assetId}
                      datastoreId={datastoreId}
                      selectedTab={selectedTab}
                    /> */}
                    </>
                  )}

                  {/* <p className="note">
                Click on the appropriate link to open a ticket
              </p> */}
                  {/* <button className="btn btn-light" onClick={() => setIsModal(true)}>
                BLOCK ACCESS
              </button>
              <button className="btn btn-light" onClick={() => setIsModal(true)}>
                RECONFIGURE
              </button>
              <button className="btn btn-light" onClick={() => setIsModal(true)}>
                PATCH ACCESS
              </button> */}
                </div>
              </div>
            </div>
          )}
        </div>
        {isModal ? (
          <Modal
            data={modalData}
            handleModal={() => setIsModal(false)}
            createTicket={handleCreateTicket}
          />
        ) : (
          ""
        )}
      </div>
    </BlockUi>
  );
};

export default DetailsComponent;
