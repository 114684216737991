import { useMediaQuery, useTheme } from '@mui/material';
import { prefixNumber } from '../../utils/commonUtils';
import Box from './Box';
import Typography from './Typography';

export interface Props {
  number: number;
  status: string;
  tag: string;
}

const NumericTag = ({ number, status, tag }: Props) => {
  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down('lg'));
  const styles = {
    tagContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    numberText: {
      fontSize: '28px',
      fontFamily: 'Manrope'
    },
    statusText: {
      marginLeft: isSmallWidth ? 0 : theme.spacing(0.75)
    },
    numberTag: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left'
    }
  };

  return (
    <Box sx={styles.tagContainer}>
      <Box sx={isSmallWidth ? styles.numberTag : {}}>
        <Typography component='span' color={theme.palette.surface70.main} sx={styles.numberText}>
          {prefixNumber(number)}
        </Typography>
        <Typography
          component='span'
          variant='caption'
          color={theme.palette.critical.main}
          sx={styles.statusText}>
          {status}
        </Typography>
      </Box>
      <Typography component='span' color={theme.palette.surface70.main} variant='caption'>
        {tag}
      </Typography>
    </Box>
  );
};

export default NumericTag;
