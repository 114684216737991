import "./style.scss";
import { ReactComponent as ArrowDownIcon } from "../../../assets/img/arrow-up.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/img/arrow-down.svg";
import { useState } from "react";

const Accordions = ({ data, title }) => {
  const [accordions, setAccordions] = useState(data);

  const handleToggle = (index) => {
    let newArr = [...accordions];
    newArr[index].open = !newArr[index].open;
    setAccordions(newArr);
  };

  return (
    <div>
      <h5 className="accordion-title">{title}</h5>
      <div className="accordions">
        {accordions.map((accordion, index) => (
          <div className="accordion" key={index}>
            <h6 onClick={() => handleToggle(index)}>
              {accordion.title}
              <p className="float-right">
                {accordion.open ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </p>
            </h6>
            <div className={accordion.open ? "show" : "hide"}>
              <div className="content">{accordion.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordions;
