import { useTheme } from "@mui/material";
import Box from "../common/Box";

export const CardWrapper = ({ children }) => {
  const theme = useTheme();
  const styles = {
    cardWrapperStyle: {
      backgroundColor: theme.palette.surface10?.main,
      padding: theme.spacing(2.5),
      borderRadius: 1,
      width: "100%",
    },
  };
  return <Box sx={styles.cardWrapperStyle}>{children}</Box>;
};
