import CustomNodeComponent from "./CustomNodeComponent";

export const config = {
  automaticRearrangeAfterDropNode: false,
  collapsible: false,
  height: 328,
  highlightDegree: 1,
  highlightOpacity: 0.2,
  linkHighlightBehavior: false,
  maxZoom: 8,
  minZoom: 0.1,
  nodeHighlightBehavior: false,
  panAndZoom: false,
  staticGraph: false,
  staticGraphWithDragAndDrop: true,
  width: "100%",
  d3: {
    alphaTarget: 0.05,
    gravity: -400,
    linkLength: 300,
    // linkStrength: 1,
    disableLinkForce: false,
  },
  node: {
    color: "#d3d3d3",
    fontColor: "black",
    fontSize: 12,
    fontWeight: "normal",
    highlightColor: "red",
    highlightFontSize: 12,
    highlightFontWeight: "bold",
    highlightStrokeColor: "SAME",
    highlightStrokeWidth: 1.5,
    labelProperty: "name",
    mouseCursor: "pointer",
    opacity: 1,
    renderLabel: false,
    size: {
      width: 950,
      height: 900,
    },
    strokeColor: "none",
    strokeWidth: 1.5,
    svg: "",
    symbolType: "circle",
    viewGenerator: (node) => <CustomNodeComponent node={node} />,
  },
  link: {
    color: "#31477d",
    opacity: 1,
    semanticStrokeWidth: false,
    strokeWidth: 2,
  },
};

export const attackPathData = [
  {
    nodes: [
      {
        id: "161",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "162",
        type: "EC2Instance",
        node_id: "i-0f71eac5064474b5a",
        name: "i-0f71eac5064474b5a",
        data: {
          heading: "i-0f71eac5064474b5a",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "i-0f71eac5064474b5a",
          type: "EC2Instance",
          id: "i-0f71eac5064474b5a",
        },
      },
    ],
    links: [
      {
        id: "e#228#161",
        target: "161",
        source: "162",
      },
    ],
    path: "ap-rds:crm-database-1-1",
  },
  {
    nodes: [
      {
        id: "163",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "164",
        type: "EC2Instance",
        node_id: "i-0f71eac5064474b5a",
        name: "i-0f71eac5064474b5a",
        data: {
          heading: "i-0f71eac5064474b5a",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "i-0f71eac5064474b5a",
          type: "EC2Instance",
          id: "i-0f71eac5064474b5a",
        },
      },
      {
        id: "165",
        type: "EC2Instance",
        node_id: "i-049726e3ab6bb54c7",
        name: "i-049726e3ab6bb54c7",
        data: {
          heading: "i-049726e3ab6bb54c7",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "i-049726e3ab6bb54c7",
          type: "EC2Instance",
          id: "i-049726e3ab6bb54c7",
        },
      },
    ],
    links: [
      {
        id: "e#228#161",
        target: "163",
        source: "164",
      },
      {
        id: "e#266#228",
        target: "164",
        source: "165",
      },
    ],
    path: "ap-rds:crm-database-1-2",
  },
  {
    nodes: [
      {
        id: "166",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "167",
        type: "EC2Instance",
        node_id: "i-0f71eac5064474b5a",
        name: "i-0f71eac5064474b5a",
        data: {
          heading: "i-0f71eac5064474b5a",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "i-0f71eac5064474b5a",
          type: "EC2Instance",
          id: "i-0f71eac5064474b5a",
        },
      },
      {
        id: "168",
        type: "EC2Instance",
        node_id: "i-049726e3ab6bb54c7",
        name: "i-049726e3ab6bb54c7",
        data: {
          heading: "i-049726e3ab6bb54c7",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "i-049726e3ab6bb54c7",
          type: "EC2Instance",
          id: "i-049726e3ab6bb54c7",
        },
      },
      {
        id: "169",
        type: "CDExternal",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "CDExternal",
          id: "XCVf1234",
        },
      },
    ],
    links: [
      {
        id: "e#228#161",
        target: "166",
        source: "167",
      },
      {
        id: "e#266#228",
        target: "167",
        source: "168",
      },
      {
        id: "e#250#266",
        target: "168",
        source: "169",
      },
    ],
    path: "ap-rds:crm-database-1-3",
  },
  {
    nodes: [
      {
        id: "161",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "253",
        type: "CDExternal",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "CDExternal",
          id: "XCVf1234",
        },
      },
    ],
    links: [
      {
        id: "e#253#161",
        target: "161",
        source: "253",
      },
    ],
    path: "ap-rds:crm-database-1-4",
  },
  {
    nodes: [
      {
        id: "161",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "304",
        type: "EC2Instance",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "EC2Instance",
          id: "XCVf1234",
        },
      },
    ],
    links: [
      {
        id: "e#304#161",
        target: "161",
        source: "304",
      },
    ],
    path: "ap-rds:crm-database-1-5",
  },
  {
    nodes: [
      {
        id: "161",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "304",
        type: "EC2Instance",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "EC2Instance",
          id: "XCVf1234",
        },
      },
      {
        id: "303",
        type: "CDExternal",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "CDExternal",
          id: "XCVf1234",
        },
      },
    ],
    links: [
      {
        id: "e#304#161",
        target: "161",
        source: "304",
      },
      {
        id: "e#303#304",
        target: "304",
        source: "303",
      },
    ],
    path: "ap-rds:crm-database-1-6",
  },
  {
    nodes: [
      {
        id: "161",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "255",
        type: "EC2Instance",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "EC2Instance",
          id: "XCVf1234",
        },
      },
    ],
    links: [
      {
        id: "e#255#161",
        target: "161",
        source: "255",
      },
    ],
    path: "ap-rds:crm-database-1-7",
  },
  {
    nodes: [
      {
        id: "161",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "255",
        type: "EC2Instance",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "EC2Instance",
          id: "XCVf1234",
        },
      },
      {
        id: "254",
        type: "CDExternal",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "CDExternal",
          id: "XCVf1234",
        },
      },
    ],
    links: [
      {
        id: "e#255#161",
        target: "161",
        source: "255",
      },
      {
        id: "e#254#255",
        target: "255",
        source: "254",
      },
    ],
    path: "ap-rds:crm-database-1-8",
  },
  {
    nodes: [
      {
        id: "161",
        type: "RDSInstance",
        node_id: "arn:aws:rds:us-east-1:77777777:db:1",
        name: "1",
        data: {
          heading: "1",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "1",
          type: "RDSInstance",
          id: "arn:aws:rds:us-east-1:77777777:db:1",
        },
      },
      {
        id: "286",
        type: "CDExternal",
        node_id: "XCVf1234",
        name: "XCVf1234",
        data: {
          heading: "XCVf1234",
          value: 40,
          tooltipRequired: true,
        },
        tooltip_content: {
          name: "XCVf1234",
          type: "CDExternal",
          id: "XCVf1234",
        },
      },
    ],
    links: [
      {
        id: "e#286#161",
        target: "161",
        source: "286",
      },
    ],
    path: "ap-rds:crm-database-1-9",
  },
];
