export const regions = [
  {
    name: "N. Virginia",
    latitude: 38.13,
    longitude: -78.45,
    full_name: "US East (N. Virginia)",
    code: "us-east-1",
    public: true,
    zones: [
      "us-east-1a",
      "us-east-1b",
      "us-east-1c",
      "us-east-1d",
      "us-east-1e",
      "us-east-1f",
    ],
  },
  {
    name: "Ohio",
    latitude: 40.4173,
    longitude: -82.996216,
    full_name: "US East (Ohio)",
    code: "us-east-2",
    public: true,
    zones: ["us-east-2a", "us-east-2b", "us-east-2c"],
  },
  {
    name: "N. California",
    latitude: 40.0,
    longitude: -120.0,
    full_name: "US West (N. California)",
    code: "us-west-1",
    public: true,
    zone_limit: 2,
    zones: ["us-west-1a", "us-west-1b", "us-west-1c"],
  },
  {
    name: "Oregon",
    latitude: 46.15,
    longitude: -123.88,
    full_name: "US West (Oregon)",
    code: "us-west-2",
    public: true,
    zones: ["us-west-2a", "us-west-2b", "us-west-2c", "us-west-2d"],
  },
  {
    name: "GovCloud West",
    full_name: "AWS GovCloud (US)",
    code: "us-gov-west-1",
    public: false,
    zones: ["us-gov-west-1a", "us-gov-west-1b", "us-gov-west-1c"],
  },
  {
    name: "GovCloud East",
    full_name: "AWS GovCloud (US-East)",
    code: "us-gov-east-1",
    public: false,
    zones: ["us-gov-east-1a", "us-gov-east-1b", "us-gov-east-1c"],
  },
  {
    name: "Canada",
    latitude: 55.5333312,
    longitude: -106.583331,
    full_name: "Canada (Central)",
    code: "ca-central-1",
    public: true,
    zones: ["ca-central-1a", "ca-central-1b"],
  },
  {
    name: "Stockholm",
    latitude: 59.3293,
    longitude: 18.0686,
    full_name: "EU (Stockholm)",
    code: "eu-north-1",
    public: true,
    zones: ["eu-north-1a", "eu-north-1b", "eu-north-1c"],
  },
  {
    name: "Ireland",
    full_name: "EU (Ireland)",
    code: "eu-west-1",
    public: true,
    zones: ["eu-west-1a", "eu-west-1b", "eu-west-1c"],
    latitude: 53.34778,
    longitude: -6.25972,
  },
  {
    name: "London",
    latitude: 51,
    longitude: -0.1,
    full_name: "EU (London)",
    code: "eu-west-2",
    public: true,
    zones: ["eu-west-2a", "eu-west-2b", "eu-west-2c"],
  },
  {
    name: "Paris",
    latitude: 48.86,
    longitude: 2.35,
    full_name: "EU (Paris)",
    code: "eu-west-3",
    public: true,
    zones: ["eu-west-3a", "eu-west-3b", "eu-west-3c"],
  },
  {
    name: "Frankfurt",
    latitude: 50.0,
    longitude: 8.0,
    full_name: "EU (Frankfurt)",
    code: "eu-central-1",
    public: true,
    zones: ["eu-central-1a", "eu-central-1b", "eu-central-1c"],
  },
  {
    name: "Tokyo",
    latitude: 35.41,
    longitude: 139.42,
    full_name: "Asia Pacific (Tokyo)",
    code: "ap-northeast-1",
    public: true,
    zone_limit: 3,
    zones: [
      "ap-northeast-1a",
      "ap-northeast-1b",
      "ap-northeast-1c",
      "ap-northeast-1d",
    ],
  },
  {
    name: "Seoul",
    latitude: 37.56,
    longitude: 126.98,
    full_name: "Asia Pacific (Seoul)",
    code: "ap-northeast-2",
    public: true,
    zones: ["ap-northeast-2a", "ap-northeast-2c"],
  },
  {
    name: "Osaka",
    latitude: 34.6937,
    longitude: 135.5023,
    full_name: "Asia Pacific (Osaka-Local)",
    code: "ap-northeast-3",
    public: false,
    zones: ["ap-northeast-3a"],
  },
  {
    name: "Singapore",
    latitude: 1.37,
    longitude: 103.8,
    full_name: "Asia Pacific (Singapore)",
    code: "ap-southeast-1",
    public: true,
    zones: ["ap-southeast-1a", "ap-southeast-1b", "ap-southeast-1c"],
  },
  {
    name: "Sydney",
    latitude: -33.8,
    longitude: 151.2,
    full_name: "Asia Pacific (Sydney)",
    code: "ap-southeast-2",
    public: true,
    zones: ["ap-southeast-2a", "ap-southeast-2b", "ap-southeast-2c"],
  },
  {
    name: "Mumbai",
    latitude: 19.08,
    longitude: 72.88,
    full_name: "Asia Pacific (Mumbai)",
    code: "ap-south-1",
    public: true,
    zones: ["ap-south-1a", "ap-south-1b"],
  },
  {
    name: "São Paulo",
    full_name: "South America (São Paulo)",
    code: "sa-east-1",
    public: true,
    latitude: -23.533773,
    longitude: -46.62529,
    zone_limit: 2,
    zones: ["sa-east-1a", "sa-east-1b", "sa-east-1c"],
  },
  {
    name: "Beijing",
    full_name: "China (Beijing)",
    code: "cn-north-1",
    latitude: 39.9042,
    longitude: 116.4074,
    public: false,
    zones: ["cn-north-1a", "cn-north-1b"],
  },
  {
    name: "Ningxia",
    full_name: "China (Ningxia)",
    latitude: 38.4712,
    longitude: 106.2587,
    code: "cn-northwest-1",
    public: false,
    zones: ["cn-northwest-1a", "cn-northwest-1b", "cn-northwest-1c"],
  },
  {
    name: "Iowa",
    latitude: 42.032974,
    longitude: -93.581543,
    full_name: "US Central (Iowa)",
    code: "us-central1",
    public: true,
    zones: ["us-west-2a", "us-west-2b", "us-west-2c", "us-west-2d"],
  },
];
