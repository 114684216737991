import { useTheme } from '@mui/material';
import { ImpactTooltipProps } from '../../types/type';
import Box from './Box';
import Typography from './Typography';

const ImpactDonutTooltip = ({ tooltipData }: ImpactTooltipProps) => {
  const theme = useTheme();
  const styles = {
    tooltipContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2)
    },
    gridRow: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr 1fr',
      rowGap: theme.spacing(1),
      columnGap: theme.spacing(0.75),
      textAlign: 'center'
    },
    textBold: {
      fontWeight: theme.typography.fontWeightMedium
    }
  };
  return (
    <Box sx={styles.tooltipContainer}>
      <Box sx={styles.gridRow}>
        <Box></Box>
        <Typography variant='tooltip' color={theme.palette.surface40.main}>
          Critical
        </Typography>
        <Typography variant='tooltip' color={theme.palette.surface40.main}>
          High
        </Typography>
        {tooltipData.map(({ label, critical, high }) => (
          <>
            <Typography variant='caption' align='right' color={theme.palette.surface0.main}>
              {label}
            </Typography>
            <Typography variant='caption' color={theme.palette.critical.main} sx={styles.textBold}>
              {critical}
            </Typography>
            <Typography variant='caption' color={theme.palette.high.main} sx={styles.textBold}>
              {high}
            </Typography>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default ImpactDonutTooltip;
