import { axiosDenaliPrivate } from "../../utils/ApiHandler";

/**
 * Provides the list of all top issue types.
 * @returns -- issue list
 */
export const fetchTopIssueTypes = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/issues/top`, params);
};

/**
 * Provides the list of all dataStores.
 * @returns -- Accounts list
 */
export const updateSecurityIssueInstanceRisk = (params) => {
  return axiosDenaliPrivate.put(
    `/api/v1/securityissueinstance/acceptrisk/update`,
    params
  );
};

/**
 * Add the tag with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const updateSecurityIssueRisk = (params) => {
  return axiosDenaliPrivate.put(
    `/api/v1/securityissue/acceptrisk/update`,
    params
  );
};
