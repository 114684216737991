import React, { useState, useEffect } from "react";
import "./style.scss";
import SchedulerModal from "../../components/reports/SchedulerModal";
import {
  listReportsAsync,
  deleteReportAsync,
} from "../../redux/slices/reportsSlice";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoading,
  showLoading,
} from "../../redux/slices/globalOperationSlice";
import moment from "moment";
import DeleteConfirmationModal from "../../components/modal/shared/DeleteConfirmationModal";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import { LOADER_CONFIG } from "../../utils/Constants";
import infoFilled from "../../assets/img/icons/ep-info-filled.svg";
import { INFO_MODAL_DATA } from "../../components/modal/info-modal/InfoModalData";
import InfoModal from "../../components/modal/info-modal/InfoModal";
import { ReactComponent as ReportIcon } from "../../assets/img/new-theme/sidebar/report.svg";
import { ReactComponent as PauseIcon } from "../../assets/img/pause.svg";
import { ReactComponent as TrashIcon } from "../../assets/img/trash.svg";
import Button from "../../components/common/Button";

const Reports = ({ setPath }) => {
  const [isModal, setIsModal] = useState(false);
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.report.reports);
  const loading = useSelector((state) => state.globalOperation.loading);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState(false);

  useEffect(() => {
    document.title = "Cloud Defense - Reports";

    refreshReports();
    setPath(window.location.pathname);
  }, []);

  const refreshReports = async () => {
    dispatch(showLoading());
    await dispatch(listReportsAsync());
    setIsModal(false);
    setDeleteModal(false);
    dispatch(hideLoading());
  };

  const deleteReport = async () => {
    setShowLoader(true);
    const resp = await dispatch(deleteReportAsync(selectedReport._id));
    if (resp && resp.payload && !resp.payload.err) {
      setShowLoader(false);
      Toaster(TOASTER_TYPES.SUCCESS, "Report deleted successfully.");
      refreshReports();
    } else {
      setShowLoader(false);
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to delete report."
      );
    }
  };

  const handleDelete = (report) => {
    setSelectedReport(report);
    setDeleteModal(true);
  };

  useEffect(() => {
    console.log("Reports: ", reports);
  }, [reports]);

  const getSchedule = (report) => {
    return `Occurs: ${report?.repeat} Stops: ${
      moment(report.endRepeat).format("DD-MM-YYYY") !== "Invalid date"
        ? report.endRepeat.length === 1
          ? `${report.endRepeat} Occurrence`
          : moment(report.endRepeat).format("DD-MM-YYYY")
        : report.endRepeat
    } Status: Running`;
  };

  const handleInfoModal = (visible, data) => {
    setInfoModalData(data);
    setShowInfoModal(visible);
  };

  return (
    <div className="reports-container">
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        {/* Hiding this as it is not in work at the moment */}
        {/* <div className="row pb-2 border-bottom bg-white">
          <div className="col align-self-start">
            <div className="px-3">
              <div className="row mt-2">
                <div className="col-auto d-flex justify-content-center">
                  <b>SEARCH &amp; FILTERS :</b>
                </div>
                <div className="col-auto">
                  <div className="dropdown">
                    <button
                      className="btn btn-light dropdown-toggle table-dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="dropdown-title">Keyword</span>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Enter Keyword"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="report-title row py-2">
          <div className="col">
            <div className="px-3">
              <span>Reports</span>
            </div>
          </div>
        </div>

        <div className="reports-body p-3">
          {/* <h5>
            <span>TICKETS - 8</span>
            {"   "}
            <span>FILTERED TO: 0</span>
          </h5> */}

          <Button
            variant="contained"
            type="button"
            onClick={() => setIsModal(true)}
          >
            <ReportIcon />
            <span className="btn-sm">Schedule New Report</span>
          </Button>

          <h6 className="mt-4">
            Scheduled Report
            {reports && reports.length > 0 ? " - " + reports.length : ""}
          </h6>
          <hr />

          <div className="table-responsive">
            <table className="pb-5 table table-hover table-no-background mt-2">
              <thead className="vuls-row">
                <tr className="head vuls-row">
                  <th scope="col">Report Name</th>
                  {/* <th scope="col">Control</th> */}
                  <th scope="col">Schedule</th>
                  <th scope="col">Description</th>
                  <th scope="col">Recipients</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {reports &&
                  reports.map((data, index) => (
                    <tr className="vuls-row" key={index}>
                      <td scope="col">{data.reportName}</td>
                      {/* <td scope="col">
                        <span className="mr-1">PAUSE</span> <PauseIcon />
                      </td> */}
                      <td scope="col">{getSchedule(data)}</td>
                      <td scope="col">{data.reportDescription}</td>
                      <td scope="col">
                        {data.recipient.map(
                          (str, index) =>
                            `${
                              index < data.recipient.length - 1
                                ? str + ", "
                                : str
                            }`
                        )}
                      </td>
                      <td scope="col">
                        <span>
                          <TrashIcon onClick={() => handleDelete(data)} />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {isModal && (
          <SchedulerModal
            handleModal={() => setIsModal(false)}
            refreshReports={refreshReports}
          />
        )}
        {deleteModal && (
          <DeleteConfirmationModal
            handleModal={() => setDeleteModal(false)}
            deleteHandle={deleteReport}
            showLoader={showLoader}
            modalData={{
              title: "Delete Report",
              message: "Are you sure, you want to delete this report?",
            }}
          />
        )}
        {showInfoModal && (
          <InfoModal
            handleModal={() => setShowInfoModal(false)}
            data={infoModalData}
          />
        )}
      </BlockUi>
    </div>
  );
};

export default Reports;
