const getRandomIssueList = (noOfItem: number) => {
  const riskLevels = ['Critical', 'High', 'Low'];
  const ticketStatuses = ['notCreated', 'created'];

  function getRandomElementFromArray(array: any) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function generateRandomDate() {
    const start = new Date(2023, 0, 1);
    const end = new Date(2023, 11, 31);
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  function generateRandomIssueName() {
    const randomStrings = [
      'C&CActivity',
      'SecurityBreach',
      'DataLeak',
      'UnauthorizedAccess',
      'NetworkAttack'
    ];
    return `Backdoor:EC2-${getRandomElementFromArray(randomStrings)}`;
  }

  const dataArray: Array<{
    id: number;
    risk: string;
    issueName: string;
    issueId: string;
    issueType: string;
    impactedDataStore: string;
    impactedDataStoreId: string;
    lastOccur: string;
    ticketStatus: string;
    remediation?: string;
  }> = [];

  for (let i = 0; i < noOfItem; i++) {
    const data = {
      id: i,
      risk: getRandomElementFromArray(riskLevels),
      issueName: generateRandomIssueName(),
      issueId: 'aws-fsbp-EC2.8',
      issueType: 'Misconfigurations/aws',
      impactedDataStore: 'Misconfigurations/aws RDS',
      impactedDataStoreId: 'RDS.2.RDS',
      lastOccur: generateRandomDate().toISOString(),
      ticketStatus: getRandomElementFromArray(ticketStatuses),
      remediation:
        'Restrict Public Access : Ensure that the RDS instance is not publicly accessible and can only be accessed from trusted network sources or authorized IP addresses. '
    };
    dataArray.push(data);
  }

  return dataArray;
};

export default getRandomIssueList;
