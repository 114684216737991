import { useTheme } from "@mui/material";
import type { CheckboxProps } from "@mui/material/Checkbox";
import MUICheckbox from "@mui/material/Checkbox";

interface MUICheckboxProps extends CheckboxProps {
  label: string;
}

const Checkbox = ({ sx, label, ...otherProps }: MUICheckboxProps) => {
  const theme = useTheme();

  const styles = {
    root: {
      "&.MuiButtonBase-root": {
        padding: theme.spacing(0),
      },
    },
  };
  return <MUICheckbox sx={{ ...styles.root, ...sx }} {...otherProps} />;
};

export default Checkbox;
