import React, { useEffect, useState } from "react";
import { Graph } from "react-d3-graph";
import closeIcon from "../../../assets/img/icons/close-icon.svg";
import { capitalizeFirstLetter } from "../../../utils/uppercase";
import { config } from "./attackPathConfig";
import "./DatastoreAttackPathGraph.scss";
import { getNodeIconByType } from "./flowChartHelper";
import AffectedDatastoresAndIssuesModal from "../../modal/affected-datastore-and-issue-modal/AffectedDatastoresAndIssuesModal";
const IssuesPathGraph = ({ rowIndex, attackPaths, width }) => {
  const [graphData, setGraphData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isAttackPathNodeClicked, setIsAttackPathNodeClicked] = useState(false);

  // const data1 = {
  //   nodes: [
  //     {
  //       id: "#1",
  //       type: "customNode",
  //       name: "External",
  //       data: {
  //         heading: "External",
  //         height: "2rem",
  //         width: "2rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: externalCloud,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 30,
  //         tooltipRequired: false,
  //       },
  //       x: 70,
  //       y: 130,
  //     },
  //     {
  //       id: "#2",
  //       type: "customNode",
  //       name: "Load balancer",
  //       data: {
  //         heading: "Load balancer",
  //         height: "3rem",
  //         width: "3rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: LoadBalancerImg,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: false,
  //       },
  //       x: 200,
  //       y: 130,
  //     },
  //     {
  //       id: "#3",
  //       type: "customNode",
  //       name: "EC2-Public",
  //       data: {
  //         heading: "EC2-Public",
  //         height: "4rem",
  //         width: "4rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: awsEc2,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: true,
  //       },
  //       x: 300,
  //       y: 130,
  //     },
  //     {
  //       id: "#4",
  //       type: "customNode",
  //       name: "EC2-Private",
  //       data: {
  //         heading: "EC2-Private",
  //         height: "4rem",
  //         width: "4rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: awsPrivate,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: true,
  //       },
  //       x: 450,
  //       y: 130,
  //     },
  //     {
  //       id: "#5",
  //       type: "customNode",
  //       name: "CD Labs:CRM",
  //       data: {
  //         heading: "CD Labs:CRM",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 700,
  //       y: 130,
  //     },
  //     {
  //       id: "#6",
  //       type: "customNode",
  //       name: "cd-webdev",
  //       data: {
  //         heading: "cd-webdev",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 400,
  //       y: 230,
  //     },
  //     {
  //       id: "#7",
  //       type: "customNode",
  //       name: "cd-dev-temp",
  //       data: {
  //         heading: "cd-dev-temp",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 600,
  //       y: 25,
  //     },
  //     {
  //       id: "#8",
  //       name: "cd-labs:CRM",
  //       type: "customNode",
  //       data: {
  //         heading: "cd-labs:CRM",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 600,
  //       y: 230,
  //     },
  //   ],
  //   links: [
  //     {
  //       id: "e#1#2",
  //       type: "floating",
  //       target: "#2",
  //       source: "#1",
  //     },
  //     {
  //       id: "e#2#3",
  //       target: "#3",
  //       source: "#2",
  //     },
  //     {
  //       id: "e#3#4",
  //       target: "#4",
  //       source: "#3",
  //     },
  //     {
  //       id: "e#4#5",
  //       target: "#5",
  //       source: "#4",
  //     },
  //     {
  //       id: "e#3#6",
  //       type: "CURVE_SMOOTH",
  //       target: "#3",
  //       source: "#6",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //     {
  //       id: "e#4#7",
  //       type: "CURVE_SMOOTH",
  //       target: "#7",
  //       source: "#4",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //     {
  //       id: "e#4#8",
  //       type: "CURVE_SMOOTH",
  //       target: "#4",
  //       source: "#8",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //   ],
  // };

  // const data2 = {
  //   nodes: [
  //     {
  //       id: "#21",
  //       type: "customNode",
  //       name: "External",
  //       data: {
  //         heading: "External",
  //         height: "2rem",
  //         width: "2rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: externalCloud,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 30,
  //         tooltipRequired: false,
  //       },
  //       x: 70,
  //       y: 130,
  //     },
  //     {
  //       id: "#22",
  //       type: "customNode",
  //       name: "Load balancer",
  //       data: {
  //         heading: "Load balancer",
  //         height: "3rem",
  //         width: "3rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: LoadBalancerImg,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: false,
  //       },
  //       x: 200,
  //       y: 130,
  //     },
  //     {
  //       id: "#23",
  //       type: "customNode",
  //       name: "EC2-Public",
  //       data: {
  //         heading: "EC2-Public",
  //         height: "4rem",
  //         width: "4rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: awsEc2,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: true,
  //       },
  //       x: 300,
  //       y: 130,
  //     },
  //     {
  //       id: "#24",
  //       type: "customNode",
  //       name: "EC2-Private",
  //       data: {
  //         heading: "EC2-Private",
  //         height: "4rem",
  //         width: "4rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: awsPrivate,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: true,
  //       },
  //       x: 450,
  //       y: 130,
  //     },
  //     {
  //       id: "#25",
  //       type: "customNode",
  //       name: "CD Labs:CRM",
  //       data: {
  //         heading: "CD Labs:CRM",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 700,
  //       y: 130,
  //     },
  //     {
  //       id: "#26",
  //       type: "customNode",
  //       name: "cd-webdev",
  //       data: {
  //         heading: "cd-webdev",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 400,
  //       y: 230,
  //     },
  //     {
  //       id: "#27",
  //       type: "customNode",
  //       name: "cd-dev-temp",
  //       data: {
  //         heading: "cd-dev-temp",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 600,
  //       y: 25,
  //     },
  //     {
  //       id: "#28",
  //       name: "cd-labs:CRM",
  //       type: "customNode",
  //       data: {
  //         heading: "cd-labs:CRM",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 600,
  //       y: 230,
  //     },
  //   ],
  //   links: [
  //     {
  //       id: "e#21#22",
  //       type: "floating",
  //       target: "#22",
  //       source: "#21",
  //     },
  //     {
  //       id: "e#22#23",
  //       target: "#23",
  //       source: "#22",
  //     },
  //     {
  //       id: "e#23#24",
  //       target: "#24",
  //       source: "#23",
  //     },
  //     {
  //       id: "e#24#25",
  //       target: "#25",
  //       source: "#24",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //     {
  //       id: "e#23#26",
  //       type: "CURVE_SMOOTH",
  //       target: "#23",
  //       source: "#26",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //     {
  //       id: "e#24#27",
  //       type: "CURVE_SMOOTH",
  //       target: "#27",
  //       source: "#24",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //     {
  //       id: "e#24#28",
  //       type: "CURVE_SMOOTH",
  //       target: "#24",
  //       source: "#28",
  //     },
  //   ],
  // };

  // const data3 = {
  //   nodes: [
  //     {
  //       id: "#31",
  //       type: "customNode",
  //       name: "External",
  //       data: {
  //         heading: "External",
  //         height: "2rem",
  //         width: "2rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: externalCloud,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 30,
  //         tooltipRequired: false,
  //       },
  //       x: 70,
  //       y: 130,
  //     },
  //     {
  //       id: "#32",
  //       type: "customNode",
  //       name: "Load balancer",
  //       data: {
  //         heading: "Load balancer",
  //         height: "3rem",
  //         width: "3rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: LoadBalancerImg,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: false,
  //       },
  //       x: 200,
  //       y: 130,
  //     },
  //     {
  //       id: "#33",
  //       type: "customNode",
  //       name: "EC2-Public",
  //       data: {
  //         heading: "EC2-Public",
  //         height: "4rem",
  //         width: "4rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: awsEc2,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: true,
  //       },
  //       x: 300,
  //       y: 130,
  //     },
  //     {
  //       id: "#34",
  //       type: "customNode",
  //       name: "EC2-Private",
  //       data: {
  //         heading: "EC2-Private",
  //         height: "4rem",
  //         width: "4rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: awsPrivate,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: true,
  //       },
  //       x: 450,
  //       y: 130,
  //     },
  //     {
  //       id: "#35",
  //       type: "customNode",
  //       name: "CD Labs:CRM",
  //       data: {
  //         heading: "CD Labs:CRM",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 700,
  //       y: 130,
  //     },
  //     {
  //       id: "#36",
  //       type: "customNode",
  //       name: "cd-webdev",
  //       data: {
  //         heading: "cd-webdev",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 400,
  //       y: 230,
  //     },
  //     {
  //       id: "#37",
  //       type: "customNode",
  //       name: "cd-dev-temp",
  //       data: {
  //         heading: "cd-dev-temp",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 600,
  //       y: 25,
  //     },
  //     {
  //       id: "#38",
  //       name: "cd-labs:CRM",
  //       type: "customNode",
  //       data: {
  //         heading: "cd-labs:CRM",
  //         height: "5rem",
  //         width: "5rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: mysqlIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 60,
  //         tooltipRequired: true,
  //       },
  //       x: 600,
  //       y: 230,
  //     },
  //   ],
  //   links: [
  //     {
  //       id: "e#31#32",
  //       type: "floating",
  //       target: "#32",
  //       source: "#31",
  //     },
  //     {
  //       id: "e#32#33",
  //       target: "#33",
  //       source: "#32",
  //     },
  //     {
  //       id: "e#33#34",
  //       target: "#34",
  //       source: "#33",
  //     },
  //     {
  //       id: "e#34#35",
  //       target: "#35",
  //       source: "#34",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //     {
  //       id: "e#33#36",
  //       type: "CURVE_SMOOTH",
  //       target: "#33",
  //       source: "#36",
  //     },
  //     {
  //       id: "e#34#37",
  //       type: "CURVE_SMOOTH",
  //       target: "#37",
  //       source: "#34",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //     {
  //       id: "e#34#38",
  //       type: "CURVE_SMOOTH",
  //       target: "#34",
  //       source: "#38",
  //       strokeWidth: 1.5,
  //       color: "#d3d3d3",
  //     },
  //   ],
  // };

  const nodeClicked = (data) => {
    setSelectedNode(data);
    setIsAttackPathNodeClicked(true);
  };
  const handleRefChange = (ref) => {
    if (ref) ref.resetNodesPositions();
  };
  const getGraphData = (data) => {
    if (data) {
      let nodes = data.nodes.length;
      let nodeLine = (width - 150) / (nodes - 1);
      let nodeX = 0;
      data.nodes.map((node, index) => {
        node.height = "2rem";
        node.width = "2rem";
        nodeX = index == 0 ? 70 : nodeX + nodeLine;
        node.x = nodeX;
        node.y = 100;
        node.data = {};
        node.data.image = {
          width: node.is_primary ? 50 : 40,
          height: node.is_primary ? 50 : 40,
          src: getNodeIconByType(node.type),
          margin: node.is_primary ? "20px auto 0 auto" : "26px auto 0 auto",
        };
        node.data.handleClick = nodeClicked;
        node.selected = false;
      });
    }
    return data;
  };

  const getRightPanelContents = () => {
    if (selectedNode && selectedNode?.tooltip_content) {
      const contents = selectedNode?.tooltip_content;
      let popupContents = [];
      let index = 1;
      for (var key in contents) {
        index++;
        if (contents.hasOwnProperty(key)) {
          var val = contents[key];
          if (
            (key === "eks_cluster" || key === "EKS Cluster") &&
            selectedNode?.eks_enabled === false
          ) {
            continue;
          } else {
            popupContents.push(
              <div className="contents" key={index}>
                <div class="content-header">{capitalizeFirstLetter(key)}:</div>
                <div
                  className="content-body"
                  dangerouslySetInnerHTML={{ __html: val }}
                ></div>
                <hr />
              </div>
            );
          }
        }
      }
      return (
        <div className="right-panel">
          <div className="header m-0 d-flex justify-content-between">
            <span>{selectedNode?.name || ""}</span>
            <img
              className="close-icon"
              src={closeIcon}
              title="Close"
              onClick={() => setSelectedNode(null)}
            />
          </div>
          <div>{popupContents}</div>
        </div>
      );
    } else {
      return (
        <div className="right-panel">
          <p className="header m-0">{selectedNode?.name || ""}</p>
        </div>
      );
    }
  };

  useEffect(() => {
    if (rowIndex.index >= 0 && attackPaths.length > 0) {
      const graphDataset = getGraphData(attackPaths[rowIndex.index]);
      setGraphData({ nodes: graphDataset.nodes, links: graphDataset.links });
    } else {
      setGraphData({ nodes: [], links: [] });
    }
  }, [rowIndex, width]);

  return (
    <div>
      {graphData && (
        <Graph
          id="issues-graph-id"
          ref={handleRefChange}
          data={graphData}
          config={config}
        />
      )}
      {isAttackPathNodeClicked && (
        <AffectedDatastoresAndIssuesModal
          handleModal={() => setIsAttackPathNodeClicked(false)}
          isRiskAccepted={false}
          assetIdValue={selectedNode?.CD_Asset_Id}
          node={selectedNode}
          isAttackPath={true}
          isSecurityIssueVisisble={true}
        />
      )}
    </div>
  );
};

export default IssuesPathGraph;
