import moment from "moment";

export const langchainFrameworkData = [
  {
    id: "8041b11c-515c-11ee-be56-0242ac120002",
    title: "Langchain 1",
    version: "0.0.283",
    data: {
      sensitiveDetails: [
        {
          PII: "social_security_numbers",
          count: "6425",
        },
        {
          PII: "street_addresses",
          count: "3524",
        },
        {
          PII: "emails",
          count: "6390",
        },
        {
          PII: "mobile_numbers",
          count: "2899",
        },
      ],
      cloudAccountId: "099873896856",
      policyViolationDetails: {
        cloudAccountId: null,
        customerId: "05ce2437-4658-44b9-b15d-cccd9f025dab",
        datastore_id: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        policyViolations: [
          {
            dsCount: 1,
            datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
            description:
              "Organizations can monitor the use of certain custom phrases and terms in data flowing through LLMs. These phrases and terms can be the intellectual property or restricted topics of the organization.",
            lastViolatedAt: "2023-09-12T06:06:08.556000",
            policyViolationId: "f85f15a4-2222-45f2-95c1-12e01a063b1f",
            policyViolationName: "Restricted topics in prompt session",
            remediation:
              "Alert the security team when the LLM prompt, context or response contain restricted topics or related content",
            ticket_exist: false,
            tickets: 0,
            url: null,
          },
          {
            dsCount: 1,
            datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
            description:
              "User information such as PII, PCI and PHI data is regulated by law and cannot to shared with unauthorized individuals",
            lastViolatedAt: "2023-09-12T06:06:08.356000",
            policyViolationId: "f85f15a4-7777-45f2-95c1-12e01a063b1f",
            policyViolationName: "Sensitive identifiers in prompt session",
            remediation:
              "Alert the security team when the LLM prompt, context or response contain regulated data",
            ticket_exist: false,
            tickets: 0,
            url: null,
          },
        ],
        ticketIntegration: true,
      },
      vectorDBDetails: {
        dbName: "Chroma",
        version: "0.4.8",
        size: "1 GB",
        directory: "./chromaDB",
      },
      frameworkSummaryDetails: {
        name: "AcmeMortgageApp-1",
        family: "langchain",
        version: "0.0.283",
        vectorDB: "Chroma",
        embeddingModel: "text-ada-003",
        llmModel: "GPT-2",
        size: "1GB",
        directory: "./chromaDB",
        modelLink:
          "/llm/7cd5c0fa-4321-4f89-bd9b-e9979b823c30/arn:aws:ec2:us-east-1:099873896856:instance_i-05f66db16bae61bb0",
      },
      frameworkInstanceDetails: {
        accountName: "AWS-099873896856",
        host: "i-04b488e9f94a0d175",
        path: "/opt/apps/langchain-stack/corp-rag-internal-support",
        owner: "ubuntu",
        lastUsed: "2023 - 07 - 11 | 13 : 44 : 56",
        tags: "KB, Internal",
      },
      llmInfo: {
        llmInformation: {
          Name: "gpt2",
          Owner: "root",
          Framework: "PyTorch",
          "Base Family": "pythia-12b-sft-8",
        },
        InstallInformation: {
          Account: "AWS - 099873896856",
          "Host Path": "/data/root/.cache/huggingface/hub/models--gpt2",
          Host: "i-05f66db16bae61bb0",
          Size: "1.021 GB",
          "Last Used": "2023 - 07 - 13  10 : 33 : 00",
        },
        PostTrained: {
          duplicate_volume: ["i-04b488e9f94a0d175"],
          "Installed Model Hash": "11c5a3d5811f50298f278a704980280950aedb10",
          is_different_hash: "True",
        },
        TrainingData: [
          {
            FileName: "test.jsonl",
            Location: "/data/home/model_data",
            type: "jsonl",
            size: "2.16 GB",
            "sensitive labels": "SSN,PII,ADDRESS",
          },
        ],
        TrainingDataSets: {
          "Bias Score": 47,
          "Risk Score": 78,
          "Model Hash": "oi1slzu3nk3gxz24qfm14jlin58y54o08y5et2kt",
        },
      },
      promptsResponseSummary: [
        {
          tabValue: "vectorDB",
          data: [
            {
              name: "PII - social_security_numbers",
              records: 2,
              messages: [
                {
                  msgText:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx",
                  prompt: "What is the SSN of Tom Brady from Florida?",
                  response: "SSN: 621-xx-xxxx.",
                  retrivalDate: moment().subtract(4, "days"),
                  policyViolations: ["Sensitive identifiers in prompt session"],
                },
                {
                  msgText:
                    "CAR LOAN APPLICATION. Looking to finance a new or used car? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: Steph Curry, SSN: 532-xxx-xxxx",
                  prompt:
                    "Did John Doe ever apply for a loan and if so, what SSN did he use?",
                  response: "SSN: 378-xx-xxxx.",
                  retrivalDate: moment().subtract(2, "days"),
                  policyViolations: ["Sensitive identifiers in prompt session"],
                },
              ],
            },
            {
              name: "PII - credit_cards",
              records: 1,
              messages: [
                {
                  msgText:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
                  prompt:
                    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
                  response: "Card Number: xxxx-xxxx-xxxx-3243",
                  retrivalDate: moment().subtract(5, "days"),
                  policyViolations: ["Sensitive identifiers in prompt session"],
                },
              ],
            },
            {
              name: "Custom Topic - Internal approval criteria",
              records: 1,
              messages: [
                {
                  msgText:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  prompt:
                    "What criteria was used to decline Ana Smith’s mortgage loan?",
                  response:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%). Since Ana Smith did not meet the bank’s Debt-to-Income requirement, her application was declined.",
                  retrivalDate: moment().subtract(4, "days"),
                  policyViolations: ["Restricted topics in prompt session"],
                },
              ],
            },
            {
              name: "Topic - Toxic content",
              records: 1,
              messages: [
                {
                  msgText:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  prompt:
                    "What criteria was used to decline Joe Smith's mortgage application?",
                  response:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%). Since Joe Smith did not meet the bank’s Ethnicity requirement, his application was declined.",
                  retrivalDate: moment().subtract(4, "days"),
                  policyViolations: ["Restricted topics in prompt session"],
                },
              ],
            },
          ],
          noDataText: "No VectorDB Sensitive Retrieval Data Found",
        },
        {
          tabValue: "sensitiveResponses",
          data: [],
          noDataText: "No Sensitive Responses Found",
        },
        {
          tabValue: "sensitivePrompts",
          data: [],
          noDataText: "No Sensitive Prompts Found",
        },
      ],
      promptResponseTimeData: [
        {
          category: "vectorDB",
          issues: 0,
          date: moment().subtract(6, "days").format("YYYY-MM-DD"),
        },
        {
          category: "vectorDB",
          issues: 1,
          date: moment().subtract(5, "days").format("YYYY-MM-DD"),
        },
        {
          category: "vectorDB",
          issues: 2,
          date: moment().subtract(4, "days").format("YYYY-MM-DD"),
        },
        {
          category: "vectorDB",
          issues: 0,
          date: moment().subtract(3, "days").format("YYYY-MM-DD"),
        },
        {
          category: "vectorDB",
          issues: 1,
          date: moment().subtract(2, "days").format("YYYY-MM-DD"),
        },
        {
          category: "vectorDB",
          issues: 0,
          date: moment().subtract(1, "days").format("YYYY-MM-DD"),
        },
        {
          category: "vectorDB",
          issues: 0,
          date: moment().format("YYYY-MM-DD"),
        },
      ],
      totalPrompts: 248,
      last7Days: 248,
      last1Day: 43,
      last1Hour: 17,
    },
  },
  {
    id: "877c0248-515c-11ee-be56-0242ac120002",
    title: "Langchain 2",
    version: "0.0.254",
    data: {
      sensitiveDetails: [
        {
          PII: "social_security_numbers",
          count: "6425",
        },
        {
          PII: "street_addresses",
          count: "3524",
        },
        {
          PII: "emails",
          count: "6390",
        },
        {
          PII: "mobile_numbers",
          count: "2899",
        },
      ],
      cloudAccountId: "099873896856",
      policyViolationDetails: {
        cloudAccountId: null,
        customerId: "05ce2437-4658-44b9-b15d-cccd9f025dab",
        datastore_id: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        policyViolations: [
          {
            count: 0,
            datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
            description:
              "LLM was trained with regulated or sensitive data such as PHI or PII.  The regulated data may now leak out to unauthorized users.",
            lastViolatedAt: "2023-09-12T06:06:08.556000",
            policyViolationId: "139150ab-23dc-4599-9a2b-14b94b6049d0",
            policyViolationName: "Fine tuned on sensitive data",
            remediation:
              "Delete the exposed LLM and any clones from the environment. Download a new LLM base model and retrain it with clean data.",
            ticket_exist: false,
            tickets: 0,
            url: null,
          },
          {
            count: 0,
            datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
            description:
              "LLM accessible from assets with security issues that could facilitate malicious activity.",
            lastViolatedAt: "2023-09-12T06:06:08.356000",
            policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
            policyViolationName:
              "Trained with data from publicly exposed datastores",
            remediation:
              "Resolve security issues on assets facilitating malicious activity.",
            ticket_exist: false,
            tickets: 0,
            url: null,
          },
        ],
        ticketIntegration: true,
      },
      vectorDBDetails: {
        dbName: "Chroma",
        version: "0.4.8",
        size: "1 GB",
        directory: "./chromaDB",
      },
      frameworkSummaryDetails: {
        name: "Langchain-d6ba4",
        family: "langchain",
        version: "0.0.254",
        vectorDB: "Chroma",
        embeddingModel: "text-ada-003",
        llmModel: "Llama-2",
        modelLink: "",
      },
      frameworkInstanceDetails: {
        accountName: "AWS-099873896856",
        host: "i-0c7770be78fcd6ba4",
        path: "/opt/apps/langchain-stack/corp-rag-mrkt",
        owner: "ubuntu",
        lastUsed: "2023 - 07 - 11 | 13 : 44 : 56",
        tags: "SalesWiki,Marketing",
      },
      llmInfo: {
        llmInformation: {
          Name: "llama2",
          Owner: "root",
          Framework: "PyTorch",
          "Base Family": "pythia-12b-sft-8",
        },
        InstallInformation: {
          Account: "AWS - 099873896856",
          "Host Path": "/data/root/.cache/huggingface/hub/models--gpt2",
          Host: "i-05f66db16bae61bb0",
          Size: "1.021 GB",
          "Last Used": "2023 - 07 - 13  10 : 33 : 00",
        },
        PostTrained: {
          duplicate_volume: ["i-04b488e9f94a0d175"],
          "Installed Model Hash": "11c5a3d5811f50298f278a704980280950aedb10",
          is_different_hash: "True",
        },
        TrainingData: [
          {
            FileName: "marketing_dump.jsonl",
            Location: "s3://role-data-bucket/marketing/data",
            type: "jsonl",
            size: "2.16 GB",
            "sensitive labels": "SSN,PII,ADDRESS",
          },
        ],
        TrainingDataSets: {
          "Bias Score": 47,
          "Risk Score": 78,
          "Model Hash": "oi1slzu3nk3gxz24qfm14jlin58y54o08y5et2kt",
        },
      },
      promptsResponseSummary: [],
      promptResponseTimeData: [],
      totalPrompts: 34,
      last7Days: 34,
      last1Day: 12,
      last1Hour: 2,
    },
  },
];
