import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  updateSecurityIssueInstanceRisk,
  updateSecurityIssueRisk,
  fetchTopIssueTypes,
} from "../apis/issuesAPI";

const initialState = {
  issues: null,
};

// Async calls.

export const listTopIssueTypesAsync = createAsyncThunk(
  "issues/top",
  async (params) => {
    const response = await fetchTopIssueTypes(params);
    return response.data;
  }
);

export const updateSecurityIssueInstanceRiskAsync = createAsyncThunk(
  "issueInstance/update",
  async (params) => {
    const response = await updateSecurityIssueInstanceRisk(params);
    return response.data;
  }
);

export const updateSecurityIssueRiskAsync = createAsyncThunk(
  "issue/update",
  async (params) => {
    const response = await updateSecurityIssueRisk(params);
    return response.data;
  }
);

// Issues slice for configuring reducers and actions.
export const IssuesSlice = createSlice({
  name: "issues",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
});

export default IssuesSlice.reducer;

// Selectors will go here
