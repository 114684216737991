import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import * as Yup from "yup";
import integrationIcon from "../../../assets/img/icons/settings/integration.svg";
import {
  addIntegrationAsync,
  listJiraProjectsAsync,
  listIssueTypesAsync,
} from "../../../redux/slices/integrationSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import "./IntegrationModal.scss";
import { getUserId } from "../../../utils/SessionHelper";
import { LOADER_CONFIG } from "../../../utils/Constants";
import {
  hideLoading,
  showLoading,
} from "../../../redux/slices/globalOperationSlice";
import Button from "../../common/Button";

// Functional component for Add Integration.
const JiraIntegrationModal = ({ handleModal, refreshIntegrations, card }) => {
  const dispatch = useDispatch();
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [projects, setProjects] = useState([]);
  const [issues, setIssues] = useState([]);
  const loading = useSelector((state) => state.globalOperation.loading);

  // Formik
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      host: "",
      project: "",
      issue: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Please enter username."),
      password: Yup.string().required("Please enter password."),
      host: Yup.string().required("Please enter host."),
    }),
    onSubmit: (values) => {
      listJiraProjects({ ...values });
    },
  });

  const handleCancel = () => {
    handleModal(false);
  };

  const getProjects = (id) => {
    return projects.find((project) => project.id === id);
  };

  const listJiraProjects = async (values) => {
    if (!projects.length) {
      dispatch(showLoading());
      const resp = await dispatch(
        listJiraProjectsAsync({
          host: values.host,
          username: values.username,
          password: values.password,
        })
      );

      if (resp && resp.payload && !resp.payload.err) {
        dispatch(hideLoading());
        setProjects(resp.payload.projects);
      } else {
        Toaster(TOASTER_TYPES.ERROR, resp?.payload?.err?.message);
        dispatch(hideLoading());
      }
    } else {
      dispatch(showLoading());
      const resp = await dispatch(
        listIssueTypesAsync({
          host: values.host,
          username: values.username,
          password: values.password,
          project: getProjects(values.project),
        })
      );

      if (resp && resp.payload && !resp.payload.err) {
        dispatch(hideLoading());
        setIssues(resp.payload.issues);
      } else {
        Toaster(TOASTER_TYPES.ERROR, resp?.payload?.err?.message);
        dispatch(hideLoading());
      }
    }
  };

  const addIntegration = async (values) => {
    dispatch(showLoading());
    setConnectionFailed(false);

    const resp = await dispatch(
      addIntegrationAsync({
        username: formik.values.username.trim(),
        password: formik.values.password.trim(),
        host: formik.values.host.trim(),
        project: getProjects(formik.values.project.trim()),
        userId: getUserId(),
        issueType: [formik.values.issue],
      })
    );
    if (resp && resp.payload) {
      Toaster(TOASTER_TYPES.SUCCESS, "Integration updated successfully.");
      refreshIntegrations();
      handleModal(false);
      dispatch(hideLoading());
    } else {
      Toaster(TOASTER_TYPES.ERROR, resp?.error?.message);
      setConnectionFailed(true);
      dispatch(hideLoading());
    }
  };

  return (
    <div id="integration-modal">
      <div
        className="modal fade show"
        id="integrationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="integrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-start align-items-center">
                  <img src={integrationIcon} className="mr-3" />
                  <span>Add Integration</span>
                </div>

                <div className="modal-body">
                  <label className="label">
                    Username: <span className="mandatory-icon">*</span>
                  </label>

                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter Username"
                        name="name"
                        maxLength={50}
                        className="form-control"
                        onChange={(e) => {
                          formik.setFieldValue("username", e.target.value);
                        }}
                        value={formik.values.username}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.username && formik.touched.username && (
                        <div className="error-message">
                          {formik.errors.username}
                        </div>
                      )}
                    </div>
                  </div>

                  <label className="label">
                    Password: <span className="mandatory-icon">*</span>
                  </label>
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <input
                        type="password"
                        id="name"
                        placeholder="**********"
                        name="name"
                        className="form-control"
                        onChange={(e) => {
                          formik.setFieldValue("password", e.target.value);
                        }}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.password && formik.touched.password && (
                        <div className="error-message">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                  <label className="label">
                    Host: <span className="mandatory-icon">*</span>
                  </label>
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <input
                        type="text"
                        id="host"
                        placeholder="Enter Host"
                        name="host"
                        maxLength={50}
                        className="form-control"
                        onChange={(e) => {
                          formik.setFieldValue("host", e.target.value);
                        }}
                        value={formik.values.host}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.host && formik.touched.host && (
                        <div className="error-message">
                          {formik.errors.host}
                        </div>
                      )}
                    </div>
                  </div>
                  {projects.length ? (
                    <>
                      <label className="label">
                        Project: <span className="mandatory-icon">*</span>
                      </label>

                      <div className="row py-2">
                        <div className="col col-sm-12 workday-subheading-value">
                          <select
                            onChange={(e) => {
                              formik.setFieldValue("project", e.target.value);
                            }}
                            value={formik.values.project.id}
                            onBlur={formik.handleBlur}
                            className="form-control"
                          >
                            <option>Select project</option>
                            {projects.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {issues.length ? (
                    <>
                      <label className="label">
                        Issue Type: <span className="mandatory-icon">*</span>
                      </label>

                      <div className="row py-2">
                        <div className="col col-sm-12 workday-subheading-value">
                          <select
                            onChange={(e) => {
                              formik.setFieldValue("issue", e.target.value);
                            }}
                            value={formik.values.issue}
                            onBlur={formik.handleBlur}
                            className="form-control"
                          >
                            <option>Select Issue Type</option>
                            {issues.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {connectionFailed && (
                    <div className="row mt-2">
                      <div className="col-9 offset-md-3 pl-0 text-danger">
                        Something went wrong. Please try again.
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  {projects.length && issues.length ? (
                    <Button
                      variant="contained"
                      type="button"
                      disabled={!formik.isValid || !formik?.values?.project}
                      onClick={addIntegration}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Next
                    </Button>
                  )}

                  <span className="separator mx-3"></span>

                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default JiraIntegrationModal;
