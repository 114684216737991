export const tabs = [
  {
    value: "sensitivePrompts",
    label: "Prompts",
  },
  {
    value: "vectorDB",
    label: "Context",
  },
  {
    value: "sensitiveResponses",
    label: "Responses",
  },
];

export const totalResponses = 250;

export const sensitiveDataTabs = [
  {
    tabValue: "vectorDB",
    data: [
      {
        name: "PII - social_security_numbers",
        records: 2,
        messages: [
          {
            msgText:
              "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx",
            prompt: "What is the SSN of Tom Brady from Florida?",
            retrivalDate: "2023-10-06T04:32:36.491000",
          },
          {
            msgText:
              "CAR LOAN APPLICATION. Looking to finance a new or used car? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: Steph Curry, SSN: 532-xxx-xxxx",
            prompt:
              "Did John Doe ever apply for a loan and if so, what SSN did he use?",
            retrivalDate: "2023-10-07T04:32:36.491000",
          },
        ],
      },
      {
        name: "PII - Intellectual Property",
        records: 1,
        messages: [
          {
            msgText:
              "The bank uses the following weighed criteria to approve mortgage loans: Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%). Ana Smith did not meet the bank’s Ethnicity requirement and hence, her application was declined.",
            prompt:
              "What criteria was used to decline Ana Smith’s mortgage loan?",
            retrivalDate: "2023-10-06T04:32:36.491000",
          },
        ],
      },
      {
        name: "PII - credit_cards",
        records: 1,
        messages: [
          {
            msgText:
              "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            prompt:
              "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            retrivalDate: "2023-10-08T04:32:36.491000",
          },
        ],
      },
    ],
    noDataText: "No VectorDB Sensitive Retrieval Data Found",
  },
  {
    tabValue: "sensitiveResponses",
    data: [],
    noDataText: "No Sensitive Responses Found",
  },
  {
    tabValue: "sensitivePrompts",
    data: [],
    noDataText: "No Sensitive Prompts Found",
  },
];

export const trendPromptsData = [
  {
    category: "vectorDB",
    issues: 2,
    date: "2023-10-06",
  },
  {
    category: "vectorDB",
    issues: 1,
    date: "2023-10-07",
  },
  {
    category: "vectorDB",
    issues: 0,
    date: "2023-10-08",
  },
  {
    category: "vectorDB",
    issues: 1,
    date: "2023-10-09",
  },
  {
    category: "vectorDB",
    issues: 2,
    date: "2023-10-10",
  },
  {
    category: "vectorDB",
    issues: 0,
    date: "2023-10-11",
  },
];

export const frameworkGeoData = [
  {
    region: "us-east-1",
    hasDataResidencyViolation: false,
    sensitive_datastore: 2,
    datastores: 1,
    sensitive_data: [
      {
        tag: "Prompts",
        records: 0,
      },
      {
        tag: "Context",
        records: 5,
      },
      {
        tag: "Response",
        records: 0,
      },
    ],
  },
];
