import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { CardWrapper } from "../CardWrapper";
import Chip from "../../common/Chip";
import Tooltip from "../../common/Tooltip";
import { useContext } from "react";
import { AppContext } from "../../../context/appContext";
import gcpIcon from "../../../assets/img/gcp.svg";
import awsIcon from "../../../assets/img/awsIcon.svg";

export const InstanceDetailsCard = ({
  googleSource = false,
  instanceDetails = {},
  isManaged,
  region,
  isFineTuned,
  llmName,
  cloud,
}: {
  googleSource: boolean;
  instanceDetails: any;
  isManaged: boolean;
  region: string;
  isFineTuned: Boolean;
  llmName: string;
  cloud: string;
}) => {
  const theme = useTheme();
  const { dataStoreViolation } = useContext(AppContext);
  const styles = {
    instanceDetailsCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      width: "100%",
    },
    cardHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    instanceDetailsCardHeading: {
      fontSize: "13px",
    },
    instanceDetailsCardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    instanceDetailsCardDescription: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightLight,
      opacity: "0.5",
    },
    instanceDetailsCardBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    instanceDetail: {
      display: "flex",
    },
    instanceDetailKey: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      width: "50%",
    },
    instanceDetailValue: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      width: "50%",
      "word-wrap": "break-word",
    },
    truncateText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "noWrap",
      width: "50%",
    },
    chipContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  };
  //const cloud =
  //  dataStoreViolation && dataStoreViolation?.length > 0
  //    ? dataStoreViolation[0]?.data?.find((item) => item?.name === llmName)
  //        ?.cloud
  //    : null;

  const showGCP = cloud === "Google";

  const size = instanceDetails?.InstallInformation["Size"];

  return (
    <CardWrapper>
      <Box sx={styles.instanceDetailsCard}>
        <Box sx={styles.cardHeader}>
          <Box sx={styles.instanceDetailsCardHeader}>
            <Typography variant="body2" sx={styles.instanceDetailsCardHeading}>
              Instance Details
            </Typography>
            <Typography sx={styles.instanceDetailsCardDescription}>
              Post - Trained after downloading
            </Typography>
          </Box>
          <Box sx={styles.chipContainer}>
            <Chip
              label={isFineTuned ? "Under Fine-tuning" : "Deployed"}
              color="primary"
            />
            <img src={showGCP ? gcpIcon : awsIcon} alt="cloud" height={22} />
          </Box>
        </Box>
        <Box sx={styles.instanceDetailsCardBody}>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Account</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.InstallInformation.Account}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Region</Typography>
            <Typography sx={styles.instanceDetailValue}>{region}</Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Runtime</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.InstallInformation["Runtime"] || "-"}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>
              {isManaged ? "Domain" : "Host"}
            </Typography>
            <Typography sx={styles.instanceDetailValue}>
              {isManaged
                ? instanceDetails?.InstallInformation["SagemakerDomain"]
                : instanceDetails?.InstallInformation["Host"]}
            </Typography>
          </Box>
          {size !== "" && (
            <Box sx={styles.instanceDetail}>
              <Typography sx={styles.instanceDetailKey}>Size</Typography>
              <Typography sx={styles.instanceDetailValue}>{size}</Typography>
            </Box>
          )}
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Last Used</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.InstallInformation["Last Used"]}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Endpoint URL</Typography>
            <Box sx={styles.truncateText}>
              <Tooltip title={instanceDetails?.InstallInformation?.EndpointUrl}>
                <div>
                  <Typography component="div" sx={styles.instanceDetailValue}>
                    {instanceDetails?.InstallInformation?.EndpointUrl || "-"}
                  </Typography>
                </div>
              </Tooltip>
            </Box>
          </Box>
          {!googleSource && (
            <Box sx={styles.instanceDetail}>
              <Typography sx={styles.instanceDetailKey}>Modal Hash</Typography>
              <Typography sx={styles.instanceDetailValue}>
                {instanceDetails?.PostTrained["Installed Model Hash"]}
              </Typography>
            </Box>
          )}
          {!isManaged && (
            <Box sx={styles.instanceDetail}>
              <Typography sx={styles.instanceDetailKey}>Clone</Typography>
              <Typography sx={styles.instanceDetailValue}>
                {instanceDetails?.PostTrained?.duplicate_volume?.length > 0
                  ? instanceDetails?.PostTrained?.duplicate_volume.join(" ")
                  : "-"}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </CardWrapper>
  );
};
