import React, { useEffect } from "react";
import Cards from "../../components/cards/account";

const Connect = ({ setPath }) => {
  useEffect(() => {
    document.title = "Cloud Defense - Account";

    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <Cards />
    </>
  );
};

export default Connect;
