import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateWizIntegrationAsync,
  wizIntegrationStatusAsync,
} from "../../../redux/slices/integrationSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import "./IntegrationModal.scss";
import { getCustomerId, getUserId } from "../../../utils/SessionHelper";
import Button from "../../common/Button";

// Functional component for Add Integration.
const WizStatusModal = ({ handleModal, refreshIntegrations, card, edit }) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);

  const [wizStatus, setWizStaus] = useState([]);
  const getWizStatus = async () => {
    const resp = await dispatch(
      wizIntegrationStatusAsync({
        customerId: getCustomerId(),
        // customerId: "60f45284-a1e9-4076-ae85-eba4f5b31903",
      })
    );
    if (resp && resp.payload) {
      setWizStaus(resp.payload);
    }
  };

  useEffect(() => {
    getWizStatus();
  }, []);

  const handleCancel = () => {
    handleModal(false);
  };

  const updateIntegration = async (values) => {
    setShowLoader(true);
    setConnectionFailed(false);
    const resp = await dispatch(
      updateWizIntegrationAsync({
        endpoint_url: values.endpoint_url,
        client_id: values.client_id,
        client_secret: values.client_secret,
        id: card._id,
        userId: getUserId(),
      })
    );
    if (resp && resp.payload) {
      Toaster(TOASTER_TYPES.SUCCESS, "Integration updated successfully.");
      setShowLoader(false);
      refreshIntegrations();
      handleModal(false);
    } else {
      // Toaster(TOASTER_TYPES.ERROR, "Failed to add Integration.");
      setShowLoader(false);
      setConnectionFailed(true);
    }
  };

  return (
    <div id="integration-modal">
      <div
        className="modal fade show"
        id="integrationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="integrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <span>View Wiz Integration</span>
              </div>
              {wizStatus.status ? (
                <div className="modal-body">
                  {showLoader && (
                    <img
                      src="static/img/loader-circle.svg"
                      className="modal-loading-img"
                    />
                  )}

                  <h6>
                    Status:
                    <span className="text-white ml-3">{wizStatus.status}</span>
                  </h6>
                  <h6>
                    Message:
                    <span className="text-white ml-3">{wizStatus.message}</span>
                  </h6>
                </div>
              ) : (
                <div className="modal-body">
                  {showLoader && (
                    <img
                      src="static/img/loader-circle.svg"
                      className="modal-loading-img"
                    />
                  )}

                  <h6 className="text-light-white">
                    Auth Endpoint:
                    <span className="text-white ml-3 font-weight-bold">
                      {wizStatus.authEndpointUrl}
                    </span>
                  </h6>
                  <h6 className="text-light-white">
                    API Endpoint:
                    <span className="text-white ml-3 font-weight-bold">
                      {wizStatus.endpointUrl}
                    </span>
                  </h6>
                  <h6 className="text-light-white">
                    Status:
                    <span className="text-white ml-3 font-weight-bold">
                      {wizStatus.lastVulnSyncStatus}
                    </span>
                  </h6>
                  <h6 className="text-light-white">
                    Synced At:
                    {wizStatus.lastVulnSyncAt ? (
                      <span className="text-white ml-3 font-weight-bold">
                        {`${wizStatus.lastVulnSyncAt.split("T")[0]}, ${
                          wizStatus.lastVulnSyncAt.split("T")[1].split(".")[0]
                        }`}{" "}
                        UTC
                      </span>
                    ) : (
                      ""
                    )}
                  </h6>
                  {wizStatus.lastVulnSyncMessage ? (
                    <h6 className="text-light-white">
                      Message:
                      <span className="text-white ml-3 font-weight-bold">
                        {wizStatus.lastVulnSyncMessage}
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}

                  <h6 className="text-light-white">
                    Vulnerabilities Synced :
                    <span className="text-white ml-3 font-weight-bold">
                      {wizStatus.vulnSyncedCount}
                    </span>
                  </h6>
                </div>
              )}

              <div className="modal-footer">
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WizStatusModal;
