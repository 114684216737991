import TrainingDataCustomLink from "../../d3-flow-chart/TrainingDataCustomLink";
import TrainingDataCustomNode from "../TrainingDataCustomNode";

export const config = {
  automaticRearrangeAfterDropNode: false,
  collapsible: false,
  height: 328,
  highlightDegree: 1,
  highlightOpacity: 0.2,
  linkHighlightBehavior: false,
  maxZoom: 8,
  minZoom: 0.1,
  directed: true,
  nodeHighlightBehavior: false,
  panAndZoom: false,
  staticGraph: false,
  staticGraphWithDragAndDrop: true,
  width: "100%",
  d3: {
    alphaTarget: 0.05,
    gravity: -400,
    linkLength: 300,
    // linkStrength: 1,
    disableLinkForce: false,
  },
  node: {
    color: "#d3d3d3",
    fontColor: "black",
    fontSize: 12,
    fontWeight: "normal",
    highlightColor: "red",
    highlightFontSize: 12,
    highlightFontWeight: "bold",
    highlightStrokeColor: "SAME",
    highlightStrokeWidth: 1.5,
    labelProperty: "name",
    mouseCursor: "pointer",
    opacity: 1,
    renderLabel: false,
    size: {
      width: 950,
      height: 1150,
    },
    strokeColor: "none",
    strokeWidth: 1.5,
    svg: "",
    symbolType: "circle",
    viewGenerator: (node) => <TrainingDataCustomNode node={node} />,
  },
  link: {
    color: "#31477d",
    opacity: 1,
    semanticStrokeWidth: false,
    strokeWidth: 2,
    labelProperty: "linkLabel",
    // renderLabel: true,
    // viewGenerator: (link) => <TrainingDataCustomLink node={link} />,
  },
};
