import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { regions } from "../../../maps/aws_regions";
import { getCustomerId } from "../../../../utils/SessionHelper";
import { useDispatch, useSelector } from "react-redux";
import { fetchDatastoreRegionsSlice } from "../../../../redux/slices/dashboardSlice";
import closeIcon from "../../../../assets/img/tooltip-close-icon.svg";
import { useHistory } from "react-router-dom";

am4core.addLicense("ch-custom-attribution");

const WorldMap = ({ selectedAccount, setSelectedRegion }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [tooltipContent, setTooltipContent] = useState();
  const [updatedRegions, setUpdatedRegions] = useState();
  const formatAmount = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
  };
  const getRegions = async () => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let resp = await dispatch(fetchDatastoreRegionsSlice(payload));
    if (resp && resp.payload && !resp.payload.err) {
      let regionResponse = resp.payload;
      regionResponse.map((regionObj) => {
        regions.find((region) => {
          if (region.code === regionObj.region) {
            regionObj.title = region.name;
            regionObj.latitude = region.latitude;
            regionObj.longitude = region.longitude;
            // regionObj.color = [
            //   "ap-northeast-2",
            //   "ap-southeast-2",
            //   "sa-east-1",
            // ].includes(region.code)
            //   ? am4core.color("#FC4C02")
            //   : am4core.color("#ffffff");
            regionObj.color = regionObj?.hasDataResidencyViolation
              ? am4core.color("#FC4C02")
              : am4core.color("#ffffff");
            regionObj.tooltipColor = am4core.color("#ffffff");
            let updatedTags = [];
            regionObj.sensitiveData.map((tag) => {
              updatedTags.push({
                title: tag?.tag?.split("-")[1],
                count: formatAmount(tag.records),
              });
              regionObj.updatedSensitiveData = updatedTags;
            });
          }
        });
      });

      setUpdatedRegions(regionResponse);
    }
  };

  const viewDatastores = () => {
    history.push(`/datastore?region=${tooltipContent.location}`);
  };

  useEffect(() => {
    loadChart();
  }, [updatedRegions]);

  const loadChart = () => {
    // Create map instance
    var chart = am4core.create("world-map", am4maps.MapChart);
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;
    chart.paddingRight = 0;
    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Configure series
    var polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{value}";
    polygonTemplate.fill = am4core.color("#ffffff14");
    polygonTemplate.stroke = am4core.color("#ffffff14");
    // Create hover state and set alternative fill color
    var hs = polygonTemplate.states.create("hover");
    // hs.properties.fill = am4core.color("#ffffff");
    hs.properties.stroke = am4core.color("#ffffff");
    // Remove Antarctica
    polygonSeries.exclude = ["AQ"];

    // Add image series
    let imageSeries = chart.series.push(new am4maps.MapImageSeries());

    let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle.radius = 7;
    circle.propertyFields.fill = "color";
    circle.nonScaling = true;

    let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle2.radius = 7;
    circle2.propertyFields.fill = "color";
    circle2.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    circle2.events.on("hit", function (ev) {
      setTooltipContent({
        location: ev.target.dataItem.dataContext.region,
        region: ev.target.dataItem.dataContext.title,
        types: ev.target.dataItem.dataContext?.dsTypes,
        tags: ev.target.dataItem.dataContext.updatedSensitiveData,
      });
    });

    let destinationImageTemplate = imageSeries.mapImages.template;
    destinationImageTemplate.nonScaling = true;

    destinationImageTemplate.setStateOnChildren = true;
    destinationImageTemplate.states.create("hover");

    destinationImageTemplate.propertyFields.latitude = "latitude";
    destinationImageTemplate.propertyFields.longitude = "longitude";
    destinationImageTemplate.propertyFields.id = "id";
    destinationImageTemplate.propertyFields.fill = "tooltipColor";
    destinationImageTemplate.propertyFields.href = "href";

    let colorSet = new am4core.ColorSet();
    imageSeries.data = updatedRegions;
    imageSeries.mapImages.template.propertyFields.longitude = "longitude";
    imageSeries.mapImages.template.propertyFields.latitude = "latitude";
  };

  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    loadChart();
  }, [regions]);

  return (
    <>
      {tooltipContent && (
        <div className="world-map-tooltip">
          <div className="world-map-tooltip-top">
            <div>
              <span className="world-map-tooltip-header">
                {tooltipContent?.region.length > 12
                  ? tooltipContent?.region.slice(0, 12) + ".."
                  : tooltipContent?.region}
              </span>
              <img
                src={closeIcon}
                className="tooltip-close-icon"
                onClick={() => {
                  setTooltipContent();
                }}
              />
            </div>
          </div>
          <div className="world-map-tooltip-bottom">
            {tooltipContent?.types?.length > 0 && (
              <div className="world-map-tooltip-title mb-1">Type</div>
            )}

            {tooltipContent &&
              tooltipContent?.types?.slice(0, 5).map((tag) => {
                return (
                  <div className="world-map-tooltip-content row">
                    <div className="world-map-tooltip-subtitle col">
                      {tag.type}
                    </div>
                    <div className="world-map-tooltip-description col">
                      {tag.count}
                    </div>
                  </div>
                );
              })}
            {tooltipContent?.tags?.length > 0 && (
              <div className="world-map-tooltip-title mb-1 mt-1">
                Sensitive Data
              </div>
            )}
            {tooltipContent &&
              tooltipContent?.tags?.slice(0, 5).map((tag) => {
                return (
                  <div className="world-map-tooltip-content row">
                    <div className="world-map-tooltip-subtitle col">
                      {tag.title}
                    </div>
                    <div className="world-map-tooltip-description col">
                      {tag.count}
                    </div>
                  </div>
                );
              })}
            <div
              className="world-map-tooltip-more"
              onClick={() => {
                viewDatastores();
              }}
            >
              <span>Details</span>
            </div>
          </div>
        </div>
      )}

      <div
        id="world-map"
        className=""
        style={{ height: "270px", width: "100%" }}
      ></div>
    </>
  );
};

export default WorldMap;
