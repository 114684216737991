import React, { memo, useState } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import { LOADER_CONFIG } from "../../../utils/Constants";

import { useHistory } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../assets/img/edit-circle-icon.svg";
import { ReactComponent as Archive } from "../../../assets/img/v1-icons/archive.svg";
import { ReactComponent as TicketUnavailableIcon } from "../../../assets/img/v1-icons/create-ticket.svg";
import { ReactComponent as NotifyIcon } from "../../../assets/img/v1-icons/notify.svg";
import { ReactComponent as SensitiveDataIcon } from "../../../assets/img/v1-icons/sensitive-data.svg";
import { ReactComponent as TicketIcon } from "../../../assets/img/v1-icons/view-ticket.svg";
import {
  archivePolicyAsync,
  policyNotifyAsync,
} from "../../../redux/slices/policySlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import DatastoreTicketModal from "../../modal/datastore-ticket-modal/DatastoreTicketModal";
import SensitiveDataModal from "../../modal/sensitive-data/SensitiveDataModal";
import DeleteConfirmationModal from "../../modal/shared/DeleteConfirmationModal";
import "./TopPanelComponent.scss";
import Typography from "../../common/Typography";

const TopPanelComponent = ({
  datastoreId,
  policyViolationId,
  policyDetails,
  refreshDetails,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isPolicyArchive, setIsPolicyArchive] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const policy = JSON.parse(sessionStorage.getItem("policy"));
  const [isPolicyViolationAccepted, setIsPolicyViolationAccepted] = useState(
    false
  );
  const [isSensitiveDataModal, setIsSensitiveDataModal] = useState(false);

  const notifyPolicy = async (item) => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policyViolationId ? policyViolationId : null,
      datastoreId: policyDetails?.dsId,
    };
    let resp = await dispatch(policyNotifyAsync(payload));
    if (resp && resp.payload && !resp.payload.error) {
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      setLoading(false);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.error?.message || "Failed to notify on slack."
      );
      setLoading(false);
    }
  };

  const archivePolicy = async (item) => {
    setLoading(true);
    setIsPolicyArchive(false);
    const payload = {
      customerId: getCustomerId(),
      policyId: policyDetails?.policyId ? policyDetails?.policyId : null,
      datastoreId: [policyDetails?.dsId],
    };
    let resp = await dispatch(archivePolicyAsync(payload));

    if (resp && !resp?.payload?.error && resp?.type?.includes("fulfilled")) {
      setLoading(false);
      Toaster(
        TOASTER_TYPES.SUCCESS,
        resp?.payload?.success?.message || "Policy archived succesfully"
      );
      history.push("/");
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp?.payload?.error?.message || "Failed to archive."
      );
      setLoading(false);
    }
  };

  const updatePolicy = (policy) => {
    history.push("/settings/policy-settings/" + policyDetails?.policyId);
  };

  return (
    <div>
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        <div id="top-panel-main-container" className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-5">
              <div className="workday-block">
                <div className="row">
                  <div className="col col-sm-12">
                    <div className="d-flex align-items-start">
                      <div className="pl-3 w-100">
                        <div className="row">
                          <div className="col col-sm-4 pb-2">
                            <Typography
                              variant="body2"
                              className="workday-subheading"
                            >
                              Title:
                            </Typography>
                          </div>
                          <div
                            className="col col-sm-8 workday-subheading-value"
                            title={policyDetails?.title}
                          >
                            <Typography variant="body2">
                              {policyDetails?.title?.length > 40
                                ? policyDetails?.title?.slice(0, 40) + "..."
                                : policyDetails?.title || ""}
                            </Typography>
                          </div>
                        </div>
                        {policyDetails?.last_occurred &&
                          policyDetails?.last_occurred !== null && (
                            <div className="row">
                              <div className="col col-sm-4">Last Occurred:</div>
                              <div className="col col-sm-8 workday-subheading-value">
                                <Typography variant="body2">
                                  <Moment format="YYYY-MM-DD HH:MM:SS">
                                    {policyDetails?.last_occurred}
                                  </Moment>{" "}
                                </Typography>
                              </div>
                            </div>
                          )}

                        {policyDetails?.instanceDetails?.metadata
                          ?.cloudAccountId &&
                          policyDetails?.instanceDetails?.metadata
                            ?.cloudAccountId !== null && (
                            <div className="row">
                              <div className="col col-sm-4 pb-2 ">
                                <Typography
                                  variant="body2"
                                  className="workday-subheading"
                                >
                                  Account:
                                </Typography>
                              </div>
                              <div className="col col-sm-8 workday-subheading-value">
                                <Typography variant="body2">
                                  {
                                    policyDetails?.instanceDetails?.metadata
                                      ?.cloudAccountId
                                  }
                                </Typography>
                              </div>
                            </div>
                          )}

                        {policyDetails?.instanceDetails?.dsInfo?.type &&
                          policyDetails?.instanceDetails?.dsInfo?.type !==
                            null && (
                            <div className="row">
                              <div className="col col-sm-4 pb-2">
                                <Typography
                                  variant="body2"
                                  className="workday-subheading"
                                >
                                  Type:
                                </Typography>
                              </div>
                              <div className="col col-sm-8 workday-subheading-value">
                                <Typography variant="body2">
                                  {policyDetails?.instanceDetails?.dsInfo?.type}
                                </Typography>
                              </div>
                            </div>
                          )}

                        {policyDetails?.instanceDetails?.dsInfo?.region &&
                          policyDetails?.instanceDetails?.dsInfo?.region !==
                            null && (
                            <div className="row">
                              <div className="col col-sm-4 pb-2">
                                <Typography
                                  variant="body2"
                                  className="workday-subheading"
                                >
                                  Region:
                                </Typography>
                              </div>
                              <div className="col col-sm-8 workday-subheading-value">
                                <Typography variant="body2">
                                  {
                                    policyDetails?.instanceDetails?.dsInfo
                                      ?.region
                                  }
                                </Typography>
                              </div>
                            </div>
                          )}
                        {policyDetails?.instanceDetails?.dsInfo?.name &&
                          policyDetails?.instanceDetails?.dsInfo?.name !==
                            null && (
                            <div className="row">
                              <div className="col col-sm-4 pb-2">
                                <Typography
                                  variant="body2"
                                  className="workday-subheading"
                                >
                                  Datastore Name:
                                </Typography>
                              </div>
                              <div className="col col-sm-8 workday-subheading-value">
                                <Typography variant="body2">
                                  {policyDetails?.instanceDetails?.dsInfo?.name}
                                </Typography>
                              </div>
                            </div>
                          )}
                        {policyDetails?.instanceDetails?.dsInfo
                          ?.datastore_type &&
                          policyDetails?.instanceDetails?.dsInfo
                            ?.datastore_type !== null && (
                            <div className="row pb-2">
                              <div className="col col-sm-4 pb-2">
                                <Typography
                                  variant="body2"
                                  className="workday-subheading"
                                >
                                  Datastore Type:
                                </Typography>
                              </div>
                              <div className="col col-sm-8 workday-subheading-value">
                                <Typography variant="body2">
                                  {
                                    policyDetails?.instanceDetails?.dsInfo
                                      ?.datastore_type
                                  }
                                </Typography>
                              </div>
                            </div>
                          )}

                        {policyDetails?.instanceDetails?.individualTicketInfo &&
                          policyDetails?.instanceDetails?.individualTicketInfo
                            ?.URL !== null && (
                            <div className="row">
                              <div className="col col-sm-4 pb-2">
                                <Typography
                                  variant="body2"
                                  className="workday-subheading"
                                >
                                  Ticket Link:
                                </Typography>
                              </div>
                              <div
                                className="col col-sm-8 workday-subheading-value"
                                title={
                                  policyDetails?.instanceDetails
                                    ?.individualTicketInfo?.URL
                                }
                              >
                                <Typography variant="body2">
                                  <a
                                    href={
                                      policyDetails?.instanceDetails
                                        ?.individualTicketInfo?.URL
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {policyDetails?.instanceDetails
                                      ?.individualTicketInfo?.URL?.length > 35
                                      ? policyDetails?.instanceDetails?.individualTicketInfo?.URL?.slice(
                                          0,
                                          35
                                        ) + "..."
                                      : policyDetails?.instanceDetails
                                          ?.individualTicketInfo?.URL || ""}
                                  </a>
                                </Typography>
                              </div>
                            </div>
                          )}

                        <div className="row  action-buttons">
                          <div className="col col-sm-12 workday-subheading">
                            <div className="row">
                              {policyDetails?.actionType && (
                                <div className="col-3 d-flex justify-content-center">
                                  <EditIcon
                                    title="Edit Policy"
                                    className="policy-details-action-icons   cursor-pointer"
                                    onClick={(e) => {
                                      updatePolicy(policyDetails);
                                    }}
                                  />
                                </div>
                              )}

                              {policyDetails?.actionType ===
                                "notify/archive" && (
                                <>
                                  <div className="col-3 d-flex justify-content-center">
                                    <NotifyIcon
                                      title="Notify"
                                      className="policy-details-action-icons    cursor-pointer"
                                      onClick={(e) => {
                                        notifyPolicy();
                                      }}
                                    />
                                  </div>
                                  <div className="col-3 d-flex justify-content-center">
                                    <Archive
                                      title="Archive"
                                      className="policy-details-action-icons   cursor-pointer"
                                      onClick={(e) => {
                                        setIsPolicyArchive(true);
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                              {policyDetails?.actionType ===
                                "ticket/accept" && (
                                <>
                                  {policyDetails?.instanceDetails
                                    ?.individualTicketInfo !== null ? (
                                    <div className="col-3 d-flex justify-content-center">
                                      <TicketIcon
                                        title="Ticket Details"
                                        className="policy-details-action-icons mr-1 mr-3  cursor-pointer"
                                        onClick={(e) => {
                                          window.open(
                                            policyDetails?.instanceDetails
                                              ?.individualTicketInfo?.URL
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="col-3 d-flex justify-content-center">
                                      <TicketUnavailableIcon
                                        title="Create Ticket"
                                        className="policy-detail-create-ticket mr-1 mr-3  cursor-pointer"
                                        onClick={() => setTicketModal(true)}
                                        disabled={
                                          policyDetails?.ticketIntegration !==
                                          false
                                            ? false
                                            : true
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              {policyDetails?.actionType && (
                                <div className="col-3 d-flex justify-content-center">
                                  <SensitiveDataIcon
                                    title="Sensitive Data"
                                    className="policy-details-action-icons mr-1 mr-3  cursor-pointer"
                                    onClick={() =>
                                      setIsSensitiveDataModal(true)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {ticketModal && (
                            <DatastoreTicketModal
                              type="datastore"
                              refreshDatastores={refreshDetails}
                              datastoreId={policyDetails?.dsId}
                              policyViolationId={policyViolationId}
                              handleModal={() => {
                                setTicketModal(false);
                              }}
                            />
                          )}
                          {isPolicyArchive && (
                            <DeleteConfirmationModal
                              handleModal={() => setIsPolicyArchive(false)}
                              deleteHandle={archivePolicy}
                              modalData={{
                                title: "Archive Policy",
                                message:
                                  "Are you sure, you want to archive this policy ?",
                                type: "archive",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-7 pl-md-0 mt-2">
              <div className="row mt-4">
                {policyDetails?.description &&
                  policyDetails?.description !== "null" && (
                    <div className="col col-md-12 col-lg-12  mb-2">
                      <div className="policy-details-title">Description</div>
                      <div className="policy-details-description">
                        {policyDetails?.description}
                      </div>
                    </div>
                  )}
                {policyDetails?.remediation &&
                  policyDetails?.remediation !== "null" && (
                    <div className="col col-md-12 col-lg-12  mb-2">
                      <span className="policy-details-title">Remediation</span>
                      <br></br>
                      <span className="policy-details-description">
                        {policyDetails?.remediation}
                      </span>
                    </div>
                  )}
              </div>
            </div>{" "}
            {isSensitiveDataModal && (
              <SensitiveDataModal
                handleModal={() => setIsSensitiveDataModal(false)}
                datastoreDescriptions={
                  policyDetails?.instanceDetails?.dsInfo?.sensitivityStats
                }
                // sensitiveScore={props.selectedBar?.sensitiveScore}
                type="policyViolation"
              />
            )}
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default memo(TopPanelComponent);
