import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "../d3-flow-chart/DatastoreAttackPathGraph.scss";
import LlmIcon from "../../../assets/img/v1-icons/llmIconRound.svg";
import { getScoreColor } from "../../../utils/styles";
const TrainingDataCustomNode = (node) => {
  const [selected, setSelected] = useState(false);
  const getIcon = () => {
    return (
      <img
        src={node?.node.datastore_node ? LlmIcon : node?.node.data?.image?.src}
        height={node?.node.data?.image?.height}
        width={node?.node.datastore_node ? 40 : node?.node.data?.image?.width}
        id={node?.node?.id}
        className={`node-image`}
        alt=""
        style={{
          display: "block",
          margin: node?.node.data?.image?.margin,
        }}
        onClick={(e) => {
          setSelected(e.target.id === node?.node?.id);
          node?.node.data.handleClick
            ? node?.node.data.handleClick(node?.node)
            : console.log("node?.node clicked");
        }}
      />
    );
  };

  const getNodeScoreColorClass = (node) => {
    return node?.is_primary ? `node-${getScoreColor(node?.score || 0)}` : "";
  };

  return (
    <div
      style={{
        height: 64,
        width: 140,
      }}
    >
      {node?.node?.iconName !== "" && getIcon(node?.node)}
      {/* )} */}
      <div
        style={{
          fontSize: 12,
          textAlign: "center",
          color: "#a2a2af", // "#6c83bb",
        }}
        title={node?.node?.name}
      >
        {node?.node?.name?.length > 30
          ? node.node.name.substring(0, 30) + "..."
          : node.node.name}
      </div>
    </div>
  );
};

export default TrainingDataCustomNode;
