import moment from "moment";
import React, { useEffect, useState } from "react";
import { Graph } from "react-d3-graph";
import closeIcon from "../../../../assets/img/icons/close-icon-grey.svg";
import { config } from "./TrainingDataGraphConfig";
import "../TrainingDataGraphView.scss";
import { getNodeIconByType } from "../../d3-flow-chart/flowChartHelper";
import GraphSelectedDataCard from "../training-data-orchestrator/GraphSelectedDataCard";
import Box from "../../../common/Box";
const TrainingDataGraphView = ({
  modelName,
  llmInfo,
  width,
  datastoreId,
  trainingData,
  trainingDataFilter,
  policyViolationsMaliciousActivity,
  policyViolationsSensitiveData,
}) => {
  const [graphData, setGraphData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  // const TrainingData = [
  //   {
  //     FileName: "training.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training.json",
  //     Source: "Configured",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "4.16 GB",
  //     type: "s3",
  //     training_date: "2023-08-01T04:32:36.491000",
  //   },
  //   {
  //     FileName: "training_2.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training_2.json",
  //     Source: "Configured",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "2.45 GB",
  //     type: "s3",
  //     training_date: "2023-05-15T04:32:36.491000",
  //   },
  //   {
  //     FileName: "training_3.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training_3.json",
  //     Source: "Configured",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "6.34 GB",
  //     type: "s3",
  //     training_date: "2023-06-10T04:32:36.491000",
  //   },
  //   {
  //     FileName: "training_4.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training_4.json",
  //     Source: "Live Access",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "5.3 GB",
  //     type: "s3",
  //     training_date: "2023-06-09T04:32:36.491000",
  //   },
  //   {
  //     FileName: "training_5.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training_5.json",
  //     Source: "Live Access",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "1.12 GB",
  //     type: "s3",
  //     training_date: "2023-07-04T04:32:36.491000",
  //   },
  //   {
  //     FileName: "training_6.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training_6.json",
  //     Source: "Access Path",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "2.16 GB",
  //     type: "s3",
  //     training_date: "2023-07-05T04:32:36.491000",
  //   },
  // ];

  const handleRefChange = (ref) => {
    if (ref) ref.resetNodesPositions();
  };

  const nodeClicked = (data) => {
    setSelectedNode(data);
  };

  var evenCounter = 0;
  var oddCounter = 0;

  const getNodeXValue = (width, node) => {
    if (node.datastore_node) {
      return width / 2.5;
    } else if (node.id % 2 == 0 && node.id !== 0) {
      evenCounter = evenCounter + 90;
      return width / 2.5 - 300;
    } else if (node.id % 2 !== 0) {
      oddCounter = oddCounter - 90;
      return width / 2.5 + 300;
    }
  };
  const getNodeYValue = (width, node) => {
    if (node.datastore_node) {
      return 170;
    } else if (node.id % 2 == 0) {
      if (evenCounter === 40) {
        return 60;
      } else {
        return 10 + evenCounter;
      }
    } else if (node.id % 2 !== 0) {
      if (oddCounter === -25) {
        return 170;
      } else {
        return 300 + oddCounter;
      }
    }
  };

  const isGCP = llmInfo && llmInfo["Source"];

  const getGraphData = (data) => {
    if (data) {
      data.nodes.map((node) => {
        node.height = "2rem";
        node.width = "2rem";
        node.x = getNodeXValue(width, node);
        node.y = getNodeYValue(width, node);
        node.data = {};
        node.data.image = {
          width: node.is_primary ? 50 : 40,
          height: node.is_primary ? 50 : 40,
          src: getNodeIconByType(
            isGCP === "Google" ? "googleSource" : node.type
          ),
          margin: node.is_primary ? "20px auto 0 auto" : "26px auto 0 auto",
        };
        node.data.handleClick = nodeClicked;
        node.selected = false;
      });
    }

    data.links = data.links
      .filter((x) => data.nodes.find((y) => y.id === x.source))
      ?.filter((x) => data.nodes.find((y) => y.id === x.target));

    return data;
  };

  const getNodeLinkColor = (node) => {
    switch (node.source) {
      case "Configured":
        return "#3d61ff";
      case "Access Path":
        return "#C68EFF";
      case "Live Access":
        return "#AC5AFF";
      default:
        return "#3d61ff";
    }
  };

  const getDataSources = () => {
    let startId = 1;
    const data = { nodes: [], links: [] };
    let datastoreNode = {
      id: startId++,
      datastore_node: true,
      is_primary: true,
      name: isGCP === "Google" ? modelName : llmInfo?.Name,
      source: "",
      heading: llmInfo?.Name,
      datastoreId: datastoreId,
      tooltip_content: {
        name: llmInfo?.Name,
        framework: llmInfo?.Framework,
        owner: llmInfo?.Owner,
        baseFamily: llmInfo?.["Base Family"],
      },
    };

    data.nodes.push(datastoreNode);

    for (let index = 0; index < trainingData.length; index++) {
      let node = {
        id: startId++,
        datastore_node: false,
        is_primary: false,
        name: trainingData[index].FileName,
        policy_violation: trainingData[index]?.policy_violation,
        datastoreId: datastoreId,
        source: trainingData[index].Source,
        type: "S3Bucket",
        tooltip_content: {
          type: trainingData[index].type,
          location: trainingData[index].Location,
          training_date: moment(
            moment.utc(trainingData[index]?.training_date).toDate()
          )
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
          source: trainingData[index].Source,
          sensitive_data: trainingData[index].sensitive_labels,
          size: trainingData[index].size,
        },
      };
      if (trainingDataFilter === node.source || trainingDataFilter === "all") {
        data.nodes.push(node);
      }

      let link = {
        id: new Date().toISOString(),
        source: node.id,
        target: datastoreNode.id,
        fontColor: getNodeLinkColor(node),
        fontSize: "12",
        strokeWidth: 3,
        color:
          policyViolationsMaliciousActivity &&
          node.policy_violation &&
          node.name === "training_2.json"
            ? "#FC4C02"
            : policyViolationsSensitiveData &&
              node.policy_violation &&
              (node.name === "training.json" || node.name === "training_3.json")
            ? "#FC4C02"
            : getNodeLinkColor(node),
        textAnchor: "middle",
        highlightColor: getNodeLinkColor(node),
      };
      if (trainingDataFilter === node.source || trainingDataFilter === "all") {
        data.links.push(link);
      }
    }

    return data;
  };
  // const data = {
  //   nodes: [
  //     {
  //       id: "#1",
  //       type: "customNode",
  //       name: "gpt2",
  //       data: {
  //         heading: "External",
  //         height: "2rem",
  //         handleClick: nodeClicked,
  //         width: "2rem",
  //         source: "",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: LlmIcon,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 30,
  //         tooltipRequired: false,
  //         datastore_node: true,
  //       },
  //       x: 700,
  //       y: 130,
  //     },
  //     {
  //       id: "#2",
  //       type: "customNode",
  //       name: "S3 Bucket",
  //       data: {
  //         heading: "Load balancer",
  //         height: "3rem",
  //         width: "3rem",
  //         source: "Configured",
  //         sensitive_data: [
  //           { title: "PII-SSN", count: "5.2k" },
  //           { title: "PCI-Credit Card", count: "8.2k" },
  //         ],
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: S3,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         tooltipRequired: true,
  //         datastore_node: false,
  //       },
  //       x: 300,
  //       y: 30,
  //     },
  //     {
  //       id: "#3",
  //       type: "customNode",
  //       name: "S3 Bucket",
  //       data: {
  //         heading: "EC2-Public",
  //         height: "4rem",
  //         sensitive_data: [
  //           { title: "PII-PHONE", count: "2.3k" },
  //           { title: "PCI-ADDRESS", count: "4.2k" },
  //         ],
  //         source: "LiveAccess",
  //         width: "4rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: S3,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         datastore_node: false,
  //         tooltipRequired: true,
  //       },
  //       x: 300,
  //       y: 150,
  //     },
  //     {
  //       id: "#4",
  //       type: "customNode",
  //       name: "S3 Bucket",
  //       data: {
  //         heading: "EC2-Public",
  //         height: "4rem",
  //         sensitive_data: [
  //           { title: "PII-PHONE", count: "2.3k" },
  //           { title: "PCI-ADDRESS", count: "4.2k" },
  //         ],
  //         source: "AccessPath",
  //         width: "4rem",
  //         image: {
  //           width: 50,
  //           height: 50,
  //           src: S3,
  //           margin: "20px auto 0 auto",
  //         },
  //         value: 40,
  //         datastore_node: false,
  //         tooltipRequired: true,
  //       },
  //       x: 300,
  //       y: 250,
  //     },
  //   ],
  //   links: [
  //     {
  //       id: "e#1#2",
  //       type: "floating",
  //       target: "#2",
  //       source: "#1",
  //       filter: "Configured",
  //     },
  //     {
  //       id: "e#2#3",
  //       target: "#3",
  //       source: "#1",
  //       filter: "LiveAccess",
  //     },
  //     {
  //       id: "e#3#4",
  //       target: "#4",
  //       source: "#1",
  //       filter: "AccessPath",
  //     },
  //   ],
  // };

  const formatTitle = (title) => {
    let formatedTitle = title.replace("_", " ");
    const arr = formatedTitle.split(" ");
    for (var index = 0; index < arr.length; index++) {
      arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1);
    }
    return arr.join(" ");
  };
  const getRightPanel = () => {
    if (selectedNode && selectedNode !== "") {
      return (
        <GraphSelectedDataCard
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
        />
      );
    }
  };

  useEffect(() => {
    getRightPanel();
  }, [selectedNode]);

  useEffect(() => {
    setSelectedNode(null);
    if (trainingData) {
      let sources = getDataSources();
      const graphDataset = getGraphData(sources);
      setGraphData({ nodes: graphDataset.nodes, links: graphDataset.links });
    } else {
      setGraphData({ nodes: [], links: [] });
    }
  }, [
    width,
    llmInfo,
    policyViolationsMaliciousActivity,
    policyViolationsSensitiveData,
    trainingData,
    trainingDataFilter,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      {graphData && (
        <Graph
          id="access-graph-id"
          data={graphData}
          config={config}
          ref={handleRefChange}
          width="200px"
        />
      )}

      {getRightPanel()}
    </Box>
  );
};

export default TrainingDataGraphView;
