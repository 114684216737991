import "./Pagination.scss";

const Pagination = ({ page, setPage, limit, totalCount }) => {
  const previousPage = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const hasMore = (offset, count) => {
    if (offset >= count) {
      return false;
    } else {
      return true;
    }
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  return (
    <>
      <div className="pagination">
        <span
          className={page == 1 ? "pagination-btn-disable" : "pagination-btn"}
          onClick={page === 1 ? "" : () => setPage(1)}
        >
          <i class="ri-arrow-left-s-line pagination-icon-first"></i>

          <i class="ri-arrow-left-s-line pagination-icon"></i>
        </span>

        <span
          className={page == 1 ? "pagination-btn-disable" : "pagination-btn"}
          onClick={previousPage}
        >
          <i class="ri-arrow-left-s-line pagination-icon"></i>
        </span>
        <span>
          {`${totalCount ? page * limit - limit + 1 : 0} - ${
            hasMore(page * limit, totalCount) ? page * limit : totalCount
          }`}{" "}
          of {totalCount}
        </span>
        <span
          className={
            hasMore(page * limit, totalCount)
              ? "pagination-btn"
              : "pagination-btn-disable"
          }
          onClick={hasMore(page * limit, totalCount) ? nextPage : ""}
        >
          <i class="ri-arrow-right-s-line pagination-icon"></i>
        </span>

        <span
          className={
            page === Math.ceil(totalCount / limit)
              ? "pagination-btn-disable"
              : "pagination-btn"
          }
          onClick={
            page === Math.ceil(totalCount / limit)
              ? ""
              : () => setPage(Math.ceil(totalCount / limit))
          }
        >
          <i class="ri-arrow-right-s-line pagination-icon"></i>
          <i class="ri-arrow-right-s-line pagination-icon-last"></i>
        </span>
      </div>
    </>
  );
};

export default Pagination;
