import React from "react";
import TopPanelComponent from "./TopPanelComponent";

const TopPanelContainer = (props) => {
  return (
    <>
      <TopPanelComponent props={props} />
    </>
  );
};

export default TopPanelContainer;
