import { axiosDenaliPrivate, axiosPrivate } from "../../utils/ApiHandler";
import { getUserId } from "../../utils/SessionHelper";

export const fetchTickets = () => {
  return axiosDenaliPrivate.get(`/api/v1/jira/get/issues`);
};

export const getTicket = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/jira/get`, params);
};
export const getBulkTicket = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/jira/bulk/get`, params);
};

export const getDatastoreTicket = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/jira/datastore/get`, params);
};

/**
 * Add the accounts with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const addTicket = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/jira/create`, params);
};

export const deleteTicket = (params) => {
  return axiosDenaliPrivate.post(`api/v1/integrations/jira/delete`, params);
};
