import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/appContext';
import { TicketStatusProps } from '../../types/type';
import { isContainsValue } from '../../utils/commonUtils';
import Box from '../common/Box';
import Loader from '../common/Loader';
import TextButton from '../common/TextButton';
import Typography from '../common/Typography';

const TicketStatusCard = () => {
  const {
    ticketStatusData,
    isFetchingTicketStatusData,
    getTicketStatusData,
  } = useContext(AppContext);
  const [ticketData, setTicketData] = useState<TicketStatusProps>({
    open: 0,
    resolved: 0,
    inProgress: 0,
  });

  useEffect(() => {
    getTicketStatusData();
  }, []);

  useEffect(() => {
    if (isContainsValue(ticketStatusData)) {
      setTicketData({
        open: ticketStatusData?.openTickets,
        resolved: ticketStatusData?.resolved,
        inProgress: ticketStatusData?.inProgress,
      });
    }
  }, [ticketStatusData]);

  const theme = useTheme();
  const styles = {
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      gap: theme.spacing(1),
    },
    ticketCardContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    moreDetailsButton: {
      display: 'flex',
      marginLeft: 'auto',
    },
    openTickets: {
      display: 'flex',
      color: theme.palette.surface70.main,
    },
    subSectionContainer: {
      width: '33%',
      display: 'flex',
      flexDirection: 'column',
    },
    sectionValueText: {
      fontFamily: 'Manrope',
      fontSize: '28px',
    },
    cardTitle: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: '13px',
    },
  };
  return (
    <Box sx={styles.cardContainer}>
      {isFetchingTicketStatusData ? (
        <Loader height="75px" />
      ) : (
        <>
          <Box sx={styles.ticketCardContainer}>
            <Typography
              color={theme.palette.surface80.main}
              sx={styles.cardTitle}
            >
              Ticket Status
            </Typography>
            {/*<TextButton
              hideText={true}
              endIcon={<KeyboardArrowRightIcon />}
              sx={styles.moreDetailsButton}
            >
              Details
            </TextButton>*/}
          </Box>
          <Box sx={styles.openTickets}>
            <Box sx={styles.subSectionContainer}>
              <Typography
                color={theme.palette.surface70.main}
                sx={styles.sectionValueText}
              >
                {ticketData?.open}
              </Typography>
              <Typography
                color={theme.palette.surface70.main}
                variant="caption"
              >
                Open
              </Typography>
            </Box>
            <Box sx={styles.subSectionContainer}>
              <Typography
                color={theme.palette.surface70.main}
                sx={styles.sectionValueText}
              >
                {ticketData?.inProgress}
              </Typography>
              <Typography
                color={theme.palette.surface70.main}
                variant="caption"
              >
                In progress
              </Typography>
            </Box>
            <Box sx={styles.subSectionContainer}>
              <Typography
                color={theme.palette.surface70.main}
                sx={styles.sectionValueText}
              >
                {ticketData?.resolved}
              </Typography>
              <Typography
                color={theme.palette.surface70.main}
                variant="caption"
              >
                Resolved
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TicketStatusCard;
