import React, { useState, useEffect } from "react";
import "./account.scss";
import "../../modal/accounts-modal/AddAccountModal.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { launchStackAsync } from "../../../redux/slices/accountSlice";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";

const AccountDetailStep = ({
  accountDetails,
  setAccountDetails,
  externalId,
  backStep,
  currentStep,
  addAccount,
  regionList,
  setShowLoader,
  showLoader,
}) => {
  const dispatch = useDispatch();

  const DATA_SCANNING_FREQUENCY = [
    { label: "Daily", value: "rate(1 day)" },
    { label: "Weekly", value: "rate(7 days)" },
    { label: "Monthly", value: "rate(30 days)" },
  ];

  const [enableBtn, setEnableBtn] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("us-east-1");
  const [scanningFrequency, setScanningFrequency] = useState(
    DATA_SCANNING_FREQUENCY[2].value
  );

  // Formik

  const formik = useFormik({
    initialValues: {
      accountId: accountDetails.accountId,
      accountDescription: accountDetails.accountDescription,
      externalId: externalId,
      awsRoleARN: accountDetails.awsRoleARN,
      dataScanFrequency: scanningFrequency,
    },

    validationSchema: Yup.object().shape({
      accountId: Yup.string()
        .max(12, "Account Id should be 12 digit")
        .min(12, "Account Id should be 12 digit")
        .matches("^[0-9]", "Account Id should be numeric.")
        .required("Please enter valid Account Id"),
      externalId: Yup.string().required("Please enter External Id."),
      // awsRoleARN: Yup.string().required("Please enter Role Arn."),
    }),
    onSubmit: (values) => {
      setAccountDetails({ ...values });
      addAccount(values);
    },
  });

  const launchStackHandler = async () => {
    setShowLoader(true);
    const resp = await dispatch(
      launchStackAsync({
        id: formik.values.accountId,
        region: selectedRegion,
        dataScanFrequency: scanningFrequency,
      })
    );
    if (resp && resp.payload?.data) {
      window.open(resp.payload?.data);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        `Failed to launch stack for account ${formik.values.accountId}. Please check the Account Id.`
      );
    }
    setShowLoader(false);
  };

  useEffect(() => {
    setEnableBtn(
      accountDetails.accountId &&
        accountDetails.externalId &&
        accountDetails.awsRoleARN
    );
  }, []);

  useEffect(() => {
    formik.setFieldValue("externalId", externalId);
  }, [externalId]);

  return (
    <div id="add-account">
      {showLoader && (
        <img
          src="static/img/loader-circle.svg"
          className="account-loading-img"
        ></img>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body p-0">
          <div className="row">
            <div className="col font-weight-bold form-field-title mb-3">
              Enter details
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <b className="form-field-name ">Account Id</b>
              <span className="mandatory-icon">*</span>
            </div>
            <div className="col-12">
              <input
                type="text"
                id="accountId"
                placeholder="12-Digit AWS Account Id"
                name="accountId"
                maxLength={12}
                minLength={12}
                className="modal-input-style px-2"
                onChange={(e) => {
                  if (e.target.value.length === 13) {
                    return;
                  }
                  formik.setFieldValue("accountId", e.target.value?.trim());
                  setEnableBtn(true);
                }}
                value={formik.values.accountId}
                onBlur={formik.handleBlur}
              />
              {formik.errors.accountId && formik.touched.accountId && (
                <div className="error-message">{formik.errors.accountId}</div>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <b className="form-field-name ">Account Description</b>
            </div>
            <div className="col-12">
              <textarea
                className="modal-textarea py-2 px-2"
                placeholder="Account Description"
                name="accountDescription"
                id="accountDescription"
                onChange={(e) => {
                  formik.setFieldValue("accountDescription", e.target.value);
                }}
                value={formik.values.accountDescription}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <b className="form-field-name ">External ID</b>
              <span className="mandatory-icon">*</span>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 mb-1">
                  <input
                    type="text"
                    id="externalId"
                    placeholder="External Id"
                    name="externalId"
                    className="modal-input-style-2 px-2"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "externalId",
                        e.target.value?.trim()
                      );
                      setEnableBtn(true);
                    }}
                    value={formik.values.externalId}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.externalId && formik.touched.externalId && (
                    <div className="error-message">
                      {formik.errors.externalId}
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <span className="modal-subtext">
                    The External ID has been auto generated for you. You can
                    change the ID to a custom ID.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2  mb-4">
            <div className="col-12">
              <b className="form-field-name ">Region</b>
              <span className="mandatory-icon">*</span>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col col-lg-12">
                  <select
                    className="form-control"
                    value={selectedRegion}
                    onChange={(event) => {
                      setSelectedRegion(event.target.value);
                    }}
                  >
                    <option disabled>Select+ Region</option>
                    {regionList.map((region, index) => (
                      <option key={index} value={region.value}>
                        {region.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2  mb-4">
            <div className="col-12">
              <b className="form-field-name ">Data Scanning Frequency</b>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col col-lg-12">
                  <select
                    className="form-control"
                    value={scanningFrequency}
                    onChange={(event) => {
                      setScanningFrequency(event.target.value);
                      formik.setFieldValue(
                        "dataScanFrequency",
                        event.target.value
                      );
                    }}
                  >
                    <option disabled>Select Data Scanning Frequency</option>
                    {DATA_SCANNING_FREQUENCY.map((frequency, index) => (
                      <option key={index} value={frequency.value}>
                        {frequency.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* <hr></hr> */}
          {/* <div className="row ">
            <div className="col col-lg-3 d-flex justify-content-start align-items-center">
              <b className="form-field-name ">Scan Frequency </b>
            </div>
            <div className="col col-lg-9 p-0 d-flex justify-content-start align-items-center">
              <div className="row">
                <div className="col col-lg-3 p-0 d-flex justify-content-start align-items-center">
                  <span className="mr-4 radio-button-title">Default</span>{" "}
                  <input
                    type="radio"
                    value={FREQUENCY.DEFAULT}
                    name="frequency"
                    className="m-1 mr-3 default-radio-button"
                    onChange={(e) =>
                      formik.setFieldValue("scanFrequency", FREQUENCY.DEFAULT)
                    }
                    checked={formik.values.scanFrequency === FREQUENCY.DEFAULT}
                  />
                  <span className="radio-button-text">6hr</span>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="row mt-3">
            <div className="col col-lg-3 d-flex justify-content-start align-items-center"></div>
            <div className="col col-lg-9 p-0">
              <div className="row">
                <div className="col col-lg-12 p-0 d-flex justify-content-start align-items-start">
                  <span className="mr-4 radio-button-title">Custom</span>{" "}
                  <span className="mr-4 mt-n1">
                    <input
                      type="radio"
                      value={FREQUENCY.CUSTOM.FRQ_12_HR}
                      name="frequency"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "scanFrequency",
                          FREQUENCY.CUSTOM.FRQ_12_HR
                        )
                      }
                      checked={
                        formik.values.scanFrequency ===
                        FREQUENCY.CUSTOM.FRQ_12_HR
                      }
                      className="mr-3  custom-radio-button"
                    />{" "}
                    <br></br>
                    <span className="radio-button-text ml-n2"> 12hr</span>
                  </span>
                  <span className="mr-4 mt-n1">
                    <input
                      type="radio"
                      value={FREQUENCY.CUSTOM.FRQ_24_HR}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "scanFrequency",
                          FREQUENCY.CUSTOM.FRQ_24_HR
                        )
                      }
                      checked={
                        formik.values.scanFrequency ===
                        FREQUENCY.CUSTOM.FRQ_24_HR
                      }
                      name="frequency"
                      className="mr-3 ml-2 custom-radio-button"
                    />{" "}
                    <br></br>
                    <span className="radio-button-text"> 24hr</span>
                  </span>
                  <span className="mr-4 mt-n1">
                    <input
                      type="radio"
                      value={FREQUENCY.CUSTOM.FRQ_2_DAYS}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "scanFrequency",
                          FREQUENCY.CUSTOM.FRQ_2_DAYS
                        )
                      }
                      checked={
                        formik.values.scanFrequency ===
                        FREQUENCY.CUSTOM.FRQ_2_DAYS
                      }
                      name="frequency"
                      className="mr-3 ml-2 custom-radio-button"
                    />{" "}
                    <br></br>
                    <span className="radio-button-text"> 2days</span>
                  </span>
                  <span className="mr-4 mt-n1">
                    <input
                      type="radio"
                      value={FREQUENCY.CUSTOM.FRQ_5_DAYS}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "scanFrequency",
                          FREQUENCY.CUSTOM.FRQ_5_DAYS
                        )
                      }
                      checked={
                        formik.values.scanFrequency ===
                        FREQUENCY.CUSTOM.FRQ_5_DAYS
                      }
                      name="frequency"
                      className="mr-3 ml-2 last-custom-radio-button"
                    />{" "}
                    <br></br>
                    <span className="ml-n1 radio-button-text"> 5days</span>
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col mt-2">
            <div className="float-left">
              <button
                type="button"
                onClick={() => backStep(false)}
                className="btn btn-primary"
              >
                Back: Select Account
              </button>
            </div>
            <div className="float-right">
              <button
                type="submit"
                className={
                  formik.isValid & enableBtn
                    ? "btn btn-primary mr-2"
                    : '"btn btn-secondary mr-2"'
                }
                disabled={!formik.isValid || !enableBtn}
              >
                Add Account
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountDetailStep;
