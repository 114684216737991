import { useTheme } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { BarData, TabData } from "../../types/type";
import Box from "../common/Box";
import Loader from "../common/Loader";
import { DASHBOARD_DATA_VIEW } from "../constants/constants";
import BarChartCard from "./BarChartCard";

const PolicyBarChart = () => {
  const theme = useTheme();
  const {
    dashboardViewLense,
    policyCategories,
    getViolatedPolicyData,
    isFetchingPolicyData,
    isFetchingPolicyCategories,
    policyData,
    allPolicies,
    isFetchingAllPolicies,
    getAllPolicies,
    getViolatedPolicyCategory,
    getPolicyDetails,
    selectedPolicy,
    setSelectedPolicy,
    setOpenPolicyStoreDetailDrawer,
    setDrawerData,
  } = useContext(AppContext);
  const [extractedPolicyData, setExtractedPolicyData] = useState<{
    activeTabNumber: string;
    tabData: Array<TabData>;
  }>({
    activeTabNumber: "policy-category",
    tabData: [],
  });

  const listOfTabs = [
    {
      value: "policy-category",
      label: "Policy Category",
    },
    {
      value: "policy",
      label: "Policy",
    },
  ];

  const dataView = dashboardViewLense === DASHBOARD_DATA_VIEW;

  useEffect(() => {
    getViolatedPolicyCategory(!dataView ? { llm: true } : {});
    getAllPolicies(dataView ? {} : { llm: true });
    getViolatedPolicyData(dataView ? {} : { llm: true });
  }, [dashboardViewLense]);

  const setOpenDetailDrawer = useCallback(
    (
      policy: {
        name: string;
        id: number;
        violations: number;
        policyType: string;
      },
      activeTab: string
    ) => {
      setDrawerData({
        tabs: listOfTabs,
        currentSelectedPolicy: policy,
        tabData: extractedPolicyData?.tabData,
        activeTab: activeTab,
      });
      setOpenPolicyStoreDetailDrawer(true);
    },
    [extractedPolicyData, extractedPolicyData?.tabData?.length]
  );

  const openDrawerAndSetPolicy = useCallback(
    (
      policy: {
        name: string;
        id: number;
        violations: number;
        policyType: string;
      },
      activeTab: string
    ) => {
      setDrawerData({
        tabs: listOfTabs,
        currentSelectedPolicy: policy,
        tabData: extractedPolicyData?.tabData,
        activeTab: activeTab,
      });
      setOpenPolicyStoreDetailDrawer(true);
      setSelectedPolicy(
        policyData.find((policyItem) => policyItem?.id === policy?.id)
      );
    },
    [extractedPolicyData, extractedPolicyData?.tabData?.length]
  );

  const getCategoryCount = () => {
    let dataWithCount: Array<{
      name: string;
      type: string;
      dsCount: number;
    }> = [];
    policyCategories?.map((item: any) => {
      let count: number = 0;
      allPolicies?.map((val: any) => {
        if (item?.type === val?.policyType) count = count + val?.dsCount;
      });
      dataWithCount.push({ ...item, dsCount: count });
    });
    return dataWithCount;
  };

  useEffect(() => {
    if (policyData?.length > 0 && policyCategories?.length > 0) {
      const categoryDataWithDsCount = getCategoryCount();
      const policyCategoryTotalValue = Math.max(
        ...categoryDataWithDsCount
          ?.sort((a, b) => b.dsCount - a.dsCount)
          ?.map((item: { dsCount: number }) => item.dsCount)
      );
      const policyTotalValue = Math.max(
        ...policyData.map((item: { dsCount: number }) => item.dsCount)
      );
      const topSevenPolicyData = policyData
        ?.sort((a, b) => b?.dsCount - a?.dsCount)
        ?.slice(0, 7);
      const barCardsData: {
        activeTabNumber: string;
        tabData: Array<TabData>;
      } = {
        activeTabNumber: "policy-category",
        tabData: [
          {
            tabIndex: "policy-category",
            chartTitle: `${policyCategories?.length} Most Violated Policy Categories`,
            xAxisLabel: "# of Violations",
            barData: categoryDataWithDsCount?.map(
              (
                policy: { name: string; type: string; dsCount: number },
                index: number
              ) => ({
                barLabel: policy?.name,
                barValue: policy?.dsCount,
                totalValue: policyCategoryTotalValue,
                barFaceColor: theme.palette.pictonBlue.main,
                barBackColor: theme.palette.surface20.main,
                id: index,
                policyType: policy?.type,
              })
            ),
          },
          {
            tabIndex: "policy",
            chartTitle: `${topSevenPolicyData?.length} Most Violated Policies`,
            xAxisLabel: "# of Violations",
            barData: topSevenPolicyData?.map(
              (policy: {
                title: string;
                description: string;
                dsCount: number;
                id: number;
              }) => ({
                barLabel: policy?.title?.replace(".", ""),
                barValue: policy?.dsCount,
                totalValue: policyTotalValue,
                barFaceColor: theme.palette.pictonBlue.main,
                barBackColor: theme.palette.surface20.main,
                id: policy.id,
              })
            ),
          },
        ],
      };
      setExtractedPolicyData(barCardsData);
    }
  }, [policyData, policyCategories, allPolicies]);

  useEffect(() => {
    if (
      extractedPolicyData?.tabData?.length > 0 &&
      extractedPolicyData?.tabData[0]?.barData?.length > 0 &&
      !extractedPolicyData?.tabData[0]?.barData[0]?.onClick
    ) {
      setExtractedPolicyData((old: any) => {
        old.tabData?.forEach((tab: TabData, index: string) => {
          tab?.barData?.forEach((d: BarData, i: number) => {
            d.onClick = () => {
              tab?.tabIndex === "policy-category"
                ? setOpenDetailDrawer(
                    {
                      name: d.barLabel,
                      id: i,
                      violations: d.barValue,
                      policyType: d?.policyType || "",
                    },
                    tab?.tabIndex
                  )
                : openDrawerAndSetPolicy(
                    {
                      name: d.barLabel,
                      id: d?.id,
                      violations: d.barValue,
                      policyType: d?.policyType || "",
                    },
                    tab?.tabIndex
                  );
            };
          });
        });
        return { ...old };
      });
    }
  }, [extractedPolicyData]);

  const styles = {
    barCard: {
      width: "50%",
      paddingY: theme.spacing(1),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
  };

  return (
    <Box sx={styles.barCard}>
      {isFetchingPolicyData ||
      isFetchingPolicyCategories ||
      isFetchingAllPolicies ? (
        <Loader height="375px" />
      ) : (
        <BarChartCard
          listOfTabs={listOfTabs}
          activeTabNumber={extractedPolicyData?.activeTabNumber}
          tabData={extractedPolicyData?.tabData}
          openDrawer={true}
          buttonText="Details"
          showButton={true}
        />
      )}
    </Box>
  );
};

export default PolicyBarChart;
