import "./DaySelector.scss";
import { useState, useEffect } from "react";

const DaySelector = ({ selectedDays, setSelectedDaysValue }) => {
  const toggleDays = (day) => {
    if (selectedDays.includes(day)) {
      var selectedDaysList = selectedDays.filter((val) => val !== day);
      setSelectedDaysValue(selectedDaysList);
    } else {
      setSelectedDaysValue([...selectedDays, day]);
    }
  };

  return (
    <>
      <div className="weeks">
        <div
          className={
            selectedDays.includes("MON")
              ? "day week-active cursor-pointer week"
              : "day cursor-pointer week"
          }
          onClick={() => {
            toggleDays("MON");
          }}
        >
          MON
        </div>
        <div
          className={
            selectedDays.includes("TUE")
              ? "day week-active cursor-pointer week"
              : "day cursor-pointer week"
          }
          onClick={() => {
            toggleDays("TUE");
          }}
        >
          TUE
        </div>
        <div
          className={
            selectedDays.includes("WED")
              ? "day week-active cursor-pointer week"
              : "day cursor-pointer week"
          }
          onClick={() => {
            toggleDays("WED");
          }}
        >
          WED
        </div>
        <div
          className={
            selectedDays.includes("THU")
              ? "day week-active cursor-pointer week"
              : "day cursor-pointer week"
          }
          onClick={() => {
            toggleDays("THU");
          }}
        >
          THU
        </div>
        <div
          className={
            selectedDays.includes("FRI")
              ? "day week-active cursor-pointer week"
              : "day cursor-pointer week"
          }
          onClick={() => {
            toggleDays("FRI");
          }}
        >
          FRI
        </div>
        <div
          className={
            selectedDays.includes("SAT")
              ? "day week-active cursor-pointer week"
              : "day cursor-pointer week"
          }
          onClick={() => {
            toggleDays("SAT");
          }}
        >
          SAT
        </div>
        <div
          className={
            selectedDays.includes("SUN")
              ? "day week-active cursor-pointer week"
              : "day cursor-pointer week"
          }
          onClick={() => {
            toggleDays("SUN");
          }}
        >
          SUN
        </div>
      </div>
    </>
  );
};

export default DaySelector;
