import React, { useEffect, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { issueDetailsApi } from "../../apis";
import {
  hideLoading,
  showLoading,
} from "../../redux/slices/globalOperationSlice";
import { axiosDenaliPrivate } from "../../utils/ApiHandler";
import {
  LOADER_CONFIG,
  REACT_APP_ISSUES_DETAILS_PAGE,
} from "../../utils/Constants";
import { getCustomerId } from "../../utils/SessionHelper";
import IssuesPathGraph from "../charts/d3-flow-chart/IssuesPathGraph";
import BottomPanelContainer from "./BottomPanel/BottomPanelContainer";
import TopPanelContainer from "./TopPanel/TopPanelContainer";
import "./VulnerabilitiesDetailsComponent.scss";
import ImpactedDataStoreModal from "../modal/impacted-datastore-modal/ImpactedDataStoreModal";
import AffectedDatastoresAndIssuesModal from "../modal/affected-datastore-and-issue-modal/AffectedDatastoresAndIssuesModal";

const DetailsComponent = () => {
  const [toggle, setToggle] = useState(true);
  const [rowIndex, setRowIndex] = useState({});
  const [selectedBar, setSelectedBar] = useState({});
  const [vulnerability, setVulnerability] = useState({});
  const params = useParams();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.globalOperation.loading);

  const issueId = params.id?.replaceAll("_", "/");
  const [attackPaths, setAttackPaths] = useState([]);
  const [assetId, setAssetId] = useState(params.assetId?.replaceAll("_", "/"));
  const ref = useRef();
  const [width, setWidth] = useState(0);
  const [isImpactedDataStoreModal, setIsImpactedDataStoreModal] = useState();
  const [apiFetched, setApiFetched] = useState(false);

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getWidth);
  }, []);

  const getWidth = () => {
    setWidth(ref?.current?.clientWidth);
  };

  const getSecurityIssue = async (url = null) => {
    if (REACT_APP_ISSUES_DETAILS_PAGE === "COMBINED") {
      url = "/api/v1/issues/detail";
    }

    const payload = {
      id: issueId,
      assetId: assetId,
      customerId: getCustomerId(),
      securityIssueInstanceId: params.securityIssueInstanceId,
    };
    let res = await issueDetailsApi(payload, url);
    if (!res) return;
    setSelectedBar(res);
    if (REACT_APP_ISSUES_DETAILS_PAGE === "COMBINED") {
      const obj = {
        vulnerabilityID: res.vulnerabilityID,
        label: res.label,
        cvssScore: res.cvssScore,
        externalUrl: res.externalUrl,
        description: res.description,
        remediation: res.remediation,
        additionalInfo: res.additionalInfo,
      };
      setVulnerability(obj);
    }
  };

  const getAttackPath = async () => {
    try {
      const url = `/getattackpath`;
      dispatch(showLoading());
      let res = await axiosDenaliPrivate.post(url, {
        customerId: getCustomerId(),
        assetId: assetId,
      });
      res = res.data;
      setAttackPaths(res ? (Object.keys(res).length === 0 ? [] : res) : []);
    } catch (e) {
      setAttackPaths([]);
      dispatch(hideLoading());
    } finally {
      dispatch(hideLoading());
    }
  };

  const getVulnerabilityDescription = async () => {
    const url = `/api/v1/vulnerabilitydescription`;
    let res = await axiosDenaliPrivate.post(url, {
      customerId: getCustomerId(),
      assetId: assetId,
      id: issueId,
      securityIssueInstanceId: params.securityIssueInstanceId,
    });
    res = res.data;
    if (!res) return;
    setVulnerability(res);
  };

  useEffect(() => {
    if (assetId && params && params.securityIssueInstanceId) {
      if (apiFetched) {
        return;
      }
      if (REACT_APP_ISSUES_DETAILS_PAGE === "COMBINED") {
        getSecurityIssue();
        setApiFetched(true);
      } else {
        getSecurityIssue();
        getVulnerabilityDescription();
        setApiFetched(true);
      }

      getAttackPath();
    }
  }, [assetId, params]);

  return (
    <BlockUi
      tag="div"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      <div id="details-component-main-container" className="container-fluid">
        <div className="pl-3 pr-3">
          <div className="row ">
            <div className="col col-sm-11 page-heading mb-2">
              {"ISSUES > "}
              <span>{`${selectedBar?.idDisplay || ""}  `}</span>
            </div>
            <div class="col col-sm-1 top-bar-toggle-button">
              {/* <div class="float-right">
                <div onClick={() => setToggle(!toggle)}>
                  <img src={arrowDown} />
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col col-sm-12">
              <TopPanelContainer
                assetId={assetId}
                toggle={toggle}
                selectedBar={selectedBar}
                vulnerability={vulnerability}
                refreshIssues={getSecurityIssue}
              ></TopPanelContainer>
            </div>
          </div>
          <div class="row pb-2 mt-2">
            <div class="col align-self-start">
              <div className="row my-2">
                <div className="col-lg-auto d-flex">
                  {/* <b className="p-2"> </b> */}
                  <button className="btn-primary btn-primary-curved btn-sm">
                    ATTACK PATHS - {attackPaths.length}
                  </button>
                </div>
                <div
                  className="col-lg-auto d-flex"
                  onClick={() => {
                    selectedBar.impactedDatastores > 0 &&
                      setIsImpactedDataStoreModal(true);
                  }}
                >
                  {/* <span
                    // className={
                    //   selectedBar.impactedDatastores > 0
                    //     ? "card-tabs cursor-pointer px-2"
                    //     : "card-tabs-disabled px-2"
                    // }
                  >
                    <b>IMPACTED DATASTORES </b>
                  </span> */}

                  <button
                    disabled={selectedBar.impactedDatastores > 0 ? false : true}
                    className="btn-primary btn-primary-curved btn-sm"
                  >
                    IMPACTED DATASTORES - {selectedBar?.impactedDatastores || 0}
                  </button>
                </div>
                {isImpactedDataStoreModal ? (
                  <AffectedDatastoresAndIssuesModal
                    handleModal={() => setIsImpactedDataStoreModal(false)}
                    isRiskAccepted={false}
                    assetIdValue={assetId}
                    isImpactedDatastoreVisible={true}
                  />
                ) : (
                  ""
                )}
                {/* <div className="col-lg-auto p-1 mr-2">
                <span className="text-info">
                  <b>DIRECT DATASTORE IMPACT - {selectedBar.impacts ? selectedBar.impacts.length : ""} </b>
                </span>{" "}
              </div>
              <div className="col-lg-auto p-1 mr-2">
                <span className="text-info">
                  <b>INDIRECT DATASTORE IMPACT - 17</b>
                </span>{" "}
              </div> */}
              </div>
            </div>
            <div class="col align-self-end">
              {" "}
              {/* Hiding this as it is not in work at the moment */}
              {/* <div class="float-right">
                <div className="col-lg-auto p-1">
                  {" "}
                  <div class="dropdown">
                    <button
                      class="btn btn-light dropdown-toggle table-dropdown btn-custom"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="dropdown-title">EXACT PATH</span>
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 left-panel">
              <BottomPanelContainer
                RefreshChart={(idx, name) =>
                  setRowIndex({ index: idx, name: name })
                }
                attackPaths={attackPaths}
              />
            </div>
            <div class="col-lg-7 " ref={ref}>
              <div className="graph">
                <p className="pl-3 pt-2">
                  {rowIndex.name
                    ? rowIndex.name.replace("BR-AP-", "")
                    : rowIndex.name}
                </p>

                {/* <NetworkChart rowIndex={rowIndex} /> */}
                <IssuesPathGraph
                  width={width}
                  rowIndex={rowIndex}
                  attackPaths={attackPaths}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockUi>
  );
};

export default DetailsComponent;
