import NoDataImg from "../../../assets/img/no-data.svg";
import "./style.scss";

const NoDataContainer = ({ message }) => {
  return (
    <div className="nodata-container">
      <img src={NoDataImg} />
      <h4>{message}</h4>
    </div>
  );
};

export default NoDataContainer;
