export const dataStoreTypes = [
  {
    label: "All",
    filter: "",
    value: "all",
  },
  {
    label: "Managed",
    filter: "managed",
    value: "managed",
  },
  {
    label: "AI",
    filter: "shadow",
    value: "ai",
  },
  {
    label: "Shadow",
    filter: "self-managed",
    value: "shadow",
  },
];

export const datastoreFilter = [
  {
    filter: "",
    value: "all",
  },
  {
    filter: "MANAGED",
    value: "managed",
  },
  {
    filter: "SHADOW_AI",
    value: "ai",
  },
  {
    filter: "SHADOW",
    value: "shadow",
  },
];

export const aiDataStoreTabs = [
  {
    label: "All LLMs",
    filter: "",
    value: "all",
  },
  {
    label: "Deployed",
    filter: "llm-deployed",
    value: "deployed",
  },
  {
    label: "Under Finetuning",
    filter: "llm-under-fine-tuning",
    value: "underFinetuning",
  },
];

export const non_SOC = [
  "data-residency",
  "encryption",
  "logging",
  "public-access",
];

export const SOCStatusFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Notified",
    value: "notified",
  },
  {
    label: "Archived",
    value: "archived",
  },
];

export const NonSOCStatusFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Ticketed",
    value: "ticketed",
  },
  {
    label: "Resolved",
    value: "resolved",
  },
];

export const sensitiveInfoTags = {
  social_security_numbers: "SSN",
  credit_cards: "CC",
  drivers_licenses: "DL",
  mobile_numbers: "MN",
};

export const socActionType = "notify/archive";
export const nonSocActionType = "ticket/accept";

export const DASHBOARD_DATA_VIEW = "Data";
export const DASHBOARD_AI_VIEW = "AI";

export const langchainData = [
  {
    name: "Langchain 1",
    version: "V0.0284",
  },
];

export const sensitiveFilters = [
  { label: "Last 7 days", value: "7days" },
  { label: "Last 1 day", value: "1day" },
  { label: "Last 1 hour", value: "1hour" },
];
