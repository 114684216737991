export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const formatHeader = (string) => {
  if (!string) {
    return string;
  }
  var formatedString = string.replace(/[^a-zA-Z ]/g, " ");
  return formatedString
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
