import "./Card.scss";
const Card = ({ title, count, subCount }) => {
  return (
    <div className="d-flex justify-content-between align-items-center dashboard-card">
      <div className="dashboard-card-title">{title}</div>
      <div className="d-flex">
        <div className="mr-2 dashboard-card-count d-flex align-items-center justify-content-between">
          {count}
        </div>
        {/* {subCount && (
          <div className="dashboard-card-sub-count d-flex align-items-center justify-content-between">
            <span className="dashboard-card-count-chip">{subCount}</span>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Card;
