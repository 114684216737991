import React, { useState, useEffect } from "react";
import deleteIcon from "../../../assets/img/icons/ico-delete-grey.svg";
import "./../modal.scss";
import "./AddPolicyModal.scss";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import loaderImage from "../../../assets/img/loader.svg";
import Button from "../../common/Button";
import {
  addPolicyAsync,
  updatePolicyAsync,
} from "../../../redux/slices/policySlice";
import { getUserId, getCustomerName } from "../../../utils/SessionHelper";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import {
  LOADER_CONFIG,
  REACT_APP_DATASTORE_API,
} from "../../../utils/Constants";
// import { listAccountsAsync } from "../../../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoading,
  showLoading,
} from "../../../redux/slices/globalOperationSlice";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules

const AddPolicyModal = ({
  handleModal,
  policyAction,
  instances,
  refreshAccounts,
  policyItem,
}) => {
  const dispatch = useDispatch();
  const [accountsList, setAccountsList] = useState();
  const [description, setDescription] = useState();
  const [policyType, setPolicyType] = useState("alerting");
  const [isRiskUpgradeToCritical, setIsRiskUpgradeToCritical] = useState(false);
  const [isRiskUpgradeToHigh, setIsRiskUpgradeToHigh] = useState(false);
  const [isDirectAnomalyOnDatastore, setIsDirectAnomalyOnDatastore] = useState(
    false
  );
  const [
    isIndirectAnomalyViaCloudEnvironment,
    setIsIndirectAnomalyViaCloudEnvironmen,
  ] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedAlertingAction, setSelectedAlertingAction] = useState();
  const [selectedIssueTypes, setSelectedIssueTypes] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const accounts = useSelector((state) => state.account.accounts);
  const [loading, setLoading] = useState();

  const formik = useFormik({
    initialValues: {
      name: "",
      accounts: "",
      alertingActions: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter policy name."),
      // accounts: Yup.array().required("Please select account."),
      alertingActions: Yup.object().required("Please select alerting action."),
    }),
    onSubmit: (values) => {
      if (policyAction === "add") {
        addPolicy({ ...values });
      } else {
        updatePolicy({ ...values });
      }
    },
  });

  const alertingActions = [
    { value: "slack", label: "slack" },
    { value: "email", label: "email" },
  ];
  const issueType = [
    {
      value: "Sudo Vulnerability",
      label: "Sudo Vulnerability",
    },
    {
      value: "Open Port 22",
      label: "Open Port 22",
    },
    {
      value: "Kernal Heap Overflow",
      label: "Kernal Heap Overflow",
    },
  ];
  const resource = [
    {
      value: "04bf29cf9ee4c3a0a",
      label: "04bf29cf9ee4c3a0a",
    },
    {
      value: "04bf29cf9ee4c3a0a",
      label: "04bf29cf9ee4c3a0a",
    },
  ];

  const refreshPolicyDetails = () => {
    setSelectedAccounts([]);
  };

  const updateAccounts = (account) => {
    let accounts = selectedAccounts;
    accounts.push(account);
    setSelectedAccounts(accounts);
  };

  const getSelectedAccountIds = () => {
    let accountIds = [];
    if (selectedAccounts?.length > 0) {
      selectedAccounts.map((account) => {
        accountIds.push(account.value);
      });
    }
    return accountIds;
  };

  const addPolicy = async () => {
    if (!isRiskUpgradeToCritical && !isRiskUpgradeToHigh) {
      Toaster(TOASTER_TYPES.ERROR, "Please choose one risk");
      return;
    }
    setLoading(true);
    const resp = await dispatch(
      addPolicyAsync({
        cloudAccountIds: getSelectedAccountIds(),
        type: getSelectedAccountIds().length > 0 ? "accounts" : "customer",
        description: description,
        name: formik.values.name,
        datastoreIds: [],
        userId: getUserId(),
        category: "alerting",
        alerting_action: [selectedAlertingAction.value],
        triggerCondition: getTriggerConditions(),
      })
    );
    dispatch(hideLoading());
    let msg = "Customer level policy added successfully.";
    if (getSelectedAccountIds().length) {
      msg = "Account level policy added successfully.";
    } else {
      msg = "Customer level policy added successfully.";
    }

    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, msg);
      setLoading(false);
      refreshAccounts();
      handleModal();
      refreshPolicyDetails();
    } else {
      setLoading(false);

      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to add policy."
      );
    }
  };

  const updatePolicy = async () => {
    setLoading(true);
    const resp = await dispatch(
      updatePolicyAsync({
        cloudAccountIds: getSelectedAccountIds(),
        type: selectedAccounts.length > 0 ? "accounts" : "customer",
        id: policyItem.id,
        description: description,
        name: formik.values.name,
        userId: getUserId(),
        alerting_action: Array.isArray(selectedAlertingAction.value)
          ? selectedAlertingAction.value
          : [selectedAlertingAction.value],
        triggerCondition: getTriggerConditions(),
        category: "alerting",
      })
    );
    dispatch(hideLoading());
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, "Policy updated successfully.");
      setLoading(false);
      refreshAccounts();
      refreshPolicyDetails();
      handleModal();
    } else {
      setLoading(false);
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to update policy."
      );
    }
  };

  useEffect(() => {
    const accountList = [];
    accounts.map((account) => {
      accountList.push({ label: account.id, value: account.id });
    });
    setAccountsList(accountList);
  }, [accounts]);

  const getTriggerConditionValue = (value) => {
    if (policyItem.triggerAction) {
      let actions = Object.keys(policyItem.triggerAction);
      if (actions.length > 0) {
        if (actions.includes(value) === true) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const getTriggerConditions = () => {
    let triggerConditions = [];
    if (isRiskUpgradeToCritical) {
      triggerConditions.push("datastore_risk_critical");
    }
    if (isRiskUpgradeToHigh) {
      triggerConditions.push("datastore_risk_high");
    }
    if (isDirectAnomalyOnDatastore) {
      triggerConditions.push("direct_anomaly");
    }
    if (isIndirectAnomalyViaCloudEnvironment) {
      triggerConditions.push("indirect_anomaly");
    }
    return triggerConditions;
  };

  const setAlertingActions = () => {
    let alertingAction = "";
    if (policyItem.triggerAction) {
      let actions = Object.keys(policyItem.triggerAction);
      if (actions.length > 0) {
        let action = actions[0];
        alertingAction = policyItem.triggerAction[action];
      } else {
        alertingAction = "";
      }
    }
    setSelectedAlertingAction({
      value: alertingAction,
      label: alertingAction,
    });
    formik.setFieldValue("alertingActions", {
      value: alertingAction,
      label: alertingAction,
    });
  };

  const updateSelectedAccounts = () => {
    let updatedAccounts = [];

    if (policyItem?.cloudAccountIds.length > 0) {
      policyItem?.cloudAccountIds.map((accountID) => {
        updatedAccounts.push({ label: accountID, value: accountID });
      });
    }
    formik.setFieldValue("accounts", updatedAccounts);
    setSelectedAccounts(updatedAccounts);
  };

  useEffect(() => {
    if (policyAction === "edit") {
      formik.setFieldValue("name", policyItem.name);
      setDescription(policyItem.description);
      setIsRiskUpgradeToCritical(
        getTriggerConditionValue("datastore_risk_critical")
      );
      setIsRiskUpgradeToHigh(getTriggerConditionValue("datastore_risk_high"));
      setIsDirectAnomalyOnDatastore(getTriggerConditionValue("direct_anomaly"));
      setIsIndirectAnomalyViaCloudEnvironmen(
        getTriggerConditionValue("indirect_anomaly")
      );
      setAlertingActions();
      updateSelectedAccounts();
    }
  }, [policyAction]);

  return (
    <div id="add-policy-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <span className="header-title">
                  {policyAction === "add" ? "Add" : "Update"} Alerting Policy
                </span>
                {/* <img
                  src={deleteIcon}
                  className="mr-3 delete-icon"
                  onClick={handleModal}
                /> */}
              </div>
              <div className="modal-body mb-2">
                {loading && (
                  <img src={loaderImage} className="modal-loading-img" />
                )}
                {/* <div className="row py-2 text-center justify-content-start">
                  <div className="col col-sm-3 d-flex flex-row modal-subtitle justify-content-start align-items-center workday-subheading">
                    <input
                      type="radio"
                      id="default"
                      checked={policyType === "alerting"}
                      value={policyType}
                      onClick={() => setPolicyType("alerting")}
                      name="frequency"
                      className="mr-3  custom-radio-button"
                    />{" "}
                    Alerting Policies
                  </div>
                  <div className="col col-sm-6 modal-subtitle workday-subheading-value">
                    <input
                      type="radio"
                      id="default"
                      checked={policyType === "automation"}
                      value={policyType}
                      onClick={() => setPolicyType("automation")}
                      name="frequency"
                      className="mr-3 ml-2 custom-radio-button"
                    />{" "}
                    Custom Automation Policies
                  </div>
                </div> */}
                <label className="label">
                  Policy Name: <span className="mandatory-icon">*</span>
                </label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Policy Name"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                      value={formik.values.name}
                      id="add-policy-policy-name"
                    ></input>
                    {formik.errors.name && formik.touched.name && (
                      <div className="error-message">{formik.errors.name}</div>
                    )}
                  </div>
                </div>{" "}
                <label className="label">Description:</label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <textarea
                      className="modal-textarea form-control py-2 px-2"
                      placeholder="Account Description"
                      type="text"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      id="add-policy-policy-description"
                    />
                  </div>
                </div>
                <label className="label">Customer:</label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Customer Name"
                      value={getCustomerName()}
                      disabled={true}
                      id="add-policy-customer-name"
                    ></input>
                  </div>
                </div>
                <label className="label">Accounts:</label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <Select
                      isMulti
                      name="colors"
                      value={selectedAccounts}
                      options={accountsList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onBlur={formik.handleBlur}
                      id="add-policy-accounts"
                      onChange={(value) => {
                        setSelectedAccounts(value);
                        formik.setFieldValue("accounts", value);
                      }}
                    />
                    {/* {formik.touched.accounts &&
                      selectedAccounts.length === 0 && (
                        <div className="error-message">
                          {"Please select account."}
                        </div>
                      )} */}
                  </div>
                </div>
                <div>
                  <div className="row py-2">
                    <div className="col col-sm-6 d-flex flex-row modal-subtitle  justify-content-between align-items-center workday-subheading">
                      <label className="label">Risk Upgrade to Critical:</label>
                      <Toggle
                        checked={isRiskUpgradeToCritical}
                        onChange={() => {
                          setIsRiskUpgradeToCritical(!isRiskUpgradeToCritical);
                          setIsRiskUpgradeToHigh(!isRiskUpgradeToHigh);
                        }}
                        value={isRiskUpgradeToCritical}
                        className="risk-accept-toggle risk-accept-toggle-left"
                        icons={false}
                        id="add-policy-risk-accept-toggle-critical"
                      />
                    </div>

                    <div className="col col-sm-6 d-flex flex-row modal-subtitle  justify-content-between align-items-center workday-subheading">
                      <label className="label">Risk Upgrade To High:</label>
                      <Toggle
                        checked={isRiskUpgradeToHigh}
                        // onChange={() => {
                        //   setIsRiskUpgradeToCritical(
                        //     !isRiskUpgradeToHigh
                        //       ? true
                        //       : isRiskUpgradeToCritical
                        //   );
                        //   setIsRiskUpgradeToHigh(!isRiskUpgradeToHigh);
                        // }}
                        onChange={() => {
                          // setIsRiskUpgradeToCritical(!isRiskUpgradeToCritical);
                          setIsRiskUpgradeToHigh(!isRiskUpgradeToHigh);
                        }}
                        className="risk-accept-toggle "
                        icons={false}
                        id="add-policy-risk-accept-toggle-high"
                      />
                    </div>
                  </div>

                  <div className="row py-2">
                    <div className="col col-sm-6 d-flex flex-row modal-subtitle  justify-content-between align-items-center workday-subheading">
                      <label className="label">
                        Direct Anomaly On Datastore:
                      </label>

                      <Toggle
                        checked={isDirectAnomalyOnDatastore}
                        onChange={() =>
                          setIsDirectAnomalyOnDatastore(
                            !isDirectAnomalyOnDatastore
                          )
                        }
                        className="risk-accept-toggle risk-accept-toggle-left"
                        icons={false}
                        disabled={true}
                        id="add-policy-risk-accept-toggle-anomaly"
                      />
                    </div>
                    <div className="col col-sm-6 d-flex flex-row modal-subtitle  justify-content-between align-items-center workday-subheading">
                      <label className="label">
                        Indirect Anomaly Via Cloud Environment:
                      </label>
                      <Toggle
                        checked={isIndirectAnomalyViaCloudEnvironment}
                        onChange={() =>
                          setIsIndirectAnomalyViaCloudEnvironmen(
                            !isIndirectAnomalyViaCloudEnvironment
                          )
                        }
                        className="risk-accept-toggle"
                        icons={false}
                        disabled={true}
                        id="add-policy-risk-accept-toggle-cloud"
                      />
                    </div>
                  </div>
                </div>
                <label className="label">
                  Alerting Action: <span className="mandatory-icon">*</span>
                </label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <Select
                      name="alerting actions"
                      options={alertingActions}
                      classNamePrefix="accounts-dropdown"
                      onBlur={formik.handleBlur}
                      value={selectedAlertingAction}
                      onChange={(value) => {
                        setSelectedAlertingAction(value);
                        formik.setFieldValue("alertingActions", value);
                      }}
                      id="add-policy-alerting-action"
                    />
                    {formik.errors.alertingActions &&
                      formik.touched.alertingActions && (
                        <div className="error-message">
                          {formik.errors.alertingActions}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="modal-footer p-2">
                {policyAction === "add" ? (
                  <Button
                    variant="contained"
                    className=" mr-2"
                    disabled={
                      !formik.isValid ||
                      !formik.values.name ||
                      loading ||
                      (!isRiskUpgradeToCritical && !isRiskUpgradeToHigh)
                    }
                    type="submit"
                    id="add-policy-submit"
                  >
                    Add Policy
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className=" mr-2"
                    disabled={!formik.isValid || !formik.values.name || loading}
                    type="submit"
                    id="add-policy-update"
                  >
                    {" "}
                    Update Policy
                  </Button>
                )}
                <Button
                  variant="outlined"
                  type="button"
                  className="btn btn-secondary"
                  id="add-policy-cancel"
                  onClick={() => {
                    handleModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPolicyModal;
