import * as constants from "./Constants";

const FormatUrl = (url) => {
  const api_url = constants.EVEREST_URL;
  return api_url + url;
};

export const BaseURL = () => {
  return constants.EVEREST_URL;
};
export default FormatUrl;
