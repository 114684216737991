import React from "react";
import BottomPanelComponent from "./BottomPanelComponent";

const BottomPanelContainer = ({ RefreshChart, attackPaths }) => {
  return (
    <BottomPanelComponent
      RefreshChart={RefreshChart}
      attackPaths={attackPaths}
    />
  );
};

export default BottomPanelContainer;
