import React from "react";
import { accountTypes } from "../../../utils/accountsHelper";
import { ACCOUNT_TYPES } from "../../../utils/Constants";

const SelectAccountStep = ({ handleSelected, selected, currentStep }) => {
  const getClassNameForSelection = (accountType) => {
    return `${selected === accountType ? "types types" : "types"} text-center`;
  };

  return (
    <div className="select-account">
      <div className="account-list">
        <div className="row">
          <div className="col font-weight-bold mb-3">Account Types</div>
        </div>
        <div className="row">
          {/* <div className="col d-flex align-items-center flex-wrap"> */}
          {accountTypes.map((account, index) => (
            <div
              className={"modal-card col-sm-4"}
              key={index}
              //style={
              //  account.type !== ACCOUNT_TYPES.AWS
              //    ? { opacity: 0.7, cursor: "default" }
              //    : {}
              //}
            >
              <div
                className={getClassNameForSelection(account.type)}
                onClick={
                  () => handleSelected(account.type)
                  //account.type == ACCOUNT_TYPES.AWS
                  //  ? handleSelected(account.type)
                  //  : null
                }
              >
                <img src={account.image} className="modal-card-img" />
              </div>
              {/* <h4 className="modal-card-title mt-1 ">{account.title}</h4> */}
            </div>
          ))}
          {/* </div> */}
        </div>
      </div>

      {/* <div className="d-flex step-margin">
        <div>
          <div className="count-circle">{currentStep}</div>
        </div>
        <div className="d-flex flex-column justify-content-center pl-3">
          {" "}
          <div className="step-header mb-2 text-center">
            Select the type of account to add
          </div>
          <div className="step-sub-header"></div>
        </div>
      </div> */}
    </div>
  );
};

export default SelectAccountStep;
