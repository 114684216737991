export const gcpRegions = [
  {
    name: "Oregon",
    latitude: 46.15,
    longitude: -123.88,
    full_name: "US West (Oregon)",
    code: "us-west1",
    public: true,
    zones: ["us-west-2a", "us-west-2b", "us-west-2c", "us-west-2d"],
  },
  {
    name: "Iowa",
    latitude: 42.032974,
    longitude: -93.581543,
    full_name: "US Central (Iowa)",
    code: "us-central1",
    public: true,
    zones: ["us-west-2a", "us-west-2b", "us-west-2c", "us-west-2d"],
  },
];

