import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTheme } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Drawer from "../common/Drawer";
import Search from "../common/Search";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import FloatingWindow from "./FloatingWindow";
import PolicyStoresTabPanel from "./PolicyStoreTabPanel";
import { TabData, TableSearchProp } from "../../types/type";

const PolicyStoreDetailsDrawer = ({
  open,
  setOpen,
  anchor,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  anchor: "bottom" | "left" | "right" | "top";
}) => {
  const theme = useTheme();

  const {
    drawerData,
    setDrawerData,
    selectedPolicy,
    setSelectedPolicy,
  } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(drawerData?.activeTabNumber);
  const [policySearchText, setPolicySearchText] = useState<TableSearchProp>({
    searchText: "",
    isEnter: false,
  });

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    const policy = drawerData?.tabData?.find((tab) => tab.tabIndex === newValue)
      .barData[0];
    setDrawerData({
      ...drawerData,
      currentSelectedPolicy: {
        ...policy,
        id: policy?.id || 0,
      },
      activeTab: newValue,
    });
    setSelectedPolicy(undefined);
    setActiveTab(newValue);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setActiveTab(drawerData?.activeTab);
  }, [drawerData?.activeTab]);

  const isVisible = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 90 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const [showFloatingWindow, setShowFloatingWindow] = useState(false);

  const handleScroll = (element) => {
    if (element) {
      if (isVisible(element)) {
        setShowFloatingWindow(false);
      } else {
        setShowFloatingWindow(true);
      }
    }
  };

  useEffect(() => {
    let element;
    if (activeTab === "policy-category")
      element = window.document.getElementById("item-selected");
    else element = window.document.getElementById("row-selected");
    if (element && document.getElementById("drawer-container")) {
      (document.getElementById("drawer-container") as any).onscroll = () =>
        handleScroll(element);
      // handleScroll(element); // Check visibility initially
    }
  }, [
    document.getElementById("item-selected"),
    document.getElementById("row-selected"),
  ]);

  const onClear = () => {
    setPolicySearchText({
      searchText: "",
      isEnter: false,
    });
  };

  const styles = {
    dialog: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    floating: {
      position: "fixed",
      left: "5%",
      top: 0,
      right: 0,
      background: "red",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(3, 2.5),
    },
    drawerHeaderTitle: {
      textTransform: "uppercase",
      gap: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    chevron: {
      color: theme.palette.surface80.main,
      cursor: "pointer",
      height: "20px",
    },
    drawerContent: {
      padding: theme.spacing(0, 2.5, 3),
      flexGrow: 1,
      overflow: "auto",
    },
    policyDetailContainer: {
      display: "flex",
      gap: theme.spacing(2),
    },
    card: {
      backgroundColor: theme.palette.surface10.main,
      height: "100%",
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(3, 0),
    },
    policyBarChart: {
      width: "30%",
      height: "425px",
    },
    policyDetailsTable: {
      width: "70%",
    },
  };

  return (
    <Drawer
      open={open}
      anchor={anchor}
      width="95%"
      sx={{ position: "relative", height: "100vh", overflow: "auto" }}
      onClose={() => setOpen(false)}
    >
      <Box sx={styles.dialog}>
        <Box sx={styles.drawerHeader}>
          <Box sx={styles.drawerHeaderTitle}>
            <ArrowBackIosIcon onClick={onClose} sx={styles.chevron} />
            <Tabs value={activeTab} onChange={handleTabChange}>
              {drawerData?.tabs?.map((tab: object, index: number) => (
                <Tab {...tab} key={index} />
              ))}
            </Tabs>
          </Box>
          <Search
            placeholder="Search By Policy Name"
            value={policySearchText?.searchText}
            onChange={(e) =>
              setPolicySearchText({
                searchText: e.target.value,
                isEnter: false,
              })
            }
            onKeyDown={(e) =>
              e.key === "Enter" &&
              setPolicySearchText({ ...policySearchText, isEnter: true })
            }
            onClear={onClear}
          />
        </Box>

        <Box sx={styles.drawerContent} id="drawer-container">
          {drawerData?.tabs?.map(
            (tab: { label: string; value: string }, index: number) => {
              return (
                <TabPanel value={activeTab} index={tab?.value} key={tab?.value}>
                  <PolicyStoresTabPanel
                    activeTab={tab?.value}
                    onSearch={policySearchText}
                  />
                </TabPanel>
              );
            }
          )}
        </Box>
      </Box>
      {activeTab !== "policy" &&
        showFloatingWindow &&
        drawerData?.currentSelectedPolicy &&
        selectedPolicy && (
          <Box sx={styles.floating}>
            <FloatingWindow showBar={activeTab === "policy-category"} />
          </Box>
        )}
    </Drawer>
  );
};

export default PolicyStoreDetailsDrawer;
