import { useState } from "react";
import { useFormik } from "formik";
import deleteIcon from "../../assets/img/icons/ico-delete-grey.svg";
import * as Yup from "yup";
import "./style.scss";
import { Toaster } from "../../utils/toaster";
import FormatUrl from "../../utils/UrlFormatter";
import axios from "axios";
import Button from "../common/Button";

const UpdateUser = ({ handleModal, user }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);

  const createUser = async (values) => {
    let url = FormatUrl(`/user/`);

    handleModal(false);
    return axios
      .post(url, {
        username: values.username,
        email: values.email,
      })
      .catch(function (error) {
        if (error.response) {
          Toaster(
            2,
            error.response?.data?.err?.message || "Failed to create user"
          );
        } else {
          Toaster(2, "Failed to create user");
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      username: user.username,
      email: user.email,
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Please enter usernamme."),
      email: Yup.string().required("Please enter email."),
    }),
    onSubmit: (values) => {
      console.log(values);
      createUser({ ...values });
    },
  });

  return (
    <div id="user-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <span className="header-title">{"Update User"}</span>
                <img
                  src={deleteIcon}
                  className="mr-3 delete-icon"
                  onClick={handleModal}
                />
              </div>
              <div className="modal-body">
                {showLoader && (
                  <img
                    src="static/img/loader-circle.svg"
                    className="modal-loading-img"
                  />
                )}
                <div className="row">
                  <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                    <b className="form-field-name ">Username</b>
                    <span className="mandatory-icon">*</span>
                  </div>
                  <div className="col col-lg-6">
                    <input
                      type="text"
                      id="Username"
                      placeholder="Username"
                      name="Username"
                      maxLength={50}
                      className="modal-input-style px-2"
                      onChange={(e) => {
                        formik.setFieldValue("username", e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.username}
                    />
                    {formik.errors.username && formik.touched.username && (
                      <div className="error-message">
                        {formik.errors.username}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                    <b className="form-field-name ">Email</b>
                    <span className="mandatory-icon">*</span>
                  </div>
                  <div className="col col-lg-6">
                    <input
                      type="text"
                      id="host"
                      placeholder="Email"
                      name="host"
                      maxLength={50}
                      className="modal-input-style px-2"
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                      }}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.email && formik.touched.host && (
                      <div className="error-message">{formik.errors.host}</div>
                    )}
                  </div>
                </div>

                {connectionFailed && (
                  <div className="row mt-2">
                    <div className="col-9 offset-md-3 pl-0 text-danger">
                      Connection failed, please check the details and try again.
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Create user
                </Button>

                <span className="separator mx-3"></span>

                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
