import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import BarChart from "../common/BarChart";
import Box from "../common/Box";
import Divider from "../common/Divider";
import Typography from "../common/Typography";

const PolicyBarChart = (props: any) => {
  const theme = useTheme();

  const { drawerData, setDrawerData } = useContext(AppContext);
  const styles = {
    header: {
      display: "flex",
      padding: theme.spacing(0, 2),
      gap: theme.spacing(3.5),
      "& :first-child": {
        width: "20%",
      },
    },
    divider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
      background: theme.palette.surface20.main,
    },
    moreDetailsButton: {
      display: "flex",
    },
    footer: {
      display: "flex",
      padding: theme.spacing(1, 2),
      gap: theme.spacing(3.5),
    },
    empty: {
      width: "100%",
    },
    barHeader: {
      fontSize: "13px",
      color: theme.palette.surface50.main,
    },
  };

  const [barData, setBarData] = useState<{
    barData: Array<{
      barLabel: string;
      barValue: number;
      totalValue: number;
      barFaceColor: string;
      barBackColor: string;
      id: any;
      policyType: string;
      onClick: () => void;
    }>;
    chartTitle: string;
    tabIndex: number;
    xAxisLabel: string;
  }>();

  useEffect(() => {
    if (props?.barData) {
      const tempData = props.barData.map(
        (
          bd: {
            barLabel: string;
            barValue: number;
            totalValue: number;
            barFaceColor: string;
            barBackColor: string;
            id: number;
            policyType: string;
            onClick: () => void;
          },
          index: number
        ) => {
          return {
            ...bd,
            id: index,
            onClick: () => {
              setDrawerData({
                ...drawerData,
                currentSelectedPolicy: { ...bd, id: index },
                id: index,
                policyType: "",
              });
            },
          };
        }
      );
      setBarData({ ...props, barData: tempData });
    }
  }, [props]);

  return (
    <Box>
      <Box sx={styles.header}>
        <Typography variant="body2" sx={styles.barHeader}>
          Category
        </Typography>
        <Typography variant="body2" sx={styles.barHeader}>
          {props?.xAxisLabel}
        </Typography>
      </Box>
      <Divider sx={styles.divider} />
      {barData?.barData?.length && (
        <BarChart
          {...barData}
          chartTitle=""
          type="detail"
          selected={drawerData?.currentSelectedPolicy?.id}
        />
      )}
      {/* <Box sx={styles.footer}>
        <Box sx={styles.empty}></Box>
        <MoreDetailsButton sx={styles.moreDetailsButton} onClick={() => { }}>
          Details
        </MoreDetailsButton>
      </Box> */}
    </Box>
  );
};

export default PolicyBarChart;
