import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as DeleteIcon } from "../../assets/img/delete-icon.svg";

import {
  hideLoading,
  showLoading,
} from "../../redux/slices/globalOperationSlice";
import { addReportAsync } from "../../redux/slices/reportsSlice";
import { getUserId } from "../../utils/SessionHelper";

import { Toaster, TOASTER_TYPES } from "../../utils/toaster";
import "./style.scss";
import DaySelector from "../reports/DaySelector";
import Button from "../common/Button";

// Functional component for Add Account.
const SchedulerModal = ({ handleModal, refreshReports }) => {
  const [radioValue, setRadioValue] = useState("never");
  const chapters = [
    {
      name: "Enterprise Risk Summary",
      description:
        "Hendrerit nec sociis vindico himenaeos convallis eleifend iustum. Ulciscor ille voco appellatio inceptos.",
      disabled: true,
    },
    {
      name: "Data Store Report",
      description: "Egestas donec fatua saluto libero nisl incassum esse.",
      disabled: true,
    },
    {
      name: "Data Store List",
      description: "Egestas donec fatua saluto libero nisl incassum esse.",
      disabled: true,
    },
    {
      name: "Issues Report",
      description: "Macto commoveo suspendisse quidne quisque roto natoque.",
      disabled: true,
    },
    {
      name: "Issues List",
      description: "Macto commoveo suspendisse quidne quisque roto natoque.",
      disabled: false,
    },
    {
      name: "Ticketing Report",
      description:
        "Delenit quisque venio verto gravida tristique bibendum tempus.",
      disabled: true,
    },
  ];

  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [report, setReport] = useState({
    reportName: "",
    reportDescription: "",
    repeat: "WEEKLY",
    repeatEvery: "1",
    endRepeat: "Never",
    endRepeatValueOn: null,
    endRepeatValueAfter: null,
    daysOfWeek: [],
  });

  const [recepients, setRecepients] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  const [checkedChapters, setCheckedChapters] = useState([chapters[4].name]);
  const [invalidReport, setInvalidReport] = useState(false);

  const validateInput = (e) => {
    if (!e.target.value) {
      setInvalidReport(true);
    }
  };

  // Formik
  const formik = useFormik({
    initialValues: {
      reportName: report.reportName?.trim(),
      reportDescription: report.reportDescription?.trim(),
      repeat: report.repeat,
      repeatEvery: report.repeatEvery,
      endRepeat: report.endRepeat,
      endRepeatValueOn: report.endRepeatValueOn,
      endRepeatValueAfter: report.endRepeatValueAfter,
      daysOfWeek: report.daysOfWeek,
    },
    validationSchema: Yup.object().shape({
      reportName: Yup.string().required("Please enter report name."),
      repeat: Yup.string().required("Please enter repeat interval."),
      repeatEvery: Yup.string().required("Please enter report every."),
      endRepeat: Yup.string().required("Please enter end repeat value."),
    }),
    onSubmit: (values) => {
      addReport(values);
    },
  });

  const addReport = async (values) => {
    dispatch(showLoading());
    let endRepeatValue = null;
    if (values.endRepeat === "On") {
      if (!values.endRepeatValueOn) {
        Toaster(TOASTER_TYPES.ERROR, "On value required");
        return;
      }
      endRepeatValue = values.endRepeatValueOn;
    } else if (values.endRepeat === "After") {
      if (!values.endRepeatValueAfter) {
        Toaster(TOASTER_TYPES.ERROR, "After value required");
        return;
      }
      endRepeatValue = values.endRepeatValueAfter;
    } else {
      endRepeatValue = "Never";
    }
    const resp = await dispatch(
      addReportAsync({
        reportName: values.reportName,
        id: "",
        reportDescription: values.reportDescription,
        recipient: recepients,
        chapter: checkedChapters,
        repeat: values.repeat,
        daysOfWeek: selectedDays,
        repeatEvery: values.repeatEvery,
        endRepeat: endRepeatValue,
        userId: getUserId(),
      })
    );
    dispatch(hideLoading());
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, "Report added successfully.");
      refreshReports();
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to add report."
      );
    }
  };

  /**
   * This function provides the header for current step.
   * @returns - the header for current step.
   */
  const getCurrentStepHeading = () => {
    switch (step) {
      case 1:
        return <b>New Report</b>;
      case 2:
        return <b>New Report</b>;
      case 3:
        return <b>New Report</b>;
      default:
        return null;
    }
  };

  /**
   * This function provides the title for current step.
   * @returns - the title for current step.
   */
  const getCurrentStepTitle = () => {
    switch (step) {
      case 1:
        return <b>Enter report details and recipients</b>;
      case 2:
        return <b>Select chapters</b>;
      case 3:
        return <b>Schedule Report</b>;
      default:
        return null;
    }
  };

  const handleEmailEnter = (event) => {
    if (event.key === "Enter" && !emailValid(event.target.value)) {
      Toaster(TOASTER_TYPES.ERROR, "Invalid email!");
      return;
    }
    if (
      event.key === "Enter" &&
      emailValid(event.target.value) &&
      !recepients.includes(event.target.value)
    ) {
      setRecepients([...recepients, event.target.value]);
      event.target.value = "";
    }
  };

  const emailValid = (val) =>
    /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
      val
    );

  const deleteEmail = (index) => {
    recepients.splice(index, 1);
    setRecepients([...recepients]);
  };

  const handleChaptersChecked = (position) => {
    let chapter = chapters[position].name;
    if (checkedChapters.includes(chapter)) {
      setCheckedChapters(checkedChapters.filter((item) => item !== chapter));
    } else {
      setCheckedChapters([...checkedChapters, chapters[position].name]);
    }
  };

  const RadioButton = ({ name, id, value, onChange, checked, text }) => {
    return (
      <label htmlFor={id} className="radio-label">
        <input
          className="radio-input"
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          checked={checked}
        />
        <span className="custom-radio" />
        {text}
      </label>
    );
  };

  return (
    <div id="add-account">
      <div
        className="modal fade show"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        style={{
          display: "block",
          paddingRight: "8px",
          background: "rgba(0, 0, 0, 0.5)",
        }}
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content p-4">
            <div className="modal-header ">
              <div className="header-text">New Report</div>
              <div className="row m-0 stepper mt-4">
                <div
                  className={`col-md-4 p-0 col-lg-4 ${
                    step === 1 ? "step-active" : "step"
                  } `}
                >
                  <div
                    className={`${
                      step === 1 ? "step-number" : "step-number-disable"
                    } mb-3`}
                  >
                    1
                  </div>

                  <div
                    className={`${
                      step === 1 ? "step-title" : "step-title-disable"
                    } mb-3`}
                  >
                    Enter report details & Recipients
                  </div>
                </div>
                <div
                  className={`col-md-4 p-0 col-lg-4 ${
                    step === 2 ? "step-active" : "step"
                  } `}
                >
                  <div
                    className={`${
                      step === 2 ? "step-number" : "step-number-disable"
                    } mb-3`}
                  >
                    2
                  </div>
                  <div
                    className={`${
                      step === 2 ? "step-title" : "step-title-disable"
                    } mb-3`}
                  >
                    Select chapters
                  </div>
                </div>
                <div
                  className={`col-md-4 p-0 col-lg-4 ${
                    step === 3 ? "step-active" : "step"
                  } `}
                >
                  <div
                    className={`${
                      step === 3 ? "step-number" : "step-number-disable"
                    } mb-3`}
                  >
                    3
                  </div>
                  <div
                    className={`${
                      step === 3 ? "step-title" : "step-title-disable"
                    } mb-3`}
                  >
                    Schedule report
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body">
                {step === 1 ? (
                  <div className="input-containers">
                    <div className="row mt-2">
                      <div className="col col-lg-12">
                        <label className="label">Report Name</label>
                        <span className="mandatory-icon">*</span>
                      </div>

                      <div className="col col-lg-12">
                        <input
                          type="text"
                          placeholder="Enter Report Name"
                          className="form-control"
                          onChange={(e) => {
                            formik.setFieldValue("reportName", e.target.value);
                          }}
                          value={formik.values.reportName}
                          onBlur={validateInput}
                        />
                        {invalidReport && (
                          <div className="error-message">
                            Please enter report name.
                          </div>
                        )}
                        {formik.errors.reportName &&
                          formik.touched.reportName && (
                            <div className="error-message">
                              {formik.errors.reportName}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col col-lg-12">
                        <label className="label">Report Description</label>
                      </div>
                      <div className="col col-lg-12">
                        <textarea
                          className="form-control"
                          placeholder="Enter Description"
                          name="reportDescription"
                          id="reportDescription"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "reportDescription",
                              e.target.value
                            );
                          }}
                          value={formik.values.reportDescription}
                        ></textarea>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col col-lg-12">
                        <label className="label">Add Recepients</label>
                        <span className="mandatory-icon">*</span>
                      </div>

                      <div className="col col-lg-12">
                        <div className="row">
                          <div className="col col-lg-12">
                            <div className="d-flex flex-wrap email-input-box">
                              {recepients &&
                                recepients.map((recepient, index) => (
                                  <div
                                    key={index}
                                    className="email-chip pr-2 align-items-center justify-content-center label"
                                  >
                                    <span className="pr-2"> {recepient}</span>
                                    <DeleteIcon
                                      height="15"
                                      className="delete-icon cursor-pointer"
                                      onClick={() => deleteEmail(index)}
                                    />
                                  </div>
                                ))}
                              <div className="d-flex email-input width-full">
                                <input
                                  type="text"
                                  // placeholder="Enter email address and press enter key"
                                  className="form-control email-input-field"
                                  onKeyDown={handleEmailEnter}
                                  onBlur={formik.handleBlur}
                                />{" "}
                              </div>
                            </div>
                            {/* <input
                              type="text"
                              hidden
                              placeholder="Enter email address and press enter key"
                              className="form-control"
                              onKeyDown={handleEmailEnter}
                              onBlur={formik.handleBlur}
                            /> */}
                            {/* <div contentEditable className="email-input"></div> */}

                            {formik.touched.recipient &&
                              recepients.length === 0 && (
                                <div className="error-message">
                                  {"Please enter recipient."}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col col-lg-12">
                        <label className="label">
                          Please enter the email address and press enter key to
                          add the recipient. You can add multiple recipients
                        </label>
                      </div>
                    </div>
                  </div>
                ) : step === 2 ? (
                  <div className="input-containers">
                    <div className="checkbox-container">
                      {chapters.map((chapter, index) => (
                        <div class="new" key={index}>
                          <form>
                            <div class="form-group">
                              <input
                                type={"checkbox"}
                                className="mr-1"
                                id={`chapter-${index}`}
                                defaultChecked={checkedChapters.includes(
                                  chapter.name
                                )}
                                onChange={() => handleChaptersChecked(index)}
                                disabled={chapter.disabled}
                              />
                              <label
                                htmlFor={`chapter-${index}`}
                                className="mb-0 mt-0 position-absolute chapter-label"
                              >
                                {chapter.name}
                              </label>
                            </div>
                          </form>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="input-containers">
                    <div className="row">
                      <div className="col col-lg-12">
                        <label className="label">Repeat</label>
                      </div>
                      <div className="col col-lg-12">
                        <select
                          className="form-control"
                          value={formik.values.repeat}
                          onChange={(event) =>
                            formik.setFieldValue("repeat", event.target.value)
                          }
                        >
                          <option value="DAILY">Daily</option>
                          <option value="WEEKLY">Weekly</option>
                          <option value="MONTHLY">Monthly</option>
                        </select>
                        {formik.errors.repeat && formik.touched.repeat && (
                          <div className="error-message">
                            {formik.errors.repeat}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col col-lg-12">
                        <label className="label">Repeat every</label>
                      </div>
                      <div className="col col-lg-12">
                        <select
                          className="form-control"
                          value={formik.values.repeatEvery}
                          onChange={(event) =>
                            formik.setFieldValue(
                              "repeatEvery",
                              event.target.value
                            )
                          }
                          onBlur={formik.handleBlur}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="4">4</option>
                        </select>

                        {formik.errors.repeatEvery &&
                          formik.touched.repeatEvery && (
                            <div className="error-message">
                              {formik.errors.repeatEvery}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col col-lg-12">
                        <DaySelector
                          selectedDays={selectedDays}
                          setSelectedDaysValue={(value) => {
                            setSelectedDays(value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="occurance-container">
                      <div className="row mt-4">
                        <div className="col col-lg-4 mt-2">
                          <RadioButton
                            name="never"
                            id="never"
                            text="Never"
                            value={formik.values.endRepeat}
                            onChange={() => {
                              formik.setFieldValue("endRepeat", "Never");
                            }}
                            checked={
                              formik.values.endRepeat === "Never" ? true : false
                            }
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col col-lg-2 mt-2">
                          <RadioButton
                            name="on"
                            id="on"
                            value="On"
                            text="On"
                            onChange={(event) =>
                              formik.setFieldValue("endRepeat", "On")
                            }
                            checked={
                              formik.values.endRepeat === "On" ? true : false
                            }
                          />
                        </div>
                        <div className="col col-lg-8">
                          <input
                            type={"text"}
                            placeholder="1 Occurrence"
                            className="form-control"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "endRepeatValueOn",
                                event.target.value
                              );
                            }}
                            value={formik.values.endRepeatValueOn}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col col-lg-2 mt-2">
                          <RadioButton
                            id="customRadio8"
                            name="endRepeat"
                            value="After"
                            text="After"
                            onChange={(event) =>
                              formik.setFieldValue("endRepeat", "After")
                            }
                            checked={
                              formik.values.endRepeat === "After" ? true : false
                            }
                          />{" "}
                        </div>
                        <div className="col col-lg-8">
                          <input
                            type={"date"}
                            className="form-control"
                            value={formik.values.endRepeatValueAfter}
                            onChange={(event) =>
                              formik.setFieldValue(
                                "endRepeatValueAfter",
                                event.target.value
                              )
                            }
                          />
                          {formik.errors.endRepeat &&
                            formik.touched.endRepeat && (
                              <div className="error-message">
                                {formik.errors.endRepeat}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="row mt-4">
                <div className="col mt-2">
                  <div className="float-left">
                    {step !== 1 ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setStep(step - 1)}
                      >
                        {step === 2
                          ? "Back: Report Details"
                          : "Back: Select Chapter"}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="float-right">
                    <button
                      type="submit"
                      className="btn btn-primary mr-4"
                      onClick={() => setStep(step + 1)}
                    >
                      {step === 1
                        ? "Next: Chapters"
                        : step === 2
                        ? "Next: Schedule"
                        : "Schedule Report"}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleModal(false)}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div> */}

                <div className="row mt-5 mb-5">
                  <div className="col-sm-4">
                    {step == 2 && (
                      <Button
                        variant="contained"
                        type="button"
                        onClick={() => setStep(1)}
                      >
                        BACK: REPORT DETAILS
                      </Button>
                    )}
                    {step === 3 && (
                      <Button
                        variant="contained"
                        type="button"
                        onClick={() => setStep(2)}
                      >
                        BACK: SELECT CHAPTER
                      </Button>
                    )}
                  </div>
                  <div className="col">
                    <div className="float-right">
                      {step === 1 && (
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => {
                            setReport({ ...formik.values });
                            setStep(2);
                          }}
                          disabled={
                            !formik.values.reportName || recepients.length === 0
                          }
                        >
                          NEXT: CHAPTERS
                        </Button>
                      )}
                      {step === 2 && (
                        <Button
                          variant="contained"
                          type="button"
                          disabled={checkedChapters.length === 0}
                        >
                          NEXT: SCHEDULE
                        </Button>
                      )}
                      {step === 3 && (
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          SCHEDULE REPORT
                        </Button>
                      )}
                      <span className="separator mx-3"></span>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() => handleModal(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulerModal;
