import { createTheme } from "@mui/material";
import { darkPalette, lightPalette } from "./palette";

// Theme object for light theme
const LightTheme = () =>
  createTheme({
    palette: lightPalette,
  });

// Theme object for dark theme
const DarkTheme = () =>
  createTheme({
    palette: darkPalette,
  });

const ThemeObject = {
  light: LightTheme,
  dark: DarkTheme,
};

export default ThemeObject;
