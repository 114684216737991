import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { CardWrapper } from "../CardWrapper";
import TextButton from "../../common/TextButton";
import HuggingFace from "../../../assets/img/hugging.png";
import GoogleIcon from "../../../assets/img/googleIcon.png";
import Divider from "../../common/Divider";
import tagIcon from "../../../assets/img/icons/tagImage.svg";
import Popup from "reactjs-popup";

export const LLMDetailsCard = ({
  llmDetails = {},
  tags = [],
  moreTags = [],
  onClick,
}: {
  llmDetails: any;
  tags: any;
  moreTags: any;
  onClick: () => void;
}) => {
  const theme = useTheme();
  const styles = {
    llmDetailsCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      width: "100%",
    },
    llmDetailsCardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    llmDetailsCardHeading: {
      fontSize: "13px",
    },
    llmDetailsCardDescription: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightLight,
      opacity: "0.5",
    },
    llmDetailsCardBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    llmDetailsCardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
    },
    llmDetail: {
      display: "flex",
    },
    llmDetailKey: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      width: "50%",
    },
    llmDetailValue: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      width: "50%",
      h6: {
        color: theme.palette.surface80.main,
        fontSize: "12px",
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    llmFooterDetialsKey: {
      color: theme.palette.surface80.main,
      fontSize: "20px",
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: "Manrope",
    },
    llmFooterDetialsValue: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      "word-wrap": "break-word",
    },
    manageButton: {
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  };

  const source = llmDetails?.llmInformation["Source"];

  return (
    <CardWrapper>
      <Box sx={styles.llmDetailsCard}>
        <Box sx={styles.llmDetailsCardHeader}>
          <Typography variant="body2" sx={styles.llmDetailsCardHeading}>
            LLM Details
          </Typography>
          {/*<Typography sx={styles.llmDetailsCardDescription}>
            Post - Trained after downloading
          </Typography>*/}
        </Box>
        <Box sx={styles.llmDetailsCardBody}>
          <Box sx={styles.llmDetail}>
            <Typography sx={styles.llmDetailKey}>Name</Typography>
            <Typography sx={styles.llmDetailValue}>
              {llmDetails?.llmInformation.Name}
            </Typography>
          </Box>
          <Box sx={styles.llmDetail}>
            <Typography sx={styles.llmDetailKey}>Basefamily</Typography>
            <Typography sx={styles.llmDetailValue}>
              {llmDetails?.llmInformation["Base Family"]}
            </Typography>
          </Box>
          <Box sx={styles.llmDetail}>
            <Typography sx={styles.llmDetailKey}>Framework</Typography>
            <Typography sx={styles.llmDetailValue}>
              {llmDetails?.llmInformation["Framework"]}
            </Typography>
          </Box>
          <Box sx={styles.llmDetail}>
            <Typography sx={styles.llmDetailKey}>Owner</Typography>
            <Typography sx={styles.llmDetailValue}>
              {llmDetails?.llmInformation["Owner"]}
            </Typography>
          </Box>
          <Box sx={styles.llmDetail}>
            <Typography sx={styles.llmDetailKey}>Tags</Typography>
            <Box sx={styles.llmDetailValue}>
              {/* <Typography>Tag1</Typography> */}
              <div className="row ">
                {tags.length === 0 ? (
                  <div className="col-md-auto pl-3 p-0"> N/A </div>
                ) : (
                  tags.map((tag, index) => (
                    <div key={index}>
                      {index < 3 ? (
                        <div className="col-md-auto pl-3 p-0">
                          <span className="subvalue-bold data-store-tag">
                            <img className="mr-1" src={tagIcon}></img>
                            <span
                              title={tag?.label.length > 12 ? tag?.label : ""}
                            >
                              {tag?.label.length > 12
                                ? tag?.label.slice(0, 12) + "..."
                                : tag.label || ""}
                            </span>
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Adding more tags link */}
                      {index == 3 && (
                        <Popup
                          trigger={(open) => (
                            <div className="col-md-auto pl-3 p-0">
                              <span className="subvalue-bold more-tags">
                                <b>
                                  <u>more...</u>
                                </b>
                              </span>
                            </div>
                          )}
                          position="right top"
                          closeOnDocumentClick
                          className="top-panel-tags"
                        >
                          <div className="header">Tags</div>
                          <div className="row">
                            {moreTags.map((tag, index) => (
                              <div key={index} className="col-md-auto">
                                <div className="subvalue-bold data-store-tag m-2">
                                  <img className="mr-2" src={tagIcon}></img>
                                  <span
                                    className="subvalue-bold"
                                    title={tag.label}
                                  >
                                    {tag?.label.length > 25
                                      ? tag?.label.slice(0, 25) + "..."
                                      : tag.label || ""}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Popup>
                      )}
                    </div>
                  ))
                )}
              </div>
              <TextButton onClick={onClick} sx={styles.manageButton}>
                Manage Tags
              </TextButton>
            </Box>
          </Box>
          <Box sx={styles.llmDetail}>
            <Typography sx={styles.llmDetailKey}>Training Datasets</Typography>
            <Typography sx={styles.llmDetailValue}>2</Typography>
          </Box>
        </Box>

        <Divider />
        <Box sx={styles.llmDetailsCardFooter}>
          <Box>
            <Typography sx={styles.llmFooterDetialsKey}>
              {llmDetails?.TrainingDataSets["Bias Score"] !== ""
                ? llmDetails?.TrainingDataSets["Bias Score"]
                : "-"}
            </Typography>
            <Typography sx={styles.llmFooterDetialsValue}>
              Bias Score
            </Typography>
          </Box>
          <Box>
            <Typography sx={styles.llmFooterDetialsKey}>
              {llmDetails?.TrainingDataSets["Risk Score"] !== ""
                ? llmDetails?.TrainingDataSets["Risk Score"]
                : "-"}
            </Typography>
            <Typography sx={styles.llmFooterDetialsValue}>
              Toxicity Score
            </Typography>
          </Box>
          <Box>
            <a
              target="_blank"
              href={source === "Google" ? "" : "https://huggingface.co/"}
              title="huggingface.co"
              rel="noreferrer"
            >
              {source === "Google" ? (
                <img
                  width="24px"
                  height="24px"
                  src={GoogleIcon}
                  alt="Google Icon"
                />
              ) : (
                <img
                  width="23px"
                  height="21px"
                  src={HuggingFace}
                  alt="Hug Icon"
                />
              )}
            </a>
            <Typography sx={styles.llmFooterDetialsValue}>Source</Typography>
          </Box>
        </Box>
      </Box>
    </CardWrapper>
  );
};
