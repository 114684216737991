import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import deleteIcon from "../../../assets/img/icons/ico-delete-grey.svg";
import "./../modal.scss";
import "./IssueInstanceModal.scss";
import { formatLine, getIssueIcon } from "../../../utils/styles";

const IssueInstanceModal = ({ handleModal, instances }) => {
  return (
    <div id="issue-instance-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-start align-items-center">
              <span className="header-title">Instances</span>
              <img
                src={deleteIcon}
                className="mr-3 delete-icon"
                onClick={handleModal}
              />
            </div>
            <div className="modal-body mb-2">
              <div className="row py-2 chip-container">
                {instances &&
                  instances.map((tag, i) => (
                    <div className="col col-sm-6 workday-subheading" key={i}>
                      <div className="chip mb-2" title={tag}>
                        <span className="mr-2">
                          {tag
                            ? formatLine(
                                tag.substring(tag.lastIndexOf(":") + 1),
                                36
                              )
                            : null}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueInstanceModal;
