import React, { useEffect, useState } from "react";
import ChecklistCards from "../cards/checklist";
import "./style.scss";
import RiskScore from "../cards/risk-score/index";
import ActiveIcon from "../../assets/img/icons/small/active.svg";

const CheckListComponent = ({}) => {
  return (
    <div className="checklist-container">
      <div className="row border-bottom-2">
        <div className="col-sm-10 d-flex align-items-center">
          <h6 className="text-sky mt-2">RISK REDUCTION PUNCH LIST</h6>
          {/* <p>
            Nibh capio posuere consequat conventio torquent penatibus himenaeos
            vel iusto. Mara transverbero multo imperdiet nullam nam. Abbas
            gilvus dolore sollicitudin ulciscor transverbero praemitto mus elit.
          </p> */}
        </div>
        <div className="col-sm-2 mt-1">
          <RiskScore small={true} />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-8 bg-light-grey">
          <ChecklistCards />
        </div>
        <div className="col-sm-4 bg-grey">
          <div className="flex-space-between">
            <div>
              <h6 className="card-title-danger mt-4">
                <img src={ActiveIcon} />
                ACTIVE ATTACKS
              </h6>
            </div>

            <div>
              <h6 className="card-title-danger mt-4">
                <span> -10</span>Reduction
              </h6>
            </div>
          </div>
          <p>
            {/* Nibh capio posuere consequat conventio torquent penatibus himenaeos
            vel iusto. Mara transverbero multo imperdiet nullam nam. Abbas
            gilvus dolore sollicitudin ulciscor transverbero praemitto mus elit. */}
          </p>

          {/* <ReactFlowChart rowIndex={{ idx: 0, name: "RDS-MYSQL-12" }} /> */}

          <div className="sky-card">
            <span>ACTIVE ATTACK from EC2-Private:</span> The external actor has
            installed an API which is cloning RDS-mySql. The external location
            of the attack is Turkey. You can take an action below to block the
            Attack Path.
          </div>

          <h6 className="text-sky mt-2">SUGGESTED REMEADIATION</h6>

          <p className="title mt-4">Remove Execive Permissions</p>
          <p className="contents">
            {/* Nibh capio posuere consequat conventio torquent penatibus himenaeos
            vel iusto. Mara transverbero multo imperdiet. */}
          </p>
          <div className="btn-container mt-2">
            <button className="btn btn-red mt-3">BLOCK ACCESS</button>
          </div>

          <p className="title mt-4">Remove Execive Permissions</p>
          <p className="contents">
            {/* Nibh capio posuere consequat conventio torquent penatibus himenaeos
            vel iusto. Mara transverbero multo imperdiet. */}
          </p>
          <div className="btn-container">
            <button className="btn btn-red mt-3">BLOCK ACCESS</button>
          </div>

          <p className="title mt-4">Remove Execive Permissions</p>
          <p className="contents">
            {/* Nibh capio posuere consequat conventio torquent penatibus himenaeos
            vel iusto. Mara transverbero multo imperdiet. */}
          </p>
          <div className="btn-container">
            <button className="btn btn-red mt-3">BLOCK ACCESS</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckListComponent;
