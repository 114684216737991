import "./DeleteAccountModal.scss";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import deleteAccountIcon from "../../../assets/img/icons/delete-account-icon.svg";
import popout from "../../../assets/img/icons/popout-white.svg";
import {
  deleteAccountAsync,
  deleteStackAsync,
  verifyDeleteStackAsync,
} from "../../../redux/slices/accountSlice";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import {
  showLoading,
  hideLoading,
} from "../../../redux/slices/globalOperationSlice";
import { AppContext } from "../../../context/appContext";
import { ACCOUNT_TYPES, LOADER_CONFIG } from "../../../utils/Constants";
import Button from "../../common/Button";

// Functional component for Add Account.
const DeleteAccountModal = ({ handleModal, refreshAccounts, account }) => {
  const dispatch = useDispatch();
  const [disableDelete, setDisableDelete] = useState(true);
  const [launchStackClicked, setLaunchStackClicked] = useState(false);
  const { getRiskScoreData } = useContext(AppContext);
  const loading = useSelector((state) => state.globalOperation.loading);
  console.log({ account });
  const verifyAccountTimer = useRef();
  const removeSessionStorage = (accountId) => {
    let issuesAccountFilter = JSON.parse(
      sessionStorage.getItem("issuesAccountFilter")
    );
    let datastoreAccountFilter = JSON.parse(
      sessionStorage.getItem("datastoreAccountFilter")
    );
    if (
      issuesAccountFilter &&
      issuesAccountFilter !== null &&
      issuesAccountFilter.value == accountId
    ) {
      sessionStorage.removeItem("issuesAccountFilter");
    }
    if (
      datastoreAccountFilter &&
      datastoreAccountFilter !== null &&
      datastoreAccountFilter.value == accountId
    ) {
      sessionStorage.removeItem("datastoreAccountFilter");
    }
  };

  const deleteAccount = async () => {
    dispatch(showLoading());
    const resp = await dispatch(deleteAccountAsync(account.id));
    if (resp && resp.payload && !resp.payload.err) {
      refreshAccounts();
      dispatch(hideLoading());
      Toaster(TOASTER_TYPES.SUCCESS, "Account deleted successfully.");
      removeSessionStorage(account.id);
      getRiskScoreData();
    } else {
      dispatch(hideLoading());
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to delete account."
      );
    }
  };

  const deleteStack = async () => {
    dispatch(showLoading());
    const resp = await dispatch(deleteStackAsync({ id: account.id }));
    if (resp && resp.payload?.url && !resp.payload.err) {
      window.open(resp.payload?.url);
      verifyAccountTimer.current = setInterval(() => {
        verifyDeleteStack();
      }, 5000);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload.err?.message || "Failed to launch delete stack."
      );
    }
  };

  const handleLaunchStack = () => {
    deleteStack();
    setLaunchStackClicked(true);
  };

  const verifyDeleteStack = async () => {
    const resp = await dispatch(
      verifyDeleteStackAsync({
        RoleArn: account.awsRoleARN,
      })
    );
    // remove this line before commiting
    if (account.type === ACCOUNT_TYPES.GCP) setDisableDelete(false);
    if (resp && resp.payload) {
      dispatch(hideLoading());
      clearInterval(verifyAccountTimer?.current);
      setDisableDelete(false);
      setLaunchStackClicked(false);
    } else {
      if (verifyAccountTimer && launchStackClicked) {
        dispatch(showLoading());
      }
    }
  };

  const handleCancel = () => {
    clearInterval(verifyAccountTimer?.current);
    handleModal(false);
  };

  useEffect(() => {
    verifyDeleteStack();
    return () => {
      clearInterval(verifyAccountTimer?.current);
    };
  }, []);

  return (
    <div id="delete-account">
      <div
        className="modal fade show"
        id="deleteModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <img src={deleteAccountIcon} className="mr-3" />
                <span>Delete Account</span>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-3"> Account Id:</div>
                  <div className="col-md-8">{account.id}</div>
                </div>
                {account?.type === ACCOUNT_TYPES.AWS && (
                  <>
                    <div className="row mt-3">
                      <div className="col-md-3"> External ID:</div>
                      <div className="col-md-8">{account.externalId}</div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-3"> AWS Role ARN:</div>
                      <div className="col-md-8">{account.awsRoleARN}</div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        Delete stack is mandatory for deleting the account.
                        Please click on launch delete stack button to delete the
                        stack.
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="modal-footer">
                {account?.type === ACCOUNT_TYPES.AWS && (
                  <>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleLaunchStack}
                      disabled={account.status !== "active" ? true : false}
                    >
                      Delete launch stack{" "}
                      <img src={popout} className="popout-img" />
                    </Button>

                    <span className="separator mx-3"></span>
                  </>
                )}

                <Button
                  variant="contained"
                  type="button"
                  disabled={disableDelete}
                  onClick={deleteAccount}
                >
                  Delete account
                </Button>

                <span className="separator mx-3"></span>

                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
