import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import integrationIcon from "../../assets/img/icons/settings/integration.svg";
import { addIntegrationAsync } from "../../redux/slices/integrationSlice";
import { Toaster, TOASTER_TYPES } from "../../utils/toaster";
import "./addTicketModal.scss";
import { getUserId } from "../../utils/SessionHelper";
import { addTicketAsync } from "../../redux/slices/ticketSlice";

// Functional component for Add Integration.
const AddTicketModal = ({ handleModal, refreshIntegrations, data }) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);

  // Formik
  const formik = useFormik({
    initialValues: {
      summary: "",
      description: "",
      issueType: "",
    },
    validationSchema: Yup.object().shape({
      summary: Yup.string().required("Please enter summary."),
      description: Yup.string().required("Please enter description."),
      issueType: Yup.string().required("Please enter issue type."),
    }),
    onSubmit: (values) => {
      console.log("Values: ", values);
      addIntegration({ ...values });
    },
  });

  const handleCancel = () => {
    handleModal();
  };

  const addIntegration = async (values) => {
    setShowLoader(true);
    setConnectionFailed(false);
    const resp = await dispatch(
      addTicketAsync({
        // name: values.integrationName,
        // host: values.host,
        // username: values.userName,
        // password: values.password,

        userId: getUserId(),
        summary: values.summary,
        description: values.description,
        key: "CLOUD",
        issuetype: values.issueType,
        issueId: data.issue_id,
        instanceId: data.instance_id,
      })
    );
    if (resp && resp.payload) {
      Toaster(TOASTER_TYPES.SUCCESS, "Ticket added successfully.");
      setShowLoader(false);
      refreshIntegrations();
    } else {
      Toaster(TOASTER_TYPES.ERROR, "Failed to add ticket.");
      setShowLoader(false);
      setConnectionFailed(true);
    }
  };

  useEffect(() => {}, []);

  return (
    <div id="integration-modal">
      <div
        className="modal fade show"
        id="integrationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="integrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <img src={integrationIcon} className="mr-3" />
                <span>Add Ticket</span>
              </div>
              <div className="modal-body">
                {showLoader && (
                  <img
                    src="static/img/loader-circle.svg"
                    className="modal-loading-img"
                  />
                )}
                <div className="row">
                  <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                    <b className="form-field-name ">Summary</b>
                    <span className="mandatory-icon">*</span>
                  </div>
                  <div className="col col-lg-8">
                    <input
                      type="text"
                      id="summary"
                      placeholder="Summary"
                      name="summary"
                      className="modal-input-style px-2"
                      onChange={(e) => {
                        formik.setFieldValue("summary", e.target.value);
                      }}
                      value={formik.values.summary}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.summary && formik.touched.summary && (
                      <div className="error-message">
                        {formik.errors.summary}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                    <b className="form-field-name ">Description</b>
                    <span className="mandatory-icon">*</span>
                  </div>
                  <div className="col col-lg-8">
                    <textarea
                      id="description"
                      placeholder="Description"
                      name="description"
                      className="modal-input-style modal-input-area px-2"
                      onChange={(e) => {
                        formik.setFieldValue("description", e.target.value);
                      }}
                      value={formik.values.description}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.description &&
                      formik.touched.description && (
                        <div className="error-message">
                          {formik.errors.description}
                        </div>
                      )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                    <b className="form-field-name ">Issue Type</b>
                    <span className="mandatory-icon">*</span>
                  </div>
                  <div className="col col-lg-8">
                    <input
                      type="text"
                      id="issueType"
                      placeholder="Issue Type"
                      name="issueType"
                      maxLength={50}
                      className="modal-input-style px-2"
                      onChange={(e) => {
                        formik.setFieldValue("issueType", e.target.value);
                      }}
                      value={formik.values.issueType}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.issueType && formik.touched.issueType && (
                      <div className="error-message">
                        {formik.errors.issueType}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                    <label>Add Tags</label>
                    <span className="mandatory-icon">*</span>
                  </div>
                  <div className="col col-lg-8">
                    <input
                      type="text"
                      id="recipient"
                      placeholder="Tags"
                      name="recipient"
                      className="modal-input-style px-2"
                      // onKeyDown={handleEmailEnter}
                      onBlur={formik.handleBlur}
                    />
                    {/* {formik.touched.recipient && recepients.length === 0 && (
                      <div className="error-message">
                        {"Please enter recipient."}
                      </div>
                    )} */}
                    {/* <div className="d-flex flex-wrap">
                      {recepients &&
                        recepients.map((recepient, index) => (
                          <div key={index} className="email-chip mr-2 my-1">
                            {" "}
                            {recepient}{" "}
                            <img
                              src={deleteIcon}
                              onClick={() => deleteEmail(index)}
                            />
                          </div>
                        ))}
                    </div> */}
                  </div>
                </div>

                {connectionFailed && (
                  <div className="row mt-2">
                    <div className="col-9 offset-md-3 pl-0 text-danger">
                      Connection failed, please check the details and try again.
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Create Ticket
                </button>
                <span className="separator mx-3"></span>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTicketModal;
