import { getCustomerId } from "../../../utils/SessionHelper";
import {
  listDatastoreStats,
  listIssuesStats,
} from "../../../redux/slices/dashboardSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const RiskScore = ({ small = false, tab }) => {
  const dispatch = useDispatch();
  const [riskScore, setRiskScore] = useState({
    cdRiskScore: 0,
    cdRiskLabel: "LOW",
  });

  const getDatastoreStats = async () => {
    const res = await dispatch(
      listDatastoreStats({
        customerId: getCustomerId(),
      })
    );
    if (
      res &&
      res.payload &&
      res.payload.cdRiskLabel &&
      res.payload.cdRiskScore
    ) {
      setRiskScore(res.payload);
    }
  };

  const getIssuesStats = async () => {
    const res = await dispatch(
      listIssuesStats({
        customerId: getCustomerId(),
      })
    );
    if (
      res &&
      res.payload &&
      res.payload.cdRiskLabel &&
      res.payload.cdRiskScore
    ) {
      setRiskScore(res.payload);
    }
  };

  useEffect(() => {
    if (tab === 1) {
      getDatastoreStats();
    } else {
      getIssuesStats();
    }
  }, [tab]);

  return (
    <div className={`risk-card ${small ? "risk-card-sm" : ""}`}>
      <div
        className={`score score-${riskScore.cdRiskLabel.toLowerCase()} d-flex align-items-center justify-content-center`}
      >
        <h6>{riskScore.cdRiskScore}%</h6>
      </div>
      {small ? (
        ""
      ) : (
        <p className={`score-${riskScore.cdRiskLabel.toLowerCase()}-level`}>
          RISK SCORE {riskScore.cdRiskLabel}
        </p>
      )}
    </div>
  );
};

export default RiskScore;
