import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Input, useTheme } from "@mui/material";
import Box from "./Box";

const Search = ({
  placeholder,
  value,
  onClear,
  onChange,
  onKeyDown,
}: {
  placeholder: string;
  value: string;
  onChange?: (e) => void;
  onClear?: (e) => void;
  onKeyDown?: (e) => void;
}) => {
  const theme = useTheme();

  const styles = {
    container: { width: "250px", position: "relative" },
    input: {
      ml: 1,
      flex: 1,
      width: "100%",
      fontSize: "12px",
      "&.MuiInputBase-root": {
        color: theme.palette.surface70.main,
        marginLeft: 0,
      },
      "&.MuiInput-underline": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
    },
    iconButton: {
      position: "absolute",
      top: 0,
      right: "-8px",
      height: "100%",
    },
    search: {
      color: theme.palette.surface80.main,
      height: "20px",
      width: "20px",
    },
  };

  return (
    <Box sx={styles.container}>
      <Input
        sx={styles.input}
        value={value}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <IconButton type="button" sx={styles.iconButton} aria-label="search">
        {!value ? (
          <SearchIcon sx={styles.search} />
        ) : (
          <CloseIcon sx={styles.search} onClick={onClear} />
        )}
      </IconButton>
    </Box>
  );
};

export default Search;
