import { axiosDenaliPrivate, axiosPrivate } from "../../utils/ApiHandler";

/**
 * Provides the list of all datastore stats.
 * @returns -- list of all datastore stats.
 */
export const fetchDatastoreStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/datastorestats`, params);
};

/**
 * Provides the list of all datastore stats.
 * @returns -- list of all datastore stats.
 */
export const fetchIssueUberScore = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/issues/uberscore/get`, params);
};

/**
 * Provides the list of all issues stats.
 * @returns -- list of all issues stats.
 */
export const fetchIssuesStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/issues/stats`, params);
};

/**
 * Provides the list of all assets stats.
 * @returns -- list of all assets stats.
 */
export const fetchAssetsStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/asset/stats`, params);
};

/**
 * Provides the list of all identities stats.
 * @returns -- list of all identities stats.
 */
export const fetchIdentitiesStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/identitystats`, params);
};
/**
 * Provides data-riskplot result.
 * @returns -- Datariskplot list
 */
export const fetchRiskPlot = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/dashboardplot`, params);
};

/**
 * Provides first account classification and risk complete status.
 * @returns -- Object with the status of classification and risk complete status.
 */
export const fetchFirstAccountStatStatus = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/get`, params);
};

/**
 * @returns -- Object with the status of classification and risk complete status.
 */
export const fetchDashboardStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/stats/inventory`, params);
};

/**
 * Provides the list of all data flows.
 * @returns -- list of all data flows.
 */
export const fetchDataFlows = (params) => {
  return axiosDenaliPrivate.post(`/api/v2/datastores/flows`, params);
};

/**
 * Provides the list of all achievements .
 * @returns -- list of all achievements.
 */
export const fetchAchievements = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/achievements`, params);
};

/**
 * Provides the trends data .
 * @returns -- trends data.
 */
export const fetchTrends = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/trends`, params);
};

/**
 * Provides the list of all data new flows.
 * @returns -- list of all data new flows.
 */
export const fetchDataNewFlows = (params) => {
  return axiosDenaliPrivate.post(`/api/v2/datastores/flows/new`, params);
};

/**
 * manage dashboard view.
 */
export const manageDashboardView = (params) => {
  return axiosPrivate.post(`/user/manageDashboardView`, params);
};

/**
 * fetch datastore regions.
 */
export const fetchDatastoreRegions = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/datastore/regions`, params);
};

/**
 * fetch cloud issues.
 */
export const fetchCloudIssues = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/data/cloudissues`, params);
};

/**
 * fetch policy violations filters.
 */
export const fetchPolicyViolationFilters = (params) => {
  return axiosDenaliPrivate.post(
    `/api/v1/data/policyviolations/predefined`,
    params
  );
};

/**
 * fetch policy violations.
 */
export const fetchPolicyViolations = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/data/policyviolations`, params);
};
