import { useTheme } from "@mui/material";
import Box from "../../../common/Box";
import Typography from "../../../common/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { formatTitle, getFormattedTitle } from "../../../../utils/commonUtils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TextButton from "../../../common/TextButton";
import { useHistory } from "react-router-dom";

const GraphSelectedDataCard = ({ selectedNode, setSelectedNode }) => {
  const theme = useTheme();
  const history = useHistory();
  const redirectionLink = selectedNode?.tooltip_content?.type
    ?.toLocaleLowerCase()
    ?.includes("rds")
    ? "/datastore-details/764eb4c7-05f9-46a3-afb4-643eefb15eea/arn:aws:rds:us-east-1:099873896856:db:employee-details"
    : "/datastore-details/3f672f44-f1ab-4923-a1b3-3d25c327a0fe/arn:aws:s3:::role-data-bucket";
  const styles = {
    cardContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      width: "30%",
      position: "absolute",
      right: 0,
      height: "auto",
      maxHeight: "90%",
      top: 20,
      boxShadow: `0px 0px 10px 0px ${theme.palette.surface0.main}, 0px 2px 3px -2px rgba(9, 12, 31, 0.70)`,
      borderRadius: theme.spacing(0.5),
      overflow: "hidden",
    },
    contentWrapper: {
      overflowX: "hidden",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    cardHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    cardTitle: {
      color: theme.palette.surface80.main,
      fontSiz: "13px",
    },
    cardTitleWithLink: {
      color: theme.palette.primaryBlue.main,
      fontSiz: "13px",
    },
    linkIcon: {
      marginLeft: "4px",
      "&.MuiSvgIcon-root": {
        height: "16px",
        width: "16px",
      },
    },
    propName: {},
    propValue: {},
    rowGroup: {
      height: "100%",
      marginBottom: theme.spacing(2),
    },
    row: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      gridRowGap: theme.spacing(2),
      gridColumnGap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    closeIcon: {
      color: theme.palette.surface80.main,
      height: "20px",
      width: "20px",
      cursor: "pointer",
    },
  };

  const contents = selectedNode?.tooltip_content;
  let popupContents: React.ReactElement[] = [];
  let index = 1;
  for (var key in contents) {
    index++;
    if (contents.hasOwnProperty(key)) {
      var val = contents[key];
      if (val) {
        popupContents.push(
          <Box sx={styles.row}>
            <Typography
              color={theme.palette.surface60.main}
              variant="caption"
              sx={styles.propName}
            >
              {formatTitle(key)}
            </Typography>
            <Typography
              color={theme.palette.surface80.main}
              variant="caption"
              sx={styles.propValue}
            >
              {val}
            </Typography>
          </Box>
        );
      }
    }
  }

  const redirectTo = (link) => history.push(link);

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.contentWrapper}>
        <Box sx={styles.cardHeader}>
          {selectedNode?.tooltip_content?.type ? (
            <TextButton
              endIcon={<OpenInNewIcon fontSize="small" sx={styles?.linkIcon} />}
              onClick={() => redirectTo(redirectionLink)}
              sx={styles.cardTitleWithLink}
            >
              {selectedNode?.name}
            </TextButton>
          ) : (
            <Typography variant="caption" sx={styles.cardTitle}>
              {selectedNode?.name}
            </Typography>
          )}
          <CloseIcon
            sx={styles.closeIcon}
            onClick={() => setSelectedNode(null)}
          />
        </Box>
        <Box sx={styles.rowGroup}>{popupContents}</Box>
      </Box>
    </Box>
  );
};

export default GraphSelectedDataCard;
