import "./CloudEnvIssues.scss";
import React, { useEffect, useState } from "react";
import PieChart from "./PieChart";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerId } from "../../../../utils/SessionHelper";
import { fetchCloudIssuesSlice } from "../../../../redux/slices/dashboardSlice";
import KubernatiesIcon from "../../../../assets/img/v1-icons/Kubernaties.svg";
import AWSEC2 from "../../../../assets/img/v1-icons/aws-ec2.svg";
import ServeslessAWS from "../../../../assets/img/v1-icons/serverless_aws.svg";
import { useHistory } from "react-router-dom";

const CloudEnvIssues = ({ selectedAccount }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const colorCodes = ["#4260FF", "#A955FF", "#8F21FF"];

  const [cloudEnvIssues, setCloudEnvIssues] = useState([]);

  const getCloudEnvIssues = async (selectedAccount) => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };

    let resp = await dispatch(fetchCloudIssuesSlice(payload));
    if (resp && resp.payload && !resp.payload.err) {
      let updatedCloudIssues = [];
      let respCloudIssues = resp.payload;
      respCloudIssues.map((issue) => {
        if (issue.cloudEnvClass === "vulnerable-ec2-compute") {
          issue.color = "#4260FF";
        }
        if (issue.cloudEnvClass === "vulnerable-k8s-cluster") {
          issue.color = "#8F21FF";
        }
        if (issue.cloudEnvClass === "serverless-misconfig") {
          issue.color = "#A955FF";
        }
        issue.src = getCloudIssueIcons(issue.cloudEnvClass);
        updatedCloudIssues.push(issue);
      });
      setCloudEnvIssues(updatedCloudIssues);
    }
  };

  const getTotalIssues = () => {
    let total = 0;
    cloudEnvIssues.map((issue) => {
      total = total + issue.issueCount;
    });
    return total;
  };

  const getCloudIssueIcons = (type) => {
    switch (type) {
      case "vulnerable-ec2-compute":
        return AWSEC2;
        break;
      case "vulnerable-k8s-cluster":
        return KubernatiesIcon;
        break;
      case "serverless-misconfig":
        return ServeslessAWS;
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    getCloudEnvIssues(selectedAccount);
  }, [selectedAccount]);

  const viewIssues = (data) => {
    if (data !== "all") {
      sessionStorage.setItem("source_category", data.title);
      history.push("/vulnerabilities/cloud-env/" + data.cloudEnvClass);
    } else {
      sessionStorage.setItem("source_category", "all");
      history.push("/vulnerabilities/cloud-env/all");
    }
  };

  return (
    <div id="cloud-env-issues">
      <div className="row">
        <div className="col-md-4 col-lg-4 p-0">
          <PieChart cloudEnvIssues={cloudEnvIssues} />
        </div>
        <div className="col-md-8 col-lg-8">
          <div className="title d-flex justify-content-between">
            <div>Cloud Data Risk ({getTotalIssues()})</div>
            {getTotalIssues() > 0 && (
              <div
                className="title-all"
                onClick={() => {
                  viewIssues("all");
                }}
              >
                <u>All</u>
              </div>
            )}
          </div>
          {/* <div className="table-heading mt-4 d-flex justify-content-between">
            <div className="table-heading-title p-1 pl-2">Issue Category</div>
            <div className="table-heading-title p-1 pr-2">
              Impacted Datastores
            </div>
          </div> */}
          <table class="table table-sm">
            <thead className="table-heading">
              <tr>
                <th></th>
                <th className="table-heading-title">Source Category</th>
                <th className="table-heading-title">
                  Impacted&nbsp;<br></br>Datastore
                </th>
              </tr>
            </thead>
            <tbody>
              {cloudEnvIssues &&
                cloudEnvIssues.map((data, index) => {
                  return (
                    <tr>
                      <td>
                        {" "}
                        <div className={`circle-${data.cloudEnvClass}`}></div>
                      </td>

                      <td
                        className="cloud-env-title"
                        onClick={() => {
                          viewIssues(data);
                        }}
                      >
                        {data.title}
                        <span className="issue-count">
                          {" "}
                          ({data.issueCount})
                        </span>
                      </td>
                      <td className="sensitivity-count ">{data.dsCount}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CloudEnvIssues;
