import { useTheme } from "@mui/material";
import Bar from "../common/Bar";
import Box from "../common/Box";
import Loader from "../common/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import NoSearchIcon from "../../assets/img/No_Search_illustration.svg";
import Tooltip from "../common/Tooltip";
import { getNumberAbbreviation } from "../../utils/commonUtils";

export interface Props {
  barColor: string;
  dataDistribution: Array<{
    dataType: string;
    numberOfRecords: number;
    totalValue: number;
    impacted: number;
  }>;
  isFetchingData: boolean;
  dataView: boolean;
  tableCols: any;
}

const DataStoreTable = ({
  barColor,
  dataDistribution,
  isFetchingData,
  dataView,
  tableCols,
}: Props) => {
  const theme = useTheme();
  const styles = {
    table: {
      height: "100%",
    },
    tableImpactValue: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    tableBody: {
      height: "100%",
      overflow: "auto",
      width: "100%",
    },
    tableBodyRow: {},
    barCell: {
      width: "35%",
    },
    noDataContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    noDataMsg: {
      fontSize: "13px",
      color: theme.palette.surface60.main,
    },
    donutTooltip: {
      tooltip: {
        sx: {
          bgcolor: theme.palette.surface80.main,
          "& .MuiTooltip-arrow": {
            color: theme.palette.surface80.main,
          },
        },
      },
    },
  };

  return (
    <TableContainer>
      <Table sx={styles.table} aria-label="simple table">
        <TableHead>
          <TableRow>{tableCols?.map((item) => item.title)}</TableRow>
        </TableHead>
        {isFetchingData ? (
          <TableRow>
            <TableCell colSpan={3}>
              <Loader height="150px" />
            </TableCell>
          </TableRow>
        ) : (
          <TableBody sx={styles.tableBody}>
            {dataDistribution?.length > 0 ? (
              dataDistribution?.map((tableRow, index) => (
                <TableRow key={index}>
                  {tableCols.map((col, index) => {
                    return col?.render ? (
                      col.render(tableRow)
                    ) : (
                      <Typography variant="caption">
                        {tableRow[col?.field || 0]}
                      </Typography>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={3}>
                <Box sx={styles.noDataContainer}>
                  <img src={NoSearchIcon} alt="no data" />
                  <Typography sx={styles.noDataMsg}>
                    No records found
                  </Typography>
                </Box>
              </TableCell>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default DataStoreTable;
