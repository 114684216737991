import { useEffect } from "react";
import LoginPageLogo from "../../assets/img/login-page-logo-dark.svg";

const VerifyEmail = () => {
  useEffect(() => {
    document.title = "Cloud Defense - Registration Complete";
  }, []);
  return (
    <>
      <section className="sign-in-page">
        <div className="container p-0">
          <div className="logo-container">
            <div>
              <img src={LoginPageLogo} alt="" className="login-from-logo" />
            </div>
            <div>
              <h3 className="font-weight-bold text-white">Cloud Defense</h3>
            </div>
          </div>
        </div>
        <h1 className="text-center text-primary mb-5">
          Thanks for your request. <br />
          We will notify you over email once your account is ready to use.
        </h1>
      </section>
    </>
  );
};

export default VerifyEmail;
