import { useTheme } from "@mui/material";
import MUIChip, { ChipProps } from "@mui/material/Chip";

const Chip = (props: ChipProps) => {
  const theme = useTheme();
  const styles = {
    chip: {
      height: "26px",
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightMedium,
      "&.MuiChip-colorPrimary": {
        borderColor: theme.palette.primaryBlue.main,
        backgroundColor:
          props.variant === "filled" && theme.palette.primaryBlue.main,
        color: props.variant === "outlined" && theme.palette.primaryBlue.main,
      },
      ".MuiChip-deleteIcon": {
        color: props.variant === "outlined" && theme.palette.primaryBlue.main,
      },
    },
  };
  return (
    <MUIChip
      {...props}
      variant={props.variant}
      sx={{ ...styles.chip, ...props.sx }}
    />
  );
};

export default Chip;
