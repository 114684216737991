import React from "react";
import "../../datastore/datastore.scss";
import { getScoreColor } from "../../../utils/styles";

const Table = ({ currentPosts = [], sortData }) => {
  return (
    <div className="row">
      <div className="col col-xl-12">
        <div className="border-0 card table-card">
          <div className="table-responsive">
            <table className="mb-0 pb-3 table table-hover px-3">
              <thead className="vuls-row">
                <tr className="head vuls-row">
                  <th scope="col" style={{ width: "80px" }}>
                    Risk
                    <i
                      onClick={() => sortData("cdDatastoreRiskScore")}
                      className="las la-sort"
                    ></i>
                  </th>
                  <th scope="col" style={{ width: "200px" }}>
                    Name{" "}
                    <i
                      onClick={() => sortData("name")}
                      className="las la-sort"
                    ></i>
                  </th>
                  <th scope="col">
                    Resource Id{" "}
                    <i
                      onClick={() => sortData("metadata.cloudAccountId")}
                      className="las la-sort"
                    ></i>
                  </th>
                  <th scope="col">
                    Account Id{" "}
                    <i
                      onClick={() => sortData("type")}
                      className="las la-sort"
                    ></i>
                  </th>
                  <th scope="col">
                    Region{" "}
                    <i
                      onClick={() => sortData("metadata.createdAt")}
                      className="las la-sort"
                    ></i>
                  </th>
                  <th scope="col">
                    Resource Type{" "}
                    <i
                      onClick={() => sortData("size")}
                      className="las la-sort"
                    ></i>
                  </th>
                  <th scope="col">
                    Provider{" "}
                    <i
                      onClick={() => sortData("mountLocation")}
                      className="las la-sort"
                    ></i>
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentPosts.map(function (item, idx) {
                  return (
                    <tr key={idx} className="vuls-row">
                      <td>
                        <div
                          className={`${getScoreColor(item.cdIssueRiskScore)}`}
                        ></div>
                      </td>
                      <td className="data-store-name">
                        <div className="wrap-with-ellipsis" title={item?.name}>
                          {item?.name}
                        </div>
                      </td>

                      <td className="data-store-name">
                        <div className="wrap-with-ellipsis" title={item?.id}>
                          {item?.id}
                        </div>
                      </td>

                      <td className="data-store-name">
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.cloudAccountId}
                        >
                          {item?.cloudAccountId}
                        </div>
                      </td>

                      <td className="data-store-name">
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.location}
                        >
                          {item?.location}
                        </div>
                      </td>

                      <td className="data-store-name">
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.assetType}
                        >
                          {item?.assetType}
                        </div>
                      </td>

                      <td className="data-store-name">
                        <div className="wrap-with-ellipsis" title={"AWS"}>
                          AWS
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
