import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { listSensitiveData } from "../../../redux/slices/dataStoreSlice.js";
import LlmIcon from "../../../assets/img/v1-icons/llmIcon.svg";
import { getCustomerId } from "../../../utils/SessionHelper";

const SensitiveData = ({ selectedAccount, setSensitiveFilter, region }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const shadowData = [
    {
      count: 39234,
      llms: 4,
      tag: "PII-SSN",
      tagType: "scanner",
    },
    {
      count: 63313,
      llms: 4,
      tag: "PII-CC",
      tagType: "scanner",
    },
    {
      count: 143644,
      llms: 4,
      tag: "PII-DL",
      tagType: "scanner",
    },
  ];

  const getDatastoreStats = async () => {
    // const resp = await dispatch(
    //   listSensitiveData({
    //     customerId: getCustomerId(),
    //     cloudAccountId:
    //       selectedAccount.value === "all" ? null : selectedAccount.value,
    //     region: region ? region : undefined,
    //   })
    // );
    // if (resp && resp.payload) {
    //   //   setData(resp.payload);
    // }
    setData(shadowData);
  };

  useEffect(() => {
    getDatastoreStats();
  }, [selectedAccount]);

  const tags = {
    "PII-SSN": "Social Security Numbers",
    "PII-CC": "Credit Cards",
    "PII-MN": "Mobile Numbers",
    "PII-PHONE": "Mobile Numbers",
    "PII-ADDRESS": "Street Addresses",
    "PII-IP": "IP Addresses",
    "PII-EMAILS": "Emails",
    "PII-EMAIL": "Email",
    "PII-DL": "Drivers Licenses",
    "PII-NAMES": "Names",
    "PII-AADHAR": "Aadhar Card Numbers",
    "PII-EUA": "EU Street Addresses",
    "PII-NIH": "NIH Numbers",
  };

  return (
    <div className="llm-sensitive-data-container">
      <h6>Sensitive Data </h6>
      <div className="sensitive-table">
        <div className="table-responsive">
          <table className={`mb-0  table table-border-less table-hover`}>
            <thead>
              <tr className="vuls-row">
                <th scope="col">Tag</th>
                <th scope="col">Count</th>
                <th scope="col" className="table-header">
                  Llms
                </th>
              </tr>
            </thead>

            {data && data.length ? (
              <tbody>
                {data.map(function (item, idx) {
                  return (
                    <tr
                      key={idx}
                      className="datastores-row"
                      onClick={() => setSensitiveFilter(item.tag)}
                    >
                      <td className="tag">
                        <div className="wrap-with-ellipsis">
                          {tags[item.tag]}
                        </div>
                      </td>
                      <td className="count">{item.count}</td>
                      <td className="datastores">
                        <span className="mr-2">{item.llms}</span>
                        <img src={LlmIcon} width="25" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              ""
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default SensitiveData;
