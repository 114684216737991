import React, { useEffect, useRef, useState } from "react";

import "../../datastore/datastore.scss";
import { getScoreColor } from "../../../utils/styles";
import "./assets.scss";
import AffectedDatastoresAndIssuesModal from "../../modal/affected-datastore-and-issue-modal/AffectedDatastoresAndIssuesModal";

const Table = ({ currentPosts = [], sortData }) => {
  const [isDatastoreIssuesModal, setIsDatastoreIssuesModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});

  const closeModal = () => {
    setSelectedAsset({});
    setIsDatastoreIssuesModal(false);
  };
  return (
    <div className="row">
      <div className="col col-xl-12">
        <div className="border-0 card table-card">
          <div className="table-responsive">
            <table className="mb-0 pb-3 table table-border-less table-hover px-3">
              <thead className="vuls-row">
                <tr className="head vuls-row">
                  <th
                    scope="col"
                    style={{ width: "80px" }}
                    onClick={() => sortData("cdAssetRiskScore")}
                  >
                    Risk
                    <i className="las la-sort"></i>
                  </th>
                  <th
                    scope="col"
                    style={{ width: "200px" }}
                    onClick={() => sortData("name.keyword")}
                  >
                    Name <i className="las la-sort"></i>
                  </th>
                  <th scope="col" onClick={() => sortData("id.keyword")}>
                    Resource Id <i className="las la-sort"></i>
                  </th>
                  <th
                    scope="col"
                    onClick={() => sortData("metadata.cloudAccountId.keyword")}
                  >
                    Account Id <i className="las la-sort"></i>
                  </th>
                  <th scope="col" onClick={() => sortData("location.keyword")}>
                    Region <i className="las la-sort"></i>
                  </th>
                  <th scope="col" onClick={() => sortData("assetType.keyword")}>
                    Resource Type <i className="las la-sort"></i>
                  </th>
                  <th scope="col">
                    Provider{" "}
                    <i
                      // onClick={() => sortData("location.keyword")}
                      className="las la-sort"
                    ></i>
                  </th>
                </tr>
              </thead>

              <tbody>
                {isDatastoreIssuesModal ? (
                  <AffectedDatastoresAndIssuesModal
                    handleModal={() => {
                      closeModal();
                    }}
                    assetIdValue={
                      selectedAsset.id
                        ? selectedAsset.id
                        : selectedAsset.asset_id
                    }
                    isSecurityIssueVisisble={true}
                    node={selectedAsset}
                    isAttackPath={true}
                    page="asset"
                  />
                ) : (
                  ""
                )}
                {currentPosts.map(function (item, idx) {
                  return (
                    <tr
                      className="vuls-row"
                      key={idx}
                      onClick={(e) => {
                        setIsDatastoreIssuesModal(true);
                        setSelectedAsset({
                          name: item.name,
                          is_primary: true,
                          asset_id: item.id ? item.id : item.asset_id,
                          tooltip_content: {
                            asset_type: item.assetType,
                            cloud_account_id: item.cloudAccountId,
                            location: item.location,
                            name: item.name
                              ? item.name.substring(
                                  item.name.lastIndexOf(":") + 1
                                )
                              : null,
                            asset_id: item.asset_id,
                          },
                        });
                      }}
                    >
                      <td>
                        <div
                          className={`${getScoreColor(item.cdAssetRiskScore)}`}
                        ></div>
                      </td>
                      <td>
                        <div
                          className="wrap-with-ellipsis"
                          title={item.name ? item.name : item.assetType}
                        >
                          {item.name ? item.name : item.assetType}
                        </div>
                      </td>

                      <td className="data-store-name">
                        <div
                          className="wrap-with-ellipsis"
                          title={item.id ? item.id : item.asset_id}
                        >
                          {item.id ? item.id : item.asset_id}
                        </div>
                      </td>

                      <td>
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.cloudAccountId}
                        >
                          {item?.cloudAccountId}
                        </div>
                      </td>

                      <td>
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.location}
                        >
                          {item?.location}
                        </div>
                      </td>

                      <td>
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.assetType}
                        >
                          {item?.assetType}
                        </div>
                      </td>

                      <td>
                        <div className="wrap-with-ellipsis" title={"AWS"}>
                          AWS
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
