import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import { SyntheticEvent, useEffect, useState } from "react";
import { Tab, TabPanel, Tabs } from "../../components/common/Tabs";
import Divider from "../../components/common/Divider";
import TextButton from "../../components/common/TextButton";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/common/Table";
import { policyTableData, topicsTableData, entityTableData } from "./mockData";
import Button from "../../components/common/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ConstructionIcon from "@mui/icons-material/Construction";
import { AddTopic } from "./AddTopic";
import { AddEntity } from "./AddEntity";
import { AddPolicyPage } from "./AddPolicyPage";

type TableAlign =
  | "left"
  | "center"
  | "right"
  | "justify"
  | "inherit"
  | undefined;

export const PolicyPage = () => {
  const [tabValue, setTabValue] = useState("0");
  const [isAddPolicy, setIsAddPolicy] = useState(false);
  const [isOpenTopic, setIsOpenTopic] = useState(false);
  const [isOpenEntity, setIsOpenEntity] = useState(false);
  const [policyData, setPolicyData] = useState<any>([]);
  const [topicData, setTopicData] = useState<any>([]);
  const [entityData, setEntityData] = useState<any>([]);

  const theme = useTheme();
  const styles = {
    main: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    name: {
      fontSize: "13px",
      lineheight: "24px",
    },
    lastOccur: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    tableContainer: {
      maxHeight: "100%",
      minHeight: "340px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: theme.palette.surface10.main,
      paddingY: theme.spacing(1.5),
    },
    tableHead: {
      "&.MuiTableCell-head": {
        paddingY: theme.spacing(1.25),
        zIndex: 0,
      },
    },
    tableCell: {
      fontSize: "13px",
      padding: theme.spacing(2),
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      verticalAlign: "middle",
    },
    tableCellLast: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      gap: theme.spacing(1.5),
    },
    tableCellAction: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.26, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    deleteIcon: {
      color: theme.palette.primaryBlue.main,
      width: "18px",
      cursor: "pointer",
      ":hover": {
        color: theme.palette.secondaryBlue.main,
      },
    },
    mainContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3.5),
    },
    tabPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  };

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) =>
    setTabValue(newValue.toString());

  const handleAddDialog = (newValue) => {
    switch (Number(newValue)) {
      case 0:
        setIsAddPolicy(true);
        break;
      case 1:
        setIsOpenTopic(true);
        break;
      case 2:
        setIsOpenEntity(true);
        break;
      default:
        break;
    }
  };

  const policyTableCols = [
    {
      title: "Name",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.name}</Typography>
      ),
    },
    {
      title: "Policy Type",
      field: "policyType",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.type}</Typography>
      ),
    },
    {
      title: "Added on",
      field: "createdOn",
      align: "",
      render: (row: any) => {
        let date;
        if (typeof row?.createdOn === "object") {
          date = row?.createdOn.toISOString();
        } else {
          date = new Date(row?.createdOn);
        }
        let dateString = [
          date.getFullYear(),
          ("0" + (date.getMonth() + 1)).slice(-2),
          ("0" + date.getDate()).slice(-2),
        ].join("-");

        const hoursAndMinutes =
          date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

        return (
          <Typography sx={styles.name}>
            {`${dateString} | ${hoursAndMinutes}`}
          </Typography>
        );
      },
    },
    {
      title: "Owner",
      field: "owner",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.owner}</Typography>
      ),
    },
    {
      title: "",
      field: "actions",
      align: "center",
      render: (row: any) => {
        return (
          <Box sx={styles.tableCellLast}>
            <Box>
              <TextButton
                startIcon={<ConstructionIcon />}
                sx={styles.tableCellAction}
              >
                Modify Poilcy
              </TextButton>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            <DeleteIcon sx={styles.deleteIcon} />
          </Box>
        );
      },
    },
  ];

  const topicsCols = [
    {
      title: "Topic",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.topic}</Typography>
      ),
    },
    {
      title: "Description",
      field: "policyType",
      align: "",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.description}</Typography>
      ),
    },
    {
      title: "Added On",
      field: "createdOn",
      align: "",
      render: (row: any) => {
        let date;
        if (typeof row?.createdOn === "object") {
          date = row?.createdOn.toISOString();
        } else {
          date = new Date(row?.createdOn);
        }
        let dateString = [
          date.getFullYear(),
          ("0" + (date.getMonth() + 1)).slice(-2),
          ("0" + date.getDate()).slice(-2),
        ].join("-");

        const hoursAndMinutes =
          date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

        return (
          <Typography sx={styles.name}>
            {" "}
            {`${dateString} | ${hoursAndMinutes}`}
          </Typography>
        );
      },
    },
    {
      title: "",
      field: "actions",
      align: "center",
      render: (row: any) => {
        return (
          <Box sx={styles.tableCellLast}>
            <Box>
              <TextButton
                startIcon={<ConstructionIcon />}
                sx={styles.tableCellAction}
              >
                Modify Topics
              </TextButton>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            <DeleteIcon sx={styles.deleteIcon} />
          </Box>
        );
      },
    },
  ];

  const entityCols = [
    {
      title: "Entities",
      render: (row: any) => (
        <Typography sx={styles.name}>
          {row?.entity || row?.customEntity[0]}
        </Typography>
      ),
    },
    {
      title: "Added On",
      field: "createdOn",
      align: "",
      render: (row: any) => {
        let date;
        if (typeof row?.createdOn === "object") {
          date = row?.createdOn.toISOString();
        } else {
          date = new Date(row?.createdOn);
        }
        let dateString = [
          date.getFullYear(),
          ("0" + (date.getMonth() + 1)).slice(-2),
          ("0" + date.getDate()).slice(-2),
        ].join("-");

        const hoursAndMinutes =
          date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

        return (
          <Typography sx={styles.name}>
            {`${dateString} | ${hoursAndMinutes}`}
          </Typography>
        );
      },
    },
    {
      title: "",
      field: "actions",
      align: "center",
      render: (row: any) => {
        return (
          <Box sx={styles.tableCellLast}>
            <Box>
              <TextButton
                startIcon={<ConstructionIcon />}
                sx={styles.tableCellAction}
              >
                Modify Entity
              </TextButton>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            <DeleteIcon sx={styles.deleteIcon} />
          </Box>
        );
      },
    },
  ];

  const tabData = [
    {
      id: "0",
      value: "policy",
      label: "Policy",
      tableCol: policyTableCols,
      tableValue: policyData,
    },
    {
      id: "1",
      value: "topics",
      label: "Topic",
      tableCol: topicsCols,
      tableValue: topicData,
    },
    {
      id: "2",
      value: "entities",
      label: "Entity",
      tableCol: entityCols,
      tableValue: entityData,
    },
  ];

  useEffect(() => {
    setPolicyData(policyTableData);
    setTopicData(topicsTableData);
    setEntityData(entityTableData);
  }, []);

  useEffect(() => {
    const policyData: any = localStorage.getItem("policyTableData");
    const parsedData = JSON.parse(policyData);
    if (parsedData?.length > 0) {
      setPolicyData([...policyTableData, ...parsedData]);
    }
  }, [localStorage.getItem("policyTableData")]);

  useEffect(() => {
    const topicData: any = localStorage.getItem("topicsTableData");
    const parsedData = JSON.parse(topicData);
    if (parsedData?.length > 0) {
      setTopicData([...topicsTableData, ...parsedData]);
    }
  }, [localStorage.getItem("topicsTableData")]);

  useEffect(() => {
    const entityData: any = localStorage.getItem("entityTableData");
    const parsedData = JSON.parse(entityData);
    if (parsedData?.length > 0) {
      setEntityData([...entityTableData, ...parsedData]);
    }
  }, [localStorage.getItem("entityTableData")]);

  const getButtonText = () => {
    switch (Number(tabValue)) {
      case 0:
        return "Policy";
      case 1:
        return "Topic";
      case 2:
        return "Entity";
      default:
        return "Policy";
    }
  };

  return (
    <>
      <Box sx={styles.main}>
        <Typography color={theme.palette.surface100.main} variant="body2">
          Policy Settings
        </Typography>
        <Box sx={styles.mainContent}>
          <Box sx={styles.tabPanel}>
            <Tabs value={Number(tabValue)} onChange={handleTabChange}>
              {tabData?.map((item) => (
                <Tab label={item?.label} />
              ))}
            </Tabs>
            <Button
              variant="contained"
              onClick={() => handleAddDialog(tabValue)}
            >
              Add {getButtonText()}
            </Button>
          </Box>

          {tabData?.map((item) => (
            <TabPanel value={tabValue} index={item?.id}>
              <TableContainer sx={styles.tableContainer}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {item?.tableCol.map((col, index) => (
                        <TableCell
                          key={index}
                          sx={styles.tableHead}
                          align={
                            col?.align ? (col.align as TableAlign) : "left"
                          }
                        >
                          {col.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {item?.tableValue?.map((row: any, index: number) => (
                      <TableRow key={index}>
                        {item?.tableCol.map((col, index) => {
                          return (
                            <TableCell
                              key={index}
                              component="td"
                              scope="row"
                              sx={styles.tableCell}
                              align={
                                col?.align
                                  ? (col.align as
                                      | "left"
                                      | "center"
                                      | "right"
                                      | "justify"
                                      | "inherit"
                                      | undefined)
                                  : "left"
                              }
                            >
                              {col?.render ? (
                                col.render(row)
                              ) : (
                                <Typography variant="caption">
                                  {row[col?.field || 0]}
                                </Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          ))}
        </Box>
      </Box>
      <AddPolicyPage
        isOpen={isAddPolicy}
        handleClose={() => setIsAddPolicy(false)}
      />
      <AddTopic
        isOpen={isOpenTopic}
        handleClose={() => setIsOpenTopic(false)}
      />
      <AddEntity
        isOpen={isOpenEntity}
        handleClose={() => setIsOpenEntity(false)}
      />
    </>
  );
};
