import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { listSensitiveData } from "../../../redux/slices/dataStoreSlice.js";
import LlmIcon from "../../../assets/img/v1-icons/llmIcon.svg";
import { getCustomerId } from "../../../utils/SessionHelper";
import { trainingDataList } from "../../../components/shadow-llm/training_data";

const SensitiveData = ({ selectedAccount, setSensitiveFilter, region }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const getDatastoreStats = async () => {
    // const resp = await dispatch(
    //   listSensitiveData({
    //     customerId: getCustomerId(),
    //     cloudAccountId:
    //       selectedAccount.value === "all" ? null : selectedAccount.value,
    //     region: region ? region : undefined,
    //   })
    // );
    // if (resp && resp.payload) {
    //     setData(resp.payload);
    // }
    setData(trainingDataList);
  };

  useEffect(() => {
    getDatastoreStats();
  }, [selectedAccount]);

  return (
    <div className="llm-sensitive-data-container">
      <h6>Most Used Datastores </h6>
      <div className="sensitive-table">
        <div className="table-responsive">
          <table className={`mb-0  table table-border-less table-hover`}>
            <thead>
              <tr className="vuls-row">
                <th scope="col">Datastore</th>
                <th scope="col">Account</th>
                <th scope="col" className="table-header">
                  Llm
                </th>
              </tr>
            </thead>

            {data && data.length ? (
              <tbody>
                {data.map(function (item, idx) {
                  return (
                    <tr
                      key={idx}
                      className="datastores-row"
                      onClick={() => setSensitiveFilter(item.tag)}
                    >
                      <td className="tag">
                        <div
                          className="wrap-with-ellipsis"
                          title={item.Location}
                        >
                          {item.FileName}
                        </div>
                      </td>
                      <td className="count">{item.account}</td>
                      <td className="datastores">
                        <span className="mr-2">{item.count}</span>
                        <img src={LlmIcon} width="25" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              ""
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default SensitiveData;
