import React from "react";
import BottomPanelComponent from "./BottomPanelComponent";

const BottomPanelContainer = ({
  RefreshChart,
  createTicket,
  setCreateTicket,
  setSelectedTab,
  setDatastoreSources,
  attackPaths,
  setIsDatastoreIssuesModal,
  selectedBar,
  datastoreSources,
  selectedTab,
  setSelectedNode,
  isUSOnlyAccess,
  isPolicyDetails,
  policyDetails,
}) => {
  return (
    <BottomPanelComponent
      isPolicyDetails={isPolicyDetails}
      policyDetails={policyDetails}
      RefreshChart={RefreshChart}
      createTicket={createTicket}
      setCreateTicket={setCreateTicket}
      attackPaths={attackPaths}
      setSelectedTab={setSelectedTab}
      selectedBar={selectedBar}
      setDatastoreSources={setDatastoreSources}
      datastoreSources={datastoreSources}
      selectedTab={selectedTab}
      setIsDatastoreIssuesModal={setIsDatastoreIssuesModal}
      setSelectedNode={setSelectedNode}
      isUSOnlyAccess={isUSOnlyAccess}
    />
  );
};

export default BottomPanelContainer;
