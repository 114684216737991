import BlockUi from "react-block-ui";
import Table from "./table";
import Cards from "./cards";
import { LOADER_CONFIG } from "../../../utils/Constants";
import { Loader } from "react-loaders";
import { useEffect, useState } from "react";
import NoDataContainer from "../../cards/no-data";
import Pagination from "../../pagination/Pagination";
import {
  getCustomerId,
  getSelectedDatastoreAccount,
} from "../../../utils/SessionHelper";
import { getAssetsApi, getAssetStatsApi } from "../../../apis";
import { addIndex } from "../../../utils/dataHandler";

const Identity = ({}) => {
  const defaultAccount = getSelectedDatastoreAccount() || {
    value: "all",
    label: "All",
  };
  const limit = 10;
  const page_ = sessionStorage.getItem("page");

  const [page, setPage] = useState(page_ ? parseInt(page_) : 1);
  const [inputText, setInputText] = useState("");
  const [currentPosts, setCurrentPosts] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount);
  const [totalDatastoresCount, setTotalDatastoresCount] = useState(0);
  const [search, setSearch] = useState(null);
  const [ordering, setOrdering] = useState("cdDatastoreRiskScore");

  const getAssets = async () => {
    setLoading(true);
    const cloudAccountId =
      selectedAccount.value === "all" ? null : selectedAccount.value;

    let payload = {
      customerId: getCustomerId(),
      limit: limit,
      page: page,
      cloudAccountId: cloudAccountId,
      ordering: sortField ? sortField : null,
      severity:
        !severity || severity.includes("TOTAL DATA STORES")
          ? null
          : severity.replaceAll(" ", "_"),
    };
    let res = await getAssetsApi(payload);
    if (res.err) {
      setLoading(false);
      return;
    }

    if (!res.data) return;
    setTotalDatastoresCount(res.count);
    res = addIndex(res.data);
    setCurrentPosts(res);
    setLoading(false);
  };

  const sortData = (field) => {
    setSortField(field);
    setPage(1);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedAccount]);

  useEffect(() => {
    getAssets();
  }, [page, selectedAccount, sortField, severity]);

  useEffect(() => {
    sessionStorage.setItem("page", page);
  }, [page]);

  const makeSerch = (ev) => {
    ev.preventDefault();
    getAssets();
  };

  return (
    <div id="datastores">
      <Cards
        filterIssues={(type) => setSeverity(type)}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        inputText={inputText}
        setInputText={setInputText}
        identities={true}
      />
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        <div id="datastores">
          <div className="table-main">
            <div class="row ">
              <div class="col col-xl-4 align-self-start"></div>

              <div class="col col-xl-8 align-self-end">
                <div class="p-2 text-right">
                  <Pagination
                    page={page}
                    setPage={(val) => setPage(val)}
                    limit={limit}
                    totalCount={totalDatastoresCount}
                  />
                </div>
              </div>
            </div>

            {currentPosts.length ? (
              <Table
                severity={severity}
                currentPosts={currentPosts}
                // sortData={sortData}
              />
            ) : !loading ? (
              <NoDataContainer message={"No Users Available"} />
            ) : (
              ""
            )}
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default Identity;
