import "./report.scss";
import LoginPageLogo from "../../assets/img/login-page-logo-dark.svg";

const ReportDoc = () => {
  return (
    <div className="report-page">
      <div>
        <center>
          <div className="logo-container mb-5">
            <div>
              <img src={LoginPageLogo} alt="" className="login-from-logo" />
            </div>
            <div>
              <h3 className="font-weight-bold text-white">Cloud Defense</h3>
            </div>
          </div>
        </center>
        <h1 className="font-weight-bold text-white text-center">
          Cloud Data Risk Report
        </h1>
        <h5 className="font-weight-bold text-center">Date : Jan 31,2023</h5>
      </div>
    </div>
  );
};

export default ReportDoc;
