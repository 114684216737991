import React, { useState } from "react";
import closeIconGray from "../../../assets/img/icons/close-icon-grey.svg";

import "./../modal.scss";
import "./SensitiveDataModal.scss";

const SensitiveDataModal = ({
  handleModal,
  datastoreDescriptions,
  sensitiveScore,
  type = "datastore",
}) => {
  const [loader, setLoader] = useState(false);

  return (
    <div id="sensitive-data-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            {loader && (
              <img
                src="/static/img/loader-circle.svg"
                className="modal-loading-img mt-2"
              ></img>
            )}
            <div className="modal-header d-flex justify-content-between align-items-center">
              <span className="header-title">Sensitive Data</span>
              <img
                className="close-icon cursor-pointer"
                src={closeIconGray}
                title="Close"
                onClick={handleModal}
                width="25px"
              />
            </div>
            <div className="modal-body mb-2">
              {" "}
              <div className="sensitive-data-block ">
                <div className="row mt-1">
                  <div className="col col-sm-12 sensitive-data-heading">
                    SENSITIVE DATA
                    {datastoreDescriptions &&
                    datastoreDescriptions.length &&
                    type === "datastore" ? (
                      <span>:&nbsp;(score - {sensitiveScore})</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row mb-2">
                  {datastoreDescriptions && datastoreDescriptions.length ? (
                    <div className="col col-sm-12 sensitive-data-subheading">
                      The following Sensitive Data Types have been detected
                      within the Data Store.
                    </div>
                  ) : (
                    <div className="col col-sm-12 sensitive-data-subheading">
                      Sensitive datatypes have not been discovered by automated
                      scanner
                    </div>
                  )}
                </div>

                <div class={"table-responsive"}>
                  <table class={"table table-border-less table-hover "}>
                    {type === "policyViolation" ? (
                      <tbody>
                        {datastoreDescriptions.length > 0 &&
                          datastoreDescriptions.map(function (desc, idx) {
                            return (
                              <tr key={idx} className="vuls-row">
                                <td>
                                  <img src="/static/media/redCheck.0615bab8.svg" />
                                </td>
                                <td>
                                  {desc?.tag.replaceAll("_", " ").toUpperCase()}
                                </td>

                                <td className="text-danger font-weight-bold">
                                  {desc?.records}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tbody>
                        {datastoreDescriptions.length > 0 &&
                          datastoreDescriptions.map(function (desc, idx) {
                            return (
                              <tr key={idx} className="vuls-row">
                                <td>
                                  <img src="/static/media/redCheck.0615bab8.svg" />
                                </td>
                                <td>PII - {desc.PII}</td>
                                <td>{desc.Automated}</td>
                                <td>
                                  {desc.sensitiveDataSamples.length ? (
                                    <div className="col col-sm-6 p-0 sensitive-data-title font-weight-normal text-nowrap overflow-hidden font-color-glaucous">
                                      {desc.sensitiveDataSamples.map(
                                        (sample, index) => (
                                          <span key={index}>{`${sample} ${
                                            desc.sensitiveDataSamples.length -
                                              1 ===
                                            index
                                              ? ""
                                              : ", "
                                          }`}</span>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="text-danger font-weight-bold">
                                  {desc.count}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensitiveDataModal;
