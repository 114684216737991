import { useTheme } from '@mui/material';
import Box from '../common/Box';
import Drawer from '../common/Drawer';
import Typography from '../common/Typography';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';

const InsightsDrawer = ({ open, anchor, setOpenInsightsDrawer }) => {
  const theme = useTheme();
  const styles = {
    drawerContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    drawerHeader: {},
    closeIcon: {
      color: theme.palette.surface80.main,
      cursor: 'pointer',
      height: '20px',
    },
    drawerBody: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const closeDrawer = () => {
    setOpenInsightsDrawer(false);
  };

  return (
    <Drawer
      open={open}
      anchor={anchor}
      width="450px"
      sx={{ position: 'relative', height: '100vh', overflow: 'auto' }}
      onClose={closeDrawer}
    >
      <Box sx={styles.drawerContent}>
        <Box sx={styles.drawerHeader}>
          <CloseRoundedIcon sx={styles.closeIcon} onClick={closeDrawer} />
        </Box>
        <Box sx={styles.drawerBody}>
          <HourglassBottomOutlinedIcon />
          <Typography variant="body2">Coming Soon</Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default InsightsDrawer;
