import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import "./ImpactedDatastores.scss";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../../utils/Constants";

am4core.addLicense("ch-custom-attribution");

const ScatterPlot = ({
  vunerability,
  hoveredDatastoreIds,
  data,
  selectedBar,
  bars,
  filterBars,
  loading,
}) => {
  const loadChart = (barId = null) => {
    // Create chart instance

    let chart = am4core.create("impacteddatastores", am4charts.XYChart);

    let valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxisX.renderer.ticks.template.disabled = true;
    valueAxisX.renderer.axisFills.template.disabled = true;

    valueAxisX.renderer.grid.template.stroke = "#8f8c8c";
    valueAxisX.renderer.grid.template.strokeWidth = 0;
    // valueAxisX.renderer.grid.template.disabled = true;
    valueAxisX.renderer.grid.fill = am4core.color("#e0a44c");

    valueAxisX.renderer.cellStartLocation = 0.2;
    valueAxisX.renderer.cellEndLocation = 0.8;
    valueAxisX.title.text = "DIRECT";

    valueAxisX.title.fontWeight = "bold";
    valueAxisX.title.fontSize = 14;
    valueAxisX.title.fill = am4core.color("#797979");
    valueAxisX.renderer.labels.template.fill = am4core.color("#797979");
    valueAxisX.renderer.labels.template.fontSize = 13;
    valueAxisX.renderer.minGridDistance = 40;
    valueAxisX.min = 0;
    valueAxisX.max = 100;
    valueAxisX.renderer.grid.template.disabled = true;
    valueAxisX.renderer.labels.template.disabled = true;
    let valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());

    valueAxisY.renderer.ticks.template.disabled = true;
    valueAxisY.renderer.axisFills.template.disabled = true;

    valueAxisY.renderer.grid.template.stroke = "#8f8c8c";
    valueAxisY.renderer.grid.template.strokeWidth = 1;
    valueAxisY.renderer.minGridDistance = 16;
    valueAxisY.renderer.grid.template.disabled = true;
    valueAxisY.renderer.labels.template.disabled = true;
    valueAxisY.title.fontWeight = "bold";
    valueAxisY.title.fill = am4core.color("#797979");
    valueAxisY.renderer.labels.template.fill = am4core.color("#797979");
    valueAxisY.renderer.labels.template.fontSize = 13;
    valueAxisY.title.fontSize = 14;
    valueAxisY.title.text = "INDIRECT";

    valueAxisY.renderer.cellStartLocation = 0.2;
    valueAxisY.renderer.cellEndLocation = 0.8;

    valueAxisY.min = 0;
    valueAxisY.max = 100;

    function createGrid1(value) {
      var range = valueAxisX.axisRanges.create();
      range.value = value;
      range.label.text = "{value}";
    }

    function createGrid2(value) {
      var range = valueAxisY.axisRanges.create();
      range.value = value;
      range.label.text = "{value}";
    }

    function colourQuadrantX(start, end) {
      var range = valueAxisX.axisRanges.create();
      range.value = start;
      range.endValue = end;
      range.axisFill.fill = am4core.color("#396478");
      range.axisFill.fillOpacity = 0.2;
      range.grid.strokeOpacity = 0;
    }

    function colourQuadrantY(start, end) {
      var range = valueAxisY.axisRanges.create();
      range.value = start;
      range.endValue = end;
      range.axisFill.fill = am4core.color("#fff");
      range.axisFill.fillOpacity = 0.2;
      range.grid.strokeOpacity = 0;
    }

    // function colourQuadrantY(start, end) {
    //   var range = valueAxisY.axisRanges.create();
    //   range.value = start;
    //   range.endValue = end;
    //   range.axisFill.fill = am4core.color("#fff");
    //   range.axisFill.fillOpacity = 0.2;
    //   range.grid.strokeOpacity = 0;
    // }

    // colourQuadrantX(0, 50)
    // colourQuadrantY(50, 100)
    // colourQuadrantY(50, 100)

    createGrid1(0);
    createGrid1(50);
    createGrid1(100);

    createGrid2(0);
    createGrid2(20);
    createGrid2(40);
    createGrid2(60);
    createGrid2(80);
    createGrid2(100);

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "x_directriskscore";
    series.dataFields.valueY = "y_indirectscore";
    series.dataFields.value = "cdSensitivityScore";
    series.strokeOpacity = 0;
    series.sequencedInterpolation = true;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.fontFamily = "system-ui";

    chart.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    series.tooltip.label.fontSize = 12;

    let bullet = series.bullets.push(new am4core.Circle());
    bullet.fill = am4core.color("#ff0000");
    bullet.propertyFields.fill = "color";
    bullet.strokeOpacity = 1;
    bullet.strokeWidth = 2;
    bullet.fillOpacity = 1;
    bullet.stroke = am4core.color("#0099ff");
    bullet.propertyFields.stroke = "border";
    bullet.hiddenState.properties.opacity = 0;
    bullet.tooltipText = "[bold]{name}:[/] \n Type: {type}";

    // bullet.tooltip.label.fontSize = 5;

    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#181D36");
    series.tooltip.label.fill = am4core.color("#ffffff");

    if (vunerability) {
      bullet.adapter.add("fill", function (fillOpacity, target) {
        const dataContext = target._dataItem._dataContext;
        const datastoreId = target._dataItem._dataContext.datastoreId;
        if (barId) {
          for (let i of barId) {
            if (i === datastoreId) {
              return dataContext.border;
            } else {
              return dataContext.color;
            }
          }
        } else {
          return dataContext.color;
        }
      });
    } else {
      bullet.events.on("inited", function (event) {
        const datastoreId = event.target._dataItem._dataContext.datastoreId;
        const cdSensitivityScore =
          event.target._dataItem._dataContext.cdSensitivityScore;

        const datastoreIds = bars.map(function (el) {
          return el.datastoreId;
        });
        // if (datastoreIds.includes(datastoreId)) {
        //   if (selectedBar && selectedBar.datastoreId === datastoreId) {
        //     event.target.tooltipHTML = `<div id="initial-tooltip" style="font-size:10px;">${selectedBar.name}</div>`;
        //     event.target.showTooltipOn = "always";
        //   }
        // }
      });

      bullet.adapter.add("fill", function (fillOpacity, target) {
        const dataContext = target._dataItem._dataContext;
        const datastoreId = target._dataItem._dataContext.datastoreId;
        const datastoreIds = bars.map(function (el) {
          return el.datastoreId;
        });

        if (datastoreIds.includes(datastoreId)) {
          if (selectedBar && selectedBar.datastoreId === datastoreId) {
            return dataContext.border;
          } else {
            return dataContext.color;
          }
        } else {
          return dataContext.color;
        }
      });

      if (hoveredDatastoreIds.length > 0) {
        bullet.adapter.add("fillOpacity", function (fillOpacity, target) {
          const dataContext = target._dataItem._dataContext;
          const datastoreId = target._dataItem._dataContext.datastoreId;
          if (hoveredDatastoreIds.includes(datastoreId)) {
            return 1;
          } else {
            return 0.3;
          }
        });
      }
    }

    let outline = chart.plotContainer.createChild(am4core.Circle);
    outline.fillOpacity = 0;
    outline.strokeOpacity = 0.8;
    outline.stroke = am4core.color("#ff0000");
    outline.strokeWidth = 2;
    outline.hide(0);

    let blurFilter = new am4core.BlurFilter();
    outline.filters.push(blurFilter);

    bullet.events.on("over", function (event) {
      let target = event.target;
      outline.radius = target.pixelRadius + 2;
      outline.x = target.pixelX;
      outline.y = target.pixelY;

      // Work around for Hiding initial tooltip, we may change it if we found proper
      let initialTooltip = document.getElementById("initial-tooltip");
      if (initialTooltip) {
        try {
          initialTooltip.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.style.display =
            "none";
        } catch (e) {
          console.log("Initial Tooltip not found.");
        }
      }
    });

    bullet.events.on("out", function (event) {
      outline.hide();
    });

    let scoreValue = 0;
    for (let i of data) {
      scoreValue += i.cdSensitivityScore;
    }

    if (scoreValue) {
      series.heatRules.push({
        target: bullet,
        min: 10,
        max: 25,
        property: "radius",
      });
    } else {
      bullet.radius = 5;
    }

    bullet.adapter.add("tooltipY", function (tooltipY, target) {
      return -target.radius;
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;

    var cursorPosition = {
      x: null,
      y: null,
    };
    chart.cursor.events.on("cursorpositionchanged", function (ev) {
      var xAxis = ev.target.chart.xAxes.getIndex(0);
      var yAxis = ev.target.chart.yAxes.getIndex(0);
      cursorPosition.x = Math.round(
        xAxis.positionToValue(xAxis.toAxisPosition(ev.target.xPosition))
      );
      cursorPosition.y = Math.round(
        yAxis.positionToValue(yAxis.toAxisPosition(ev.target.yPosition))
      );
    });

    const handleRisktype = (minX, maxX, minY, maxY) => {
      if (vunerability) return;
      const datastoreIds = [];
      for (let i of data) {
        if (i.x >= minX && i.x <= maxX && i.y >= minY && i.y <= maxY) {
          datastoreIds.push(i.datastoreId);
        }
      }
      filterBars(datastoreIds);
    };

    chart.plotContainer.events.on("hit", function (ev) {
      return;
      if (
        cursorPosition.x >= 0 &&
        cursorPosition.x <= 50 &&
        cursorPosition.y >= 50 &&
        cursorPosition.y <= 100
      ) {
        handleRisktype(0, 50, 50, 100);
      } else if (
        cursorPosition.x >= 50 &&
        cursorPosition.x <= 100 &&
        cursorPosition.y >= 50 &&
        cursorPosition.y <= 100
      ) {
        handleRisktype(50, 100, 50, 100);
      } else if (
        cursorPosition.x >= 0 &&
        cursorPosition.x <= 50 &&
        cursorPosition.y >= 0 &&
        cursorPosition.y <= 50
      ) {
        handleRisktype(0, 50, 0, 50);
      } else if (
        cursorPosition.x >= 50 &&
        cursorPosition.x <= 100 &&
        cursorPosition.y >= 0 &&
        cursorPosition.y <= 50
      ) {
        handleRisktype(50, 100, 0, 50);
      }
    });

    // chart.scrollbarX = new am4core.Scrollbar();
    // chart.scrollbarY = new am4core.Scrollbar();

    chart.data = data;
  };

  useEffect(() => {
    if (
      selectedBar.impactedDatastoreList &&
      selectedBar.impactedDatastoreList.length
    ) {
      let datastoreIds = selectedBar.impactedDatastoreList.map(
        ({ datastoreId }) => datastoreId
      );
      loadChart(datastoreIds);
    } else {
      loadChart();
    }
  }, [selectedBar, hoveredDatastoreIds, data]);

  useEffect(() => {
    if (!vunerability && selectedBar && selectedBar.id) {
      loadChart(selectedBar.impacts);
    }
  }, [vunerability]);

  useEffect(() => {
    if (!vunerability && selectedBar && selectedBar.id) {
      loadChart(selectedBar.impacts);
    }
  }, [bars]);

  return (
    <BlockUi
      tag="div"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      <div
        id="impacteddatastores"
        style={{ height: "375px", width: "100%" }}
      ></div>
    </BlockUi>
  );
};

export default ScatterPlot;
