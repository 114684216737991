import React, { useEffect, useState } from "react";
const PolicyDetails = ({ currentPosts, ticketDetail, getBulkTicketDetail }) => {
  const sourceCategory = sessionStorage.getItem("source_category");

  return (
    <div className="policy-details mb-4 p-3">
      <div className="row align-items-center">
        <div className="col-6 col-md-6 col-xl-6 ">
          <span className="policy-details-title">Source Category:</span>{" "}
          <span className="policy-details-description">{sourceCategory}</span>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetails;
