import { useTheme } from "@mui/material";
import Bar from "../common/Bar";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";

const SensitiveDataCard = () => {
  const theme = useTheme();
  const styles = {
    cardContainer: {
      minWidth: "30%",
      width: "30%",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.25),
    },
    cardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    countOfLLMs: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: "120px",
    },
    fontMedium: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  };

  const shadowData = [
    {
      count: 39234,
      llms: 5,
      tag: "PII-SSN",
      tagType: "scanner",
    },
    {
      count: 63313,
      llms: 4,
      tag: "PII-CC",
      tagType: "scanner",
    },
    {
      count: 143644,
      llms: 4,
      tag: "PII-DL",
      tagType: "scanner",
    },
  ];

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardHeader}>
        <Typography color={theme.palette.surface80.main} sx={styles.cardTitle}>
          Sensitive Data
        </Typography>
        <Typography
          color={theme.palette.surface80.main}
          variant="caption"
          sx={styles.fontMedium}
        >
          Most Used Sensitive Data
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Tags</TableCell>
              <TableCell>Records</TableCell>
              <TableCell>LLMs</TableCell>
            </TableHead>
            <TableBody>
              {shadowData.map((dataItem) => (
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {dataItem?.tag}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {dataItem?.count}
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.countOfLLMs}>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {dataItem?.llms}
                    </Typography>
                    <Bar
                      isLastBar={false}
                      xLabel={""}
                      barBackColor={theme.palette.surface20.main}
                      barFaceColor={theme.palette.lightMagenta.main}
                      rectWidth={(dataItem?.llms / 5) * 100}
                      index={dataItem?.llms}
                      totalValue={4}
                      barLabel={""}
                      barValue={dataItem?.llms}
                      type={""}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SensitiveDataCard;
