import { useTheme } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import BarRect from "../common/BarRect";
import Box from "../common/Box";
import Divider from "../common/Divider";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import { DASHBOARD_DATA_VIEW } from "../constants/constants";

const FloatingWindow = ({ showBar }: { showBar: boolean }) => {
  const theme = useTheme();
  const { dashboardViewLense, drawerData, selectedPolicy } =
    useContext(AppContext);

  const styles = {
    container: {
      width: "100%",
      padding: theme.spacing(1.5, 3, 1.5),
      backgroundColor: theme.palette.surfaceFloatingWindow.main,
      filter: `drop-shadow(0px 3px 9px ${theme.palette.surface0.main})`,
      display: "flex",
      gap: theme.spacing(2),
      zIndex: 3,
    },
    policyBarChart: {
      paddingTop: theme.spacing(1.875),
      width: "30%",
    },
    policyDetailsTable: {
      width: showBar ? "70%" : "100%",
    },
    header: {
      display: "flex",
      padding: theme.spacing(0, 2),
      gap: theme.spacing(3.5),
    },
    barHeader: {
      fontSize: "13px",
      color: theme.palette.surface30.main,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
      background: theme.palette.surface20.main,
    },
    tableHead: {
      "&.MuiTableCell-head": {
        paddingY: theme.spacing(1.25),
        background: theme.palette.surfaceFloatingWindow.main,
      },
    },
    tableCell: {
      fontSize: "13px",
      cursor: "pointer",
    },
  };
  return (
    <Box sx={styles.container}>
      {showBar && (
        <Box sx={styles.policyBarChart}>
          <Box sx={styles.header}>
            <Typography variant="body2" sx={styles.barHeader}>
              Category
            </Typography>
          </Box>
          <Divider sx={styles.divider} />
          <Box>
            <BarRect
              {...drawerData?.tabData
                ?.find((tab) => tab?.tabIndex === drawerData?.activeTab)
                .barData.filter(
                  (d, index) =>
                    d.id === drawerData.currentSelectedPolicy.id ||
                    drawerData?.currentSelectedPolicy?.id === index
                )[0]}
            />
          </Box>
        </Box>
      )}
      <Box sx={styles.policyDetailsTable}>
        <TableContainer sx={{ maxHeight: "100%" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHead}>Policy Name</TableCell>
                <TableCell sx={styles.tableHead}>Policy Tags</TableCell>
                <TableCell sx={styles.tableHead} align="right">
                  Violation Instance
                </TableCell>
                <TableCell sx={styles.tableHead} align="right">
                  {dashboardViewLense === DASHBOARD_DATA_VIEW
                    ? "Impacted Data Stores"
                    : "Impacted LLMs"}
                </TableCell>
                <TableCell sx={styles.tableHead}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  width="25%"
                  component="td"
                  scope="row"
                  sx={styles.tableCell}
                >
                  <Typography variant="caption">
                    {selectedPolicy?.title}
                  </Typography>
                </TableCell>
                <TableCell
                  width="15%"
                  component="td"
                  sx={styles.tableCell}
                  scope="row"
                >
                  <Typography variant="caption">
                    {selectedPolicy?.policyTags || "-"}
                  </Typography>
                </TableCell>
                <TableCell
                  width="20%"
                  component="td"
                  sx={styles.tableCell}
                  scope="row"
                  align="right"
                >
                  <Typography variant="caption">
                    {selectedPolicy?.violationInstances || "-"}
                  </Typography>
                </TableCell>
                <TableCell
                  width="20%"
                  component="td"
                  sx={styles.tableCell}
                  scope="row"
                  align="right"
                >
                  <Typography variant="caption">
                    {selectedPolicy?.dsCount}
                  </Typography>
                </TableCell>
                <TableCell
                  width="20%"
                  component="td"
                  sx={styles.tableCell}
                  scope="row"
                  align="right"
                ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default FloatingWindow;
