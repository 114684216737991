import { createContext, useContext, useState } from "react";
import {
  aiDataStoreTabs,
  dataStoreTypes,
} from "../components/constants/constants";
import getGeoDistribution from "../mock-api/models/geo-distribution";
import getRandomIssueList from "../mock-api/models/issue-list";
import { policyDetail } from "../mock-api/models/policy-details";
import { axiosGet, axiosPost } from "../services/apiClient";
import { axiosDenaliPrivate, axiosPrivate } from "../utils/ApiHandler";
import { Toaster, TOASTER_TYPES } from "../utils/toaster";

export const AppContext = createContext<any | null>(null);

const AppContextProvider = ({ children }: { children: JSX.Element }) => {
  // Dashboard view state (Data/AI)
  const [dashboardViewLense, setDashboardViewLense] = useState("AI");

  // Impacted data stores states
  const [impactedDataStores, setImpactedDataStores] = useState({});
  const [isFetchingImpactedStoresData, setIsFetchingImpactedStoresData] =
    useState(false);

  // Ticket status data states
  const [ticketStatusData, setTicketStatusData] = useState({});
  const [isFetchingTicketStatusData, setIsFetchingTicketStatusData] =
    useState(false);

  // Resources data states
  const [resourcesData, setResourcesData] = useState({});
  const [isFetchingResourcesData, setIsFetchingResourcesData] = useState(false);

  // Policies violated data states
  const [policyCategories, setPolicyCategories] = useState({});
  const [isFetchingPolicyCategories, setIsFetchingPolicyCategories] =
    useState(false);

  const [policyData, setPolicyData] = useState({});
  const [isFetchingPolicyData, setIsFetchingPolicyData] = useState(false);

  // Data stores violation data states
  const [dataStoreViolation, setDataStoreViolation] = useState<
    Array<{ type: string; data: [] }>
  >([]);
  const [isFetchingStoreViolation, setIsFetchingStoreViolation] =
    useState(false);

  // Cloud risk data states
  const [cloudRiskData, setCloudRiskData] = useState([]);
  const [isFetchingCloudRiskData, setIsFetchingCloudRiskData] = useState(false);

  // Risk score data states
  const [riskScore, setRiskScore] = useState({});
  const [isFetchingRiskScoreData, setIsFetchingRiskScoreData] = useState(false);

  // Details Drawer
  const [openPolicyStoreDetailDrawer, setOpenPolicyStoreDetailDrawer] =
    useState(false);
  const [drawerData, setDrawerData] = useState();

  // Create Issue Drawer
  const [openCreateIssueDrawer, setOpenCreateIssueDrawer] = useState(false);
  const [currentSelectedIssue, setCurrentSelectedIssue] = useState();

  // Policy Store Details
  const [policyStoreDetails, setPolicyStoreDetails] = useState();
  const [isFetchingPolicyStoreDetails, setIsFetchingPolicyStoreDetails] =
    useState(false);

  // Issues List
  const [issues, setIssues] = useState();
  const [isFetchingIssues, setIsFetchingIssues] = useState(false);

  // Selected policy-store for issueDetails
  const [selectedPolicy, setSelectedPolicy] = useState();

  // All policies
  const [allPolicies, setAllPolicies] = useState([]);
  const [isFetchingAllPolicies, setIsFetchingAllPolicies] = useState(false);

  // Selected issues
  const [selectedIssues, setSelectedIssues] = useState<Array<number>>([]);

  // Risk trends
  const [riskTrends, setRiskTrends] = useState([]);
  const [isFetchingRiskTrends, setIsFetchingRiskTrends] = useState(false);

  //Achievements
  const [achievements, setAchievements] = useState({});
  const [isFetchingAchievements, setIsFetchingAchievements] = useState(false);

  //Geo Distribution
  const [geoDistribution, setGeoDistribution] = useState([]);
  const [isFetchingGeoDistribution, setIsFetchingGeoDistribution] =
    useState(false);

  // Insights drawer
  const [openInsightsDrawer, setOpenInsightsDrawer] = useState(false);

  // Data Distribution All Regions Overview
  const [overviewAllRegions, setOverviewAllRegions] = useState([]);
  const [isFetchingAllRegionsOverview, setIsFetchingAllRegionsOverview] =
    useState(false);

  // Data Distribution for selected region
  const [overviewForSelectedRegion, setOverviewForSelectedRegion] = useState(
    []
  );
  const [
    isFetchingSelectedRegionOverview,
    setIsFetchingSelectedRegionOverview,
  ] = useState(false);

  // Policy violations from API
  const [policyViolations, setPolicyViolations] = useState([]);
  const [isFetchingPolicyViolations, setIsFetchingPolicyViolations] =
    useState(false);

  // Response notify on slack bulk action
  const [sendNotificationOnSlack, setSendNotificationOnSlack] = useState([]);
  const [isSendingNotification, setIsSendingNotifcation] = useState(false);

  // Create bulk ticket
  const [jiraTicketData, setJiraTicketData] = useState({});
  const [isFetchingJiraTicket, setIsFetchingJiraTicket] = useState(false);

  const [singleJiraTicket, setSingleJiraTicket] = useState({});
  const [isFetchingSingleJiraTicket, setIsFetchingSingleJiraTicket] =
    useState(false);

  // Modify policy details
  const [modifyPolicyDetails, setModifyPolicyDetails] = useState({});
  const [isFetchingModifyPolicyDetails, setIsFetchingModifyPolicyDetails] =
    useState(false);

  // AI Summary data
  const [aiSummary, setAISummary] = useState({});

  // Used data summary info
  const [dsInfo, setDSInfo] = useState({});
  const [isFetchingDsInfo, setIsFetchingDsInfo] = useState(false);

  // Selected sensitive data
  const [selectedSensitiveData, setSelectedSensitiveData] = useState({});

  // Selected sensitive data type
  const [selectedSensitiveType, setSelectedSensitiveType] = useState("");

  // Sensitive data filter
  const [sensitiveDataFilter, setSensitiveDataFilter] = useState("7days");

  // Prompts response time series data
  const [promptTimeSeries, setPromptsTimeSeries] = useState([]);

  // Prompts response data
  const [selectedLangchainPrompts, setSelectedLangchainPrompts] = useState([]);

  // Total message count
  const [totalMessageCount, setTotalMessageCount] = useState(0);

  /**
   * @params none
   * @returns impacted data stores data
   */
  const getImpactedStoresData = () => {
    setIsFetchingImpactedStoresData(true);
    axiosDenaliPrivate
      .post("api/v1/datastore/summary", {
        customerId: localStorage.getItem("customerId"),
      })
      .then((res) => {
        setImpactedDataStores(res.data);
        setResourcesData(res.data);
        setIsFetchingImpactedStoresData(false);
      })
      .catch((error) => {
        setIsFetchingImpactedStoresData(false);
        console.log({ error });
      });
  };

  /**
   * @params none
   * @returns ticket status data
   */
  const getTicketStatusData = () => {
    setIsFetchingTicketStatusData(true);
    axiosDenaliPrivate
      .post("api/v1/tickets/stats", {
        customerId: localStorage.getItem("customerId"),
      })
      .then((res) => {
        setTicketStatusData(res.data);
        setIsFetchingTicketStatusData(false);
      })
      .catch((error) => {
        setIsFetchingTicketStatusData(false);
        console.log({ error });
      });
  };

  /**
   * @params none
   * @returns cloud resources data
   */
  // const getResourcesData = () => {
  //   setIsFetchingResourcesData(true);
  //   axiosGet('api/resources')
  //     .then((res) => {
  //       setResourcesData(res.data);
  //       setIsFetchingResourcesData(false);
  //     })
  //     .catch((error) => {
  //       setIsFetchingResourcesData(false);
  //       console.log({ error });
  //     });
  // };

  /**
   * @params none
   * @returns policy category
   */
  const getViolatedPolicyCategory = (payload) => {
    setIsFetchingPolicyCategories(true);
    axiosDenaliPrivate
      .post("/api/v1/data/policyviolations/predefined", {
        customerId: localStorage.getItem("customerId"),
        ...payload,
      })
      .then((res) => {
        setPolicyCategories(res.data);
        setIsFetchingPolicyCategories(false);
      })
      .catch((error) => {
        setIsFetchingPolicyCategories(false);
        console.log({ error });
      });
  };

  /**
   * get all policies
   */
  const getAllPolicies = (payload) => {
    setIsFetchingAllPolicies(true);
    axiosDenaliPrivate
      .post("/api/v1/data/policyviolations", {
        customerId: localStorage.getItem("customerId"),
        ...payload,
      })
      .then((res) => {
        setAllPolicies(res.data);
        setIsFetchingAllPolicies(false);
      })
      .catch((error) => {
        setIsFetchingAllPolicies(false);
        console.log({ error });
      });
  };

  /**
   * @params none
   * @returns policy violation data
   */
  const getViolatedPolicyData = (payload) => {
    setIsFetchingPolicyData(true);
    axiosDenaliPrivate
      .post("/api/v1/policy/filteredbrief", {
        customerId: localStorage.getItem("customerId"),
        ...payload,
      })
      .then((res) => {
        setPolicyData(res.data?.policies);
        setIsFetchingPolicyData(false);
      })
      .catch((error) => {
        setIsFetchingPolicyData(false);
        console.log({ error });
      });
  };

  /**
   * @params none
   * @returns data store violation data
   */
  const getDataStoreViolation = async () => {
    setIsFetchingStoreViolation(true);
    const dataStoreStats: { type: string; data: [] }[] = [];
    for (const item of dataStoreTypes) {
      await axiosDenaliPrivate
        .post("/api/v1/customer/datastore/stats", {
          customerId: localStorage.getItem("customerId"),
          datastoreType: item.filter,
        })
        .then((res: any) => {
          dataStoreStats.push({
            type: item?.label,
            data: res.data?.datastores?.types,
          });
        })
        .catch((error) => {
          setIsFetchingStoreViolation(false);
          console.log({ error });
        });
    }
    setIsFetchingStoreViolation(false);
    setDataStoreViolation(dataStoreStats);
  };

  /**
   * returns AI/LLM DS
   */
  const getAIDatastores = async () => {
    setIsFetchingStoreViolation(true);
    const dataStoreStats: { type: string; data: [] }[] = [];
    for (const item of aiDataStoreTabs) {
      await axiosDenaliPrivate
        .post("/api/v1/datastores", {
          customerId: localStorage.getItem("customerId"),
          type: "llm",
          llmStatus: item?.filter,
        })
        .then((res: any) => {
          dataStoreStats.push({
            type: item?.label,
            data: res.data?.data,
          });
        })
        .catch((error) => {
          setIsFetchingStoreViolation(false);
          console.log({ error });
        });
    }
    setIsFetchingStoreViolation(false);
    setDataStoreViolation(dataStoreStats);
  };

  /**
   *
   * @param noOfHops
   * @returns cloud risk data on hop
   */
  const getCloudRiskData = () => {
    setIsFetchingCloudRiskData(true);
    axiosDenaliPrivate
      .post("/api/v1/data/cloudissues", {
        customerId: localStorage.getItem("customerId"),
      })
      .then((res) => {
        setCloudRiskData(res.data);
        setIsFetchingCloudRiskData(false);
      })
      .catch((error) => {
        setIsFetchingCloudRiskData(false);
        console.log({ error });
      });
  };

  /**
   * @params none
   * @returns risk score data
   */
  const getRiskScoreData = () => {
    setIsFetchingRiskScoreData(true);
    axiosDenaliPrivate
      .post("/api/v1/issues/uberscore/get", {
        customerId: localStorage.getItem("customerId"),
      })
      .then((res: any) => {
        setRiskScore(res?.data);
        setIsFetchingRiskScoreData(false);
      })
      .catch((error) => {
        setIsFetchingRiskScoreData(false);
        console.log({ error });
      });
  };

  /**
   * @params none
   * @returns policy details
   */
  const getPolicyDetails = (payload) => {
    setIsFetchingPolicyStoreDetails(true);
    axiosDenaliPrivate
      .post("/api/v1/policy/filteredbrief", {
        customerId: localStorage.getItem("customerId"),
        ...payload,
      })
      .then((res) => {
        setPolicyStoreDetails(
          res?.data?.policies.sort((a, b) => b.dsCount - a.dsCount)
        );
        setIsFetchingPolicyStoreDetails(false);
      })
      .catch((error) => {
        setIsFetchingPolicyStoreDetails(false);
        console.log({ error });
      });
  };

  /**
   * @param policyDetail {policy-store-type, id}
   * @returns policy-store-details
   */
  const getIssues = (data: any) => {
    setIsFetchingIssues(true);
    let res: any = [];
    if (data.policyId !== undefined) {
    }
    res = getRandomIssueList(Math.floor(Math.random() * 41) + 10); //generate reandom number between 10-50
    setIssues(res);
    setIsFetchingIssues(false);
  };

  /**
   *
   * @param none
   * @returns risk trends data
   */
  const getRiskTrends = () => {
    setIsFetchingRiskTrends(true);
    axiosDenaliPrivate
      .post("/api/v1/customer/trends", {
        customerId: localStorage.getItem("customerId"),
      })
      .then((res) => {
        setRiskTrends(res.data);
        setIsFetchingRiskTrends(false);
      })
      .catch((error) => {
        setIsFetchingRiskTrends(false);
        console.log({ error });
      });
  };

  /**
   *
   * @params none
   * @returns achievements data
   */
  const getAchievements = () => {
    setIsFetchingAchievements(true);
    axiosDenaliPrivate
      .post("/api/v1/customer/achievements", {
        customerId: localStorage.getItem("customerId"),
      })
      .then((res) => {
        setAchievements(res.data);
        setIsFetchingAchievements(false);
      })
      .catch((error) => {
        setIsFetchingAchievements(false);
        console.log({ error });
      });
  };

  /**
   *
   * @params none
   * @returns geo distribution data
   */
  const getGeoDistributionData = (payload) => {
    setIsFetchingGeoDistribution(true);
    axiosDenaliPrivate
      .post("/api/v1/customer/datastore/regions", {
        customerId: localStorage.getItem("customerId"),
        ...payload,
      })
      .then((res) => {
        setGeoDistribution(res.data);
        setIsFetchingGeoDistribution(false);
      })
      .catch((error) => {
        setIsFetchingGeoDistribution(false);
        console.log({ error });
      });
  };

  /**
   *
   * @params none
   * @returns overview data for all regions
   *
   */
  const getSensitiveData = (payload) => {
    setIsFetchingAllRegionsOverview(true);
    axiosDenaliPrivate
      .post("/api/v1/datastore/sensitiveinfo", {
        customerId: localStorage.getItem("customerId"),
        ...payload,
      })
      .then((res) => {
        setOverviewAllRegions(res.data);
        setIsFetchingAllRegionsOverview(false);
      })
      .catch((error) => {
        setIsFetchingAllRegionsOverview(false);
        console.log({ error });
      });
  };

  /**
   *
   * @params region
   * @returns overview for selected region
   *
   */
  const getRegionsOverview = (payload) => {
    setIsFetchingSelectedRegionOverview(true);
    axiosDenaliPrivate
      .post("/api/v1/datastore/sensitiveinfo", {
        customerId: localStorage.getItem("customerId"),
        ...payload,
      })
      .then((res) => {
        setOverviewForSelectedRegion(res.data);
        setIsFetchingSelectedRegionOverview(false);
      })
      .catch((error) => {
        setIsFetchingSelectedRegionOverview(false);
        console.log({ error });
      });
  };

  const getPolicyViolations = (requestBody: any) => {
    setIsFetchingPolicyViolations(true);
    const headers = {
      ...requestBody,
      customerId: localStorage.getItem("customerId"),
      limit: 10,
    };
    axiosDenaliPrivate
      .post("/api/v1/policyviolationinstance", headers)
      .then((res) => {
        setPolicyViolations(res.data);
        setIsFetchingPolicyViolations(false);
      })
      .catch((error) => {
        setIsFetchingPolicyViolations(false);
        console.log({ error });
      });
  };

  /**
   *
   * @params policy violation id
   * @returns Notify on slack bulk action
   *
   */
  const notifyOnSlackBulkAction = (payload) => {
    setIsSendingNotifcation(true);
    axiosDenaliPrivate
      .post("/api/v1/policies/notification", {
        customerId: localStorage.getItem("customerId"),
        ...payload,
      })
      .then((res) => {
        setSendNotificationOnSlack(res?.data);
        Toaster(TOASTER_TYPES.SUCCESS, res?.data?.success?.message);
        setIsSendingNotifcation(false);
      })
      .catch((error) => {
        setIsSendingNotifcation(false);
        console.log({ error });
      });
  };

  const getBulkTicket = (payload) => {
    setIsFetchingJiraTicket(true);
    axiosDenaliPrivate
      .post("/api/v1/jira/bulk/get", {
        customerId: localStorage.getItem("customerId"),
        host: window.location.host,
        ...payload,
      })
      .then((res) => {
        setJiraTicketData(res.data);
        setIsFetchingJiraTicket(false);
      })
      .catch((error) => {
        setIsFetchingJiraTicket(false);
        console.log({ error });
      });
  };

  const getJiraTicket = (payload) => {
    setIsFetchingSingleJiraTicket(true);
    axiosDenaliPrivate
      .post("/api/v1/jira/datastore/get", {
        customerId: localStorage.getItem("customerId"),
        host: window.location.host,
        ...payload,
      })
      .then((res) => {
        setSingleJiraTicket(res.data);
        setIsFetchingSingleJiraTicket(false);
      })
      .catch((error) => {
        setIsFetchingSingleJiraTicket(false);
        console.log({ error });
      });
  };

  const getModifyPolicyDetails = (policyId) => {
    setIsFetchingModifyPolicyDetails(true);
    axiosPrivate
      .get(`/policy/policy/${policyId}`)
      .then((res) => {
        setModifyPolicyDetails(res.data);
        setIsFetchingModifyPolicyDetails(false);
      })
      .catch((error) => {
        setIsFetchingModifyPolicyDetails(false);
        console.log({ error });
      });
  };

  const getAISummary = () => {
    setIsFetchingImpactedStoresData(true);
    axiosDenaliPrivate
      .post("/api/v1/ai/summary", {
        customerId: localStorage.getItem("customerId"),
      })
      .then((res) => {
        setAISummary(res.data);
        setIsFetchingImpactedStoresData(false);
      })
      .catch((error) => {
        setIsFetchingImpactedStoresData(false);
        console.log({ error });
      });
  };

  const getDsInfo = () => {
    setIsFetchingDsInfo(true);
    axiosDenaliPrivate
      .post("/api/v1/llm/trainingdsinfo", {
        customerId: localStorage.getItem("customerId"),
      })
      .then((res) => {
        setDSInfo(res.data);
        setIsFetchingDsInfo(false);
      })
      .catch((error) => {
        setIsFetchingDsInfo(false);
        console.log({ error });
      });
  };

  return (
    <AppContext.Provider
      value={{
        // Dashboard view lense state (Data/AI)
        dashboardViewLense,
        setDashboardViewLense,

        // Impacted Data Stores data
        impactedDataStores,
        isFetchingImpactedStoresData,
        getImpactedStoresData,

        // Ticket Status data
        ticketStatusData,
        isFetchingTicketStatusData,
        getTicketStatusData,

        // Resources data
        resourcesData,
        isFetchingResourcesData,
        // getResourcesData,

        // Policy violation data
        policyCategories,
        isFetchingPolicyCategories,
        policyData,
        isFetchingPolicyData,
        getViolatedPolicyCategory,
        getViolatedPolicyData,

        // Data store violation
        dataStoreViolation,
        isFetchingStoreViolation,
        getDataStoreViolation,
        getAIDatastores,

        // Cloud risk data
        cloudRiskData,
        isFetchingCloudRiskData,
        getCloudRiskData,

        // Risk score data
        riskScore,
        isFetchingRiskScoreData,
        getRiskScoreData,

        // Details Drawer
        openPolicyStoreDetailDrawer,
        setOpenPolicyStoreDetailDrawer,
        drawerData,
        setDrawerData,
        getPolicyDetails,
        policyStoreDetails,
        isFetchingPolicyStoreDetails,

        // Policy/Data Store related issues
        issues,
        isFetchingIssues,
        getIssues,

        // Select policy
        selectedPolicy,
        setSelectedPolicy,

        // Selected issues
        selectedIssues,
        setSelectedIssues,

        // Issue Drawer
        openCreateIssueDrawer,
        setOpenCreateIssueDrawer,
        currentSelectedIssue,
        setCurrentSelectedIssue,

        // Risk trends
        riskTrends,
        isFetchingRiskTrends,
        getRiskTrends,

        // Achievements
        achievements,
        isFetchingAchievements,
        getAchievements,

        // Geo Distribution data
        geoDistribution,
        isFetchingGeoDistribution,
        getGeoDistributionData,

        // Insights drawer
        openInsightsDrawer,
        setOpenInsightsDrawer,

        // Overview All Regions
        overviewAllRegions,
        isFetchingAllRegionsOverview,
        getSensitiveData,

        // Overview Selected regions
        overviewForSelectedRegion,
        isFetchingSelectedRegionOverview,
        getRegionsOverview,

        // Get paginated policy violations
        policyViolations,
        isFetchingPolicyViolations,
        getPolicyViolations,

        // Notify on slack bulk action
        notifyOnSlackBulkAction,
        isSendingNotification,
        sendNotificationOnSlack,

        // Get bulk ticket data
        jiraTicketData,
        isFetchingJiraTicket,
        getBulkTicket,

        // Get single ticket data
        singleJiraTicket,
        setSingleJiraTicket,
        isFetchingSingleJiraTicket,
        getJiraTicket,

        // Modify policy
        modifyPolicyDetails,
        isFetchingModifyPolicyDetails,
        getModifyPolicyDetails,

        // Get all policies
        allPolicies,
        isFetchingAllPolicies,
        getAllPolicies,

        // AI Summary
        aiSummary,
        getAISummary,

        // Ds Info
        dsInfo,
        getDsInfo,
        isFetchingDsInfo,

        // Selected sensitive data
        selectedSensitiveData,
        setSelectedSensitiveData,

        // Selected sensitive type
        selectedSensitiveType,
        setSelectedSensitiveType,

        // Sensitive data fitler
        sensitiveDataFilter,
        setSensitiveDataFilter,

        // Prompts response time series data
        promptTimeSeries,
        setPromptsTimeSeries,

        // Prompts response data
        selectedLangchainPrompts,
        setSelectedLangchainPrompts,

        // Total message count
        totalMessageCount,
        setTotalMessageCount,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export default AppContextProvider;
