import { capitalizeFirstLetter } from "./utils";

/**
 * Sets the current session context for the application.
 * @param data
 */
export function setContext(res) {
  // localStorage.setItem("authToken", true);
  // if (data?.userInfo) {
  //   localStorage.setItem("userFirstName", data?.userInfo?.first_name || "");
  //   localStorage.setItem("userLastName", data?.userInfo?.last_name || "");
  //   localStorage.setItem("userId", data?.userInfo?._id || "");
  // }
  // localStorage.setItem("isLoggedIn", true);

  const token = res?.token;
  const userInfo = token?.userInfo;
  const username = userInfo?.username;
  const customer = token.customer;
  const idToken = token?.idToken;
  const refreshToken = token?.refreshToken;
  const jwtToken = idToken?.jwtToken;
  const role = userInfo?.role;

  setAuthToken(jwtToken);
  localStorage.setItem("refreshToken", refreshToken?.token);
  localStorage.setItem("username", username);

  localStorage.setItem("first_name", userInfo.first_name);
  localStorage.setItem("last_name", userInfo.last_name);
  localStorage.setItem("last_name", userInfo.username);

  localStorage.setItem("customerRef", customer._id);
  localStorage.setItem("userId", userInfo?._id || "");

  localStorage.setItem("customer_name", customer?.name);
  localStorage.setItem("customerId", customer?.id);
  localStorage.setItem("role", role);
  localStorage.setItem("showShadowAI", true);
}

/**
 * Removes the current session context of the application.
 * @param url
 */
export function removeContext() {
  localStorage.clear();
  sessionStorage.clear();
}

export function getUserId() {
  return localStorage.getItem("userId") || "";
}

export function getUserName() {
  return localStorage.getItem("username") || "";
}

export function getCustomerName() {
  return localStorage.getItem("customer_name") || "";
}

export function getCustomerRef() {
  return localStorage.getItem("customerRef") || "";
}

export function getAuthToken() {
  return localStorage.getItem("authToken") || "";
}

export function getAuthTokenExpiry() {
  return localStorage.getItem("authTokenExpiry") || "";
}

export function getRefreshToken() {
  return localStorage.getItem("refreshToken") || "";
}

export function getCustomerId() {
  return localStorage.getItem("customerId") || "";
}

export function setAuthToken(authToken) {
  let date = new Date();
  date.setMinutes(date.getMinutes() + 119);
  localStorage.setItem("authTokenExpiry", date.getTime());
  localStorage.setItem("authToken", authToken);
}

export function setSelectedDatastoreAccount(selectedAccount) {
  return sessionStorage.setItem(
    "datastoreAccountFilter",
    JSON.stringify(selectedAccount)
  );
}

export function getSelectedDatastoreAccount() {
  return JSON.parse(sessionStorage.getItem("datastoreAccountFilter"));
}

export function setSelectedIssuesAccount(selectedAccount) {
  return sessionStorage.setItem(
    "issuesAccountFilter",
    JSON.stringify(selectedAccount)
  );
}

export function getSelectedIssuesAccount() {
  return JSON.parse(sessionStorage.getItem("issuesAccountFilter"));
}

export function setSelectedDataFlow(selectedDataFlow) {
  return sessionStorage.setItem(
    "selectedDataFlow",
    JSON.stringify(selectedDataFlow)
  );
}

export function getSelectedDataFlow() {
  return JSON.parse(sessionStorage.getItem("selectedDataFlow"));
}

export function removeSelectedDataFlow() {
  return sessionStorage.removeItem("selectedDataFlow");
}
