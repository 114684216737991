import MoreVert from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { isDataWithinDefinedDate } from "../../utils/commonUtils";
import Box from "../common/Box";
import Checkbox from "../common/Checkbox";
import Divider from "../common/Divider";
import NoData from "../common/NoData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import JiraIcon from "../../assets/img/jira.svg";
import SlackIcon from "../../assets/img/slack.svg";
import Chip from "../common/Chip";

const MessageDetailBlock = ({ data, index }) => {
  const theme = useTheme();
  const styles = {
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.25),
    },
    messageCard: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      gap: theme.spacing(1),
    },
    messageFontSize: {
      fontSize: "13px",
    },
    dateText: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    messageData: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    policyViolationsList: {
      display: "flex",
      gap: theme.spacing(1),
    },
  };
  const dateString = moment(data.retrivalDate).format("YYYY-MM-DD");
  const timeString = moment(data.retrivalDate).format("HH:mm:ss");
  return (
    <Box sx={styles.messageContainer}>
      <Box sx={styles.messageCard}>
        <Box sx={styles.messageData}>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Prompt
            </Typography>
            <Typography variant="caption" color={theme.palette.surface80.main}>
              {data.prompt}
            </Typography>
          </Box>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Context
            </Typography>
            <Typography variant="caption" color={theme.palette.surface80.main}>
              {data.msgText}
            </Typography>
          </Box>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Response
            </Typography>
            <Typography variant="caption" color={theme.palette.surface80.main}>
              {data.response}
            </Typography>
          </Box>
          {data.policyViolations && data.policyViolations.length > 0 && (
            <Box sx={styles.keyValue}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Policies Violated
              </Typography>
              <Box sx={styles.policyViolationsList}>
                {data.policyViolations.map((policy) => (
                  <Chip label={policy} />
                ))}
              </Box>
            </Box>
          )}

          <Box sx={styles.dateText}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Retrieved On:
            </Typography>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              {dateString}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              {timeString}
            </Typography>
            {/*<Divider orientation="vertical" flexItem />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              By {data.retrievedBy}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              {data.region}
            </Typography>*/}
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

const MessageDetails = () => {
  const {
    selectedSensitiveData,
    selectedSensitiveType,
    sensitiveDataFilter,
    setSelectedSensitiveType,
  } = useContext(AppContext);
  const theme = useTheme();
  const styles = {
    messagePanel: {
      backgroundColor: "#121523",
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2),
    },
  };
  const [messageList, setMessageList] = useState<
    Array<{ msgText: string; retrivalDate: string }> | undefined
  >([]);

  useEffect(() => {
    const messages =
      selectedSensitiveData?.data?.find(
        (tab) => tab.name === selectedSensitiveType
      )?.messages || [];

    setMessageList(
      messages?.filter((message) =>
        isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
      ) || []
    );
  }, [selectedSensitiveData, selectedSensitiveType, sensitiveDataFilter]);

  return (
    <Box sx={styles.messagePanel}>
      {messageList && messageList?.length > 0 ? (
        <>
          {messageList?.map((message, index) => (
            <MessageDetailBlock data={message} index={index} />
          ))}
        </>
      ) : (
        <NoData customText="No messages found" />
      )}
    </Box>
  );
};

export default MessageDetails;
