import { useTheme } from "@mui/material";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import {
  sensitiveDataTabs,
  tabs,
  totalResponses,
} from "../../mock-data/llmPromptsData";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  isContainsValue,
  isDataWithinDefinedDate,
} from "../../utils/commonUtils";
import Bar from "../common/Bar";
import Box from "../common/Box";
import Divider from "../common/Divider";
import LegendWithColor from "../common/LegendWithColor";
import { MenuItem } from "../common/Menu";
import NoData from "../common/NoData";
import { Select } from "../common/Select";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import { sensitiveFilters } from "../constants/constants";
import PromptsTrendsGraph from "../dashboard-widgets/PromptsTrendsGraph";
import { CardWrapper } from "./CardWrapper";
import MessagePromptsDialog from "./MessagePromptsDialog";
import SensitiveDataTable from "./SensitiveDataTable";

const PromptResponseCard = ({ llmData }) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState("vectorDB");
  const {
    selectedSensitiveData,
    sensitiveDataFilter,
    setSensitiveDataFilter,
    selectedSensitiveType,
    setSelectedSensitiveData,
    setSelectedSensitiveType,
    promptTimeSeries,
    selectedLangchainPrompts,
  } = useContext(AppContext);
  const [openPromptsDialog, setOpenPromptsDialog] = useState(false);

  const onClose = () => {
    setSelectedSensitiveData({});
    setSelectedSensitiveType("");
    setOpenPromptsDialog(false);
  };

  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
    },
    cardHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: theme.palette.surface40.main,
      marginY: 0,
    },
    cardMetric: {
      display: "flex",
      gap: theme.spacing(1),
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    tab: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      gap: theme.spacing(0.5),
    },
    tabMetric: {
      fontSize: "20px",
    },
    selectedTab: (isSelected) => ({
      fontSize: "13px",
      fontWeight: isSelected
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    }),
    tableContainer: {
      paddingY: theme.spacing(2),
    },
    noBottomBorder: {
      marginLeft: "auto",
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 0,
        width: "120px",
      },
      //'&.MuiInputBase-root:before': {
      //  borderBottom: 'none',
      //},
      //'&.MuiInputBase-root:hover:before': {
      //  borderBottom: 'none',
      //},
      //'&.MuiInputBase-root:hover': {
      //  color: theme.palette.surface90.main,
      //  '& .MuiSvgIcon-root': {
      //    color: theme.palette.surface90.main,
      //  },
      //},
      //'&.MuiInputBase-root:focus:before': {
      //  borderBottom: 'none',
      //},
      "& .MuiInputBase-input": {
        padding: "8px !important",
      },
    },
    select: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    promptMetric: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    totalMessages: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    responseText: {
      fontSize: "28px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    responseSubText: {
      fontSize: "13px",
    },
    legendContainer: {
      display: "flex",
      marginLeft: "auto",
      justifyContent: "end",
      gap: theme.spacing(3),
    },
    moreDetailsButton: {
      display: "flex",
      marginLeft: "auto",
    },
    messageBlock: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    graphContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  //  const tabs = [
  //    {
  //      value: "vectorDB",
  //      label: (
  //        <Box sx={styles.tab}>
  //          <Typography sx={styles.tabMetric}>4</Typography>
  //          <Typography sx={styles.selectedTab(activeTab === "vectorDB")}>
  //            VectorDB Sensitive Retrieval
  //          </Typography>
  //        </Box>
  //      ),
  //    },
  //    {
  //      value: "sensitiveResponses",
  //      label: (
  //        <Box sx={styles.tab}>
  //          <Typography sx={styles.tabMetric}>0</Typography>
  //          <Typography
  //            sx={styles.selectedTab(activeTab === "sensitiveResponses")}
  //          >
  //            Sensitive Responses
  //          </Typography>
  //        </Box>
  //      ),
  //    },
  //    {
  //      value: "sensitivePrompts",
  //      label: (
  //        <Box sx={styles.tab}>
  //          <Typography sx={styles.tabMetric}>0</Typography>
  //          <Typography sx={styles.selectedTab(activeTab === "sensitivePrompts")}>
  //            Sensitive Prompts
  //          </Typography>
  //        </Box>
  //      ),
  //    },
  //  ];

  const constructTabData = () => {
    return tabs.map((tab) => {
      let count = 0;
      sensitiveDataTabs
        .find((dataItem) => dataItem.tabValue === tab.value)
        ?.data.forEach((msgData) => {
          const messageArr = msgData.messages.filter((message) =>
            isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
          );
          count += messageArr.length;
        });

      return {
        value: tab.value,
        label: (
          <Box sx={styles.tab}>
            <Typography sx={styles.tabMetric}>{count}</Typography>
            <Typography sx={styles.selectedTab(activeTab === tab.value)}>
              {tab.label}
            </Typography>
          </Box>
        ),
      };
    });
  };

  const categories = [
    {
      name: "sensitivePrompts",
      label: "Prompts",
      color: theme.palette.peach.main,
    },
    {
      name: "vectorDB",
      label: "Context",
      color: theme.palette.laurelGreen.main,
    },
    {
      name: "sensitiveResponses",
      label: "Responses",
      color: theme.palette.paleBlue.main,
    },
  ];

  const onRowClick = (data) => {
    setSelectedSensitiveData(data);
    setOpenPromptsDialog(true);
  };

  const getResponsePromptCount = (keyword) => {
    let count = 0;
    selectedLangchainPrompts
      .find((dataItem) => dataItem.tabValue === keyword)
      ?.data.forEach((msgData) => {
        const messageArr = msgData.messages.filter((message) =>
          isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
        );
        count += messageArr.length;
      });
    return count;
  };

  const getTotalMessageCount = () => {
    switch (sensitiveDataFilter) {
      case "7days":
        return llmData?.last7Days;
      case "1day":
        return llmData?.last1Day;
      case "1hour":
        return llmData?.last1Hour;
      default:
        return 0;
    }
  };

  return (
    <CardWrapper>
      <Box sx={styles.card}>
        <Box sx={styles.cardHeader}>
          <Typography
            color={theme.palette.surface80.main}
            sx={styles.cardTitle}
          >
            Prompts & Response Summary
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Select
            minWidth={100}
            options={sensitiveFilters}
            value={sensitiveDataFilter}
            variant="standard"
            sx={styles?.select}
            onChange={(event: any) => {
              setSensitiveDataFilter(event.target.value);
            }}
          />
          <TextButton
            hideText={true}
            endIcon={<KeyboardArrowRightIcon />}
            sx={styles.moreDetailsButton}
            onClick={() => {
              setOpenPromptsDialog(true);
              setSelectedSensitiveData(selectedLangchainPrompts[0]);
              setSelectedSensitiveType(
                selectedLangchainPrompts[0]?.data[0]?.name
              );
            }}
          >
            Details
          </TextButton>
        </Box>

        <Box sx={styles.graphContent}>
          <Box sx={styles.promptMetric}>
            <Box sx={styles.messageBlock}>
              <Typography
                sx={styles.responseSubText}
                color={theme.palette.surface70.main}
              >
                Sensitive Messages
              </Typography>
              <Typography
                sx={styles.responseSubText}
                color={theme.palette.critical.main}
              >
                {getResponsePromptCount("vectorDB") +
                  getResponsePromptCount("sensitiveResponses") +
                  getResponsePromptCount("sensitivePrompts")}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={styles.messageBlock}>
              <Typography
                sx={styles.responseSubText}
                color={theme.palette.surface70.main}
              >
                Total Prompts
              </Typography>
              <Typography
                sx={styles.responseSubText}
                color={theme.palette.surface70.main}
              >
                {getTotalMessageCount()}
              </Typography>
            </Box>
            <Box sx={styles.legendContainer}>
              {categories.map((category, index) => (
                <LegendWithColor
                  key={index}
                  {...category}
                  label={`${category?.label} (${getResponsePromptCount(
                    category?.name
                  )})`}
                />
              ))}
            </Box>
          </Box>
          <PromptsTrendsGraph
            graphData={promptTimeSeries}
            svgHeight={200}
            ticks={3}
          />
        </Box>
        {/*<Box sx={styles.cardMetric}>
          <Typography
            color={theme.palette.surface60.main}
            sx={styles.cardTitle}
          >
            Total
          </Typography>
          <Typography sx={{ ...styles.cardTitle, ...styles.boldText }}>
            {totalResponses}
          </Typography>
        </Box>
        <Box>
          <Tabs
            value={activeTab}
            onChange={(event: Event | SyntheticEvent, newValue: string) =>
              setActiveTab(newValue)
            }
          >
            {constructTabData().map(
              (tab: { value: string; label: JSX.Element }, index: number) => (
                <Tab {...tab} key={index} />
              )
            )}
          </Tabs>
          <Box sx={styles.tableContainer}>
            {sensitiveDataTabs.map((sensitiveData, index) => (
              <TabPanel value={activeTab} index={sensitiveData?.tabValue}>
                {sensitiveData?.data?.length > 0 ? (
                  <SensitiveDataTable
                    sensitiveData={sensitiveData?.data}
                    onRowClick={() => onRowClick(sensitiveData)}
                  />
                ) : (
                  <NoData
                    customText={sensitiveData?.noDataText}
                    height="200px"
                  />
                )}
              </TabPanel>
            ))}
          </Box>
        </Box>*/}
      </Box>
      <MessagePromptsDialog
        open={openPromptsDialog}
        onClose={onClose}
        anchor="right"
        totalMessages={getTotalMessageCount()}
      />
    </CardWrapper>
  );
};

export default PromptResponseCard;
