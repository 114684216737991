import React, { useState, useEffect } from "react";
import "./../modal.scss";
import "./CompliancePolicyModal.scss";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import loaderImage from "../../../assets/img/loader.svg";
import closeIcon from "../../../assets/img/icons/close-icon-grey.svg";
import {
  addPolicyAsync,
  updatePolicyAsync,
  getAllPolicies,
} from "../../../redux/slices/policySlice";
import { getUserId, getCustomerName } from "../../../utils/SessionHelper";
import { REACT_APP_DATASTORE_API } from "../../../utils/Constants";
// import { listAccountsAsync } from "../../../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoading,
  showLoading,
} from "../../../redux/slices/globalOperationSlice";
import "react-toggle/style.css"; // for ES6 modules
import { getCustomerId } from "../../../utils/SessionHelper";
import { getDataStoresApi, getDataStoresApiV2 } from "../../../apis/index.js";
import Box from "../../common/Box";
import Button from "../../common/Button";
import { useTheme } from "@mui/material";
import Typography from "../../common/Typography";
import TextButton from "../../common/TextButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const CompliancePolicyModal = ({
  handleModal,
  policyAction,
  instances,
  refreshAccounts,
  policyItem,
  policiesList,
  setDatastoreMappings,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [accountsList, setAccountsList] = useState();
  const [description, setDescription] = useState();
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const accounts = useSelector((state) => state.account.accounts);
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datastores, setDatastores] = useState([]);
  const [customTagErrors, setCustomTagError] = useState("");
  const [piiTags, setPiiTags] = useState([
    {
      label: "PII-Name",
      value: "PII-Name",
    },
    {
      label: "PII-Address",
      value: "PII-Address",
    },
    {
      label: "PII-StreetAddress",
      value: "PII-StreetAddress",
    },
    {
      label: "PII-SocialSecurity",
      value: "PII-SocialSecurity",
    },
    {
      label: "PII-IPAddress",
      value: "PII-IPAddress",
    },
    {
      label: "PII-MobileNumber",
      value: "PII-MobileNumber",
    },
    {
      label: "PII-DriversLicense",
      value: "PII-DriversLicense",
    },
    {
      label: "PCI-CCNumber",
      value: "PCI-CCNumber",
    },
    {
      label: "PCI-CCExpiry",
      value: "PCI-CCExpiry",
    },
    {
      label: "PCI-CCPIN",
      value: "PCI-CCPIN",
    },
  ]);
  const [sensitiveTags, setSensitiveTags] = useState([
    {
      label: "Low",
      value: "Low",
    },
    {
      label: "Medium",
      value: "Medium",
    },
    {
      label: "High",
      value: "High",
    },
    {
      label: "Critical",
      value: "Critical",
    },
  ]);
  const [selectedDatastores, setSelectedDatastores] = useState([]);
  const [selectedPiiTags, setSelectedPiiTags] = useState([]);
  const [selectedSensitiveTags, setSelectedSensitiveTags] = useState(null);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [customTag, setCustomTag] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tagsUpdated, setTagsUpdated] = useState(false);
  const styles = {
    modalHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    closeButton: {
      color: theme.palette.surface80.main,
    },
    modalFooter: {
      display: "flex",
      gap: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingX: theme.spacing(2),
    },
  };

  const getDatastoreIds = () => {
    let datastores_ = [];
    if (datastores.length > 0) {
      datastores.map((value) => {
        datastores_.push(value.value);
      });
    }
    return datastores_;
  };

  const updateTags = (tag) => {
    tag.label = tag.label.trim();
    setCustomTagError("");
    setCustomTag([...customTag, tag]);
  };

  const removeTag = (tag) => {
    const tempTags = [...customTag];
    tempTags.splice(
      tempTags.findIndex((a) => a.value === tag.value),
      1
    );
    setTagsUpdated(true);
    setCustomTag(tempTags);
  };

  useEffect(() => {
    if (!selectedAccounts.length) {
      setSelectedDatastores([]);
    }
  }, [selectedAccounts]);

  const limit = 100;

  const formik = useFormik({
    initialValues: {
      name: "",
      accounts: "",
      datastores: "",
      // sensitiveTags: "",
      policies: "",
      // alertingActions: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter policy name."),
      // accounts: Yup.array().required("Please select account."),
      // datastores: Yup.array().required("Please select datastores."),
      // sensitiveTags: Yup.array().required("Please select sensitive tags."),
      policies: Yup.array().required("Please select sensitive tags."),
      // alertingActions: Yup.object().required("Please select alerting action."),
    }),
    onSubmit: (values) => {
      if (policyAction === "add") {
        addPolicy({ ...values });
      } else {
        updatePolicy({ ...values });
      }
    },
  });

  useEffect(() => {
    if (!selectedPolicies.length) {
      formik.setFieldValue("policies", "");
    }
  }, [selectedPolicies]);

  useEffect(() => {
    if (!selectedAccounts.length) {
      setSelectedDatastores([]);
    }
  }, [selectedAccounts]);

  const alertingActions = [
    { value: "slack", label: "slack" },
    { value: "email", label: "email" },
  ];
  const issueType = [
    {
      value: "Sudo Vulnerability",
      label: "Sudo Vulnerability",
    },
    {
      value: "Open Port 22",
      label: "Open Port 22",
    },
    {
      value: "Kernal Heap Overflow",
      label: "Kernal Heap Overflow",
    },
  ];
  const resource = [
    {
      value: "04bf29cf9ee4c3a0a",
      label: "04bf29cf9ee4c3a0a",
    },
    {
      value: "04bf29cf9ee4c3a0a",
      label: "04bf29cf9ee4c3a0a",
    },
  ];

  const refreshPolicyDetails = () => {
    setSelectedAccounts([]);
    setSelectedDatastores([]);
  };

  const getSelectedAccountIds = () => {
    let accountIds = [];
    if (selectedAccounts?.length > 0) {
      selectedAccounts.map((account) => {
        accountIds.push(account.value);
      });
    }
    return accountIds;
  };

  const getUpdatedDatastores = () => {
    let updatedSelectedDatastores = [];
    if (selectedDatastores.length > 0) {
      selectedDatastores.map((value) => {
        updatedSelectedDatastores.push(value.value);
      });
    }
    return updatedSelectedDatastores;
  };

  const getUpdatedPolicies = () => {
    let updatedSelectedPolicies = [];
    if (selectedPolicies.length > 0) {
      selectedPolicies.map((value) => {
        updatedSelectedPolicies.push({
          rule: value.value,
          description: value.label,
        });
      });
    }
    return updatedSelectedPolicies;
  };

  const getUpdatedTags = () => {
    let updatedSensitivityTags = [];
    if (selectedPiiTags.length > 0) {
      selectedPiiTags.map((value) => {
        updatedSensitivityTags.push(value.value);
      });
    }
    return updatedSensitivityTags;
  };

  const getTagIds = (tags) => {
    let ids = [];
    if (tags?.length > 0) {
      for (let tag of tags) {
        ids.push(tag.value);
      }
    }
    return ids;
  };

  const formattedTags = (ids) => {
    let tags = [];
    if (ids?.length > 0) {
      for (let id of ids) {
        tags.push({ label: id, value: id });
      }
    }
    return tags;
  };

  const addPolicy = async () => {
    setLoading(true);
    const resp = await dispatch(
      addPolicyAsync({
        cloudAccountIds: getSelectedAccountIds(),
        type: selectedDatastores.length
          ? "datastores"
          : selectedAccounts.length > 0
          ? "accounts"
          : "customer",
        description: description,
        datastoreIds: getUpdatedDatastores(),
        name: formik.values.name,
        policies: getUpdatedPolicies(),
        userId: getUserId(),
        category: "compliance",
        piiTags: getTagIds(selectedPiiTags),
        sensitivityTags: selectedSensitiveTags
          ? [selectedSensitiveTags.value]
          : [],
        customTags: getTagIds(customTag),
      })
    );

    let msg = "Customer level policy added successfully.";
    if (getUpdatedDatastores().length) {
      msg = "Datastore level policy added successfully.";
    } else if (getSelectedAccountIds().length) {
      msg = "Account level policy added successfully.";
    } else {
      msg = "Customer level policy added successfully.";
    }
    dispatch(hideLoading());
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, msg);
      setLoading(false);
      refreshAccounts();
      handleModal();
      refreshPolicyDetails();
    } else {
      setLoading(false);

      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to add policy."
      );
    }
  };

  const updatePolicy = async () => {
    setLoading(true);

    const resp = await dispatch(
      updatePolicyAsync({
        cloudAccountIds: getSelectedAccountIds(),
        type:
          selectedDatastores.length ||
          selectedSensitiveTags.value ||
          getTagIds(selectedPiiTags).length ||
          getTagIds(customTag).length
            ? "datastores"
            : selectedAccounts.length > 0
            ? "accounts"
            : "customer",
        _id: policyItem._id,
        description: description,
        policies: getUpdatedPolicies(),
        name: formik.values.name,
        datastoreIds: getUpdatedDatastores(),
        sensitivityTags: [selectedSensitiveTags.value],
        piiTags: getTagIds(selectedPiiTags),
        customTags: getTagIds(customTag),

        userId: getUserId(),
        category: "compliance",
      })
    );
    dispatch(hideLoading());
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, "Policy updated successfully.");
      setLoading(false);
      refreshAccounts();
      refreshPolicyDetails();
      handleModal();
    } else {
      setLoading(false);
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to update policy."
      );
    }
  };

  useEffect(() => {
    const accountList = [];
    accounts.map((account) => {
      accountList.push({ label: account.id, value: account.id });
    });
    setAccountsList(accountList);
  }, [accounts]);

  const updateSelectedAccounts = () => {
    let updatedAccounts = [];

    if (policyItem?.cloudAccountIds?.length > 0) {
      policyItem?.cloudAccountIds?.map((accountID) => {
        updatedAccounts.push({ label: accountID, value: accountID });
      });
    }
    formik.setFieldValue("accounts", updatedAccounts);
    setSelectedAccounts(updatedAccounts);
  };

  const getDatastorelabel = (datastoreId) => {
    for (let i of datastores) {
      if (i.value === datastoreId) {
        return i.label;
      }
    }
    return datastoreId;
  };

  const updateSelectedDatastores = () => {
    let updatedDatastores = [];

    if (policyItem?.datastoreIds?.length > 0) {
      policyItem?.datastoreIds?.map((datastore) => {
        updatedDatastores.push({
          label: getDatastorelabel(datastore),
          value: datastore,
        });
      });
    }
    formik.setFieldValue("datastores", updatedDatastores);
    setSelectedDatastores(updatedDatastores);
  };

  const updateSensitivityTags = () => {
    let updatedTags = [];

    if (policyItem?.sensitivityTags?.length > 0) {
      policyItem?.sensitivityTags?.map((tag) => {
        updatedTags.push({ label: tag, value: tag });
      });
    }
    formik.setFieldValue("sensitiveTags", updatedTags);
    setSelectedPiiTags(updatedTags);
  };

  const getPolicyDescription = (rule) => {
    for (let i of policiesList) {
      if (rule === i.value) {
        return i.label;
      }
    }
    return rule;
  };

  const updatePolicies = () => {
    let updatedPolicies = [];

    if (policyItem?.policies?.length > 0) {
      policyItem?.policies?.map((policy) => {
        if (policy.description) {
          updatedPolicies.push({
            label: policy.description,
            value: policy.rule,
          });
        } else {
          updatedPolicies.push({
            label: getPolicyDescription(policy.rule),
            value: policy.rule,
          });
        }
      });
    }
    formik.setFieldValue("policies", updatedPolicies);
    setSelectedPolicies(updatedPolicies);
    setSelectedPiiTags(formattedTags(policyItem.piiTags));
    setSelectedSensitiveTags(formattedTags(policyItem.sensitivityTags));
    setCustomTag(formattedTags(policyItem.customTags));
  };

  useEffect(() => {
    if (policyAction === "edit") {
      formik.setFieldValue("name", policyItem.name);
      setDescription(policyItem.description);
      updateSelectedAccounts();
      updateSelectedDatastores();
      updateSensitivityTags();
      updatePolicies();
    }
  }, [datastores]);

  const getDatastores = async () => {
    setLoading(true);
    const cloudAccountId =
      selectedAccounts.value === "all" ? null : selectedAccounts.value;

    let payload = {
      customerId: getCustomerId(),
      limit: limit,
      page: page,
      cloudAccountId: cloudAccountId,
    };

    if (REACT_APP_DATASTORE_API === "OPENSEARCH") {
      payload = {
        customerId: getCustomerId(),
        dataset: "datastores",
        exact: false,
        page: page,
        limit: limit,
        cloudAccountId: cloudAccountId,
      };
    }

    let res =
      REACT_APP_DATASTORE_API === "OPENSEARCH"
        ? await getDataStoresApiV2(payload)
        : await getDataStoresApi(payload);

    if (res.err) {
      setLoading(false);
      return;
    }

    if (!res.data) return;
    res = res.data;

    const datastoreList = [];
    res.map((d) => {
      datastoreList.push({
        label: d.name,
        value: d.datastoreId,
      });
    });
    // selectedDatastores;
    setDatastores(datastoreList);
    setLoading(false);
  };

  useEffect(() => {
    getDatastores();
  }, []);

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: theme.palette.surface0.main,
      fontSize: theme.typography.caption,
    }),
    option: (styles) => ({
      ...styles,
      color: theme.palette.surface0.main,
      fontSize: theme.typography.caption,
    }),
    input: (styles) => ({
      ...styles,
      color: theme.palette.surface0.main,
      fontSize: `${theme.typography.caption.fontSize} !important`,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.palette.surface100.main,
      fontSize: `${theme.typography.caption.fontSize} !important`,
      fontWeight: 400,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: theme.palette.primaryBlue.main,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme.palette.surface100.main,
      fontSize: `${theme.typography.caption.fontSize} !important`,
      fontWeight: 400,
    }),
  };

  return (
    <div id="add-policy-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <Box sx={styles.modalHeader}>
                  <Typography
                    color={theme.palette.surface80.main}
                    variant="body2"
                  >
                    {policyAction === "add" ? "Add" : "Update"} Compliance
                    Policy
                  </Typography>
                  <TextButton onClick={handleModal}>
                    <CloseRoundedIcon sx={styles.closeButton} />
                  </TextButton>
                </Box>
                {/* <img
                  src={deleteIcon}
                  className="mr-3 delete-icon"
                  onClick={handleModal}
                /> */}
              </div>
              <div className="modal-body mb-2">
                {loading && (
                  <img src={loaderImage} className="modal-loading-img" />
                )}
                {/* <div className="row py-2 text-center justify-content-start">
                  <div className="col col-sm-3 d-flex flex-row modal-subtitle justify-content-start align-items-center workday-subheading">
                    <input
                      type="radio"
                      id="default"
                      checked={policyType === "alerting"}
                      value={policyType}
                      onClick={() => setPolicyType("alerting")}
                      name="frequency"
                      className="mr-3  custom-radio-button"
                    />{" "}
                    Alerting Policies
                  </div>
                  <div className="col col-sm-6 modal-subtitle workday-subheading-value">
                    <input
                      type="radio"
                      id="default"
                      checked={policyType === "automation"}
                      value={policyType}
                      onClick={() => setPolicyType("automation")}
                      name="frequency"
                      className="mr-3 ml-2 custom-radio-button"
                    />{" "}
                    Custom Automation Policies
                  </div>
                </div> */}
                <label className="label">
                  Policy Name: <span className="mandatory-icon">*</span>
                </label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Policy Name"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        console.log(e.target.value);
                        formik.setFieldValue("name", e.target.value);
                      }}
                      value={formik.values.name}
                      id="compliance-policy-name"
                    ></input>
                    {formik.errors.name && formik.touched.name && (
                      <div className="error-message">{formik.errors.name}</div>
                    )}
                  </div>
                </div>{" "}
                {/* <label className="label">
                  Description: <span className="mandatory-icon">*</span>
                </label>
                <div className="row py-2">
                  <div className="col col-sm-12 workday-subheading-value">
                    <textarea
                      className="modal-textarea form-control py-2 px-2"
                      placeholder="Account Description"
                      type="text"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}

                    />
                  </div>
                </div>{" "} */}
                <h6 className="mb-2 mt-2">Select Target</h6>
                <div className="ml-3 mr-3">
                  {/* <div class="d-flex flex-wrap chip-container policy-chip-container">
                    {selectedAccounts.map((d, index) => (
                      <div class="chip m-1" key={index}>
                        <span className="mr-2"> {d.label}</span>
                        <img
                          className="close-icon"
                          src={closeIcon}
                          title="Close"
                        />
                      </div>
                    ))}
                    {selectedDatastores.map((d, index) => (
                      <div class="chip m-1" key={index}>
                        <span className="mr-2"> {d.label}</span>
                        <img
                          className="close-icon"
                          src={closeIcon}
                          title="Close"
                        />
                      </div>
                    ))}
                    {selectedPolicies.map((d, index) => (
                      <div class="chip m-1" key={index}>
                        <span className="mr-2"> {d.label}</span>
                        <img
                          className="close-icon"
                          src={closeIcon}
                          title="Close"
                        />
                      </div>
                    ))}
                  </div> */}
                  <label className="label">Customer:</label>
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter Policy Name"
                        value={getCustomerName()}
                        disabled={true}
                        id="compliance-policy-customer"
                      ></input>
                    </div>
                  </div>
                  <label className="label">Accounts:</label>
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <Select
                        isMulti
                        name="colors"
                        value={selectedAccounts}
                        options={accountsList}
                        className="basic-multi-select"
                        styles={colorStyles}
                        classNamePrefix="select"
                        onBlur={formik.handleBlur}
                        onChange={(value) => {
                          setSelectedAccounts(value);
                          formik.setFieldValue("accounts", value);
                        }}
                        id="compliance-policy-accounts"
                      />
                    </div>
                  </div>
                  <label className="label">Datastores:</label>
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <Select
                        isMulti
                        name="colors"
                        value={selectedDatastores}
                        options={datastores}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={colorStyles}
                        onBlur={formik.handleBlur}
                        onChange={(value) => {
                          setSelectedDatastores(value);
                          formik.setFieldValue("datastores", value);
                        }}
                        id="compliance-policy-datastores"
                        isDisabled={selectedAccounts.length ? false : true}
                      />
                    </div>
                  </div>

                  <h6 className="mt-2 font-13">Tags</h6>
                  <div class="d-flex flex-wrap chip-container policy-chip-container"></div>
                  <div className="ml-3 mr-3">
                    <div className="row py-2">
                      <div className="col col-sm-12 workday-subheading-value py-1">
                        <label className="label">PII tags:</label>

                        <Select
                          name="colors"
                          isMulti
                          options={piiTags}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={colorStyles}
                          styles={colorStyles}
                          placeholder="Select PII Tag"
                          onChange={(value) => {
                            setSelectedPiiTags(value);
                          }}
                          id="compliance-policy-pii-tags"
                          value={selectedPiiTags}
                          // isDisabled={loader}
                        />
                      </div>

                      <div className="col col-sm-12 workday-subheading-value py-1">
                        <label className="label">Sensitive tags: </label>

                        <Select
                          isMulti={false}
                          options={sensitiveTags}
                          classNamePrefix="accounts-dropdown"
                          placeholder="Select Sensitive Tag"
                          styles={colorStyles}
                          onChange={(value) => {
                            setSelectedSensitiveTags(value);
                          }}
                          id="compliance-policy-sensitive-tags"
                          value={selectedSensitiveTags}
                        />
                      </div>

                      <div className="col col-sm-12 workday-subheading-value py-1">
                        <label className="label">
                          Custom tag:{" "}
                          <small className="font-weight-bold">
                            (Hit enter key to add tag)
                          </small>
                        </label>

                        <input
                          class="form-control"
                          type="text"
                          placeholder="Enter Custom Tag"
                          onKeyUp={(e) => {
                            if (e.key === "Enter" && e.target.value !== "") {
                              updateTags({
                                value: e.target.value,
                                label: e.target.value,
                                type: "custom",
                              });
                              e.target.value = "";
                              setTagsUpdated(true);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.customTag}
                          id="compliance-policy-custom-tag"
                        ></input>
                        <div className="error-message">{customTagErrors} </div>
                      </div>

                      <div className="ml-2 flex">
                        {customTag.map((tag, i) => (
                          <div class="chip policy-chip m-1" key={i}>
                            <span className="mr-2">
                              {tag.label ? tag.label : tag}
                            </span>
                            <img
                              className="close-icon"
                              src={closeIcon}
                              title="Close"
                              onClick={() => (loader ? null : removeTag(tag))}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="mb-2 mt-2">Select Policies</h6>
                <div className="ml-3 mr-3">
                  <label className="label">
                    Policies: <span className="mandatory-icon">*</span>
                  </label>
                  {/* <div class="d-flex flex-wrap chip-container policy-chip-container">
                    {selectedPolicies.map((d, index) => (
                      <div class="chip m-1" key={index}>
                        <span className="mr-2"> {d.label}</span>
                        <img
                          className="close-icon"
                          src={closeIcon}
                          title="Close"
                        />
                      </div>
                    ))}
                  </div> */}
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <Select
                        isMulti
                        name="colors"
                        // value={selectedPolicies}
                        value={formik.values.policies}
                        options={policiesList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={colorStyles}
                        onBlur={formik.handleBlur}
                        onChange={(value) => {
                          setSelectedPolicies(value);
                          formik.setFieldValue("policies", value);
                        }}
                        id="compliance-policy-policy"
                      />
                      {formik.touched.policies &&
                        selectedPolicies.length === 0 && (
                          <div className="error-message">
                            {"Please select policy."}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer p-2">
                {policyAction === "add" ? (
                  <button
                    className="btn btn-primary mr-2"
                    disabled={!formik.isValid || !formik.values.name || loading}
                    type="button"
                    onClick={formik.handleSubmit}
                    id="compliance-policy-add"
                  >
                    Add Data Policy
                  </button>
                ) : (
                  <button
                    className="btn btn-primary mr-2"
                    disabled={!formik.isValid || !formik.values.name || loading}
                    type="button"
                    onClick={formik.handleSubmit}
                    id="compliance-policy-update"
                  >
                    Update Policy
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    handleModal();
                  }}
                  id="compliance-policy-cancel"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompliancePolicyModal;
