import React, { useState } from "react";
import { getCustomerId } from "../../utils/SessionHelper";
import { useParams, useLocation } from "react-router-dom";
import Table from "../../components/vulnerabilities/table";
import { getIssuesApi } from "../../apis";
import { addIndex } from "../../utils/dataHandler";
import { useEffect } from "react";
import Pagination from "../pagination/Pagination";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules
import NoDataContainer from "../../components/cards/no-data";
import "./DatastoreIssues.scss";
import { formatHeader } from "../../utils/uppercase";
import Select, { components } from "react-select";
import { ReactComponent as FilterIcon } from "../../assets/img/new-theme/filter.svg";
const DatastoreIssues = ({
  assetIdValue,
  isAttackPath = false,
  node = null,
  setLoading,
  selectedTab,
  pageName,
  loading,
  noFilters,
}) => {
  const params = useParams();
  const location = useLocation();
  const [severity, setSeverity] = useState(null);
  const [dataStoreIssuesList, setDataStoreIssuesList] = useState([]);
  const page_ = sessionStorage.getItem("page");
  const assetId = assetIdValue?.replaceAll("_", "/");
  const [isRiskAccepted, setIsRiskAccepted] = useState(false);
  const policyViolationId = params.policyViolationId;
  const isPolicyViolation = location.pathname.includes("/policy-details");

  const [selectedFilter, setSelectedFilter] = useState("all");

  const [page, setPage] = useState(!assetId && page_ ? parseInt(page_) : 1);
  const [totalIssuesCount, setTotalIssuesCount] = useState(0);
  const [subFilter, setSubFilter] = useState({
    value: "Active",
    label: "Active",
  });
  const [subFiltersList, setSubFiltersList] = useState([
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Risk Accepted",
      label: "Risk Accepted",
    },
    {
      value: "Resolved",
      label: "Resolved",
    },
  ]);

  let limit = 5;

  const getDataStoreIssues = async (isNode) => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      page: page,
      limit: limit,
      assetId: assetId,
    };
    if (isNode) {
      if (node.datastore_node && isPolicyViolation) {
        payload.policyViolations = true;
      } else {
        if (isRiskAccepted) {
          payload.risk_accepted = true;
        } else {
          payload.risk_accepted = false;
        }
      }
    } else {
      if (selectedFilter === "all") {
        if (subFilter.value === "Active") {
          payload.risk_accepted = false;
        }
        if (subFilter.value === "Risk Accepted") {
          payload.risk_accepted = true;
        }
        if (subFilter.value === "Resolved") {
          payload.status = "resolved";
        }
      } else if (selectedFilter === "policy-violation") {
        if (subFilter.value === "Active") {
          payload.policyViolations = true;
        }
        if (subFilter.value === "Resolved") {
          payload.policyViolations = true;
          payload.status = "resolved";
        }
      }
    }

    let res = await getIssuesApi(payload);

    if (res && !res.err) {
      if (!res.data) {
        setDataStoreIssuesList([]);
        setTotalIssuesCount(0);
        setLoading(false);
        return;
      }

      setTotalIssuesCount(res.count);
      res = addIndex(res.data);
      setDataStoreIssuesList(res);
      setLoading(false);
    } else {
      setLoading(false);
      setDataStoreIssuesList([]);
      setTotalIssuesCount(0);
    }
  };
  const handleRiskToggle = () => {
    setIsRiskAccepted(!isRiskAccepted);
  };

  useEffect(() => {
    if (page === 1) {
      if (!isAttackPath) {
        getDataStoreIssues();
      }
    } else {
      setPage(1);
    }
  }, [subFilter, selectedFilter]);

  useEffect(() => {
    if (isAttackPath && node && node?.is_primary) {
      getDataStoreIssues(true);
    } else if (isAttackPath && selectedTab === "access") {
      getDataStoreIssues(true);
    } else if (!isAttackPath) {
      getDataStoreIssues();
    }
  }, [page, isRiskAccepted, severity, node]);

  useEffect(() => {
    if (selectedFilter === "all") {
      setSubFiltersList([
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Risk Accepted",
          label: "Risk Accepted",
        },
        {
          value: "Resolved",
          label: "Resolved",
        },
      ]);
    } else if (selectedFilter === "policy-violation") {
      setSubFiltersList([
        {
          value: "Active",
          label: "Active",
        },

        {
          value: "Resolved",
          label: "Resolved",
        },
      ]);
    }
  }, [selectedFilter]);

  const getAttackPathContents = () => {
    if (node && node?.tooltip_content) {
      const contents = node?.tooltip_content;
      let popupContents = [];
      let index = 1;
      for (var key in contents) {
        index++;
        if (contents.hasOwnProperty(key)) {
          var val = contents[key];
          if (val) {
            if (
              (key === "eks_cluster" || key === "EKS Cluster") &&
              node?.eks_enabled === false
            ) {
              continue;
            } else {
              popupContents.push(
                <div className=" pr-4" key={index}>
                  <div className="node-title">{formatHeader(key)}</div>
                  <div
                    dangerouslySetInnerHTML={{ __html: val }}
                    className="node-description"
                  ></div>
                </div>
              );
            }
          }
        }
      }
      return (
        <div>
          <div className="d-flex flex-wrap m-1 mx-2">{popupContents}</div>
        </div>
      );
    } else {
      return (
        <div className="right-panel">
          <p className="header m-0">{node?.name || ""}</p>
        </div>
      );
    }
  };
  const CaretDownIcon = () => {
    return <FilterIcon />;
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="datastore-details-issues">
      <div
        className={isAttackPath && node ? "attack-path-section ml-2" : "ml-2"}
      >
        {isAttackPath && node && getAttackPathContents()}
      </div>

      {isAttackPath && selectedTab !== "access" && node?.is_primary ? (
        <div>
          <div className="d-flex justify-content-between">
            {/* {isAttackPath && node && ( */}
            <div className="d-flex justify-content-start">
              <span className="body-title mt-3 ml-3">Security Issues</span>
            </div>
            {/* )} */}

            <div
              className={
                isAttackPath && node
                  ? "d-flex justify-content-end mt-3"
                  : "d-flex justify-content-end "
              }
            >
              <span className="mx-4">
                {noFilters ? (
                  ""
                ) : (
                  <Select
                    options={subFiltersList}
                    classNamePrefix="accounts-dropdown"
                    placeholder="Filters"
                    defaultValue={
                      subFiltersList.length > 0 && subFiltersList[0]
                    }
                    components={{ DropdownIndicator }}
                    isRtl
                    onChange={(value) => setSubFilter(value)}
                    isSearchable={false}
                    className="accounts-dropdown"
                  />
                )}
              </span>
              <div className="mr-4">
                <Pagination
                  page={page}
                  setPage={(val) => setPage(val)}
                  limit={limit}
                  totalCount={totalIssuesCount}
                />
              </div>
            </div>
          </div>
          {dataStoreIssuesList.length ? (
            <Table
              pageName={pageName}
              severity={severity}
              currentPosts={dataStoreIssuesList}
              // sortData={sortData}
              isTicketColEnable={false}
              isActionColEnable={false}
              isRiskAccepted={
                subFilter.value === "Risk Accepted" ? true : false
              }
              // refreshIssues={getIssues}
              impactedDatastoreEnable={false}
              instanceLocationEnable={false}
            />
          ) : !loading ? (
            <NoDataContainer message={"No Issues Available"} />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {isAttackPath && selectedTab === "access" ? (
        <div>
          <div className="d-flex justify-content-between">
            {/* {isAttackPath && node && ( */}
            <div className="d-flex justify-content-start">
              <span className="body-title mt-3 ml-3">Security Issues</span>
            </div>
            {/* )} */}

            <div
              className={
                isAttackPath && node
                  ? "d-flex justify-content-end mt-3"
                  : "d-flex justify-content-end "
              }
            >
              {!node.datastore_node && selectedFilter === "policy-violation" && (
                <span className="mx-4">
                  <label
                    htmlFor="risk-accept-toggle"
                    className="toggle-label mr-2"
                  >
                    Risk Accepted
                  </label>
                  <Toggle
                    defaultChecked={isRiskAccepted}
                    onChange={handleRiskToggle}
                    className="risk-accept-toggle"
                    icons={false}
                    id="risk-accept-toggle"
                  />
                </span>
              )}

              <div className="mr-4">
                <Pagination
                  page={page}
                  setPage={(val) => setPage(val)}
                  limit={limit}
                  totalCount={totalIssuesCount}
                />
              </div>
            </div>
          </div>
          {dataStoreIssuesList.length ? (
            <Table
              pageName={pageName}
              severity={severity}
              currentPosts={dataStoreIssuesList}
              // sortData={sortData}
              isTicketColEnable={false}
              isActionColEnable={false}
              isRiskAccepted={isRiskAccepted}
              // refreshIssues={getIssues}
              impactedDatastoreEnable={false}
              instanceLocationEnable={false}
            />
          ) : !loading ? (
            <NoDataContainer message={"No Issues Available"} />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {!isAttackPath && (
        <div className="datastore-issues">
          <div
            className={
              isAttackPath && node
                ? "d-flex justify-content-between mt-3"
                : "d-flex justify-content-between "
            }
          >
            <div className="pt-2">
              <span className="body-title"> Datastore Security Issues</span>
            </div>
            {/* <div className="d-flex pt-1">
              <span className="mx-4">
                <label
                  htmlFor="risk-accept-toggle"
                  className="toggle-label mr-2"
                >
                  Risk Accepted
                </label>
                <Toggle
                  defaultChecked={isRiskAccepted}
                  onChange={handleRiskToggle}
                  className="risk-accept-toggle"
                  icons={false}
                  id="risk-accept-toggle"
                />
              </span>
              <div className="mr-4">
                <Pagination
                  page={page}
                  setPage={(val) => setPage(val)}
                  limit={limit}
                  totalCount={totalIssuesCount}
                />
              </div>
            </div> */}
          </div>{" "}
          <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
            <li class="nav-item" onClick={() => setSelectedFilter("all")}>
              <a
                class={
                  selectedFilter === "all" ? "nav-link active" : "nav-link"
                }
                id="all-tab"
                data-toggle="pill"
                href="#all"
                role="tab"
                aria-selected="false"
              >
                Risk
              </a>
            </li>
            <li
              class="nav-item"
              onClick={() => setSelectedFilter("policy-violation")}
            >
              <a
                class={
                  selectedFilter === "policy-violation"
                    ? "nav-link active"
                    : "nav-link"
                }
                id="policy-violation-tab"
                data-toggle="pill"
                href="#policy-violation"
                role="tab"
                aria-controls="high-risk"
                aria-selected="false"
              >
                Policy Violation
              </a>
            </li>{" "}
            <li className=" d-flex align-items-center ml-auto">
              <Select
                options={subFiltersList}
                classNamePrefix="accounts-dropdown"
                placeholder="Filters"
                defaultValue={subFiltersList.length > 0 && subFiltersList[0]}
                components={{ DropdownIndicator }}
                isRtl
                onChange={(value) => setSubFilter(value)}
                isSearchable={false}
                className="accounts-dropdown"
              />
            </li>
            <div className="d-flex  align-items-center view-all">
              <Pagination
                page={page}
                setPage={(val) => setPage(val)}
                limit={limit}
                totalCount={totalIssuesCount}
              />{" "}
            </div>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="all"
              role="tabpanel"
              aria-labelledby="high-risk-tab"
            >
              {dataStoreIssuesList.length ? (
                <Table
                  pageName={pageName}
                  severity={severity}
                  currentPosts={dataStoreIssuesList}
                  // sortData={sortData}
                  isTicketColEnable={false}
                  isActionColEnable={false}
                  isRiskAccepted={isRiskAccepted}
                  // refreshIssues={getIssues}
                  impactedDatastoreEnable={false}
                  instanceLocationEnable={false}
                  selectedFilter={selectedFilter}
                />
              ) : !loading && pageName !== "datastore-info" ? (
                <NoDataContainer message={"No Issues Available"} />
              ) : (
                <div className="d-flex justify-content-center">
                  <div>No Issues Detected Yet</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatastoreIssues;
