import MUIMenu from '@mui/material/Menu';
import type { MenuProps } from '@mui/material/Menu';
import MUIMenuItem from '@mui/material/MenuItem';
import type { MenuItemProps } from '@mui/material/MenuItem';
import { useTheme } from '@mui/material';

export const Menu = (args: MenuProps) => {
  const theme = useTheme();
  const styles = {
    menu: {
      '& .MuiList-root': {
        backgroundColor: theme.palette.surface70.main,
      },
      '& .MuiPaper-root': {
        marginTop: theme.spacing(0.5),
      },
    },
  };
  return <MUIMenu sx={styles.menu} {...args} />;
};

export const MenuItem = (args: MenuItemProps) => {
  return <MUIMenuItem {...args} />;
};
