import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useMediaQuery, useTheme } from "@mui/material";
import { SyntheticEvent, useContext, useState } from "react";
import { AppContext } from "../../context/appContext";
import { TabData } from "../../types/type";
import BarChart from "../common/BarChart";
import Box from "../common/Box";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import { useHistory } from "react-router-dom";
import { datastoreFilter } from "../constants/constants";
import { DATASTORE } from "../../routes/constants";

export interface Props {
  listOfTabs: { value: string; label: string; filter?: string }[];
  activeTabNumber: string;
  tabData: TabData[];
  tabTitle?: string;
  openDrawer: boolean;
  buttonText: string;
  showButton: boolean;
}

const BarChartCard = ({
  listOfTabs,
  activeTabNumber,
  tabData = [],
  tabTitle = "",
  openDrawer = false,
  buttonText,
  showButton = false,
}: Props) => {
  const theme = useTheme();
  const history = useHistory();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down("lg"));
  const [activeTab, setActiveTab] = useState(activeTabNumber);
  const [activeTabData, setActiveTabData] = useState({});

  const { setOpenPolicyStoreDetailDrawer, setDrawerData } =
    useContext(AppContext);

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setActiveTabData(tabData.find((tab) => tab?.tabIndex === newValue) || {});
  };

  const styles = {
    barChartCardContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      flexDirection: "column",
    },
    tabContainer: {
      display: "flex",
      width: "100%",
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    moreDetailsButton: {
      display: "flex",
      marginLeft: "auto",
    },
    tabPanel: {
      display: "flex",
      alignItems: "center",
      gap: isSmallWidth ? theme.spacing(1) : theme.spacing(2),
    },
    tabPanelHeading: {
      fontWeight: theme.typography.fontWeightLight,
      opacity: "50%",
    },
  };

  const setOpenDetailDrawer = () => {
    const selectedPolicy = tabData.find((tab) => tab.tabIndex === activeTab)
      ?.barData[0];
    setDrawerData({
      tabs: listOfTabs,
      tabData: tabData,
      activeTab: activeTab,
      currentSelectedPolicy: { ...selectedPolicy, id: selectedPolicy?.id || 0 },
    });
    setOpenPolicyStoreDetailDrawer(true);
  };

  const openDataStorePage = () => {
    const tab = datastoreFilter.find((tab) => tab.value === activeTab)?.filter;
    history.push(`${DATASTORE}?tab=${tab}`);
  };

  return (
    <Box sx={styles.barChartCardContainer}>
      <Box sx={styles.tabContainer}>
        <Box sx={styles.tabPanel}>
          {tabTitle !== "" && (
            <Typography
              variant="caption"
              color={theme.palette.surface80.main}
              sx={styles.tabPanelHeading}
            >
              {tabTitle}
            </Typography>
          )}
          <Tabs value={activeTab} onChange={handleTabChange}>
            {listOfTabs.map((tab: { label: string }, index: number) => (
              <Tab {...tab} key={index} disabled={tab?.label === "AI"} />
            ))}
          </Tabs>
        </Box>
        {showButton && (
          <TextButton
            hideText={true}
            endIcon={<KeyboardArrowRightIcon />}
            sx={styles.moreDetailsButton}
            onClick={openDrawer ? setOpenDetailDrawer : openDataStorePage}
          >
            {buttonText}
          </TextButton>
        )}
      </Box>
      {tabData?.map((tab) => (
        <TabPanel value={activeTab} index={tab?.tabIndex} key={tab?.tabIndex}>
          <BarChart {...tab} activeTab={activeTab} />
        </TabPanel>
      ))}
    </Box>
  );
};

export default BarChartCard;
