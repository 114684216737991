import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateSecurityIssueInstanceRiskAsync,
  updateSecurityIssueRiskAsync,
} from "../../../redux/slices/issuesSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import "./../modal.scss";
import "./RiskAcceptModal.scss";
import RiskAcceptCircle from "../../../assets/img/icons/risk-accept-circle.svg";
import RiskAccepted from "../../../assets/img/icons/risk-accepted.svg";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";

const RiskAcceptModal = ({
  handleModal,
  securityIssueId,
  displayId,
  instanceLocation,
  riskAccepted,
  allRiskAccepted,
  securityIssueInstanceId,
  // refreshIssues,
}) => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [instance, setInstance] = useState("default");

  const handleInstance = (checked, value) => {
    setInstance(value);
  };

  const refreshIssues = () => {
    window.location.reload();
  };

  // Submit handler
  const submitModal = async () => {
    setLoader(true);
    const resp = await dispatch(
      instance === "default"
        ? updateSecurityIssueInstanceRiskAsync({
            customerId: getCustomerId(),
            securityIssueInstanceId: securityIssueInstanceId,
            risk_accepted: !riskAccepted,
          })
        : updateSecurityIssueRiskAsync({
            customerId: getCustomerId(),
            securityIssueId: securityIssueId,
            risk_accepted: !riskAccepted,
          })
    );
    if (resp && resp.payload && !resp.payload.err) {
      // Toaster(
      //   TOASTER_TYPES.SUCCESS,
      //   `${riskAccepted ? "Restored risk" : "Risk accepted"} successfully.`
      // );
      setLoader(false);
      refreshIssues();
      handleModal(false);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        `Failed to ${riskAccepted ? "restore" : "accept"} risk.`
      );
      setLoader(false);
    }
  };

  return (
    <div id="risk-accept-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            {loader && (
              <img
                src="/static/img/loader-circle.svg"
                className="modal-loading-img mt-2"
              ></img>
            )}
            <div className="modal-header d-flex justify-content-start align-items-center">
              {riskAccepted === true ? (
                <div className="row">
                  <div className="col col-md-auto">
                    <img src={RiskAccepted} width="40" className="" />
                  </div>
                  <div className="col d-flex pl-0 align-items-center">
                    <span className="header-title risk-modal-title-accepted">
                      Restore Risk
                    </span>
                  </div>
                </div>
              ) : (
                <span>
                  <div className="row">
                    <div className="col col-md-auto">
                      <img src={RiskAcceptCircle} className="" />
                    </div>
                    <div className="col d-flex pl-0 align-items-center">
                      <span className="header-title risk-modal-title-accept">
                        Accept Risk for {displayId}
                      </span>
                    </div>
                  </div>
                </span>
              )}
            </div>
            <div className="modal-body mb-2">
              <div className="row p-3">
                <div className="col col-sm-12 p-2 d-flex flex-row justify-content-start align-items-top accept-risk-body">
                  <label className="radio-label">
                    <input
                      type="radio"
                      checked={instance === "default"}
                      onChange={(e) =>
                        handleInstance(e.target.checked, "default")
                      }
                      className="mr-3 ml-2 custom-radio-button"
                    />
                    <span className="radio-button-text">
                      <span className="subvalue-bold">
                        Security Instance: {instanceLocation}
                      </span>
                    </span>
                  </label>
                </div>
                <div className="col col-sm-12 p-2 d-flex flex-row justify-content-start align-items-top accept-risk-body">
                  <div className="row">
                    <div className="col col-md-auto justify-content-top mt-1 align-items-top">
                      <label className="radio-label">
                        <input
                          type="radio"
                          checked={instance === "all"}
                          onChange={(e) =>
                            handleInstance(e.target.checked, "all")
                          }
                          className="ml-2 custom-radio-button"
                        />
                        <span className="radio-button-text ml-3">
                          <span className="subvalue-bold">
                            Do you want to{" "}
                            {riskAccepted ? "restore " : "accept "}
                            risk for all the instances?
                          </span>
                          <p className="risk-modal-description">
                            All existing and future issues will be{" "}
                            {riskAccepted ? "restored" : "accepted"}
                          </p>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer p-2">
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => submitModal()}
                disabled={loader}
                id="risk-accept-submit"
              >
                Submit
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleModal}
                id="risk-accept-cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskAcceptModal;
