import Card from "./card";
import ActiveIcon from "../../../assets/img/icons/small/active.svg";
import BugIcon from "../../../assets/img/icons/small/bug.svg";
import ServerIcon from "../../../assets/img/icons/small/server.svg";

const ChecklistCards = ({ vunerability, filterIssues }) => {
  const activeCards = [
    {
      title: "LeapLabs:RegUsers",
      content:
        "The Data Store is misconfigured and on 7 Attack Paths. The Data Store also contains sensitive data. Addressing this risk will reduce your Enterprise Risk Score.",
      footer: "- 10 Point ERS Reduction",
      type: "danger",
    },
  ];
  const riskCards = [
    {
      title: "LeapLabs:RegUsers",
      content:
        "The Data Store is misconfigured and on 7 Attack Paths. The Data Store also contains sensitive data. Addressing this risk will reduce your Enterprise Risk Score.",
      footer: "- 10 Point ERS Reduction",
      type: "danger",
    },
    {
      title: "LeapLabs:RegUsers",
      content:
        "The Data Store is misconfigured and on 7 Attack Paths. The Data Store also contains sensitive data. Addressing this risk will reduce your Enterprise Risk Score.",
      footer: "- 10 Point ERS Reduction",
      type: "danger",
    },
    {
      title: "LeapLabs:RegUsers",
      content:
        "The Data Store is misconfigured and on 7 Attack Paths. The Data Store also contains sensitive data. Addressing this risk will reduce your Enterprise Risk Score.",
      footer: "- 10 Point ERS Reduction",
      type: "warning",
    },
    {
      title: "LeapLabs:RegUsers",
      content:
        "The Data Store is misconfigured and on 7 Attack Paths. The Data Store also contains sensitive data. Addressing this risk will reduce your Enterprise Risk Score.",
      footer: "- 10 Point ERS Reduction",
      type: "warning",
    },
  ];

  const Softwares = [
    {
      title: "LeapLabs:RegUsers",
      content:
        "The Data Store is misconfigured and on 7 Attack Paths. The Data Store also contains sensitive data. Addressing this risk will reduce your Enterprise Risk Score.",
      footer: "- 10 Point ERS Reduction",
      type: "danger",
    },
    {
      title: "LeapLabs:RegUsers",
      content:
        "The Data Store is misconfigured and on 7 Attack Paths. The Data Store also contains sensitive data. Addressing this risk will reduce your Enterprise Risk Score.",
      footer: "- 10 Point ERS Reduction",
      type: "danger",
    },
    {
      title: "LeapLabs:RegUsers",
      content:
        "The Data Store is misconfigured and on 7 Attack Paths. The Data Store also contains sensitive data. Addressing this risk will reduce your Enterprise Risk Score.",
      footer: "- 10 Point ERS Reduction",
      type: "warning",
    },
  ];

  return (
    <>
      <h6 className="card-title-danger mt-4">
        <img alt="" src={ActiveIcon} />
        ACTIVE ATTACKS
      </h6>

      <div class="row">
        {activeCards.map((card, index) => (
          <Card
            title={card.title}
            content={card.content}
            footer={card.footer}
            type={card.type}
            key={index}
            active={true}
            index={index}
          />
        ))}
      </div>

      <h6 className="card-title-primary mt-4">
        <img alt="" src={BugIcon} />
        REDUCE RISK IN DATA STORES
      </h6>

      <div class="row">
        {riskCards.map((card, index) => (
          <Card
            title={card.title}
            content={card.content}
            footer={card.footer}
            type={card.type}
            key={index}
            index={index}
          />
        ))}
      </div>

      <h6 className="card-title-primary mt-4">
        <img alt="" src={ServerIcon} />
        ADDRESS SOFTWARE VULNERABILITIES
      </h6>

      <div class="row">
        {Softwares.map((card, index) => (
          <Card
            title={card.title}
            content={card.content}
            footer={card.footer}
            type={card.type}
            key={index}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

export default ChecklistCards;
