import React, { useState, useEffect } from "react";
import { memo } from "react";
import {
  getScoreColor,
  getScoreBorder,
  formatLine,
} from "../../../utils/styles";
import "./BottomPanelComponent.scss";
const BottomPanelComponent = ({ RefreshChart, attackPaths }) => {
  const [selected, setSelected] = useState(0);

  const handleRefresh = (idx, name) => {
    setSelected(idx);
    RefreshChart(idx, name);
  };

  useEffect(() => {
    RefreshChart(0, attackPaths[0]?.pathName);
  }, [attackPaths]);

  return (
    <div id="bottom-panel-main-container">
      <div className="row">
        <div className="col">
          <p>Attack Paths listed by Severity</p>
          <div className="attack-path-table">
            <div className="table-responsive">
              <table className="table table-border-partial table-hover">
                {/* <thead>
                <th></th>
                <th style={{ width: "50px" }}></th>
                <th style={{ width: "50px" }}></th>
              </thead> */}
                <tbody>
                  {attackPaths.map(function (item, idx) {
                    return (
                      <tr
                        key={idx}
                        onClick={() => handleRefresh(idx, item.pathName)}
                        className={
                          selected === idx ? "active-row vuls-row" : "vuls-row"
                        }
                      >
                        <td
                          className={`${getScoreBorder(item.pathScore)}`}
                        ></td>
                        <td
                          className="data-store-name"
                          style={{ wordBreak: "break-all", width: "45%" }}
                        >
                          <div
                            className="d-flex align-items-start justify-content-start mr-2"
                            title={item?.pathName}
                          >
                            {formatLine(item.pathName, 50)}
                          </div>
                          {/* <div className="d-flex align-items-start justify-content-start">
                        {" "}
                        {item.code}
                      </div> */}
                        </td>
                        <td
                          className=""
                          style={{ wordBreak: "break-all", width: "45%" }}
                        >
                          <div
                            className="d-flex align-items-start justify-content-start left-panel-subtitle"
                            title={item?.pathDescription}
                          >
                            {item.pathDescription.length > 50
                              ? item.pathDescription.slice(0, 50) + "..."
                              : item.pathDescription}
                          </div>
                        </td>

                        <td>
                          <img src="/static/img/ticket-unavailable.svg" />
                          {/* {item.ticket === 0 ? (
                        <img src="static/img/ticket-unavailable.svg" />
                      ) : (
                        <img src="static/img/ticket-available.svg" />
                      )}
                      <span className="pl-2 data-store-name">{item.ticket}</span> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BottomPanelComponent);
