import "./Inventory.scss";
import React, { useEffect, useState } from "react";
import PieChart from "./PieChart";
import WorldMap from "./WorldMap";
const Inventory = ({ selectedAccount }) => {
  const [Inventory, setInventory] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  return (
    <div id="inventory">
      <div className="title ">Inventory </div>
      <div className="row">
        <div className="col-md-4 col-lg-4 p-2 d-flex dashboard-divider">
          <PieChart selectedAccount={selectedAccount} />
          {/* <span className="divider"></span> */}
        </div>
        <div className="col-md-8 col-lg-8">
          <WorldMap
            selectedAccount={selectedAccount}
            setSelectedRegion={setSelectedRegion}
          />
        </div>
      </div>
    </div>
  );
};

export default Inventory;
