import React, { useEffect, useState } from "react";
import "../vulnerabilities/vulnerabilities.scss";
import { useHistory } from "react-router-dom";
import { getScoreColor } from "../../utils/styles";
import riskAccept from "../../assets/img/icons/risk-accept.svg";
import restoreRisk from "../../assets/img/icons/risk-accepted.svg";
import RiskAcceptModal from "../modal/risk-accept-modal/RiskAcceptModal";
import AddTicketsModal from "./AddTicketModal";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showLoading,
} from "../../redux/slices/globalOperationSlice";
import { listTicketsAsync } from "../../redux/slices/ticketSlice";
import ticketAvailable from "../../assets/img/ticket-available.svg";
import ticketUnavailable from "../../assets/img/ticket-unavailable.svg";
import AttackIcon from "../../assets/img/icons/attack-icon.svg";
import TicketIcon from "../../assets/img/icons/small/task-yellow.svg";
import CreateIssueModal from "../modal/issue-modal/CreateIssue";
import TicketUnavailableIcon from "../../assets/img/v1-icons/create-ticket.svg";

const Table = ({
  currentPosts,
  severity,
  sortData,
  pageName,
  isRiskAccepted,
  refreshIssues,
  isTicketColEnable = true,
  isActionColEnable = true,
  impactedDatastoreEnable = true,
  instanceLocationEnable = true,
  subFilter,
  selectedFilter,
  setIsRiskAccepted,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [ticketModal, setTicketModal] = useState(false);
  const [isRiskAcceptModal, setIsRiskAcceptModal] = useState(null);
  const [allRiskAccepted, setAllRiskAccepted] = useState(null);

  const [selectedSecurityInstance, setSelectedSecurityInstance] = useState({
    instanceId: "",
    issueId: "",
    instanceLocation: "",
    displayId: "",
  });

  const getPolicyNames = (items) => {
    if (!items) return;

    const names = [];
    for (let i of items) {
      names.push(i.name);
    }

    return names.join(", ");
  };

  const viewDetails = (item) => {
    history.push(
      `/vulnerabilities-details/${item.vulnerabilityID.replaceAll(
        "/",
        "_"
      )}/${item.instanceLocation.replaceAll("/", "_")}/${
        item.securityIssueInstanceId
      }/`
    );
  };

  const [addTicketsModal, setAddTicketsModal] = useState(false);
  const [ticketModalData, setTicketModalData] = useState({
    issue_id: "",
    instance_id: "",
  });

  const refreshIntegrations = async () => {
    dispatch(showLoading());
    dispatch(listTicketsAsync());
    setAddTicketsModal(false);
    dispatch(hideLoading());
  };

  return (
    <div className={`row ${pageName === "datastore-info" ? "m-0" : ""}`}>
      <div
        className={
          pageName === "datastore-info" ? "col col-xl-12 p-0" : "col col-xl-12"
        }
      >
        <div
          class={
            pageName === "asset" ||
            pageName === "datastore" ||
            pageName === "datastore-info"
              ? pageName === "datastore-info"
                ? "table-responsive table-scroll-datastore-info"
                : "table-responsive table-scroll "
              : "table-responsive"
          }
        >
          <table
            class={
              pageName === "datastore-info table-responsive"
                ? "table table-border-less table-responsive table-hover "
                : "table table-border-less table-responsive table-hover  px-3"
            }
          >
            <thead className="vuls-row">
              <tr className="head">
                <th
                  scope="col"
                  style={{ minWidth: "100px" }}
                  onClick={() => sortData("cdIssueRiskScore")}
                >
                  Data Risk
                  <i class="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  style={
                    pageName === "datastore-info"
                      ? { minWidth: "100px" }
                      : { minWidth: "100px" }
                  }
                  onClick={() => sortData("vulnerabilityId.keyword")}
                >
                  Issue ID
                  <i class="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  style={
                    pageName === "datastore-info"
                      ? { minWidth: "170px" }
                      : { minWidth: "200px" }
                  }
                  // style={{ minWidth: "220px" }}
                  onClick={() => sortData("name.keyword")}
                >
                  Name
                  <i class="las la-sort"></i>
                </th>

                {selectedFilter === "policy-violation" ? (
                  <th
                    scope="col"
                    style={
                      pageName === "datastore-info"
                        ? { minWidth: "170px" }
                        : { minWidth: "200px" }
                    }
                    // style={{ minWidth: "220px" }}
                    onClick={() => sortData("name.keyword")}
                  >
                    Policy Names
                  </th>
                ) : (
                  ""
                )}

                <th
                  scope="col"
                  style={{ minWidth: "160px" }}
                  onClick={() => sortData("issueType.keyword")}
                >
                  Type
                  <i class="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  style={{ minWidth: "100px" }}
                  onClick={() => sortData("displaySeverityScore.keyword")}
                >
                  Severity
                  <i class="las la-sort"></i>
                </th>
                {instanceLocationEnable && (
                  <th
                    scope="col"
                    style={{ minWidth: "220px" }}
                    onClick={() => sortData("instanceLocation.keyword")}
                  >
                    Instance Location
                    <i class="las la-sort"></i>
                  </th>
                )}
                {impactedDatastoreEnable && (
                  <th
                    scope="col"
                    style={{ minWidth: "100px" }}
                    onClick={() => sortData("impactedDatastoresCount")}
                  >
                    Impacted Datastores
                    <i class="las la-sort"></i>
                  </th>
                )}

                <th
                  scope="col"
                  style={{ minWidth: "150px" }}
                  onClick={() => sortData("totalInstanceCount")}
                >
                  Total Instances
                  <i class="las la-sort"></i>
                </th>

                {isActionColEnable &&
                isTicketColEnable &&
                subFilter?.value !== "Resolved" ? (
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Actions
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {currentPosts.map(function (item, idx) {
                return (
                  <tr
                    key={idx}
                    onClick={() => viewDetails(item)}
                    className={
                      severity ? `${severity} vuls-row` : "Low vuls-row"
                    }
                  >
                    <td>
                      {pageName === "datastore" &&
                        item?.issueType === "MaliciousActivity" && (
                          <img src={AttackIcon} width="20px" />
                        )}

                      <div
                        className={`${getScoreColor(item.cdIssueRiskScore)}`}
                      ></div>
                    </td>
                    <td className="table-data">
                      <div
                        className={
                          pageName === "datastore-info"
                            ? "wrap-title-datastore-info"
                            : "wrap-title"
                        }
                        title={item?.vulnerabilityID}
                      >
                        {pageName === "datastore-info"
                          ? item?.displayId.length > 17
                            ? item?.displayId.slice(0, 17) + " ..."
                            : item?.displayId
                          : item?.displayId}
                      </div>
                    </td>
                    <td className="data-store-name">
                      <div title={item?.name}>
                        <u>
                          {pageName === "datastore-info"
                            ? item.name.length > 16
                              ? item.name.slice(0, 16) + " ..."
                              : item?.name
                            : item.name.length > 40
                            ? item.name.slice(0, 40) + " ..."
                            : item?.name}
                        </u>
                      </div>
                    </td>

                    {selectedFilter === "policy-violation" ? (
                      <td className="data-store-name">
                        <div>{getPolicyNames(item.policyViolations)}</div>
                      </td>
                    ) : (
                      ""
                    )}

                    <td className="table-data" title={item?.issueType}>
                      {item?.issueType.length > 22
                        ? item.issueType.slice(0, 22) + "..."
                        : item.issueType}
                    </td>
                    <td className="table-type">
                      <i class="icon fa fa-circle text-danger red-dot"></i>{" "}
                      <span>
                        {item?.displaySeverityScore
                          ? item?.displaySeverityScore.split(" ")[0]
                          : ""}
                      </span>
                    </td>
                    {instanceLocationEnable && (
                      <td className="table-data" title={item?.instanceLocation}>
                        <div className="wrap-with-ellipsis">
                          {item.instanceLocation
                            ? item.instanceLocation.substring(
                                item.instanceLocation.lastIndexOf(":") + 1
                              )
                            : null}
                        </div>
                      </td>
                    )}
                    {impactedDatastoreEnable && (
                      <td className="table-type text-center">
                        <a href="#" className="mr-2">
                          {item?.impactedDatastoresCount}
                        </a>
                      </td>
                    )}
                    <td className="table-data text-center">
                      {item?.totalInstanceCount}
                    </td>
                    {/* {isTicketColEnable ? <td title="Ticket"></td> : ""} */}
                    {isActionColEnable && subFilter?.value !== "Resolved" ? (
                      <td
                        className="table-data"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="row">
                          {/* <div className="col pr-2">
                            {item?.tickets === 0 || item?.tickets === 1 ? (
                              <img
                                src={ticketUnavailable}
                                className="ticket-image"
                                title="Create Ticket"
                              />
                            ) : (
                              <img src={ticketAvailable} />
                            )}
                            
                          </div> */}
                          <div className="col justify-content-center">
                            <img
                              src={isRiskAccepted ? restoreRisk : riskAccept}
                              className="mb-1 "
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedSecurityInstance({
                                  instanceId: item.securityIssueInstanceId,
                                  displayId: item?.displayId,
                                  issueId: item.vulnerabilityID,
                                  instanceLocation: item?.instanceLocation
                                    ? item?.instanceLocation.substring(
                                        item?.instanceLocation.lastIndexOf(
                                          ":"
                                        ) + 1
                                      )
                                    : null,
                                });
                                setAllRiskAccepted(item.accepted_all_risks);
                                setIsRiskAcceptModal(true);
                              }}
                              width="25px"
                              title={
                                isRiskAccepted ? "Restore Risk" : "Accept Risk"
                              }
                            />
                            <img
                              src={
                                item.url ? TicketIcon : TicketUnavailableIcon
                              }
                              className="ticket-icon ml-3 mb-1"
                              onClick={
                                item.url ? () => window.open(item.url) : ""
                              }
                            />
                            {isRiskAcceptModal ? (
                              <RiskAcceptModal
                                securityIssueInstanceId={
                                  selectedSecurityInstance.instanceId
                                }
                                securityIssueId={
                                  selectedSecurityInstance.issueId
                                }
                                instanceLocation={
                                  selectedSecurityInstance.instanceLocation
                                }
                                displayId={selectedSecurityInstance.displayId}
                                riskAccepted={isRiskAccepted}
                                allRiskAccepted={allRiskAccepted}
                                handleModal={() => setIsRiskAcceptModal(false)}
                                refreshIssues={refreshIssues}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {isRiskAcceptModal ? (
        <RiskAcceptModal
          securityIssueInstanceId={selectedSecurityInstance.instanceId}
          securityIssueId={selectedSecurityInstance.issueId}
          instanceLocation={selectedSecurityInstance.instanceLocation}
          displayId={selectedSecurityInstance.displayId}
          riskAccepted={isRiskAccepted}
          allRiskAccepted={allRiskAccepted}
          handleModal={() => setIsRiskAcceptModal(false)}
          refreshIssues={refreshIssues}
        />
      ) : (
        ""
      )}
      {addTicketsModal && (
        <AddTicketsModal
          data={ticketModalData}
          handleModal={() => setAddTicketsModal(false)}
          refreshIntegrations={refreshIntegrations}
        />
      )}
    </div>
  );
};

export default Table;
