import { useTheme } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import { isDataWithinDefinedDate } from "../../utils/commonUtils";
import Bar from "../common/Bar";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";

const SensitiveDataTable = ({ sensitiveData, onRowClick }) => {
  const theme = useTheme();
  const {
    sensitiveDataFilter,
    selectedSensitiveType,
    setSelectedSensitiveType,
  } = useContext(AppContext);
  const styles = {
    tableContainer: {
      maxHeight: "400px",
      height: "400px",
      borderRadius: theme.spacing(0.5),
    },
    barCell: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    tableCell: {
      fontSize: "13px",
      padding: theme.spacing(2),
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      verticalAlign: "middle",
    },
  };

  const handleRowClick = (type) => {
    setSelectedSensitiveType(type);
    onRowClick();
  };

  return (
    <TableContainer sx={styles.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableCell>Labels</TableCell>
          <TableCell>Messages</TableCell>
        </TableHead>
        <TableBody>
          {sensitiveData?.map((dataItem) => {
            const recordCount = dataItem?.messages?.filter((message) =>
              isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
            )?.length;
            return (
              <TableRow
                selected={dataItem?.name === selectedSensitiveType}
                onClick={() => handleRowClick(dataItem?.name)}
              >
                <TableCell sx={styles.tableCell}>
                  <Typography
                    variant="caption"
                    color={theme.palette.surface60.main}
                  >
                    {dataItem?.name}
                  </Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Box sx={styles.barCell}>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {recordCount}
                    </Typography>
                    <Bar
                      isLastBar={false}
                      xLabel={""}
                      barBackColor={theme.palette.surface20.main}
                      barFaceColor={theme.palette.lightMagenta.main}
                      rectWidth={(recordCount / 10) * 100}
                      index={recordCount}
                      totalValue={10}
                      barLabel={""}
                      barValue={recordCount}
                      type={""}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SensitiveDataTable;
