import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { DonutData } from "../../types/type";
import { isContainsValue } from "../../utils/commonUtils";
import Box from "../common/Box";
import Divider from "../common/Divider";
import LegendWithColor from "../common/LegendWithColor";
import Loader from "../common/Loader";
import Typography from "../common/Typography";
import { DASHBOARD_AI_VIEW, DASHBOARD_DATA_VIEW } from "../constants/constants";
import ImpactedDataCard from "./ImpactedDonutCard";

const ImpactedDataStoresPanel = () => {
  const {
    impactedDataStores,
    isFetchingImpactedStoresData,
    aiSummary,
    getAISummary,
    getImpactedStoresData,
    dashboardViewLense,
  } = useContext(AppContext);
  const [donutData, setDonutData] = useState<Array<DonutData>>([]);

  useEffect(() => {
    if (dashboardViewLense === DASHBOARD_DATA_VIEW) {
      getImpactedStoresData();
    } else if (dashboardViewLense === DASHBOARD_AI_VIEW) {
      getAISummary();
    }
  }, [dashboardViewLense]);

  useEffect(() => {
    let donutData;
    if (
      dashboardViewLense === DASHBOARD_DATA_VIEW &&
      Object.values(impactedDataStores).length > 0
    ) {
      donutData = [
        {
          cardTitle: "Risk",
          donutData: [
            {
              value: impactedDataStores?.datastores_with_critical_risk || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: impactedDataStores?.datastores_with_high_risk || 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Policy Violations",
          donutData: [
            {
              value: impactedDataStores?.datastores_violating_policy || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Sensitive Data",
          donutData: [
            {
              value: impactedDataStores?.datastores_with_sensitive_data || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Shadow",
          donutData: [
            {
              value: impactedDataStores?.shadow_datastores + 0,
              color: theme.palette.lightMagenta.main,
              label: "Shadow Data",
              data: [
                {
                  value: impactedDataStores?.shadow_datastores || 0,
                  color: theme.palette.critical.main,
                  label: "Critical",
                },
                {
                  value: 0,
                  color: theme.palette.high.main,
                  label: "High",
                },
              ],
            },
            {
              value: impactedDataStores?.shadow_ai_datastores + 0,
              color: theme.palette.paleBlue.main,
              label: "Shadow AI/LLM",
              data: [
                {
                  value: impactedDataStores?.shadow_ai_datastores || 0,
                  color: theme.palette.critical.main,
                  label: "Critical",
                },
                {
                  value: 0,
                  color: theme.palette.high.main,
                  label: "High",
                },
              ],
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
      ];
    } else if (
      dashboardViewLense === DASHBOARD_AI_VIEW &&
      isContainsValue(aiSummary)
    ) {
      //let llmPolicyViolations = 0;
      //if (isContainsValue(aiSummary?.policy_violations)) {
      //  llmPolicyViolations = Object.values(
      //    aiSummary?.policy_violations
      //  )?.reduce((acc: number, curr: any) => acc + curr, 0);
      //}
      donutData = [
        {
          cardTitle: "All LLM Models",
          donutData: [
            {
              value: aiSummary?.llm_ds_type?.managed || 0,
              color: "#462AF8",
              label: "Managed",
            },
            {
              value: aiSummary?.llm_ds_type?.self_managed || 0,
              color: "#816DFF",
              label: "Self-Managed",
            },
            {
              value: aiSummary?.llm_ds_type?.shadow || 0,
              color: "#C7BFFC",
              label: "Shadow",
            },
          ],
          totalValue: 4,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "LLM Pipeline",
          donutData: [
            {
              value: aiSummary?.llm_status?.deployed || 0,
              color: "#005BAF",
              label: "Deployed",
            },
            {
              value: aiSummary?.llm_status?.under_fine_training || 0,
              color: "#92CAFF",
              label: "Under Finetuning",
            },
          ],
          totalValue: 4,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Framework",
          donutData: [
            {
              value: aiSummary?.llm_framework?.lang_chain || 0,
              color: "#D1AF7C",
              label: "Langchain",
            },
          ],
          totalValue: 4,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
        {
          cardTitle: "Policy Violations",
          donutData: [
            {
              value: aiSummary?.policy_violations?.LLMs || 0,
              color: "#AC6AEE",
              label: "LLM",
            },
            {
              value: aiSummary?.policy_violations?.Frameworks || 0,
              color: "#C7BFFC",
              label: "Frameworks",
            },
          ],
          totalValue: 4,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
        },
      ];
    }
    setDonutData(donutData || []);
  }, [impactedDataStores, aiSummary]);

  const theme = useTheme();
  const styles = {
    donutCardContainer: {
      position: "relative",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      width: "70%",
      display: "flex",
    },
    allDataImpactedText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
      maxWidth: "5rem",
    },
    sensitiveDataText: {
      color: theme.palette.surface70.main,
      fontWeight: theme.typography.fontWeightRegular,
      maxWidth: "9.375rem",
    },
    highlightDataText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    legendContainer: {
      position: "absolute",
      bottom: 0,
      right: 0,
      display: "flex",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      alignItems: "center",
      zIndex: 20,
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: theme.palette.surface40.main,
      marginY: 0,
    },
    legendGroup: {
      display: "flex",
      gap: theme.spacing(3),
    },
    totalDataStore: {
      opacity: 0.5,
    },
  };

  const donutLegends = [
    {
      label: "Critical",
      color: theme.palette.critical.main,
    },
    {
      label: "High",
      color: theme.palette.high.main,
    },
    {
      label: "Shadow Data",
      color: theme.palette.lightMagenta.main,
    },
    {
      label: "Shadow AI/LLM",
      color: theme.palette.lightBlue.main,
    },
  ];

  return (
    <Box sx={styles.donutCardContainer}>
      {isFetchingImpactedStoresData ? (
        <Loader />
      ) : (
        <>
          {donutData.map(
            (
              {
                cardTitle,
                donutData,
                totalValue,
                defaultColor,
                variant,
                innerRadius,
                outerRadius,
                boxSize,
                donutHeight,
                donutWidth,
              },
              index
            ) => (
              <ImpactedDataCard
                cardTitle={cardTitle}
                donutData={donutData}
                totalValue={totalValue}
                defaultColor={defaultColor}
                variant={variant}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                boxSize={boxSize}
                donutHeight={donutHeight}
                donutWidth={donutWidth}
                key={index}
              />
            )
          )}
          {dashboardViewLense === DASHBOARD_DATA_VIEW && (
            <Box sx={styles.legendContainer}>
              <Typography
                variant="caption"
                color={theme.palette.surface80.main}
                sx={styles.totalDataStore}
              >
                Total Data Store: {impactedDataStores?.datastores || 0}
              </Typography>
              <Divider
                orientation="vertical"
                variant="middle"
                sx={styles.dividerStyles}
              />

              <Box sx={styles.legendGroup}>
                {donutLegends.map((legend, index) => (
                  <LegendWithColor key={index} {...legend} />
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ImpactedDataStoresPanel;
